import { useEffect,useState } from 'react';
import { useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { useHistory } from 'react-router';
import { useAppSelector } from 'hooks';
import { IFilterParams } from 'models/filters';
import AdminTopPanel from 'adminComponents/AdminTopPanel/AdminTopPanel';
import Pagination from 'adminComponents/Pagination/Pagination';
import EmailStorageTable from './EmailStorageTable/EmailStorageTable';
import { emailStorageTableHead } from './constants';
import { Spinner } from 'react-bootstrap';
import {
    fetchEmailsStorageRequestAction,
    getAdminEmailsStorageExportToCsvRequestAction,
    cleanNewNotificationMessageAction
} from 'store/actions/adminEmailsStorage/adminEmailsStorage.action';
import Paths from 'constants/paths';
import Alert from 'sharedComponents/Alert';

const EmailStorage = () => {
    const [filterParams] = useState<IFilterParams>({
        page: 1,
        searchWord: '',
        categoryId: '',
    });
    const history = useHistory();
    const dispatch = useDispatch();
    const loading = useAppSelector(state => state.adminEmailsStorage.loading);
    const page = useAppSelector(state => state.adminEmailsStorage.page);
    const message = useAppSelector(state => state.adminEmailsStorage.message);
    const error = useAppSelector(state => state.adminEmailsStorage.error);
    const lastPage: number =useAppSelector(state => state.adminEmailsStorage.totalNumber)
    const csv = useAppSelector(state => state.adminEmailsStorage.csv);
    useEffect(() => {
        const { page, categoryId, searchWord } = filterParams as IFilterParams;
        dispatch(fetchEmailsStorageRequestAction(page, searchWord, categoryId));
        return () => {
            dispatch(cleanNewNotificationMessageAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterParams]);
    
    useEffect(() => {
        dispatch(getAdminEmailsStorageExportToCsvRequestAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const emailsList = useAppSelector(state => state.adminEmailsStorage.items);
    const exportCSV = () => {
        if (!csv) return;
        const fileData = new Blob([csv], { type: 'text/csv' });
        saveAs(fileData, 'emails storage');
    };

    const changePageHandler = (newPage: number) => {
        const { categoryId, searchWord } = filterParams as IFilterParams;
        dispatch(fetchEmailsStorageRequestAction(newPage+1, searchWord, categoryId));
    };
    return (
        <> 
            {error && <Alert message={error} isError={true} />}
            {message && <Alert message={message} isError={false} />}
            <AdminTopPanel
                butonText={'New Notification'}
                buttonOnClick={() => history.push(Paths.ADMIN_NEW_NOTIFICATION)}
                exportButtonOnClick={exportCSV}
            />
            {loading ? (
                <div className="container-fluid d-flex justify-content-center align-items-center mt-5">
                    <Spinner animation="border" />
                </div>
            ) : (
            <div className="App-table-default-wrapper">
                <div className="table-responsive">
                    <EmailStorageTable
                        tableHead={emailStorageTableHead}
                        tableBody={emailsList}
                        params={filterParams}
                        page={page}
                    />
                </div>
                <Pagination
                    currentPage={page -1}
                    changePageHandler={changePageHandler}
                    lastPage={lastPage}
                />
            </div>)}
        </>
    );
};

export default EmailStorage;

import {
    IInitialStoriesValue,
    INewStory,
} from 'adminComponents/NewStory/NewStoryFrom/constants';
import { AxiosResponse } from 'axios';
import { axiosInstance } from 'services/interceptor';

export const getStoryDetailsEndpoint = (
    id: string,
): Promise<AxiosResponse<IInitialStoriesValue>> => {
    return axiosInstance.get(`admin/stories/${id}`);
};

export const editStoryEndpoin = (
    newStory: INewStory | FormData,
    id: string,
): Promise<AxiosResponse> => {
    return axiosInstance.put(`/admin/stories/${id}`, newStory, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

import Paths from 'constants/paths';
import { useAppSelector } from 'hooks';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { fieldesNameType, routes } from 'routes/config';
import { loginUserSuccess } from 'store/actions/adminAuth/auth.actions';

const AdminRouter = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useAppSelector(state => state.auth.adminToken);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            dispatch(loginUserSuccess(token));
        } else {
            history.push(Paths.ADMIN_LOGIN);
        }
    }, [dispatch, history, auth]);

    return auth ? (
        <Switch>
            {routes
                .filter(rout => rout.fieldName === fieldesNameType.ADMIN_PRIVATE)
                .map(({ path, component: Component, exact }) => (
                    <Route path={path} exact={exact} key={path}>
                        <Component />
                    </Route>
                ))}
            <Redirect to={Paths.ADMIN_LIST} />
        </Switch>
    ) : (
        <Switch>
            {routes
                .filter(rout => rout.fieldName === fieldesNameType.ADMIN_PUBLIC)
                .map(({ path, component: Component, exact }) => (
                    <Route path={path} exact={exact} key={path}>
                        <Component />
                    </Route>
                ))}
        </Switch>
    );
};

export default AdminRouter;

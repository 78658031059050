import { TMapDataFilters, TMapItem } from 'types/map';

export const selectMapItemsByFilter = (mapItems: TMapItem[], filter: TMapDataFilters) => {
    if (!mapItems?.length) {
        return null;
    }
    if (!filter) {
        return mapItems;
    }
    switch (filter) {
        case 'story': {
            return mapItems.filter((item) => item.type === 1);
        }
        case 'kiosk': {
            return mapItems.filter((item) => item.type === 2);
        }
        case 'cloud': {
            return mapItems.filter((item) => item.type === 3);
        }
        default: {
            return mapItems;
        }
    }
};

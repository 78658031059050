import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import ArrowIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import styles from './LanguageSelect.module.scss';
import { useLocation } from 'react-router-dom';

type TProps = {
    variant?: 'default' | 'podcast' | 'story' | 'map';
    french?: boolean;
    showLang?: string[];
};

const LanguageSelect: FC<TProps> = ({ variant = 'default', french = true, showLang }) => {
    const location = useLocation();
    const { t } = useTranslation();

    const handleLanguageChange = (lang: 'en' | 'ar' | 'fr') => {
        if (localStorage.getItem('i18nextLng') === lang) {
            return;
        }
        document.documentElement.lang = lang;
        localStorage.setItem('i18nextLng', lang);
        window.location.replace(location.pathname);
    };

    return (
        <>
            {!french ? (
                <div className={styles.languageSelectWrapper}>
                    <div
                        className={cn(styles.languageSelect, variant, {
                            [styles.french]: french,
                        })}>
                        <div className={styles.textWrapper}>
                            <span className={styles.title}>{t('bookmark')}</span>
                            {variant !== 'map' && (
                                <ArrowIcon className={styles.arrowIcon} />
                            )}
                        </div>
                    </div>
                    <div className={styles.stripsWrapper}>
                        <div
                            className={styles.stripsItem}
                            onClick={() => handleLanguageChange('ar')}>
                            العربية
                        </div>
                        <div
                            className={styles.stripsItem}
                            onClick={() => handleLanguageChange('en')}>
                            English
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {showLang?.length !== 0 && (
                        <div className={styles.languageSelectWrapper}>
                            <div
                                className={cn(styles.languageSelect, variant, {
                                    [styles.french]: french,
                                })}>
                                <div className={styles.textWrapper}>
                                    <span className={styles.title}>{t('bookmark')}</span>
                                    {variant !== 'map' && (
                                        <ArrowIcon className={styles.arrowIcon} />
                                    )}
                                </div>
                            </div>
                            <div className={styles.stripsWrapper}>
                                <div
                                    className={styles.stripsItem}
                                    onClick={() => handleLanguageChange('ar')}>
                                    العربية
                                </div>
                                {showLang?.includes('en') && (
                                    <div
                                        className={styles.stripsItem}
                                        onClick={() => handleLanguageChange('en')}>
                                        English
                                    </div>
                                )}
                                {french && showLang?.includes('fr') && (
                                    <div
                                        className={styles.stripsItem}
                                        onClick={() => handleLanguageChange('fr')}>
                                        Français
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default LanguageSelect;

enum Paths {
    HOME = '/',
    EMAIL_SUBSCRIPTION = '/email-subscription',
    MAP = '/map',
    PUBLISHER = '/publisher/:id',
    STORY_CATEGORIES = '/SHS',
    STORY = '/SHS/:categoryId/:storyId',
    STORY_STATISTICS = '/SHS/statistics',
    PODCAST_CATEGORIES = '/LC',
    PODCAST = '/LC/:categoryId/:podcastId',
    PODCAST_STATISTICS = '/LC/statistics',
    PODCAST_PUBLISHER = '/LC-publisher/:id',
    CHILDREN_STORY_CATEGORIES = '/children/SHS',
    CHILDREN_STORY = '/children/SHS/:categoryId/:storyId',

    ADMIN_LOGIN = '/admin',
    ADMIN_LIST = '/admin/admin-list',
    ADMIN_STORIES = '/admin/SHS',
    ADMIN_PODCASTS = '/admin/LC',
    ADMIN_KIOSKS = '/admin/kiosks',
    ADMIN_KIOSK_DETAIL = '/admin/kiosks/:id',
    ADMIN_MAP_DATA = '/admin/map-data',
    ADMIN_MAP_DATA_DETAIL = '/admin/map-data/:id',
    ADMIN_EMAIL_STORAGE = '/admin/email-storage',
    ADMIN_NEW_ADMIN = '/admin/new-admin',
    ADMIN_NEW_STORY = '/admin/new-SHS',
    ADMIN_NEW_NOTIFICATION = '/admin/new-notification',
    ADMIN_STORY_DETAIL = '/admin/SHS/:id',
    ADMIN_PODCAST_DETAIL = '/admin/LC/:id',
    ADMIN_NEW_PODCAST = '/admin/new-LC',
    ADMIN_NEW_KIOSK = '/admin/new-kiosk',
    ADMIN_NEW_MAP_DATA = '/admin/new-map-data',
    CHANGE_PASSWORD = '/admin/change-password',

    PARTNER = 'https://engage.moc.gov.sa/literary_partner/',
}

export default Paths;

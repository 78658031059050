import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import './i18n';
import { store } from 'store/store';
import history from 'routes/history';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-210816269-1');

ReactDOM.render(
    <Suspense fallback={<div></div>}>
        <React.StrictMode>
            <Provider store={store}>
                <Router history={history}>
                    <App />
                </Router>
            </Provider>
        </React.StrictMode>
    </Suspense>,
    document.getElementById('root'),
);

reportWebVitals();

import { AdminMapDataTypes, IMapDataListItem } from './adminMapData.types';
import { INewLocation } from 'adminPages/NewMapDataPage/NewLocationForm/constans';
import { IFilterParams } from 'models/filters';
import { IPaginationResponse } from 'services/types';

export const fetchMapDataRequestAction = (
    page: number = 1,
    searchWord?: string,
    categoryId?: string
    ) => ({
    type: AdminMapDataTypes.FETCH_ADMIN_MAP_DATA_REQUEST,
    payload: { page, searchWord, categoryId },
});


export const fetchMapDataSuccessAction = (
    mapDataResponse: IPaginationResponse<IMapDataListItem>,
) => ({
    type: AdminMapDataTypes.FETCH_ADMIN_MAP_DATA_SUCCESS,
    payload: mapDataResponse,
});

export const fetchMapDataFailureAction = (message: string) => ({
    type: AdminMapDataTypes.FETCH_ADMIN_MAP_DATA_FAILURE,
    payload: message,
});

export const postNewLocationRequestActionType = (
    newLocation: INewLocation | FormData,
) => ({
    type: AdminMapDataTypes.POST_NEW_MAP_DATA_REQUEST,
    payload: newLocation,
});
export const postNewLocationSuccessAction = (message: string) => ({
    type: AdminMapDataTypes.POST_NEW_MAP_DATA_SUCCESS,
    payload: message,
});
export const postNewLocationFailureAction = (message: string) => ({
    type: AdminMapDataTypes.POST_NEW_MAP_DATA_FAILURE,
    payload: message,
});
export const cleanNewLocationMessageAction = () => ({
    type: AdminMapDataTypes.CLEAN_NEW_MAP_MESSAGE,
});

export const deleteAdminEmailRequestAction = (
    locationId: string,
    params: IFilterParams,
) => ({
    type: AdminMapDataTypes.DELETE_ADMIN_MAP_DATA_REQUEST,
    payload: {
        locationId,
        params,
    },
});
export const deleteAdminEmailFailureAction = (error: string) => ({
    type: AdminMapDataTypes.DELETE_ADMIN_MAP_DATA_FAILURE,
    payload: error,
});

export const deleteAdminEmailSuccessAction = () => {
    return {
        type: AdminMapDataTypes.DELETE_ADMIN_MAP_DATA_SUCCESS,
    };
};

import { call, all, takeLatest, put } from 'redux-saga/effects';
import {
    activateAdminRequestActionType,
    AddNewAdminRequestActionType,
} from './../actions/adminList/adminList.types';
import * as AdminEndpoints from 'services/admin.services/admins/admins.endpoints';
import {
    AdminListsTypes,
    DeactivateAdminRequestActionType,
    FetchAdminsRequestActionType,
    IAdminListItem,
} from 'store/actions/adminList/adminList.types';
import * as AdminListActions from 'store/actions/adminList/adminsList.action';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IAdminCreateError } from 'services/admin.services/admins/admins.types';
import { IPaginationResponse } from 'services/types';
import { fetchAdminsFailureAction } from 'store/actions/adminList/adminsList.action';

function* fetchAdminListWorker(action: FetchAdminsRequestActionType) {
    const page = action.payload;
    try {
        const { data }: { data: IPaginationResponse<IAdminListItem> } = yield call(
            AdminEndpoints.getAdminListEndpoin,
            page,
        );
        yield put(AdminListActions.fetchAdminsSuccessAction(data));
    } catch (error: any) {
        yield put(fetchAdminsFailureAction(error.message));
    }
}

function* deactivateAdminWorker(action: DeactivateAdminRequestActionType) {
    const id = action.payload.adminId;
    const page = action.payload.page;

    try {
        yield call(AdminEndpoints.deactivateAdminStatusEndpoint, id, page);
        yield put(AdminListActions.deactivateAdminSuccessAction());
        yield put(AdminListActions.fetchAdminsRequestAction(page));
    } catch (error: any) {
        // yield put(deactivateAdminFailureAction(error.message));
    }
}
function* activateAdminWorker(action: activateAdminRequestActionType) {
    const id = action.payload.adminId;
    const page = action.payload.page;

    try {
        yield call(AdminEndpoints.activateAdminStatusEndpoint, id, page);
        yield put(AdminListActions.activateAdminSuccessAction());
        yield put(AdminListActions.fetchAdminsRequestAction(page));
    } catch (error: any) {
        // yield put(activateAdminFailureAction(error.message));
    }
}

function* addNewAdminWorker(action: AddNewAdminRequestActionType) {
    const admin = action.payload;

    try {
        yield call(AdminEndpoints.createNewAdminEndpoint, admin);
        yield put(AdminListActions.addNewAdminSuccessAction('Created Successfully'));
    } catch (error: IAdminCreateError | unknown) {
        const newAdminErr = error as IAdminCreateError;
        yield put(AdminListActions.addNewAdminFailureAction(newAdminErr.message));
    }
}

export function* adminsListSaga() {
    yield all([
        takeLatest(AdminListsTypes.FETCH_ADMINS_REQUEST, fetchAdminListWorker),
        takeLatest(AdminListsTypes.DEACTIVATE_ADMIN_REQUEST, deactivateAdminWorker),
        takeLatest(AdminListsTypes.ACTIVATE_ADMIN_REQUEST, activateAdminWorker),
        takeLatest(AdminListsTypes.ADD_NEW_ADMIN_REQUEST, addNewAdminWorker),
    ]);
}

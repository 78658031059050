import { NewStoryForm } from 'adminComponents/NewStory/NewStoryFrom';
import { IInitialStoriesValue } from 'adminComponents/NewStory/NewStoryFrom/constants';
import { useAppSelector } from 'hooks';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import { serialize } from 'object-to-formdata';
import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import Alert from 'sharedComponents/Alert';
import { getAdminsStoriesCategoriesRequestAction } from 'store/actions/adminStories/adminStories.action';
import {
    cleanStoryMessageAction,
    editStoryRequestAction,
    fetchStoryDetailsRequestAction,
} from 'store/actions/adminStoryDetails/storyDetails.action';
import { difference } from 'utils/helpers';

const AdminStoryDetails = () => {
    const { id } = useParams<{ id: string }>();
    const storyDetails = useAppSelector(store => store.adminStoryDetails.storyDetails);
    const loading = useAppSelector(store => store.adminStoryDetails.loading);
    const categories = useAppSelector(state => state.adminStories.storyCategories);
    const message = useAppSelector(state => state.adminStoryDetails.message);
    const error = useAppSelector(state => state.adminStoryDetails.error);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAdminsStoriesCategoriesRequestAction());
        return () => {
            dispatch(cleanStoryMessageAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(fetchStoryDetailsRequestAction(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const onSubmit = (values: IInitialStoriesValue) => {
        const formData = serialize(difference(storyDetails!, values));
        dispatch(editStoryRequestAction(formData, id));
    };

    return (
        <AdminLayout title={'Story Details'}>
            {message && <Alert message={message} isError={false} />}
            {error && <Alert message={error} isError={true} />}
            {loading ? (
                <div className="container-fluid d-flex justify-content-center align-items-center h-100">
                    <Spinner animation="border" />
                </div>
            ) : (
                storyDetails && (
                    <NewStoryForm
                        categories={categories}
                        initialValues={storyDetails}
                        onSubmit={onSubmit}
                        buttonText={'Edit'}
                    />
                )
            )}
        </AdminLayout>
    );
};

export default AdminStoryDetails;

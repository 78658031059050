import React from 'react';
import styles from './AdminLoginLayout.module.scss';

interface IAdminLayoutProps {
    children: React.ReactNode;
}

const AdminLoginLayout: React.FC<IAdminLayoutProps> = ({ children }) => (
    <div className={styles.adminLoginPage}>
        <div></div>
        {children}
        <div className={styles.copyright}>
            Copyright - The Literature, Publishing & Translation Commission ©{' '}
            {new Date().getFullYear()}
        </div>
    </div>
);

export default AdminLoginLayout;

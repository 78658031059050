import { IChangePasswordFields } from 'adminPages/ChangePassword/constants';
import { Field, Form, Formik } from 'formik';
import { useAppSelector } from 'hooks';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ChangePasswordBody } from 'services/admin.services/auth/auth.types';
import Alert from 'sharedComponents/Alert';
import { ChangePasswordRequest } from 'store/actions/adminAuth/auth.actions';
import { changePasswordValidationSchema } from 'utils/validationSchemas';
import styles from './ChangePasswordForm.module.scss';

type IInitialValues = ChangePasswordBody;
const initialValues: IInitialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
};
interface IChangePasswordForm {
    fields: IChangePasswordFields[];
}
const ChangePasswordForm = ({ fields }: IChangePasswordForm) => {
    const dispatch = useDispatch();
    const { loginError, message } = useAppSelector(state => state.auth);
    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={values => {
                    dispatch(ChangePasswordRequest(values));
                }}
                validationSchema={changePasswordValidationSchema}>
                {({ values, errors, touched }) => (
                    <Form className={styles.formWrapper}>
                        {loginError && <Alert message={loginError} />}
                        {message && <Alert message={message} isError={false} />}
                        {fields.map(el => (
                            <React.Fragment key={el.name}>
                                <label
                                    className="form-label d-flex align-items-start flex-column"
                                    key={el.name}>
                                    {el.label}
                                    <Field
                                        className="form-control mt-1"
                                        type="password"
                                        name={el.name}
                                        placeholder={el.label}
                                    />
                                </label>
                                {touched[el.name as keyof IInitialValues] &&
                                    errors[el.name as keyof IInitialValues] && (
                                        <div className="text-danger">
                                            {errors[el.name as keyof IInitialValues]}
                                        </div>
                                    )}
                            </React.Fragment>
                        ))}
                        <button
                            className="btn btn-secondary align-self-end mt-2"
                            type="submit"
                            disabled={
                                Object.keys(errors).length > 0 &&
                                !Object.keys(touched).length
                            }>
                            Save
                        </button>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ChangePasswordForm;

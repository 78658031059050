import { Route, Switch } from 'react-router-dom';
import { fieldesNameType, routes } from 'routes/config';

const PortalRouter = () => {
    const portalPrivateRoutes = routes.filter(
        rout => rout.fieldName === fieldesNameType.PRIVATE,
    );


    return (
        <Switch>
            {portalPrivateRoutes.map(({ path, component: Component, ...props }) => (
                <Route path={path} {...props} key={path}>
                    <Component />
                </Route>
            ))}
        </Switch>
    );
};

export default PortalRouter;

import classNames from 'classnames';
import React from 'react';

import styles from './Pagination.module.scss';

interface IPagination {
    currentPage: number;
    lastPage: number;
    changePageHandler: (page: number) => void;
}

interface IPageLink {
    value: number | string;
    isActive: boolean;
    class: string;
    onClick?: () => void;
}

const PREVIOUS = 'Previous';
const NEXT = 'Next';

const Pagination: React.FC<IPagination> = ({
    currentPage,
    lastPage,
    changePageHandler,
}) => {
    const nextPage: boolean = currentPage < lastPage - 1;
    const previousPage: boolean = currentPage > 0;

    const pageLinks: IPageLink[] = [
        {
            value: currentPage - 1,
            class: previousPage ? '' : 'disabled',
            isActive: previousPage,
            onClick: () => changePage(PREVIOUS),
        },
        {
            value: currentPage,
            class: `active ${'disabled'}`,
            isActive: true,
        },
        {
            value: currentPage + 1,
            class: nextPage ? '' : 'disabled',
            isActive: nextPage,
            onClick: () => changePage(NEXT),
        },
    ];

    const changePage = (condition: string): void => {
        if (condition === NEXT && nextPage) {
            changePageHandler(currentPage + 1);
        } else if (condition === PREVIOUS && previousPage) {
            changePageHandler(currentPage - 1);
        }
    };

    return lastPage ? (
        <div className="w-100 d-flex justify-content-center mt-4 flex-column align-items-center">
            <nav aria-label="Page navigation example">
                <ul
                    className={classNames(
                        'pagination justify-content-end',
                        styles.paginationContainer,
                    )}>
                    {
                        <li
                            className={classNames(
                                `page-item ${previousPage ? '' : 'disabled'}`,
                            )}>
                            <button
                                className={classNames('page-link', styles.pageLink)}
                                onClick={() => changePage(PREVIOUS)}>
                                {PREVIOUS}
                            </button>
                        </li>
                    }
                    {pageLinks.map(
                        (item, index) =>
                            item.isActive && (
                                <li
                                    className={classNames(
                                        `page-item ${item.class || ''}`,
                                    )}
                                    key={index}>
                                    <button
                                        className={classNames(
                                            'page-link',
                                            styles.pageLink,
                                        )}
                                        onClick={item?.onClick}>
                                        {(item.value as number) + 1}
                                    </button>
                                </li>
                            ),
                    )}
                    {
                        <li
                            className={classNames(
                                `page-item ${nextPage ? '' : 'disabled'}`,
                            )}>
                            <button
                                className={classNames('page-link', styles.pageLink)}
                                onClick={() => changePage(NEXT)}>
                                {NEXT}
                            </button>
                        </li>
                    }
                </ul>
            </nav>
            <span>{`Page ${currentPage + 1} of ${lastPage}`}</span>
        </div>
    ) : null;
};

export default Pagination;

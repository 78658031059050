import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    editStoryRequestActionType,
    StoryDetailsTypes,
} from '../actions/adminStoryDetails/storyDetails.types';
import { fetchStoryDetailsRequestActionType } from 'store/actions/adminStoryDetails/storyDetails.types';
import * as StoryDetailsEndpoints from 'services/admin.services/adminStories/storyDetails.endpoint';
import * as storyDetailsAction from '../actions/adminStoryDetails/storyDetails.action';
import { IInitialStoriesValue } from 'adminComponents/NewStory/NewStoryFrom/constants';

function* fetchStoryDetailsWorker(action: fetchStoryDetailsRequestActionType) {
    const storyId = action.payload;
    try {
        const { data }: { data: IInitialStoriesValue } = yield call(
            StoryDetailsEndpoints.getStoryDetailsEndpoint,
            storyId,
        );
        const initialValue = {
            titleEn: data.titleEn,
            titleAr: data.titleAr,
            titleFr: data.titleFr,
            storyDurationEn: data.storyDurationEn,
            storyDurationAr: data.storyDurationAr,
            storyDurationFr: data.storyDurationFr,
            imageEn: data.imageEn,
            imageAr: data.imageAr,
            imageFr: data.imageFr,
            categoryId: data.category?.id,
            storyType: data.storyType,
            storyTextEn: data.storyTextEn,
            storyTextAr: data.storyTextAr,
            storyTextFr: data.storyTextFr,
            bookNameEn: data.bookNameEn,
            bookNameAr: data.bookNameAr,
            bookNameFr: data.bookNameFr,
            authorEn: data.authorEn,
            authorAr: data.authorAr,
            authorFr: data.authorFr,
            publisherNameEn: data.publisherNameEn,
            publisherNameAr: data.publisherNameAr,
            publisherNameFr: data.publisherNameFr,
            publisherImageEn: data.publisherImageEn,
            publisherImageAr: data.publisherImageAr,
            publisherImageFr: data.publisherImageFr,
            publisherLinkEn: data.publisherLinkEn || undefined,
            publisherLinkAr: data.publisherLinkAr || undefined,
            publisherLinkFr: data.publisherLinkFr || undefined,
            publisherDetailsEn: data.publisherDetailsEn,
            publisherDetailsAr: data.publisherDetailsAr,
            publisherDetailsFr: data.publisherDetailsFr,
            moreInfoEn: data.moreInfoEn,
            moreInfoAr: data.moreInfoAr,
            moreInfoFr: data.moreInfoFr,
            darkLogo: data.darkLogo,
            translateEn: data.translateEn,
            translateFr: data.translateFr

        } as IInitialStoriesValue;
        yield put(storyDetailsAction.fetchStoryDetailsSuccessAction(initialValue));
    } catch (error: any) {
        yield put(storyDetailsAction.fetchStoryDetailsFailureAction(error.message));
    }
}

function* editStoryWorker(action: editStoryRequestActionType) {
    const storyId = action.payload.id;
    const newStory = action.payload.newStory;

    try {
        yield call(StoryDetailsEndpoints.editStoryEndpoin, newStory, storyId);
        yield put(storyDetailsAction.editStorySuccessAction('Updated Successfully'));
        yield put(storyDetailsAction.fetchStoryDetailsRequestAction(storyId));
    } catch (error: any) {
        yield put(storyDetailsAction.editStoryFailureAction(error.message));
    }
}

export function* adminStoryDetailsSaga() {
    yield all([
        takeLatest(StoryDetailsTypes.FETCH_STORY_DETAIL_REQUEST, fetchStoryDetailsWorker),
        takeLatest(StoryDetailsTypes.EDIT_STORY_REQUEST, editStoryWorker),
    ]);
}

import _ from 'lodash';

export const getUnixTimeFormat = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time - minutes * 60);
    const totalMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const totalSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${totalMinutes}: ${totalSeconds}`;
};

export const getEngOrArData = <T>(property: string, object: T) => {
    if (object) {
        const arProperty = `${property}Ar` as keyof T;
        const enProperty = `${property}En` as keyof T;
        return object[arProperty] || object[enProperty];
    }
};
export const getEngOrArOrFrData = <T>(property: string, object: T) => {
    if (object) {
        const arProperty = `${property}Ar` as keyof T;
        const enProperty = `${property}En` as keyof T;
        const frProperty = `${property}Fr` as keyof T;
        return object[arProperty] || object[enProperty] || object[frProperty];
    }
};

export const paragraphIsBlank = (paragraph: string) => {
    let isBlank = true;
    if (!paragraph) return isBlank;
    const deserializedParagraph = JSON.parse(paragraph);
    deserializedParagraph.forEach((element: any) => {
        if (element.children[0].text) isBlank = false;
    });
    return isBlank;
};

export const existingValues = (obj: object) => {
    return Object.keys(obj).reduce((acc, el) => {
        if (obj[el as keyof object]) {
            return {
                ...acc,
                [el]: obj[el as keyof object],
            };
        } else {
            return acc;
        }
    }, {});
};

export const difference = (origObj: object, newObj: object) => {
    const changes = (newObj: object, origObj: object) => {
        let arrayIndexCounter = 0;
        return _.transform(newObj, (result: any, value, key) => {
            if (!_.isEqual(value, origObj[key])) {
                let resultKey = _.isArray(origObj) ? arrayIndexCounter++ : key;
                result[resultKey] =
                    _.isObject(value) && _.isObject(origObj[key])
                        ? changes(value, origObj[key])
                        : value;
                result[resultKey] = result[resultKey] === '' ? 'null' : result[resultKey];
            }
        });
    };
    return changes(newObj, origObj);
};

export const websiteLinkGenerator = (categoryId: string) => {
    const baseUrl = 'https://lew.moc.gov.sa';
    const itemType = window.location.pathname.split('/')[2];
    const itemId = window.location.pathname.split('/')[3];
    return `${baseUrl}/${itemType}/${categoryId}/${itemId}`;
};

export const disableSubmit = (e: any) => {
    if ((e.charCode || e.keyCode) === 13) {
        e.preventDefault();
    }
};
export const getDifferentValues = (obj1: any, obj2: any) => {
    const newObj: any = {};
    for (const key of Object.keys(obj1)) {
        if (obj2[key] !== obj1[key]) {
            newObj[key] = obj2[key];
        }
    }
    return newObj;
};

import React from 'react';
import styles from './AdminLoginForm.module.scss';
import { ReactComponent as ReactLogo } from '../../assets/images/LPTC_Logo.svg';
import { Field, Form, Formik } from 'formik';
import { LoginValidationSchema } from 'utils/validationSchemas';
import { useDispatch } from 'react-redux';
import { loginUserRequest } from 'store/actions/adminAuth/auth.actions';
import Alert from 'sharedComponents/Alert';
import { useAppSelector } from 'hooks';

const initialValue = {
    email: '',
    password: '',
};

const AdminLoginForm = () => {
    const dispatch = useDispatch();
    const { loginError } = useAppSelector(state => state.auth);
    return (
        <div className={styles.loginForm}>
            <div className={styles.logoContainer}>
                <ReactLogo className={styles.logo} />
            </div>
            <br />
            <p>Enter your email and password to access control panel.</p>
            {loginError && <Alert message={loginError} />}
            <div className={styles.loginFormMain}>
                <Formik
                    onSubmit={(values, { resetForm }) => {
                        dispatch(loginUserRequest(values));
                        resetForm();
                    }}
                    initialValues={initialValue}
                    validationSchema={LoginValidationSchema}>
                    {({ errors, touched }) => (
                        <Form>
                            <div className={styles.formGroup}>
                                <div className="mb-3 position-relative pb-1">
                                    <label className="form-label d-flex align-items-start flex-column">
                                        Email
                                        <Field
                                            type="email"
                                            name="email"
                                            className="form-control mt-2"
                                        />
                                    </label>
                                    {errors.email && touched.email ? (
                                        <div className={styles.error}>{errors.email}</div>
                                    ) : null}
                                </div>
                                <div className="position-relative pb-1">
                                    <label className="form-label d-flex align-items-start flex-column">
                                        Password
                                        <Field
                                            type="password"
                                            name="password"
                                            className="form-control mt-2"
                                        />
                                    </label>
                                    {errors.password && touched.password ? (
                                        <div className={styles.error}>
                                            {errors.password}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <button type="submit" className={styles.submitBtn}>
                                Log In
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default AdminLoginForm;

import React from 'react';

interface IDangerAlert {
    message: string;
    isError?: boolean;
}

const Alert: React.FC<IDangerAlert> = ({ message, isError = true }) => {
    const alertStyles = isError ? 'alert alert-danger' : 'alert alert-success';
    return (
        <div className={alertStyles} role="alert">
            {message}
        </div>
    );
};

export default Alert;

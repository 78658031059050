import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import background from 'assets/images/headerBg.svg';
import Button from 'portalComponents/Button';
import Paths from 'constants/paths';
import socialMedia from 'portalComponents/Footer/constants';
import { Link } from 'react-router-dom';
import footerLogo from 'assets/images/footerLogoLight.svg';
import styles from './DarkFooter.module.scss';

type TProps = {
    showSubscribeButton: boolean;
}

const DarkFooter: FC<TProps> = ({
    showSubscribeButton,
}) => {
    const { t } = useTranslation();

    return (
        <div
            className={classNames(styles.footer, {
                [styles.footerWithSubscribe]: showSubscribeButton,
            })}
        >
            <img
                src={background}
                alt="Footer background"
                className={styles.footerBg}
            />
            {showSubscribeButton && (
                <div className={styles.buttonSectionMain}>
                    <Link to={Paths.EMAIL_SUBSCRIPTION}>
                        <Button width="fixedLarge" fontSize="small">
                            {t('footer.registerButton')}
                        </Button>
                    </Link>

                </div>
            )}
            <div className={styles.socialMedia}>
                {socialMedia.map(({ mediaIcon, mediaLink }) => (
                    <a
                        href={mediaLink}
                        key={`${mediaIcon}-${mediaLink}`}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <img src={mediaIcon} alt="social media" />
                    </a>
                ))}
            </div>
            <Link to={Paths.HOME}>
                <img src={footerLogo} alt="Footer logo" className={styles.footerLogo} />
            </Link>
        </div>
    );
};

export default DarkFooter;

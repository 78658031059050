import { IPaginationResponse } from 'services/types';
import { INewNotification } from 'services/admin.services/adminEmailsStorage/adminEmailsStorage.types';
import { IFilterParams } from 'models/filters';
export interface IEmailStorageListItem {
    id: string;
    email: string;
    phone: string;
    name: string;
    createdAt: string;
    isDisabled:boolean;
}

export type IInitialState = IPaginationResponse<IEmailStorageListItem> & {
    csv: string;
    loading: boolean;
    error: string;
    message?: string;
};

export enum AdminEmailsStorageTypes {
    FETCH_EMAILS_STORAGE_DATA_REQUEST = 'FETCH_EMAILS_STORAGE_DATA_REQUEST',
    FETCH_EMAILS_STORAGE_DATA_SUCCESS = 'FETCH_EMAILS_STORAGE_DATA_SUCCESS',
    FETCH_EMAILS_STORAGE_DATA_FAILURE = 'FETCH_EMAILS_STORAGE_DATA_FAILURE',

    EMAILS_STORAGE_EXPORT_REQUEST = 'EMAILS_STORAGE_EXPORT_REQUEST',
    EMAILS_STORAGE_EXPORT_SUCCESS = 'EMAILS_STORAGE_EXPORT_SUCCESS',
    EMAILS_STORAGE_EXPORT_FAILURE = 'EMAILS_STORAGE_EXPORT_FAILURE',

    ADD_NEW_NOTIFICATION_REQUEST = 'ADD_NEW_NOTIFICATION_REQUEST',
    ADD_NEW_NOTIFICATION_SUCCESS = 'ADD_NEW_NOTIFICATION_SUCCESS',
    ADD_NEW_NOTIFICATION_FAILURE = 'ADD_NEW_NOTIFICATION_FAILURE',

    CLEAN_NEW_NOTIFICATION_MESSAGE = 'CLEAN_NEW_NOTIFICATION_MESSAGE',

    DELETE_ADMIN_EMAIL_REQUEST = 'DELETE_ADMIN_EMAIL_REQUEST',
    DELETE_ADMIN_EMAIL_SUCCESS = ' DELETE_ADMIN_EMAIL_SUCCESS',
    DELETE_ADMIN_EMAIL_FAILURE = ' DELETE_ADMIN_EMAIL_FAILURE',

    ACTIVATE_ADMIN_EMAIL_REQUEST = 'ACTIVATE_ADMIN_EMAIL_REQUEST',
    ACTIVATE_ADMIN_EMAIL_SUCCESS = 'ACTIVATE_ADMIN_EMAIL__SUCCESS',
    ACTIVATE_ADMIN_EMAIL_FAILURE = ' ACTIVATE_ADMIN_EMAIL_FAILURE',

    DEACTIVATE_ADMIN_EMAIL_REQUEST = 'DEACTIVATE_ADMIN_EMAIL_REQUEST',
    DEACTIVATE_ADMIN_EMAIL_SUCCESS = 'DEACTIVATE_ADMIN_EMAIL_SUCCESS',
    DEACTIVATE_ADMIN_EMAIL_FAILURE = 'DEACTIVATE_ADMIN_EMAIL_FAILURE',
}

export interface FetchAdminEmailsStorageRequestActionType {
    type: AdminEmailsStorageTypes.FETCH_EMAILS_STORAGE_DATA_REQUEST;
    payload: { page: number; categoryId: string; searchWord: string; storyType:number;};
}

export interface FetchAdminEmailsStorageRequestSuccessActionType {
    type: AdminEmailsStorageTypes.FETCH_EMAILS_STORAGE_DATA_SUCCESS;
    payload: {
        curPage: number;
        emails: IEmailStorageListItem[];
        totalDocs: number;
        totalPages: number;
    };
}

export interface FetchAdminEmailsStorageRequestFailureActionType {
    type: AdminEmailsStorageTypes.FETCH_EMAILS_STORAGE_DATA_FAILURE;
    payload: string;
}

export interface getEmailsStorageExportRequestActionType {
    type: AdminEmailsStorageTypes.EMAILS_STORAGE_EXPORT_REQUEST;
    payload: string;
}

export interface getEmailsStorageExportSuccessActionType {
    type: AdminEmailsStorageTypes.EMAILS_STORAGE_EXPORT_FAILURE;
    payload: string;
}

export interface getEmailsStorageExportFailureActionType {
    type: AdminEmailsStorageTypes.EMAILS_STORAGE_EXPORT_SUCCESS;
    payload: string;
}

export interface AddNewNotificationRequestActionType {
    type: AdminEmailsStorageTypes.ADD_NEW_NOTIFICATION_REQUEST;
    payload: INewNotification;
}

export interface AddNewNotificationSuccessActionType {
    type: AdminEmailsStorageTypes.ADD_NEW_NOTIFICATION_SUCCESS;
    payload: string;
}

export interface AddNewNotificationFailureActionType {
    type: AdminEmailsStorageTypes.ADD_NEW_NOTIFICATION_FAILURE;
    payload: string;
}

export interface CleanNewNotificationMessageActionType {
    type: AdminEmailsStorageTypes.CLEAN_NEW_NOTIFICATION_MESSAGE;
}

export interface DeleteAdminEmailRequestActionType {
    type: AdminEmailsStorageTypes.DELETE_ADMIN_EMAIL_REQUEST;
    payload: {
        emailId: string;
        params: IFilterParams;
    };
}
export interface DeleteAdminEmailSuccessActionType {
    type: AdminEmailsStorageTypes.DELETE_ADMIN_EMAIL_SUCCESS;
}
export interface DeleteAdminEmailFailureActionType {
    type: AdminEmailsStorageTypes.DELETE_ADMIN_EMAIL_FAILURE;
    payload: string;
}


export interface DeactivateAdminEmailStorageRequestActionType {
    type: AdminEmailsStorageTypes.DEACTIVATE_ADMIN_EMAIL_REQUEST;
    payload: {
        emailId: string;
        page: number;
    };
}
export interface DeactivateAdminEmailStorageSuccessActionType {
    type: AdminEmailsStorageTypes.DEACTIVATE_ADMIN_EMAIL_SUCCESS;
}

export interface DeactivateAdminEmailStorageFailureActionType {
    type: AdminEmailsStorageTypes.DEACTIVATE_ADMIN_EMAIL_FAILURE;
    payload: string;
}

export interface ActivateAdminEmailStorageRequestActionType {
    type: AdminEmailsStorageTypes.ACTIVATE_ADMIN_EMAIL_REQUEST;
    payload: {
        emailId: string;
        page: number;
        value: boolean;
    };
}

export interface ActivateAdminEmailStorageSuccessActionType {
    type: AdminEmailsStorageTypes.ACTIVATE_ADMIN_EMAIL_SUCCESS;
}

export interface ActivateAdminEmailStorageFailureActionType {
    type: AdminEmailsStorageTypes.ACTIVATE_ADMIN_EMAIL_FAILURE;
    payload: string;
}



export type AdminEmailsStorageActionTypes =
    | FetchAdminEmailsStorageRequestActionType
    | FetchAdminEmailsStorageRequestSuccessActionType
    | FetchAdminEmailsStorageRequestFailureActionType
    | getEmailsStorageExportRequestActionType
    | getEmailsStorageExportSuccessActionType
    | getEmailsStorageExportFailureActionType
    | AddNewNotificationRequestActionType
    | AddNewNotificationSuccessActionType
    | AddNewNotificationFailureActionType
    | CleanNewNotificationMessageActionType
    | DeleteAdminEmailRequestActionType
    | DeleteAdminEmailSuccessActionType
    | DeleteAdminEmailFailureActionType
    | DeactivateAdminEmailStorageRequestActionType
    | DeactivateAdminEmailStorageSuccessActionType
    | DeactivateAdminEmailStorageFailureActionType
    | ActivateAdminEmailStorageRequestActionType
    | ActivateAdminEmailStorageSuccessActionType
    | ActivateAdminEmailStorageFailureActionType;


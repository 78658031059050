import {
    AdminEmailsStorageTypes,
    IInitialState,
    AdminEmailsStorageActionTypes,
} from 'store/actions/adminEmailsStorage/adminEmailsStorage.types';

const InitialState: IInitialState = {
    csv: '',
    items: [],
    loading: false,
    error: '',
    message: '',
    page: 1,
    perPage: 10,
    totalNumber: 0,
};

export const AdminEmailsStorageReducer = (
    state = InitialState,
    action: AdminEmailsStorageActionTypes,
): IInitialState => {
    switch (action.type) {
        case AdminEmailsStorageTypes.FETCH_EMAILS_STORAGE_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminEmailsStorageTypes.FETCH_EMAILS_STORAGE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.emails,
                perPage: 10,
                page: action.payload.curPage,
                totalNumber: action.payload.totalPages,
            };
        case AdminEmailsStorageTypes.FETCH_EMAILS_STORAGE_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminEmailsStorageTypes.EMAILS_STORAGE_EXPORT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminEmailsStorageTypes.EMAILS_STORAGE_EXPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                csv: action.payload,
            };
        case AdminEmailsStorageTypes.EMAILS_STORAGE_EXPORT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminEmailsStorageTypes.ADD_NEW_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminEmailsStorageTypes.ADD_NEW_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                message: action.payload,
            };
        case AdminEmailsStorageTypes.ADD_NEW_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminEmailsStorageTypes.CLEAN_NEW_NOTIFICATION_MESSAGE:
            return {
                ...state,
                message: '',
                error: '',
            };
        case AdminEmailsStorageTypes.DELETE_ADMIN_EMAIL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminEmailsStorageTypes.DELETE_ADMIN_EMAIL_SUCCESS:
            return {
                ...state,
                message: 'Deleted Successfully',
                loading: false,
            };
        case AdminEmailsStorageTypes.DELETE_ADMIN_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminEmailsStorageTypes.DEACTIVATE_ADMIN_EMAIL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminEmailsStorageTypes.DEACTIVATE_ADMIN_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case AdminEmailsStorageTypes.DEACTIVATE_ADMIN_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminEmailsStorageTypes.ACTIVATE_ADMIN_EMAIL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminEmailsStorageTypes.ACTIVATE_ADMIN_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case AdminEmailsStorageTypes.ACTIVATE_ADMIN_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IInitialKiosksValue } from 'adminComponents/NewKioskDevice/NewKioskDeviceForm/constants';
import {
    editKioskEndpoint,
    getKioskDetailsEndpoint,
} from 'services/admin.services/adminKiosks/kioskDetails.endpoint';
import {
    editKioskFailureAction,
    editKioskSuccessAction,
    fetchKioskDetailsFailureAction,
    fetchKioskDetailsRequestAction,
    fetchKioskDetailsSuccessAction,
} from 'store/actions/adminKioskDetails/kioskDetails.action';
import {
    editKioskRequestActionType,
    fetchKioskDetailsRequestActionType,
    KioskDetailsTypes,
} from 'store/actions/adminKioskDetails/kioskDetails.types';

function* fetchKioskDetailsWorker(action: fetchKioskDetailsRequestActionType) {
    const kioskId = action.payload;
    try {
        const { data }: { data: IInitialKiosksValue } = yield call(
            getKioskDetailsEndpoint,
            kioskId,
        );
        yield put(fetchKioskDetailsSuccessAction(data));
    } catch (error: any) {
        console.log(error);
        yield put(fetchKioskDetailsFailureAction(error.message));
    }
}

function* editKioskWorker(action: editKioskRequestActionType) {
    const podcastId = action.payload.id;
    const { id, readingCount, ...newKiosk } = action.payload.newKiosk;
    const json = JSON.stringify(newKiosk);

    try {
        yield call(editKioskEndpoint, podcastId, json);
        yield put(editKioskSuccessAction('Updated Successfully'));
        yield put(fetchKioskDetailsRequestAction(podcastId));
    } catch (error: any) {
        yield put(editKioskFailureAction(error.message));
    }
}

export function* adminKioskDetailsSaga() {
    yield all([
        takeLatest(KioskDetailsTypes.FETCH_KIOSK_DETAIL_REQUEST, fetchKioskDetailsWorker),
        takeLatest(KioskDetailsTypes.EDIT_KIOSK_REQUEST, editKioskWorker),
    ]);
}

import { INewStory } from 'adminComponents/NewStory/NewStoryFrom/constants';
import { IFilterParams } from 'models/filters';
import { IPaginationResponse } from 'services/types';

export enum AdminStoryTypes {
    FETCH_STORIES_REQUEST = 'FETCH_STORIES_REQUEST',
    FETCH_STORIES_SUCCESS = 'FETCH_STORIES_SUCCESS',
    FETCH_STORIES_FAILURE = 'FETCH_STORIES_FAILURE',

    DELETE_STORY_REQUEST = 'DELETE_STORY_REQUEST',
    DELETE_STORY_SUCCESS = 'DELETE_STORY_SUCCESS',
    DELETE_STORY_FAILURE = 'DEACTIVATE_STORY_FAILURE',

    DEACTIVATE_STORY_REQUEST = 'DEACTIVATE_STORY_REQUEST',
    DEACTIVATE_STORY_SUCCESS = 'DEACTIVATE_STORY_SUCCESS',
    DEACTIVATE_STORY_FAILURE = 'DEACTIVATE_STORY_FAILURE',

    ACTIVATE_STORY_REQUEST = 'ACTIVATE_STORY_REQUEST',
    ACTIVATE_STORY_SUCCESS = 'ACTIVATE_STORY_SUCCESS',
    ACTIVATE_STORY_FAILURE = 'ACTIVATE_STORY_FAILURE',

    SHORT_STORY_CATEGORIES_REQUEST = 'SHORT_STORY_CATEGORIES_REQUEST',
    SHORT_STORY_CATEGORIES_SUCCESS = 'SHORT_STORY_CATEGORIES_SUCCESS',
    SHORT_STORY_CATEGORIES_FAILURE = 'SHORT_STORY_CATEGORIES_FAILURE',

    POST_NEW_STORY_REQUEST = 'POST_NEW_STORY_REQUEST',
    POST_NEW_STORY_SUCCESS = 'POST_NEW_STORY_SUCCESS',
    POST_NEW_STORY_FAILURE = 'POST_NEW_STORY_FAILURE',

    CLEAN_STORY_MESSAGE = 'CLEAN_STORY_MESSAGE',

    STORY_EXPORT_REQUEST = 'STORY_EXPORT_REQUEST',
    STORY_EXPORT_FAILURE = 'STORY_EXPORT_FAILURE',
    STORY_EXPORT_SUCCESS = 'STORY_EXPORT_SUCCESS',
}

export interface IInitialState {
    csv: string;
    stories: IAdminStoryItem[];
    loading: boolean;
    error: string;
    page: number;
    perPage: number;
    totalNumber: number;
    storyCategories: IStoryPodcastCategories[];
    postNewStoryError: string;
    message: string;
}

export interface IAdminStoryItem {
    id: string;
    titleEn: string;
    titleAr: string;
    imageEn: string;
    imageAr: string;
    createdAt: string;
    storyDurationAr: number;
    storyDurationEn: number;
    publisherNameAr:string;
    authorAr: string;
    category: {
        id: string;
        descriptionAr: string;
        descriptionEn: string;
        titleAr: string;
        titleEn: string;
    };
    isDeleted: boolean;
}
export interface IStoryPodcastCategories {
    id: string;
    image: string;
    titleEn: string;
    descriptionEn: string;
    titleAr?: string;
    descriptionAr?: string;
    sort?: number;
}

export interface IStoryPodcastTypes {
    id: number;
    titleEn:string
}

export interface FetchStoriesRequestActionType {
    type: AdminStoryTypes.FETCH_STORIES_REQUEST;
    payload: { page: number; categoryId: string; searchWord: string; storyType:number | null; };
}

export interface FetchStoriesSuccessActionType {
    type: AdminStoryTypes.FETCH_STORIES_SUCCESS;
    payload: IPaginationResponse<IAdminStoryItem>;
}

export interface FetchStoriesFailureActionType {
    type: AdminStoryTypes.FETCH_STORIES_FAILURE;
    payload: string;
}
export interface DeactivateStoryRequestActionType {
    type: AdminStoryTypes.DEACTIVATE_STORY_REQUEST;
    payload: {
        storyId: string;
        page: number;
    };
}

export interface DeactivateStorySuccessActionType {
    type: AdminStoryTypes.DEACTIVATE_STORY_SUCCESS;
}

export interface DeactivateStoryFailureActionType {
    type: AdminStoryTypes.DEACTIVATE_STORY_FAILURE;
    payload: string;
}

export interface DeleteStoryRequestActionType {
    type: AdminStoryTypes.DELETE_STORY_REQUEST;
    payload: {
        storyId: string;
        params: IFilterParams;
    };
}

export interface DeleteStorySuccessActionType {
    type: AdminStoryTypes.DELETE_STORY_SUCCESS;
}

export interface DeleteStoryFailureActionType {
    type: AdminStoryTypes.DELETE_STORY_FAILURE;
    payload: string;
}
export interface activateStoryRequestActionType {
    type: AdminStoryTypes.ACTIVATE_STORY_REQUEST;
    payload: {
        storyId: string;
        page: number;
    };
}

export interface activateStorySuccessActionType {
    type: AdminStoryTypes.ACTIVATE_STORY_SUCCESS;
}

export interface activateStoryFailureActionType {
    type: AdminStoryTypes.ACTIVATE_STORY_FAILURE;
    payload: string;
}
export interface getStoryCategoriesRequestActionType {
    type: AdminStoryTypes.SHORT_STORY_CATEGORIES_REQUEST;
}

export interface getStoryCategoriesSuccessActionType {
    type: AdminStoryTypes.SHORT_STORY_CATEGORIES_SUCCESS;
    payload: IStoryPodcastCategories[];
}

export interface getStoryCategoriesFailureActionType {
    type: AdminStoryTypes.SHORT_STORY_CATEGORIES_FAILURE;
    payload: string;
}
export interface postNewStoryRequestActionType {
    type: AdminStoryTypes.POST_NEW_STORY_REQUEST;
    payload: INewStory | FormData;
}

export interface postNewStorySuccessActionType {
    type: AdminStoryTypes.POST_NEW_STORY_SUCCESS;
    payload: string;
}

export interface postNewStoryFailureActionType {
    type: AdminStoryTypes.POST_NEW_STORY_FAILURE;
    payload: string;
}
export interface cleanNewStoryMessageActionType {
    type: AdminStoryTypes.CLEAN_STORY_MESSAGE;
    payload: string;
}

export interface getStoryExportFailureActionType {
    type: AdminStoryTypes.STORY_EXPORT_FAILURE;
    payload: string;
}

export interface getStoryExportRequestActionType {
    type: AdminStoryTypes.STORY_EXPORT_REQUEST;
    payload: string;
}

export interface getStoryExportSuccessActionType {
    type: AdminStoryTypes.STORY_EXPORT_SUCCESS;
    payload: string;
}

export type AdminsStoryActionTypes =
    | FetchStoriesRequestActionType
    | FetchStoriesSuccessActionType
    | FetchStoriesFailureActionType
    | DeactivateStoryRequestActionType
    | DeactivateStorySuccessActionType
    | DeactivateStoryFailureActionType
    | activateStoryRequestActionType
    | activateStorySuccessActionType
    | activateStoryFailureActionType
    | getStoryCategoriesRequestActionType
    | getStoryCategoriesSuccessActionType
    | getStoryCategoriesFailureActionType
    | postNewStoryRequestActionType
    | postNewStorySuccessActionType
    | postNewStoryFailureActionType
    | cleanNewStoryMessageActionType
    | DeleteStoryFailureActionType
    | DeleteStoryRequestActionType
    | DeleteStorySuccessActionType
    | getStoryExportFailureActionType
    | getStoryExportRequestActionType
    | getStoryExportSuccessActionType;

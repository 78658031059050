import { createSlice } from '@reduxjs/toolkit';
import { TAdminKioskState } from 'store/admin/kiosk/types';
import * as actions from './actions';

const initialState: TAdminKioskState = {
    exportCSV: {
        loading: false,
        data: null,
        error: null,
    }
};

export const adminKioskSlice = createSlice({
    name: 'adminKiosk',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(actions.exportKiosksList.pending, (state) => {
            state.exportCSV.loading = true;
            state.exportCSV.data = null;
            state.exportCSV.error = null;
        });
        builder.addCase(actions.exportKiosksList.fulfilled, (state, action) => {
            state.exportCSV.loading = false;
            state.exportCSV.data = action.payload;
            state.exportCSV.error = null;
        });
        builder.addCase(actions.exportKiosksList.rejected, (state, action) => {
            state.exportCSV.loading = false;
            state.exportCSV.data = null;
            state.exportCSV.error = action.payload as string;
        });
    }
});

export default adminKioskSlice.reducer;

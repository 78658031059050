import { TExportKioskDeviceData } from 'types/kiosk';
import { TApiResponse } from 'types/api';
import { axiosInstance } from 'configs/axiosConfig';

const exportToSCV = async (data: TExportKioskDeviceData): Promise<TApiResponse<string>> => {
    return axiosInstance.get(`statistic-device/admin/export-to-csv/${data.isDisabled}`, {
        params: {
            dateRange: data.dateRange,
            deviceId: data.deviceId,
            storyCategory: data.storyCategory
        }
    });
};

export const adminKioskApi = {
    exportToSCV,
};

import {
    IInitialPodcastsValue,
    INewPodcast,
} from 'adminComponents/NewPodcast/NewPodcastForm/constants';
export enum PodcastDetailsTypes {
    FETCH_PODCAST_DETAIL_REQUEST = 'FETCH_PODCAST_DETAIL_REQUEST',
    FETCH_PODCAST_DETAIL_SUCCESS = 'FETCH_PODCAST_DETAIL_SUCCESS',
    FETCH_PODCAST_DETAIL_FAILURE = 'FETCH_PODCAST_DETAIL_FAILURE',

    EDIT_PODCAST_REQUEST = 'EDIT_PODCAST_REQUEST',
    EDIT_PODCAST_SUCCESS = 'EDIT_PODCAST_SUCCESS',
    EDIT_PODCAST_FAILURE = 'EDIT_PODCAST_FAILURE',

    CLEAR_PODCAST_MESSAGE = 'CLEAR_PODCAST_MESSAGE',
}

export interface IInitialPodcastDetailsState {
    podcastDetails: IInitialPodcastsValue | null;
    loading: boolean;
    error: string;
    message: string;
}

export interface fetchPodcastDetailsRequestActionType {
    type: PodcastDetailsTypes.FETCH_PODCAST_DETAIL_REQUEST;
    payload: string;
}

export interface fetchPodcastDetailsSuccessActionType {
    type: PodcastDetailsTypes.FETCH_PODCAST_DETAIL_SUCCESS;
    payload: IInitialPodcastsValue;
}

export interface fetchPodcastDetailsFailureActionType {
    type: PodcastDetailsTypes.FETCH_PODCAST_DETAIL_FAILURE;
    payload: string;
}

export interface editPodcastRequestActionType {
    type: PodcastDetailsTypes.EDIT_PODCAST_REQUEST;
    payload: {
        newPodcast: INewPodcast | FormData;
        id: string;
    };
}

export interface editPodcastSuccessActionType {
    type: PodcastDetailsTypes.EDIT_PODCAST_SUCCESS;
    payload: string;
}

export interface editPodcastFailureActionType {
    type: PodcastDetailsTypes.EDIT_PODCAST_FAILURE;
    payload: string;
}
export interface cleanPodcastMessageActionType {
    type: PodcastDetailsTypes.CLEAR_PODCAST_MESSAGE;
}

export type PodcastDetailsActionTypes =
    | fetchPodcastDetailsRequestActionType
    | fetchPodcastDetailsSuccessActionType
    | fetchPodcastDetailsFailureActionType
    | editPodcastRequestActionType
    | editPodcastSuccessActionType
    | editPodcastFailureActionType
    | cleanPodcastMessageActionType;

import {
    IInitialKiosksValue, INewKiosk,
} from 'adminComponents/NewKioskDevice/NewKioskDeviceForm/constants';
import { KioskDetailsTypes } from './kioskDetails.types';

export const fetchKioskDetailsRequestAction = (id: string) => ({
    type: KioskDetailsTypes.FETCH_KIOSK_DETAIL_REQUEST,
    payload: id,
});

export const fetchKioskDetailsFailureAction = (message: string) => ({
    type: KioskDetailsTypes.FETCH_KIOSK_DETAIL_FAILURE,
    payload: message,
});

export const fetchKioskDetailsSuccessAction = (kioskDetail: IInitialKiosksValue) => ({
    type: KioskDetailsTypes.FETCH_KIOSK_DETAIL_SUCCESS,
    payload: kioskDetail,
});

export const editKioskRequestAction = (newKiosk: INewKiosk, id: string) => ({
    type: KioskDetailsTypes.EDIT_KIOSK_REQUEST,
    payload: {
        newKiosk,
        id,
    },
});

export const editKioskSuccessAction = (message: string) => ({
    type: KioskDetailsTypes.EDIT_KIOSK_SUCCESS,
    payload: message,
});

export const editKioskFailureAction = (message: string) => ({
    type: KioskDetailsTypes.EDIT_KIOSK_FAILURE,
    payload: message,
});

export const cleanKioskMessageAction = () => ({
    type: KioskDetailsTypes.CLEAR_KIOSK_MESSAGE,
});

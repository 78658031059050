import React from 'react';
import AdminLoginForm from '../../adminComponents/AdminLoginForm/AdminLoginForm';
import AdminLoginLayout from '../../layouts/AdminLoginLayout/AdminLoginLayout';

const AdminLoginPage = () => {
    return (
        <AdminLoginLayout>
            <AdminLoginForm />
        </AdminLoginLayout>
    );
};

export default AdminLoginPage;

import React, { useEffect, useState } from 'react';
import ItemDetailLayout from 'layouts/ItemDetailLayout';
import Player from 'portalComponents/Player';
import { Link, useParams } from 'react-router-dom';
import Paths from 'constants/paths';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    getAnotherPodcastDetailAction,
    getPodcastDetailAction,
} from 'store/actions/portalActions/portal.actions';
import { useAppSelector } from 'hooks';
import { getEngOrArData, paragraphIsBlank } from 'utils/helpers';
import { BASE_URL } from 'constants/api';
import { compile } from 'path-to-regexp';
import { useAnalytics } from 'utils/googleAnalytics';
import footerBgImg from '../../assets/images/footerBg.svg';
import { getAdminsPodcastsCategoriesRequestAction } from 'store/actions/adminPodcasts/adminPodcasts.action';
import styles from './Podcast.module.scss';
import SocialMediaShare from 'portalComponents/SocialMediaShare/SocialMediaShare';

const Podcast = () => {
    const { t } = useTranslation();
    const [isPlaying, setIsPlaying] = useState(false);
    const dispatch = useDispatch();
    const { categoryId, podcastId } =
        useParams<{ categoryId: string; podcastId: string }>();
    const { podcastDetail } = useAppSelector(state => state.portal);
    const [setPageName] = useAnalytics();
    const handleClick = () => {
        setIsPlaying(false);
        dispatch(getAnotherPodcastDetailAction(categoryId, podcastId));
    };
    const image = `${BASE_URL}/${getEngOrArData('image', podcastDetail)}`;
    const audioLink = `${BASE_URL}/${getEngOrArData('audioLink', podcastDetail)}`;
    const audioDuratoin = getEngOrArData('audioDuration', podcastDetail) as number;
    const title = podcastDetail.titleEn || podcastDetail.titleAr;
    const categories = useAppSelector(state => state.adminPodcasts.podcastsCategories);
    const isPublisherDisabled = paragraphIsBlank(podcastDetail.publisherDetailsAr)
        && paragraphIsBlank(podcastDetail.audioDetailsAr)
        && !podcastDetail.publisherLinkAr
        && !podcastDetail.numberEpisodesAr
        && !podcastDetail.totalDurationAr
        && !getEngOrArData('publisherImage', podcastDetail);

    useEffect(() => {
        const categoryIndex = categories.findIndex((podcast) => podcast.id === categoryId);
        const categoryTitle = getEngOrArData('title', categories[categoryIndex]) as string;
        setPageName(`LC - ${categoryTitle}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories]);

    useEffect(() => {
        setPageName(title);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title]);

    useEffect(() => {
        dispatch(getAdminsPodcastsCategoriesRequestAction());
        dispatch(getPodcastDetailAction.request(podcastId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <ItemDetailLayout withoutBtn>
            <img
                className={`${styles.headerImgBg} ${getEngOrArData('image', podcastDetail) ? null : styles.headerImgBgDefault}`}
                src={getEngOrArData('image', podcastDetail) ? image : footerBgImg}
                alt=""
            />
            <div className={styles.header}>
                <div className={styles.infoWrapper}>
                    <div className={styles.title}>
                        {getEngOrArData('title', podcastDetail)}
                    </div>
                    <div className={styles.subtitle}>
                        {getEngOrArData('author', podcastDetail)}
                    </div>
                    <Link
                        to={isPublisherDisabled ? "#" : compile(Paths.PODCAST_PUBLISHER)({ id: podcastId })}
                        className={styles.link}
                    >
                        {getEngOrArData('publisherName', podcastDetail)}
                    </Link>
                </div>
            </div>
            <div className={styles.body}>
                <div className={styles.playerWrapper}>
                    <Player
                        src={audioLink}
                        duration={audioDuratoin}
                        isPlaying={isPlaying}
                        setIsPlaying={setIsPlaying}
                    />
                    <div className={styles.buttonWrapper} onClick={handleClick}>
                        <span>{t('podcast.change_content')}</span>
                        <button className={styles.next} />
                    </div>
                </div>
                <Link to={Paths.PODCAST_CATEGORIES}>
                    <button className={styles.categoriesButton}>
                        {t('podcast.literatureCloudCategories')}
                    </button>
                </Link>
            </div>
            <div className={styles.shareButtonWrapper}>
                <SocialMediaShare
                    url={window.location.href}
                    text={t('share.podcastText', { title})}
                />
            </div>
        </ItemDetailLayout>
    );
};

export default Podcast;

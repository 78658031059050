import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import MapData from 'adminComponents/MapData/MapData';

const AdminMapDataPage = () => {
    return (
        <AdminLayout title={'Map Data List'}>
            <MapData />
        </AdminLayout>
    );
};

export default AdminMapDataPage;

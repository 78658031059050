import React from 'react'
import AdminList from 'adminComponents/AdminList/AdminList';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';

const AdminListPage = () => {

    return (
        <AdminLayout title={'Admins List'}>
            <AdminList />
        </AdminLayout>
    );
};

export default AdminListPage;

import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styles from 'portalPages/Story/Story.module.scss';
import ScrollProgressBar from 'portalComponents/ScrollProgressBar';
import { useTranslation } from 'react-i18next';
import Paths from 'constants/paths';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useClickOutside } from 'hooks/useClickOutside';
import footerLogoLight from '../../assets/images/footerLogoLight.svg';
import footerLogo from '../../assets/images/footerLogo.svg';
import footerBgImg from '../../assets/images/footerBg.svg';
import footerBgImgDark from '../../assets/images/footerBgDark.svg';
import { useDispatch } from 'react-redux';
import {
    getAnotherStoryAction,
    getStoryDetailAction,
} from 'store/actions/portalActions/portal.actions';
import { useAppSelector } from 'hooks';
import { compile } from 'path-to-regexp';
import SettingBar from 'portalComponents/SettingBar';
import { deserialize } from 'utils/richTextToHTML';
import { useAnalytics } from 'utils/googleAnalytics';
import { getEngOrArOrFrData, paragraphIsBlank } from 'utils/helpers';
import { getAdminsStoriesCategoriesRequestAction } from 'store/actions/adminStories/adminStories.action';
import SocialMediaShare from 'portalComponents/SocialMediaShare';
import LanguageSelect from 'portalComponents/Header/LanguageSelect';

const Story = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [setPageName] = useAnalytics();
    const { categoryId, storyId } = useParams<{ categoryId: string; storyId: string }>();
    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [currentFontSize, setCurrentFontSize] = useState(2);
    const [darkTeme, setDarkTheme] = useState(false);
    const ref = useRef(null);
    const { storyDetail } = useAppSelector(state => state.portal);

    const image = `${process.env.REACT_APP_URL}/${getEngOrArOrFrData(
        'image',
        storyDetail,
    )}`;
    const title = getEngOrArOrFrData('title', storyDetail) as string;
    const bookName = getEngOrArOrFrData('bookName', storyDetail) as string;
    const author = getEngOrArOrFrData('author', storyDetail) as string;
    const storyText = getEngOrArOrFrData('storyText', storyDetail) as string;
    const publisherName = getEngOrArOrFrData('publisherName', storyDetail) as string;
    const categories = useAppSelector(state => state.adminStories.storyCategories);
    const isPublisherDisabled =
        paragraphIsBlank(storyDetail.publisherDetailsAr) &&
        paragraphIsBlank(storyDetail.moreInfoAr) &&
        !storyDetail.publisherLinkAr &&
        !getEngOrArOrFrData('publisherImage', storyDetail);

    const checkAvailableLanguage = () => {
        if (storyDetail.translateFr && storyDetail.translateEn) {
            return ['ar', 'en', 'fr'];
        }
        if (!storyDetail.translateFr && !storyDetail.translateEn) {
            return [];
        }
        if (!storyDetail.translateFr && storyDetail.translateEn) {
            return ['ar', 'en'];
        }
        if (storyDetail.translateFr && !storyDetail.translateEn) {
            return ['ar', 'fr'];
        }
    };
    checkAvailableLanguage();

    useEffect(() => {
        const categoryIndex = categories.findIndex(story => story.id === categoryId);
        const categoryTitle = getEngOrArOrFrData(
            'title',
            categories[categoryIndex],
        ) as string;
        setPageName(`SHS - ${categoryTitle}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories]);

    useEffect(() => {
        setPageName(title);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title]);

    useEffect(() => {
        dispatch(getAdminsStoriesCategoriesRequestAction());
        dispatch(getStoryDetailAction.request(storyId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleClick = () => {
        setContextMenuOpen(!contextMenuOpen);
    };
    const increaseFonSize = () => {
        if (currentFontSize < 4) {
            setCurrentFontSize(prev => prev + 1);
        }
    };
    const decreaseFonSize = () => {
        if (currentFontSize > 1) {
            setCurrentFontSize(prev => prev - 1);
        }
    };
    const getAnotherContent = () => {
        dispatch(getAnotherStoryAction.request(categoryId, storyId));
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useClickOutside(ref, () => setContextMenuOpen(false), !!contextMenuOpen);

    return (
        <div className={cn(styles.pageWrapper, darkTeme && styles.darkTheme)}>
            <img
                className={`${styles.headerImgBg} ${
                    getEngOrArOrFrData('image', storyDetail)
                        ? null
                        : styles.headerImgBgDefault
                }`}
                src={
                    getEngOrArOrFrData('image', storyDetail)
                        ? image
                        : darkTeme
                        ? footerBgImgDark
                        : footerBgImg
                }
                alt=""
            />
            <header className={styles.header}>
                <div className={styles.title}>{title}</div>
                <div className={styles.subtitle}>{bookName}</div>
                <div className={styles.subtitle}>{author}</div>
                {!!storyDetail.publisherLinkAr ? (
                    <Link
                        to={
                            isPublisherDisabled
                                ? '#'
                                : compile(Paths.PUBLISHER)({ id: storyId })
                        }
                        className={styles.link}>
                        {publisherName}
                    </Link>
                ) : (
                    <div className={styles.link}>{publisherName}</div>
                )}
            </header>
            <>
                <div className={styles.body}>
                    <div
                        className={cn(
                            styles.article,
                            styles[`bodySize_${currentFontSize}`],
                        )}>
                        {!paragraphIsBlank(storyText) && (
                            <div className={styles.conatent}>
                                {JSON.parse(storyText)?.map((el: any) => deserialize(el))}
                            </div>
                        )}
                    </div>
                    <div className={styles.buttonsWrapper}>
                        <button
                            // to={compile(Paths.PUBLISHER)({ id: storyId })}
                            className={styles.btnAbout}
                            onClick={getAnotherContent}>
                            {t('story-detail-page.btn-other-content')}
                        </button>
                        <button
                            className={styles.btnOtherContent}
                            onClick={() => {
                                history.push(Paths.STORY_CATEGORIES);
                            }}>
                            {t('story-detail-page.btn-story-categories')}
                        </button>
                    </div>
                </div>
                <div className={styles.shareButtonWrapper}>
                    <SocialMediaShare
                        url={window.location.href}
                        text={t('share.storyText', { title })}
                        darkMode={darkTeme}
                    />
                </div>
                <div className={styles.footer}>
                    <img
                        src={darkTeme ? footerLogoLight : footerLogo}
                        alt=""
                        className={styles.footerLogo}
                        onClick={() => history.push(Paths.HOME)}
                    />
                    <img
                        className={styles.bgImg}
                        src={darkTeme ? footerBgImgDark : footerBgImg}
                        alt=""
                    />
                </div>
            </>
            <div
                ref={ref}
                className={cn(
                    styles.progressWrapper,
                    contextMenuOpen && styles.activeSettingWrapper,
                )}>
                <ScrollProgressBar />
                <SettingBar
                    handleClick={handleClick}
                    decreaseFonSize={decreaseFonSize}
                    increaseFonSize={increaseFonSize}
                    setDarkTheme={setDarkTheme}
                    contextMenuOpen={contextMenuOpen}
                    darkMode={darkTeme}
                />
            </div>
        </div>
    );
};

export default Story;

import {
    activateAdminPodcastsRequestActionType,
    AdminPodcastsTypes,
    DeactivateAdminPodcastsRequestActionType,
    FetchAdminPodcastsRequestActionType,
    postNewPodcastRequestActionType,
    DeleteAdminPodcastsRequestActionType,
} from './../actions/adminPodcasts/adminPodcasts.types';
import * as adminPodcastsEndPoints from '../../services/admin.services/adminPodcasts/adminPodcasts.endpoints';
import { IAdminPodcasts } from './../../services/admin.services/adminPodcasts/adminPodcasts.types';
import * as AdminPodcastsActions from '../actions/adminPodcasts/adminPodcasts.action';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IPaginationResponse } from 'services/types';
import { AxiosResponse } from 'axios';
import { IStoryPodcastCategories } from 'store/actions/adminStories/adminStories.types';
import { IFilterParams } from 'models/filters';


function* fetchAdminPodcastsWorker(action: FetchAdminPodcastsRequestActionType) {
    const values = action.payload
    const filteredObj = Object.keys(values).reduce((acc, item) => {
        if(values[item as keyof IFilterParams]!==''){
            return {
                ...acc,
                [item]: values[item as keyof IFilterParams]
            } 
        } else return acc
    }, {})

    const {page, searchWord, categoryId}  = filteredObj as IFilterParams;

    try {
        const { data }: { data: IPaginationResponse<IAdminPodcasts> } = yield call(
            adminPodcastsEndPoints.getAdminPodcastsEndpoint,
            page,
            searchWord,
            categoryId,
        );
        yield put(AdminPodcastsActions.fetchAdminPodcastsSuccesstAction(data));
    } catch (error) {
        // yield put(AdminPodcastsActions.fetchAdminPodcastsFailureAction(error.message));
    }
}

function* deactivateAdminPodcastsWorker(
    action: DeactivateAdminPodcastsRequestActionType,
) {
    const id = action.payload.podcastId;
    const page = action.payload.page;

    try {
        yield call(adminPodcastsEndPoints.deactivateAdminPodcastsEndpoint, id, page);
        yield put(AdminPodcastsActions.deactivateAdminPodcastsSuccesstAction());
        yield put(AdminPodcastsActions.fetchAdminPodcastsRequestAction(page));
    } catch (error: any) {
        // yield put(deactivateAdminFailureAction(error.message));
    }
}

function* deleteAdminPodcastsWorker(action: DeleteAdminPodcastsRequestActionType) {
    const id = action.payload.podcastId;
    const {page, searchWord, categoryId} = action.payload.params;

    try {
        yield call(adminPodcastsEndPoints.deleteAdminPodcastsEndpoint, id);
        yield put(AdminPodcastsActions.deleteAdminPodcastsSuccesstAction());
        yield put(AdminPodcastsActions.fetchAdminPodcastsRequestAction(page, searchWord, categoryId));
    } catch (error: any) {
        yield put(AdminPodcastsActions.deleteAdminPodcastsFailureAction(error.message));
    }
}

function* activateAdminPodcastsWorker(action: activateAdminPodcastsRequestActionType) {
    const id = action.payload.podcastId;
    const page = action.payload.page;

    try {
        yield call(adminPodcastsEndPoints.activateAdminPodcastsEndpoint, id, page);
        yield put(AdminPodcastsActions.activateAdminPodcastsSuccesstAction());
        yield put(AdminPodcastsActions.fetchAdminPodcastsRequestAction(page));
    } catch (error: any) {
        // yield put(activateAdminFailureAction(error.message));
    }
}

export function* getAdminPodcastsCategoriesWorker() {
    try {
        const categoriesResponse: AxiosResponse<IStoryPodcastCategories[]> = yield call(
            adminPodcastsEndPoints.getAdminPodcastsCategoriesEndpoin,
        );
        yield put(
            AdminPodcastsActions.getAdminsPodcastsCategoriesSuccessAction(
                categoriesResponse.data,
            ),
        );
    } catch (error) {
        // yield put(getAdminsPodcastsCategoriesFailureAction(error.message as string));
    }
}

export function* getAdminsPodcastsExportToCsv() {
    try {
        const exportResponse: AxiosResponse = yield call(
            adminPodcastsEndPoints.getAdminsPodcastsExportToCsvEndpoint,
        );
        yield put(
            AdminPodcastsActions.getAdminsPodcastsExportToCsvSuccessAction(
                exportResponse.data,
            ),
        );
    } catch (error) {
        // yield put(getAdminsPodcastsCategoriesFailureAction(error.message as string));
    }
}


export function* postNewPodcastWorker(action: postNewPodcastRequestActionType) {
    try {
        yield call(adminPodcastsEndPoints.postNewPodcastEndpoin, action.payload);
        yield put(
            AdminPodcastsActions.postNewPodcastSuccessAction('Created Successfully'),
        );
    } catch (error: any) {
        yield put(
            AdminPodcastsActions.postNewPodcastFailureAction(error.message as string),
        );
    }
}

export function* adminPodcastsSaga() {
    yield all([
        takeLatest(
            AdminPodcastsTypes.FETCH_ADMIN_PODCASTS_REQUEST,
            fetchAdminPodcastsWorker,
        ),
        takeLatest(
            AdminPodcastsTypes.DEACTIVATE_ADMIN_PODCASTS_REQUEST,
            deactivateAdminPodcastsWorker,
        ),
        takeLatest(
            AdminPodcastsTypes.ACTIVATE_ADMIN_PODCASTS_REQUEST,
            activateAdminPodcastsWorker,
        ),
        takeLatest(
            AdminPodcastsTypes.PODCASTS_CATEGORIES_REQUEST,
            getAdminPodcastsCategoriesWorker,
        ),
        takeLatest(AdminPodcastsTypes.POST_NEW_PODCAST_REQUEST, postNewPodcastWorker),
        takeLatest(
            AdminPodcastsTypes.DELETE_ADMIN_PODCASTS_REQUEST,
            deleteAdminPodcastsWorker,
        ),
        takeLatest(
            AdminPodcastsTypes.PODCASTS_EXPORT_REQUEST,
            getAdminsPodcastsExportToCsv,
        ),
    ]);
}

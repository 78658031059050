import React, { useEffect, useState } from 'react';
import AdminFilterPanel from 'adminComponents/AdminFilterPanel/AdminFilterPanel';
import Pagination from 'adminComponents/Pagination/Pagination';
import Paths from 'constants/paths';
import { useAppSelector } from 'hooks';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
    fetchAdminsStoriesRequestAction,
    getAdminsStoriesCategoriesRequestAction,
    getAdminsStoryExportToCsvRequestAction,
} from 'store/actions/adminStories/adminStories.action';
import Alert from 'sharedComponents/Alert';
import { cleanNewStoryMessageAction } from 'store/actions/adminStories/adminStories.action';
import AdminTopPanel from '../AdminTopPanel/AdminTopPanel';
import { storiesTableHead } from './constants';
import StoriesTable from './StoriesTable/StoriesTable';
import { IFilterParams } from 'models/filters';
import { saveAs } from 'file-saver';

const Stories = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [filterParams, setFilterParams] = useState<IFilterParams>({
        page: 0,
        searchWord: '',
        categoryId: '',
        storyType: null,
    });

    const adminStories = useAppSelector(state => state.adminStories.stories);
    const loading = useAppSelector(state => state.adminStories.loading);
    const page = useAppSelector(state => state.adminStories.page);
    const perPage = useAppSelector(state => state.adminStories.perPage);
    const totalElements = useAppSelector(state => state.adminStories.totalNumber);
    const categories = useAppSelector(state => state.adminStories.storyCategories);
    const error = useAppSelector(state => state.adminStories.postNewStoryError);
    const message = useAppSelector(state => state.adminStories.message);
    const totalNumber = useAppSelector(state => state.adminStories.totalNumber);
    const csv = useAppSelector(state => state.adminStories.csv)

    const lastPage: number = Math.ceil(totalElements / perPage);

    useEffect(() => {
        const { page, categoryId, searchWord, storyType } = filterParams as IFilterParams
        dispatch(getAdminsStoriesCategoriesRequestAction());
        dispatch(fetchAdminsStoriesRequestAction(page, searchWord, categoryId,storyType))
        return () => {
            dispatch(cleanNewStoryMessageAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterParams]);

    useEffect(() => {
        dispatch(getAdminsStoryExportToCsvRequestAction())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adminStories])

    const changePageHandler = (newPage: number): void => {
        const { categoryId, searchWord } = filterParams as IFilterParams
        dispatch(fetchAdminsStoriesRequestAction(newPage, searchWord, categoryId))
    };

    const exportCSV = () => {
        if (!csv) return
        const fileData = new Blob([csv], { type: 'text/csv' });
        saveAs(fileData, 'stories');
    }
    const optionsType = [
        { id: 1, titleEn: 'Adult' },
        { id: 2, titleEn: 'Children' },
    ];

    return (
        <>
            {error && <Alert message={error} isError={true} />}
            {message && <Alert message={message} isError={false} />}
            <AdminTopPanel
                butonText={'New Story'}
                buttonOnClick={() => history.push(Paths.ADMIN_NEW_STORY)}
                exportButtonOnClick={exportCSV}
            />
            <AdminFilterPanel
                options={categories}
                params={filterParams}
                setFilterParams={setFilterParams}
                totalNumber={totalNumber}
                optionsType={optionsType}
                typeFilter

            />
            {loading ?
                <div className="container-fluid d-flex justify-content-center align-items-center mt-5">
                    <Spinner animation="border" />
                </div>
                : <div className="App-table-default-wrapper">
                    <div className="table-responsive">
                        <StoriesTable
                            params={filterParams}
                            tableHead={storiesTableHead}
                            tableBody={adminStories}
                            page={page}
                        />
                        <Pagination
                            currentPage={page}
                            changePageHandler={changePageHandler}
                            lastPage={lastPage}
                        />
                    </div>
                </div>}
        </>
    );
};

export default Stories;


import { IStoryPodcastCategories } from './../../../store/actions/adminStories/adminStories.types';
import { AxiosResponse } from 'axios';
import { axiosInstance } from 'services/interceptor';
import { IPaginationResponse } from 'services/types';
import { IAdminStoryItem } from 'store/actions/adminStories/adminStories.types';
import { INewStory } from 'adminComponents/NewStory/NewStoryFrom/constants';

export const getAdminStoriesEndpoin = (
    page: number = 0,
    searchWord: string,
    categoryId: string,
    storyType?: number | null,
): Promise<AxiosResponse<IPaginationResponse<IAdminStoryItem>>> => {
    return axiosInstance.get('admin/stories', {
        params: { page, categoryId, searchWord, storyType, perPage: 30 },
    });
};

export const deactivateAdminStoryEndpoin = (
    storyId: string,
    page: number,
): Promise<AxiosResponse<IAdminStoryItem[]>> => {
    return axiosInstance.delete(`admin/stories/${storyId}`, { params: { page } });
};

export const deleteAdminStoryEndpoint = (
    storyId: string,
    page: number,
): Promise<AxiosResponse<IAdminStoryItem[]>> => {
    return axiosInstance.delete(`admin/stories/irreversible/${storyId}`, {
        params: { page },
    });
};

export const activateAdminStoryEndpoin = (
    storyId: string,
    page: number,
): Promise<AxiosResponse<IAdminStoryItem[]>> => {
    return axiosInstance.put(`admin/stories/restore/${storyId}`, { params: { page } });
};

export const getAdminStoriesCategoriesEndpoin = (): Promise<
    AxiosResponse<IStoryPodcastCategories[]>
> => {
    return axiosInstance.get('/short-story-categories');
};


export const getAdminsStoryExportToCsvEndpoint = (): Promise<
    AxiosResponse<string>
> => {
    return axiosInstance.get('/admin/stories/export-to-csv');
};

export const postNewStoryEndpoin = (
    newStory: INewStory | FormData,
): Promise<AxiosResponse> => {
    return axiosInstance.post('/admin/stories', newStory, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

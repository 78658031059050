import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './IconButton.module.scss';

type TProps = {
    children: React.ReactNode;
    variant?: 'default' | 'story' | 'podcast' | 'kiosk';
    height?: 'default' | 'small';
    onClick?: () => void;
}

const IconButton: FC<TProps> = ({
    children,
    variant = 'default',
    height = 'default',
    onClick
}) => (
    <button
        className={classNames(styles.wrapper, variant, `height-${height}`)}
        onClick={onClick}
    >
        {children}
    </button>
);

export default IconButton;

import { createSlice } from '@reduxjs/toolkit';
import { TMapState } from './types';
import * as actions from './actions';

const initialState: TMapState = {
    items: {
        loading: false,
        error: null,
        data: null,
    }
};

export const portalMapSlice = createSlice({
    name: 'portalMap',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(actions.getMapData.pending, (state) => {
            state.items.loading = true;
            state.items.error = null;
            state.items.data = null;
        });
        builder.addCase(actions.getMapData.fulfilled, (state, action) => {
            state.items.loading = false;
            state.items.error = null;
            state.items.data = action.payload;
        });
        builder.addCase(actions.getMapData.rejected, (state, action) => {
            state.items.loading = false;
            state.items.error = action.payload as string;
            state.items.data = null;
        });
    }
});

export default portalMapSlice.reducer;

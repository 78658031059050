import { combineReducers } from 'redux';

import { AuthReducer } from './reducers/auth.reducer';
import { AdminsListReducer } from './reducers/AdminList.reducer';
import { AdminStoriesReducer } from './reducers/AdminStories.reducer';
import { AdminPodcastsReducer } from './reducers/adminPodcasts.reducer';
import { AdminStoryDetailsReducer } from './reducers/adminStoryDetail.reducer';
import { AdminPodcastDetailsReducer } from './reducers/adminPodcastDetail.reducer';
import { AdminKiosksReducer } from './reducers/adminKiosks.reducer';
import { AdminKioskDetailsReducer } from './reducers/adminKioskDetail.reducer';
import { AdminMapDataReducer } from 'store/reducers/adminMapData.reducer';
import { AdminMapDataDetailsReducer } from 'store/reducers/adminMapDataDetails.reducer';
import { AdminEmailsStorageReducer } from 'store/reducers/adminEmaisStorage.reducer';
import PortalReducer from './reducers/portal.reducer';

import adminKioskReducer from './admin/kiosk/slice';

import portalPodcastReducer from './portal/podcast/slice';
import portalStoryReducer from './portal/story/slice';
import portalEmailStorageReducer from './portal/emailStorage/slice';
import portalMapReducer from './portal/map/slice';

export const rootReducer = combineReducers({
    auth: AuthReducer,
    admins: AdminsListReducer,
    adminStories: AdminStoriesReducer,
    adminPodcasts: AdminPodcastsReducer,
    adminStoryDetails: AdminStoryDetailsReducer,
    adminPodcastDetails: AdminPodcastDetailsReducer,
    adminKiosks: AdminKiosksReducer,
    adminKioskDetails: AdminKioskDetailsReducer,
    adminMapData: AdminMapDataReducer,
    adminMapDataDetails: AdminMapDataDetailsReducer,
    adminEmailsStorage: AdminEmailsStorageReducer,
    adminKiosk: adminKioskReducer,

    portal: PortalReducer,
    portalPodcast: portalPodcastReducer,
    portalStory: portalStoryReducer,
    portalEmailStorage: portalEmailStorageReducer,
    portalMap: portalMapReducer
});

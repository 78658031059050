import {
    AdminKiosksActionTypes,
    IInitialState,
    AdminKiosksTypes,
} from 'store/actions/adminKiosks/adminKiosks.types';

const InitialState: IInitialState = {
    csv: '',
    adminKiosks: [],
    loading: false,
    error: '',
    page: 0,
    perPage: 10,
    totalNumber: 0,
    podcastsCategories: [],
    postNewKioskError: '',
    message: '',
};

export const AdminKiosksReducer = (
    state = InitialState,
    action: AdminKiosksActionTypes,
): IInitialState => {
    switch (action.type) {
        case AdminKiosksTypes.FETCH_ADMIN_KIOSKS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminKiosksTypes.FETCH_ADMIN_KIOSKS_SUCCESS:
            return {
                ...state,
                loading: false,
                adminKiosks: action.payload.items,
                page: action.payload.page,
                perPage: action.payload.perPage,
                totalNumber: action.payload.totalNumber,
            };
        case AdminKiosksTypes.FETCH_ADMIN_KIOSKS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminKiosksTypes.DEACTIVATE_ADMIN_KIOSKS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminKiosksTypes.DEACTIVATE_ADMIN_KIOSKS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case AdminKiosksTypes.DEACTIVATE_ADMIN_KIOSKS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminKiosksTypes.KIOSKS_EXPORT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminKiosksTypes.KIOSKS_EXPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                csv: action.payload,
            };
        case AdminKiosksTypes.KIOSKS_EXPORT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminKiosksTypes.DELETE_ADMIN_KIOSKS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminKiosksTypes.DELETE_ADMIN_KIOSKS_SUCCESS:
            return {
                ...state,
                message: 'Deleted Successfully',
                loading: false,
            };
        case AdminKiosksTypes.DELETE_ADMIN_KIOSKS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminKiosksTypes.ACTIVATE_ADMIN_KIOSKS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminKiosksTypes.ACTIVATE_ADMIN_KIOSKS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case AdminKiosksTypes.ACTIVATE_ADMIN_KIOSKS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminKiosksTypes.KIOSKS_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminKiosksTypes.KIOSKS_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                podcastsCategories: action.payload,
            };
        case AdminKiosksTypes.KIOSKS_CATEGORIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminKiosksTypes.POST_NEW_KIOSK_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminKiosksTypes.POST_NEW_KIOSK_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload,
            };
        case AdminKiosksTypes.POST_NEW_KIOSK_FAILURE:
            return {
                ...state,
                loading: false,
                postNewKioskError: action.payload,
            };
        case AdminKiosksTypes.CLEAN_KIOSK_MESSAGE:
            return {
                ...state,
                loading: false,
                message: '',
                postNewKioskError: '',
            };
        case AdminKiosksTypes.CLEAR_EXPORT_REQUST:
            return {
                ...state,
                csv: '',
            };
        default:
            return state;
    }
};

import {
    IInitialMapDataState,
    MapDataDetailsActionTypes,
    mapDataDetailsTypes,
} from 'store/actions/adminMapDataDetails/adminMapDataDetails.types';

const InitialState: IInitialMapDataState = {
    mapDataDetails: null,
    loading: false,
    error: '',
    message: '',
};

export const AdminMapDataDetailsReducer = (
    state = InitialState,
    action: MapDataDetailsActionTypes,
) => {
    switch (action.type) {
        case mapDataDetailsTypes.FETCH_MAP_DATA_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case mapDataDetailsTypes.FETCH_MAP_DATA_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                mapDataDetails: action.payload,
            };
        case mapDataDetailsTypes.FETCH_MAP_DATA_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case mapDataDetailsTypes.EDIT_MAP_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case mapDataDetailsTypes.EDIT_MAP_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload,
            };
        case mapDataDetailsTypes.EDIT_MAP_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

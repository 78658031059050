import React from 'react';
import Stories from '../../adminComponents/Stories/Stories';
import AdminLayout from '../../layouts/AdminLayout/AdminLayout';

const StoriesPage = () => {
    return (
        <AdminLayout title={'Stories List'}>
            <Stories />
        </AdminLayout>
    );
};

export default StoriesPage;

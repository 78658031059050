import { axiosInstance } from '../../interceptor';
import { AxiosResponse } from 'axios';
import { IMapDataListItem } from '../../../store/actions/adminMapData/adminMapData.types';
import { INewLocation } from 'adminPages/NewMapDataPage/NewLocationForm/constans';
import { IPaginationResponse } from 'services/types';

export const getAdminMapDataListEndpoint = (
    page = 0,
    searchWord: string,
    categoryId: string,
    perPage = 10,
): Promise<AxiosResponse<IPaginationResponse<IMapDataListItem>>> => {
    return axiosInstance.get('/admin/map-data/fetch', {
        params: { page, perPage, categoryId, searchWord },
    });
};

export const postNewLocationEndpoint = (
    newLocation: INewLocation,
): Promise<AxiosResponse<string>> => {
    const json = JSON.stringify(newLocation);
    return axiosInstance.post('admin/map-data/insert', json, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const deleteMapDataEndpoint = (
    locationId: string,
): Promise<AxiosResponse> => {
    return axiosInstance.delete(`admin/map-data/delete/${locationId}`);
};


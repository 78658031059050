import { createSlice } from '@reduxjs/toolkit';
import { TPodcastState } from './types';
import * as podcastActions from './actions';

const initialState: TPodcastState = {
    statistics: {
        loading: false,
        error: null,
        data: null,
    }
};

export const podcastSlice = createSlice({
    name: 'portalPodcast',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(podcastActions.getPodcastStatistics.pending, (state) => {
            state.statistics.loading = true;
        });
        builder.addCase(
            podcastActions.getPodcastStatistics.fulfilled,
            (state, action) => {
                state.statistics.loading = false;
                state.statistics.data = action.payload;
            }
        );
        builder.addCase(
            podcastActions.getPodcastStatistics.rejected,
            (state, action) => {
                state.statistics.loading = false;
                state.statistics.error = action.payload as string;
            }
        );
    }
});

export default podcastSlice.reducer;

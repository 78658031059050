export interface ITableHeadItem {
    title: string;
    key: string;
}

export const tableHead: ITableHeadItem[] = [
    {
        title: '#',
        key: '#',
    },
    {
        title: 'Name',
        key: 'name',
    },
    {
        title: 'Email',
        key: 'email',
    },
    {
        title: 'Inactive',
        key: 'inactive',
    },
];

import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import bannerEn from 'assets/images/podcastStatisticsBannerEn.png';
import bannerAr from 'assets/images/podcastStatisticsBannerAr.svg';
import Paths from 'constants/paths';
import PageLayout from 'layouts/PageLayout';
import StatisticsContent from 'portalComponents/StatisticsContent';
import { getPodcastStatistics } from 'store/portal/podcast/actions';
import { useAppSelector } from 'hooks';
import { selectPodcastStatistics } from 'store/portal/podcast/selectors';
import styles from './PodcastStatistics.module.scss';
import { TLanguages } from 'types/shared';
import { Link } from 'react-router-dom';
import Button from 'portalComponents/Button';

const podcastStatisticsBanners = {
    en: bannerEn,
    ar: bannerAr,
};

const PodcastStatistics: FC = () => {
    const { t, i18n: { language } } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { data, loading } = useAppSelector(selectPodcastStatistics);

    const banner = podcastStatisticsBanners[language as TLanguages];

    useEffect(() => {
        dispatch(getPodcastStatistics());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageLayout
            showHeader
            headerProps={{
                variant: 'small',
                symbols: { position: 'left' },
                banner: (<img
                    src={banner}
                    alt="Podcast statistics banner"
                    className={styles.headerBanner}
                />),
                content: {
                    leftRenderer: () => (
                        <Link to={Paths.HOME}>
                            <Button>{t('buttons.homePage')}</Button>
                        </Link>
                    ),
                },
                languageSelect: { variant: 'podcast' }
            }}
            showFooter
            footerProps={{ variant: 'dark' }}
            contentClassName={styles.wrapper}
        >
            {loading && (
                <Spinner animation="border" />
            )}
            {!loading && data && (
                <StatisticsContent
                    type="podcast"
                    total={{
                        onClick: () => history.push(Paths.PODCAST_CATEGORIES),
                        title: t('podcast.statistics.total'),
                        count: data.totalNumber,
                    }}
                    items={data.items}
                />
            )}
        </PageLayout>
    );
};

export default PodcastStatistics;

import axios, { AxiosRequestConfig } from 'axios';
import Paths from 'constants/paths';

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_URL,
});
const NO_REDIRECT_PATH = ['/auth/password', '/auth/login'];
const handleRequest = (request: AxiosRequestConfig) => {
    const token = localStorage.getItem('token');
    const lng = localStorage.getItem('i18nextLng') || 'ar';
    const sessionId = localStorage.getItem('sessionId');

    request.headers['Content-Language'] = lng;
    if (window.location.pathname.includes('admin')) {
        request.headers['Content-Language'] = 'en';
    }
    if (token) {
        request.headers['Authorization'] = `Bearer ${token}`;
    }
    if(sessionId){
        request.headers['sessionId'] = sessionId;
    }
    return request;
};
axiosInstance.interceptors.request.use(req => handleRequest(req));
axiosInstance.interceptors.response.use(
    res => res,
    error => {
        if (error.response.data.statusCode === 401) {
            if (NO_REDIRECT_PATH.indexOf(error.config.url) === -1) {
                localStorage.removeItem('token');
                window.location.href = Paths.ADMIN_LOGIN;
            }
        }
        return Promise.reject(error.response.data);
    },
);

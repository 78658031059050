export const PASSWORD_REGEX = new RegExp(
    /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/,
);

export const LINK_REGEX = new RegExp(
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
);

export const PHONE_NUMBER_REGEX = /^5[0-9]{8}$/g;

export const FULL_NAME_REGEX = /^[\u0621-\u064A\u0660-\u0669a-zA-Z\-_\s]+$/g;

import axios from 'axios';
import Paths from 'constants/paths';

const NO_REDIRECT_PATH = ['/auth/password', '/auth/login'];

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_URL,
});

axiosInstance.interceptors.request.use(request => {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('i18nextLng') || 'ar';
    const sessionId = localStorage.getItem('sessionId');

    request.headers['Content-Type'] = 'application/json';
    request.headers['Content-Language'] = language;

    if (window.location.pathname.includes('admin')) {
        request.headers['Content-Language'] = 'en';
    }

    if (sessionId) {
        request.headers['sessionId'] = sessionId;
    }

    if (token) {
        request.headers['Authorization'] = `Bearer ${token}`;
    }

    return request;
});

axiosInstance.interceptors.response.use(
    response =>
        ({
            data: response.data,
            success: true,
            error: null,
        } as any),
    error => {
        if (error.response.data.statusCode === 401) {
            if (NO_REDIRECT_PATH.indexOf(error.config.url) === -1) {
                localStorage.removeItem('token');
                window.location.href = Paths.ADMIN_LOGIN;
            }
        }
        return {
            data: null,
            success: false,
            error:
                error.response?.data.message ||
                error.response?.data.error ||
                error.response.statusText,
        };
    },
);

export { axiosInstance };

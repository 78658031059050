import {
    IInitialStoriesValue,
    INewStory,
} from 'adminComponents/NewStory/NewStoryFrom/constants';
export enum StoryDetailsTypes {
    FETCH_STORY_DETAIL_REQUEST = 'FETCH_STORY_DETAIL_REQUEST',
    FETCH_STORY_DETAIL_SUCCESS = 'FETCH_STORY_DETAIL_SUCCESS',
    FETCH_STORY_DETAIL_FAILURE = 'FETCH_STORY_DETAIL_FAILURE',

    EDIT_STORY_REQUEST = 'EDIT_STORY_REQUEST',
    EDIT_STORY_SUCCESS = 'EDIT_STORY_SUCCESS',
    EDIT_STORY_FAILURE = 'EDIT_STORY_FAILURE',

    CLEAR_STORY_MESSAGE = 'CLEAR_STORY_MESSAGE',
}

export interface IInitialStoryDetailsState {
    storyDetails: IInitialStoriesValue | null;
    loading: boolean;
    error: string;
    message: string;
}

export interface fetchStoryDetailsRequestActionType {
    type: StoryDetailsTypes.FETCH_STORY_DETAIL_REQUEST;
    payload: string;
}

export interface fetchStoryDetailsSuccessActionType {
    type: StoryDetailsTypes.FETCH_STORY_DETAIL_SUCCESS;
    payload: IInitialStoriesValue;
}

export interface fetchStoryDetailsFailureActionType {
    type: StoryDetailsTypes.FETCH_STORY_DETAIL_FAILURE;
    payload: string;
}
export interface editStoryRequestActionType {
    type: StoryDetailsTypes.EDIT_STORY_REQUEST;
    payload: {
        newStory: INewStory | FormData;
        id: string;
    };
}

export interface editStorySuccessActionType {
    type: StoryDetailsTypes.EDIT_STORY_SUCCESS;
    payload: string;
}

export interface editStoryFailureActionType {
    type: StoryDetailsTypes.EDIT_STORY_FAILURE;
    payload: string;
}
export interface cleanStoryMessageActionType {
    type: StoryDetailsTypes.CLEAR_STORY_MESSAGE;
}

export type StoryDetailsActionTypes =
    | fetchStoryDetailsRequestActionType
    | fetchStoryDetailsSuccessActionType
    | fetchStoryDetailsFailureActionType
    | editStoryRequestActionType
    | editStorySuccessActionType
    | editStoryFailureActionType
    | cleanStoryMessageActionType;

import {
    IInitialPodcastsValue,
    INewPodcast,
} from 'adminComponents/NewPodcast/NewPodcastForm/constants';
import { AxiosResponse } from 'axios';
import { axiosInstance } from 'services/interceptor';

export const getPodcastDetailsEndpoint = (
    id: string,
): Promise<AxiosResponse<IInitialPodcastsValue>> => {
    return axiosInstance.get(`admin/audio/${id}`);
};

export const editPodcastEndpoin = (
    newPodcast: INewPodcast | FormData,
    id: string,
): Promise<AxiosResponse> => {
    return axiosInstance.put(`/admin/audio/${id}`, newPodcast, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

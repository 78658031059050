import React, { useRef, useState } from 'react';
import styles from './Player.module.scss';
import { getUnixTimeFormat } from 'utils/helpers';
import { useEffect } from 'react';
interface IPlayer {
    src: string;
    duration: number;
    isPlaying: boolean;
    setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
}
const Player = ({ src, duration = 0, isPlaying, setIsPlaying }: IPlayer) => {
    const [currentTime, setCurrentTime] = useState(0);
    const audioPlayer = useRef<HTMLAudioElement>(null);
    const progressBar = useRef<HTMLInputElement>(null);
    const animationRef = useRef<any>(null);

    useEffect(() => {
        progressBar.current!.value = '0';
        audioPlayer.current!.currentTime = 0;
        setCurrentTime(0);
        changePlayerCurrentTime();
        return () => {
            cancelAnimationFrame(animationRef.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src, duration]);

    const togglePlayPause = () => {
        if (!isPlaying) {
            setIsPlaying(true);
            audioPlayer.current!.play();
            animationRef.current = requestAnimationFrame(whilePlaying);
        } else {
            audioPlayer.current?.pause();
            cancelAnimationFrame(animationRef.current!);
            setIsPlaying(false);
        }
    };

    const whilePlaying = () => {
        if (+progressBar.current!.value !== duration) {
            changePlayerCurrentTime();
            progressBar.current!.value = audioPlayer.current?.currentTime + '';
            animationRef.current = requestAnimationFrame(whilePlaying);
        } else {
            setCurrentTime(+progressBar.current!.value);
            progressBar.current!.value = '0';
            animationRef.current = 0;
            setIsPlaying(false);
            return;
        }
    };

    const changeRange = () => {
        audioPlayer.current!.currentTime = +progressBar.current!.value;
        changePlayerCurrentTime();
    };

    const changePlayerCurrentTime = () => {
        const res = (+progressBar.current!.value / Math.floor(duration)) * 100;

        progressBar.current!.style.setProperty(
            '--seek-before-width',
            `${isNaN(res) ? 0 : res}%`,
        );
        setCurrentTime(+progressBar.current!.value);
    };

    return (
        <>
            <button
                className={isPlaying ? styles.pause : styles.play}
                onClick={togglePlayPause}
            />
            <div className={styles.progressWrapper}>
                <input
                    type="range"
                    className={styles.progress}
                    ref={progressBar}
                    onChange={changeRange}
                    max={duration}
                    step="1"
                />
                <div className={styles.infoWrapper}>
                    <div className={styles.info}>{getUnixTimeFormat(currentTime)}</div>
                    <div className={styles.info}>{getUnixTimeFormat(duration)}</div>
                </div>
                <audio ref={audioPlayer} src={src} />
            </div>
        </>
    );
};

export default Player;

import {
    activateStoryRequestActionType,
    DeleteStoryRequestActionType,
    IStoryPodcastCategories,
    postNewStoryRequestActionType,
} from './../actions/adminStories/adminStories.types';
import {
    activateAdminStorySuccessAction,
    deactivateAdminStorySuccessAction,
    deleteAdminStoryFailureAction,
    deleteAdminStorySuccessAction,
    fetchAdminsStoriesRequestAction,
    getAdminsStoriesCategoriesSuccessAction,
    postNewStoriesFailureAction,
    postNewStoriesSuccessAction,
    getAdminsStoryExportToCsvSuccessAction
} from './../actions/adminStories/adminStories.action';
import {
    activateAdminStoryEndpoin,
    deactivateAdminStoryEndpoin,
    deleteAdminStoryEndpoint,
    getAdminStoriesCategoriesEndpoin,
    getAdminsStoryExportToCsvEndpoint,
    postNewStoryEndpoin,
} from './../../services/admin.services/adminStories/adminStories.endpoints';
import { call, all, takeLatest, put } from 'redux-saga/effects';
import { getAdminStoriesEndpoin } from 'services/admin.services/adminStories/adminStories.endpoints';
import { fetchAdminsStoriesSuccessAction } from 'store/actions/adminStories/adminStories.action';
import {
    AdminStoryTypes,
    DeactivateStoryRequestActionType,
    FetchStoriesRequestActionType,
    IAdminStoryItem,
} from 'store/actions/adminStories/adminStories.types';
import { IPaginationResponse } from 'services/types';
import { AxiosResponse } from 'axios';
import { IFilterParams } from 'models/filters';


function* fetchAdminStoriesWorker(action: FetchStoriesRequestActionType) {
    const values = action.payload
    const filteredObj = Object.keys(values).reduce((acc, item) => {
        if(values[item as keyof IFilterParams]!==''){
            return {
                ...acc,
                [item]: values[item as keyof IFilterParams]
            } 
        } else return acc
    }, {})

    const {page, searchWord, categoryId, storyType}  = filteredObj as IFilterParams;

    try {
        const { data }: { data: IPaginationResponse<IAdminStoryItem> } = yield call(
            getAdminStoriesEndpoin,
            page,
            searchWord,
            categoryId,
            storyType
        );
        yield put(fetchAdminsStoriesSuccessAction(data));
    } catch (error: any) {
        // yield put(fetchAdminsStoriesFailureAction(error.message));
    }
}

function* deactivateAdminStoryWorker(action: DeactivateStoryRequestActionType) {
    const id = action.payload.storyId;
    const page = action.payload.page;

    try {
        yield call(deactivateAdminStoryEndpoin, id, page);
        yield put(deactivateAdminStorySuccessAction());
        yield put(fetchAdminsStoriesRequestAction(page));
    } catch (error: any) {
        // yield put(deactivateAdminStoryFailureAction(error.message));
    }
}

function* deleteAdminStoryWorker(action: DeleteStoryRequestActionType) {
    const id = action.payload.storyId;
    const {page, searchWord, categoryId} = action.payload.params;

    try {
        yield call(deleteAdminStoryEndpoint, id, page);
        yield put(deleteAdminStorySuccessAction());
        yield put(fetchAdminsStoriesRequestAction(page, searchWord, categoryId));
    } catch (error: any) {
        yield put(deleteAdminStoryFailureAction(error.message));
    }
}

function* activateAdminStoryWorker(action: activateStoryRequestActionType) {
    const id = action.payload.storyId;
    const page = action.payload.page;

    try {
        yield call(activateAdminStoryEndpoin, id, page);
        yield put(activateAdminStorySuccessAction());
        yield put(fetchAdminsStoriesRequestAction(page));
    } catch (error: any) {
        // yield put(activateAdminStoryFailureAction(error.message));
    }
}

export function* getAdminStoriesCategoriesWorker() {
    try {
        const categoriesResponse: AxiosResponse<IStoryPodcastCategories[]> = yield call(
            getAdminStoriesCategoriesEndpoin,
        );
        yield put(getAdminsStoriesCategoriesSuccessAction(categoriesResponse.data));
    } catch (error) {
        // yield put(getAdminsStoriesCategoriesFailureAction(error.message as string));
    }
}

export function* postNewStoryWorker(action: postNewStoryRequestActionType) {
    try {
        yield call(postNewStoryEndpoin, action.payload);
        yield put(postNewStoriesSuccessAction('Created Successfully'));
    } catch (error: any) {
        yield put(postNewStoriesFailureAction(error.message as string));
    }
}

export function* getAdminsStoryExportToCsv() {
    try {
        const exportResponse: AxiosResponse = yield call(
            getAdminsStoryExportToCsvEndpoint,
        );
        yield put(
            getAdminsStoryExportToCsvSuccessAction(
                exportResponse.data,
            ),
        );
    } catch (error) {
        // yield put(getAdminsPodcastsCategoriesFailureAction(error.message as string));
    }
}

export function* adminsStoriesSaga() {
    yield all([
        takeLatest(AdminStoryTypes.FETCH_STORIES_REQUEST, fetchAdminStoriesWorker),
        takeLatest(AdminStoryTypes.DEACTIVATE_STORY_REQUEST, deactivateAdminStoryWorker),
        takeLatest(AdminStoryTypes.ACTIVATE_STORY_REQUEST, activateAdminStoryWorker),
        takeLatest(AdminStoryTypes.POST_NEW_STORY_REQUEST, postNewStoryWorker),
        takeLatest(
            AdminStoryTypes.SHORT_STORY_CATEGORIES_REQUEST,
            getAdminStoriesCategoriesWorker,
        ),
        takeLatest(AdminStoryTypes.DELETE_STORY_REQUEST, deleteAdminStoryWorker),
        takeLatest(AdminStoryTypes.STORY_EXPORT_REQUEST, getAdminsStoryExportToCsv),
    ]);
}

import {
    IInitialKiosksValue,
    INewKiosk,
} from 'adminComponents/NewKioskDevice/NewKioskDeviceForm/constants';
import { AxiosResponse } from 'axios';
import { axiosInstance } from 'services/interceptor';

export const getKioskDetailsEndpoint = (
    id: string,
): Promise<AxiosResponse<IInitialKiosksValue>> => {
    return axiosInstance.get(`device/${id}`);
};

export const editKioskEndpoint = (
    id: string,
    newKiosk: INewKiosk | string,
): Promise<AxiosResponse> => {
    return axiosInstance.put(`device/${id}`, newKiosk, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

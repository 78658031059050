import React, { ReactNode, useCallback, useRef } from 'react';
import { Editable, withReact, useSlate, Slate, ReactEditor } from 'slate-react';
import {
    Editor,
    Transforms,
    createEditor,
    Element as SlateElement,
    BaseEditor,
    BaseText,
} from 'slate';
import { HistoryEditor } from 'slate-history';
import { Button, Toolbar, Icon } from './components';
import { useField } from 'formik';

import styles from './TextEditor.module.scss';
import { Descendant } from 'slate/dist/interfaces/node';

interface ToolbarElements {
    format: string;
    icon: string;
}

interface TextFormatVariation {
    text: string;
    bold?: boolean;
    underline?: boolean;
}

interface ILeaf {
    leaf: TextFormatVariation;
    children: ReactNode;
    attributes: {
        'data-slate-leaf': boolean;
    };
}

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor;

export type ParagraphElement = {
    type: string;
    children: CustomText[];
};

export type ListItemElement = {
    type: string;
    children: ParagraphElement[];
};

export type CustomElement = ParagraphElement | ListItemElement;

export type FormattedText = {
    text: string;
    bold?: boolean;
    underline?: boolean;
    children?: CustomElement[];
};

export type CustomText = FormattedText;

declare module 'slate' {
    interface CustomTypes {
        Editor: CustomEditor;
        Element: CustomElement;
        Text: CustomText;
    }
}

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

interface RichTextExampleProps {
    placeholder?: string;
    fieldName: string;
}

const RichTextExample: React.FC<RichTextExampleProps> = ({ placeholder, fieldName }) => {
    const [field, meta, helpers] = useField(fieldName);

    let { value } = meta;
    const { setValue } = helpers;

    const [stateValue, setStateValue] = React.useState(JSON.parse(value) as Descendant[]);
    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    const editorRef = useRef<Editor>();
    if (!editorRef.current) editorRef.current = withReact(createEditor());

    const editor = editorRef.current;

    React.useEffect(() => {
        if (stateValue === null) setStateValue(secondaryValue);
        if (!Array.isArray(stateValue)) setStateValue(secondaryValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setValue(JSON.stringify(stateValue));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateValue]);

    const secondaryValue = [
        {
            type: 'paragraph',
            children: [{ text: '' }],
        },
    ];

    return stateValue ? (
        <Slate
            {...field}
            editor={editor}
            value={stateValue as Descendant[]}
            onChange={val => {
                setStateValue(val);
            }}>
            <Toolbar>
                <MarkButton format="bold" icon="format_bold" />
                <MarkButton format="underline" icon="format_underlined" />
                <BlockButton format="numbered-list" icon="format_list_numbered" />
                <BlockButton format="bulleted-list" icon="format_list_bulleted" />
            </Toolbar>
            <Editable
                className={styles.richText__placeholder}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder={placeholder}
                spellCheck
            />
        </Slate>
    ) : null;
};

const toggleBlock = (editor: CustomEditor, format: string) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);
    Transforms.unwrapNodes(editor, {
        match: n => {
            return LIST_TYPES.includes(
                // @ts-ignore
                !Editor.isEditor(n) && SlateElement.isElement(n) && n.type,
            );
        },
        split: true,
    });
    const newProperties: Partial<SlateElement> = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

const toggleMark = (editor: CustomEditor, format: string) => {
    // @ts-ignore
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

const isBlockActive = (editor: CustomEditor, format: string) => {
    const [match]: any = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });

    return !!match;
};

const isMarkActive = (editor: CustomEditor, format: keyof Omit<BaseText, 'text'>) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

export const Element: React.FC<any> = ({ attributes, children, element }) => {
    switch (element.type) {
        case 'bulleted-list':
            return (
                <ul className="p-2 m-2" {...attributes}>
                    {children}
                </ul>
            );
        case 'list-item':
            return (
                <li className={`p-2 m-2 ${styles.list_item}`} {...attributes}>
                    {children}
                </li>
            );
        case 'numbered-list':
            return (
                <ol className="p-2 m-2" {...attributes}>
                    {children}
                </ol>
            );
        default:
            return (
                <p className="p-1 m-2" {...attributes}>
                    {children}
                </p>
            );
    }
};

export const Leaf: React.FC<ILeaf> = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }
    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
};

export const BlockButton: React.FC<ToolbarElements> = ({ format, icon }) => {
    const editor = useSlate();
    return (
        <Button
            active={isBlockActive(editor, format)}
            onMouseDown={(event: MouseEvent) => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}>
            <Icon>{icon}</Icon>
        </Button>
    );
};

export const MarkButton: React.FC<ToolbarElements> = ({ format, icon }) => {
    const editor = useSlate();

    return (
        <Button
            active={isMarkActive(editor, format as never)}
            onMouseDown={(event: MouseEvent) => {
                event.preventDefault();
                toggleMark(editor, format);
            }}>
            <Icon>{icon}</Icon>
        </Button>
    );
};

export default RichTextExample;

import { createSlice } from '@reduxjs/toolkit';
import { TStoryState } from './types';
import * as storyActions from './actions';

const initialState: TStoryState = {
    statistics: {
        loading: false,
        error: null,
        data: null,
    },
    categories: {
        loading: false,
        error: null,
        data: null,
    },
};

export const storySlice = createSlice({
    name: 'portalStory',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(storyActions.getStoryStatistics.pending, state => {
            state.statistics.loading = true;
        });
        builder.addCase(storyActions.getStoryStatistics.fulfilled, (state, action) => {
            state.statistics.loading = false;
            console.log('storySlice');
            state.statistics.data = action.payload;
        });
        builder.addCase(storyActions.getStoryStatistics.rejected, (state, action) => {
            state.statistics.loading = false;
            state.statistics.error = action.payload as string;
        });

        builder.addCase(storyActions.getStoryCategories.pending, state => {
            state.categories.loading = true;
            state.categories.data = null;
            state.categories.error = null;
        });
        builder.addCase(storyActions.getStoryCategories.fulfilled, (state, action) => {
            state.categories.loading = false;
            state.categories.data = action.payload;
            state.categories.error = null;
        });
        builder.addCase(storyActions.getStoryCategories.rejected, (state, action) => {
            state.categories.loading = false;
            state.categories.data = null;
            state.categories.error = action.payload as string;
        });
    },
});

export default storySlice.reducer;

import { useClickOutside } from 'hooks/useClickOutside';
import React, { useRef, useState } from 'react';
import AdminSidebar from '../../adminComponents/AdminSidebar/AdminSidebar';
import AdminTopbar from '../../adminComponents/AdminTopbar/AdminTopbar';

import styles from './AdminLayout.module.scss';

interface IAdminLayoutProps {
    title: string;
    children: React.ReactNode;
}

const AdminLayout: React.FC<IAdminLayoutProps> = ({ title, children }) => {
    const [open, setOpen] = useState(false);
    const sidebarRef = useRef(null);
    useClickOutside(sidebarRef, () => setOpen(false), open);
    return (
        <div className={styles.wrapper}>
            <AdminTopbar setOpen={setOpen} />
            <div style={{ border: 'solid 1px red' }} ref={sidebarRef}>
                <AdminSidebar isCondensed={open} />
            </div>
            <div className={`${styles.content} ${open ? styles.open : styles.close}`}>
                {title && (
                    <div className={styles.titleWrapper}>
                        <h4 className={`page-title ${styles.titleText}`}>{title}</h4>
                    </div>
                )}

                {children}
            </div>
        </div>
    );
};

export default AdminLayout;

import React, { useEffect, useState } from 'react';
import styles from './ScrollProgressBar.module.scss';

const ScrollProgressBar = () => {
    const [currentScrollValue, setCurrentScrollValue] = useState(0);
    const scrollHandler = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const height =
            document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrolled = (winScroll / height) * 100;
        setCurrentScrollValue(scrolled);
    };
    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => {
            return window.removeEventListener('scroll', scrollHandler);
        };
    }, [currentScrollValue]);
    return <progress value={currentScrollValue} max={100} className={styles.progress} />;
};
export default React.memo(ScrollProgressBar);

import { axiosInstance } from 'configs/axiosConfig';
import { TStatistics } from 'types/shared';
import { TApiResponse } from 'types/api';

const getStatistics = async (): Promise<TApiResponse<TStatistics>> => {
    return axiosInstance.get('/category-statistics/statistics', {
        params: { type: 'audio' }
    });
};

export const podcastApi = {
    getStatistics,
};

import { INewPodcast } from 'adminComponents/NewPodcast/NewPodcastForm/constants';
import { IFilterParams } from 'models/filters';
import { IPaginationResponse } from 'services/types';
import { IStoryPodcastCategories } from '../adminStories/adminStories.types';
import { IAdminPodcasts } from './../../../services/admin.services/adminPodcasts/adminPodcasts.types';

export enum AdminPodcastsTypes {
    FETCH_ADMIN_PODCASTS_REQUEST = 'FETCH_ADMIN_PODCASTS_REQUEST',
    FETCH_ADMIN_PODCASTS_SUCCESS = 'FETCH_ADMIN_PODCASTS_SUCCESS',
    FETCH_ADMIN_PODCASTS_FAILURE = 'FETCH_ADMIN_PODCASTS_FAILURE',

    DEACTIVATE_ADMIN_PODCASTS_REQUEST = 'DEACTIVATE_ADMIN_PODCASTS_REQUEST',
    DEACTIVATE_ADMIN_PODCASTS_SUCCESS = 'DEACTIVATE_ADMIN_PODCASTS_SUCCESS',
    DEACTIVATE_ADMIN_PODCASTS_FAILURE = 'DEACTIVATE_ADMIN_PODCASTS_FAILURE',

    DELETE_ADMIN_PODCASTS_REQUEST = 'DELETE_ADMIN_PODCASTS_REQUEST',
    DELETE_ADMIN_PODCASTS_SUCCESS = 'DELETE_ADMIN_PODCASTS_SUCCESS',
    DELETE_ADMIN_PODCASTS_FAILURE = 'DELETE_ADMIN_PODCASTS_FAILURE',

    PODCASTS_CATEGORIES_REQUEST = 'PODCASTS_CATEGORIES_REQUEST',
    PODCASTS_CATEGORIES_SUCCESS = 'PODCASTS_CATEGORIES_SUCCESS',
    PODCASTS_CATEGORIES_FAILURE = 'PODCASTS_CATEGORIES_FAILURE',

    POST_NEW_PODCAST_REQUEST = 'POST_NEW_PODCAST_REQUEST',
    POST_NEW_PODCAST_SUCCESS = 'POST_NEW_PODCAST_SUCCESS',
    POST_NEW_PODCAST_FAILURE = 'POST_NEW_PODCAST_FAILURE',

    ACTIVATE_ADMIN_PODCASTS_REQUEST = 'ACTIVATE_ADMIN_PODCASTS_REQUEST',
    ACTIVATE_ADMIN_PODCASTS_SUCCESS = 'ACTIVATE_ADMIN_PODCASTS_SUCCESS',
    ACTIVATE_ADMIN_PODCASTS_FAILURE = 'ACTIVATE_ADMIN_PODCASTS_FAILURE',

    CLEAN_PODCAST_MESSAGE = 'CLEAN_PODCAST_MESSAGE',

    PODCASTS_EXPORT_REQUEST = 'PODCASTS_EXPORT_REQUEST',
    PODCASTS_EXPORT_SUCCESS = 'PODCASTS_EXPORT_SUCCESS',
    PODCASTS_EXPORT_FAILURE = 'PODCASTS_EXPORT_FAILURE',
}

export interface IInitialState {
    csv: string,
    adminPodcasts: IAdminPodcasts[];
    loading: boolean;
    error: string;
    page: number;
    perPage: number;
    totalNumber: number;
    podcastsCategories: IStoryPodcastCategories[];
    postNewPodcastError: string;
    message: string;
}

export interface FetchAdminPodcastsRequestActionType {
    type: AdminPodcastsTypes.FETCH_ADMIN_PODCASTS_REQUEST;
    payload: { page: number; categoryId: string; searchWord: string; storyType: number | null; };
}

export interface FetchAdminPodcastsSuccessActionType {
    type: AdminPodcastsTypes.FETCH_ADMIN_PODCASTS_SUCCESS;
    payload: IPaginationResponse<IAdminPodcasts>;
}

export interface FetchAdminPodcastsFailureActionType {
    type: AdminPodcastsTypes.FETCH_ADMIN_PODCASTS_FAILURE;
    payload: string;
}

export interface DeactivateAdminPodcastsRequestActionType {
    type: AdminPodcastsTypes.DEACTIVATE_ADMIN_PODCASTS_REQUEST;
    payload: {
        podcastId: string;
        page: number;
    };
}
export interface DeactivateAdminPodcastsSuccessActionType {
    type: AdminPodcastsTypes.DEACTIVATE_ADMIN_PODCASTS_SUCCESS;
}

export interface DeactivateAdminPodcastsFailureActionType {
    type: AdminPodcastsTypes.DEACTIVATE_ADMIN_PODCASTS_FAILURE;
    payload: string;
}
export interface activateAdminPodcastsRequestActionType {
    type: AdminPodcastsTypes.ACTIVATE_ADMIN_PODCASTS_REQUEST;
    payload: {
        podcastId: string;
        page: number;
    };
}

export interface activateAdminPodcastsSuccessActionType {
    type: AdminPodcastsTypes.ACTIVATE_ADMIN_PODCASTS_SUCCESS;
}

export interface activateAdminPodcastsFailureActionType {
    type: AdminPodcastsTypes.ACTIVATE_ADMIN_PODCASTS_FAILURE;
    payload: string;
}

export interface DeleteAdminPodcastsRequestActionType {
    type: AdminPodcastsTypes.DELETE_ADMIN_PODCASTS_REQUEST;
    payload: {
        podcastId: string;
        params: IFilterParams
    };
}

export interface DeleteAdminPodcastsSuccessActionType {
    type: AdminPodcastsTypes.DELETE_ADMIN_PODCASTS_SUCCESS;
}

export interface DeleteAdminPodcastsFailureActionType {
    type: AdminPodcastsTypes.DELETE_ADMIN_PODCASTS_FAILURE;
    payload: string;
}
export interface getPodcastsCategoriesRequestActionType {
    type: AdminPodcastsTypes.PODCASTS_CATEGORIES_REQUEST;
}

export interface getPodcastsCategoriesSuccessActionType {
    type: AdminPodcastsTypes.PODCASTS_CATEGORIES_SUCCESS;
    payload: IStoryPodcastCategories[];
}

export interface getPodcastsCategoriesFailureActionType {
    type: AdminPodcastsTypes.PODCASTS_CATEGORIES_FAILURE;
    payload: string;
}

export interface postNewPodcastRequestActionType {
    type: AdminPodcastsTypes.POST_NEW_PODCAST_REQUEST;
    payload: INewPodcast | FormData;
}

export interface postNewPodcastSuccessActionType {
    type: AdminPodcastsTypes.POST_NEW_PODCAST_SUCCESS;
    payload: string;
}

export interface postNewPodcastFailureActionType {
    type: AdminPodcastsTypes.POST_NEW_PODCAST_FAILURE;
    payload: string;
}
export interface cleanNewPodcastMessageActionType {
    type: AdminPodcastsTypes.CLEAN_PODCAST_MESSAGE;
}

export interface getPodcastsExportFailureActionType {
    type: AdminPodcastsTypes.PODCASTS_EXPORT_FAILURE;
    payload: string;
}

export interface getPodcastsExportRequestActionType {
    type: AdminPodcastsTypes.PODCASTS_EXPORT_REQUEST;
    payload: string;
}

export interface getPodcastsExportSuccessActionType {
    type: AdminPodcastsTypes.PODCASTS_EXPORT_SUCCESS;
    payload: string;
}


export type AdminPodcastsActionTypes =
    | FetchAdminPodcastsRequestActionType
    | FetchAdminPodcastsSuccessActionType
    | FetchAdminPodcastsFailureActionType
    | DeactivateAdminPodcastsRequestActionType
    | DeactivateAdminPodcastsSuccessActionType
    | DeactivateAdminPodcastsFailureActionType
    | activateAdminPodcastsRequestActionType
    | activateAdminPodcastsSuccessActionType
    | activateAdminPodcastsFailureActionType
    | getPodcastsCategoriesRequestActionType
    | getPodcastsCategoriesSuccessActionType
    | getPodcastsCategoriesFailureActionType
    | postNewPodcastRequestActionType
    | postNewPodcastSuccessActionType
    | postNewPodcastFailureActionType
    | cleanNewPodcastMessageActionType
    | DeleteAdminPodcastsRequestActionType
    | DeleteAdminPodcastsSuccessActionType
    | DeleteAdminPodcastsFailureActionType
    | getPodcastsExportSuccessActionType
    | getPodcastsExportRequestActionType
    | getPodcastsExportFailureActionType;

import React, { FC, ReactNode } from 'react';
import styles from './Section.module.scss';
import classNames from 'classnames';

type TProps = {
    children: ReactNode;
    className?: string;
}

const Section: FC<TProps> = ({ children, className }) => (
    <div className={classNames(styles.wrapper, className)}>
        {children}
    </div>
);

export default Section;

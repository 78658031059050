import { AxiosResponse } from 'axios';
import { axiosInstance } from 'services/interceptor';
import { IPaginationResponse } from 'services/types';
import { IAdminListItem } from 'store/actions/adminList/adminList.types';
import { INewAdmin } from './admins.types';

export const getAdminListEndpoin = (
    page: number = 0,
    perPage: number = 10,
): Promise<AxiosResponse<IPaginationResponse<IAdminListItem>>> => {
    return axiosInstance.get('/admin/admins', {
        params: { page, perPage },
    });
};

export const deactivateAdminStatusEndpoint = (
    adminId: string,
    page: number,
): Promise<AxiosResponse> => {
    return axiosInstance.delete(`/admin/${adminId}`, { params: { page } });
};

export const activateAdminStatusEndpoint = (
    adminId: string,
    page: number,
): Promise<AxiosResponse> => {
    return axiosInstance.put(`/admin/restore/${adminId}`, { params: { page } });
};

export const createNewAdminEndpoint = (admin: INewAdmin): Promise<AxiosResponse> => {
    return axiosInstance.post(`/admin`, admin);
};

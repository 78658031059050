export const deserialize = el => {
    let children = el.children?.map(deserialize);
    if(el.children && !el.children[0].text) return; 
    switch (el.type) {
        case 'paragraph':
            return (
                <>
                    <p>{children}</p>
                    <br />
                </>
            );

        case 'numbered-list':
            return <ol>{children}</ol>;
        case 'list-item':
            return <li>{children}</li>;
        case 'bulleted-list':
            return <ul>{children}</ul>;
        default:
            if (el.bold) {
                return el.underline ? (
                    <u>
                        <b>{el.text}</b>
                    </u>
                ) : (
                    <b>{el.text}</b>
                );
            } else if (el.underline) {
                return <u>{el.text}</u>;
            }
            return <span>{el.text}</span>;
    }
};

import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as TSwiper } from 'swiper/types';
import 'swiper/swiper.min.css';
import 'swiper/modules/virtual/virtual.min.css';
import podcastLogo from 'assets/images/podcastLogo.svg';
import storyLogo from 'assets/images/storyLogo.svg';
import kioskLogo from 'assets/icons/print_purple.svg';
import { TMapItem } from 'types/map';
import styles from './CardList.module.scss';
import classNames from 'classnames';

const logos = {
    1: <img src={storyLogo} alt="Story logo" className={styles.cardItemLogo} />,
    2:<img src={kioskLogo} alt="Kiosk logo" className={styles.cardItemLogo}/>,
    3: <img src={podcastLogo} alt="Podcast logo" className={styles.cardItemLogo} />
};

type TProps = {
    items: TMapItem[];
    onSelectedChange: (item: TMapItem) => void;
    swiperRef: TSwiper | null;
    setSwiperRef: (swiper: TSwiper | null) => void;
}

const CardList: FC<TProps> = ({
    items,
    onSelectedChange,
    swiperRef,
    setSwiperRef
}) => {
    const { t, i18n: { language } } = useTranslation();

    const dir = useMemo(() => language === 'ar' ? 'rtl' : 'ltr', [language]);

    const handleSlideChange = useCallback((swiper: TSwiper) => {
        onSelectedChange(items[swiper.realIndex]);
    }, [items, onSelectedChange]);

    const handleCardClick = useCallback((item: TMapItem, index) => {
        onSelectedChange(item);
        swiperRef?.slideTo(index + items.length);
    }, [swiperRef, onSelectedChange, items.length]);

    return (
        <div className={styles.cardList}>
            <Swiper
                modules={[Mousewheel]}
                spaceBetween={20}
                slidesPerView="auto"
                centeredSlides
                mousewheel
                loop
                className={styles.swiper}
                dir={dir}
                onSlideChange={handleSlideChange}
                onInit={(swiper) => {
                    setSwiperRef(swiper);
                }}
            >
                {items.map((item, index) => (
                    <SwiperSlide
                        key={item.id}
                        virtualIndex={index}
                        className={styles.swiperSlide}
                        onClick={() => handleCardClick(item, index)}
                    >
                        <button className={styles.cardItem}>
                            {logos[item.type as keyof typeof logos]}
                            <div className={styles.cardText}>
                                <h3 className={styles.cardItemTitle}>{item.title}</h3>
                                <a
                                    href={`https://www.google.com/maps/@${item.latitude},${item.longitude},13z`}
                                    rel="noreferrer"
                                    target="_blank"
                                    className={classNames(styles.cardLink, {
                                        [styles.storyLink]: item.type === 1,
                                        [styles.kioskLink]: item.type === 2,
                                        [styles.podcastLink]: item.type === 3
                                    })}
                                >
                                    {t('map.link')}
                                </a>
                            </div>
                        </button>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default memo(CardList);

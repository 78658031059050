import { FC } from 'react';
import Paths from 'constants/paths';
import * as AdminPages from 'adminPages';
import * as PortalPages from 'portalPages';

export const ADMIN: string = 'admin';

export enum fieldesNameType {
    PRIVATE = 'private',
    PUBLIC = 'public',
    ADMIN_PRIVATE = 'admin-private',
    ADMIN_PUBLIC = 'admin-public',
}

export interface IRoute {
    path: Paths;
    exact: boolean;
    component: FC;
    fieldName: fieldesNameType;
}

export const routes: IRoute[] = [
    {
        path: Paths.HOME,
        fieldName: fieldesNameType.PRIVATE,
        exact: true,
        component: PortalPages.Home,
    },
    {
        path: Paths.STORY_CATEGORIES,
        fieldName: fieldesNameType.PRIVATE,
        exact: true,
        component: PortalPages.StoryCategories,
    },
    {
        path: Paths.ADMIN_LOGIN,
        fieldName: fieldesNameType.ADMIN_PUBLIC,
        exact: true,
        component: AdminPages.AdminLoginPage,
    },
    {
        path: Paths.ADMIN_PODCASTS,
        fieldName: fieldesNameType.ADMIN_PRIVATE,
        exact: true,
        component: AdminPages.PodcastsPage,
    },
    {
        path: Paths.ADMIN_KIOSKS,
        fieldName: fieldesNameType.ADMIN_PRIVATE,
        exact: true,
        component: AdminPages.KiosksPage,
    },
    {
        path: Paths.ADMIN_MAP_DATA,
        fieldName: fieldesNameType.ADMIN_PRIVATE,
        exact: true,
        component: AdminPages.AdminMapDataPage,
    },
    {
        path: Paths.ADMIN_EMAIL_STORAGE,
        fieldName: fieldesNameType.ADMIN_PRIVATE,
        exact: true,
        component: AdminPages.AdminEmailStoragePage,
    },
    {
        path: Paths.ADMIN_NEW_NOTIFICATION,
        fieldName: fieldesNameType.ADMIN_PRIVATE,
        exact: true,
        component: AdminPages.NewNotificationPage,
    },
    {
        path: Paths.ADMIN_STORIES,
        fieldName: fieldesNameType.ADMIN_PRIVATE,
        exact: true,
        component: AdminPages.StoriesPage,
    },
    {
        path: Paths.ADMIN_LIST,
        fieldName: fieldesNameType.ADMIN_PRIVATE,
        exact: true,
        component: AdminPages.AdminListPage,
    },
    {
        path: Paths.ADMIN_NEW_ADMIN,
        fieldName: fieldesNameType.ADMIN_PRIVATE,
        exact: true,
        component: AdminPages.AddAdminPage,
    },
    {
        path: Paths.ADMIN_NEW_STORY,
        fieldName: fieldesNameType.ADMIN_PRIVATE,
        exact: true,
        component: AdminPages.NewStoryPage,
    },
    {
        path: Paths.ADMIN_NEW_PODCAST,
        fieldName: fieldesNameType.ADMIN_PRIVATE,
        exact: true,
        component: AdminPages.NewPodcastPage,
    },
    {
        path: Paths.ADMIN_NEW_KIOSK,
        fieldName: fieldesNameType.ADMIN_PRIVATE,
        exact: true,
        component: AdminPages.NewKioskPage,
    },
    {
        path: Paths.ADMIN_NEW_MAP_DATA,
        fieldName: fieldesNameType.ADMIN_PRIVATE,
        exact: true,
        component: AdminPages.NewMapDataPage,
    },
    {
        path: Paths.ADMIN_STORY_DETAIL,
        fieldName: fieldesNameType.ADMIN_PRIVATE,
        exact: false,
        component: AdminPages.AdminStoryDetails,
    },
    {
        path: Paths.ADMIN_PODCAST_DETAIL,
        fieldName: fieldesNameType.ADMIN_PRIVATE,
        exact: false,
        component: AdminPages.AdminPodcastDetails,
    },
    {
        path: Paths.ADMIN_KIOSK_DETAIL,
        fieldName: fieldesNameType.ADMIN_PRIVATE,
        exact: false,
        component: AdminPages.AdminKioskDeatils,
    },
    {
        path: Paths.ADMIN_MAP_DATA_DETAIL,
        fieldName: fieldesNameType.ADMIN_PRIVATE,
        exact: false,
        component: AdminPages.AdminMapDataDetails,
    },
    {
        path: Paths.STORY,
        fieldName: fieldesNameType.PRIVATE,
        exact: false,
        component: PortalPages.Story,
    },
    {
        path: Paths.PUBLISHER,
        fieldName: fieldesNameType.PRIVATE,
        exact: false,
        component: PortalPages.Publisher,
    },
    {
        path: Paths.PODCAST_CATEGORIES,
        fieldName: fieldesNameType.PRIVATE,
        exact: true,
        component: PortalPages.PodcastCategories,
    },
    {
        path: Paths.PODCAST,
        fieldName: fieldesNameType.PRIVATE,
        exact: false,
        component: PortalPages.Podcast,
    },
    {
        path: Paths.PODCAST_PUBLISHER,
        fieldName: fieldesNameType.PRIVATE,
        exact: true,
        component: PortalPages.Publisher,
    },
    {
        path: Paths.CHANGE_PASSWORD,
        fieldName: fieldesNameType.ADMIN_PRIVATE,
        exact: true,
        component: AdminPages.ChangePassword,
    },
    {
        path: Paths.STORY_STATISTICS,
        fieldName: fieldesNameType.PRIVATE,
        exact: true,
        component: PortalPages.StoryStatistics,
    },
    {
        path: Paths.PODCAST_STATISTICS,
        fieldName: fieldesNameType.PRIVATE,
        exact: true,
        component: PortalPages.PodcastStatistics,
    },
    {
        path: Paths.EMAIL_SUBSCRIPTION,
        fieldName: fieldesNameType.PRIVATE,
        exact: true,
        component: PortalPages.EmailSubscription,
    },
    {
        path: Paths.MAP,
        fieldName: fieldesNameType.PRIVATE,
        exact: true,
        component: PortalPages.Map,
    },
    // {
    //     path: Paths.CHILDREN_STORY_CATEGORIES,
    //     fieldName: fieldesNameType.PRIVATE,
    //     exact: true,
    //     component: PortalPages.ChildrenStoryCategories,
    // },
];

import React, { FocusEvent } from 'react';
import Select from 'react-select';
import { useFormik } from 'formik';
import Form from 'react-bootstrap/Form';
import styles from './ExportPanel.module.scss';
import Section from 'adminPages/Section';
import classNames from 'classnames';

type TProps<FormData> = {
    fields: TExportPanelFields;
    initialValues: FormData;
    onExport: (data: FormData) => void;
    validationSchema: any | (() => any);
    disabled?: boolean;
};

export type TExportPanelFields<Fields = Record<string, string>> = TExportPanelField<keyof Fields>[];

export type TExportPanelField<Fields> =
    | TTextField<Fields>
    | TDateField<Fields>
    | TSelectField<Fields>

export type TTextField<Fields> = {
    type: 'text';
    name: Fields;
    label?: string;
    placeholder?: string;
}

export type TDateField<Fields> = {
    type: 'date';
    name: Fields;
    label?: string;
    placeholder?: string;
    minDate?: string;
    maxDate?: string;
};

export type TSelectField<Fields> = {
    type: 'select';
    name: Fields;
    label?: string;
    options: TSelectFieldOption[];
    maxMenuHeight?: number;
    isSearchable?: boolean;
}

export type TSelectFieldOption = {
    value: string | boolean;
    label: string;
}

const ExportPanel = <FormData extends Record<string, any>>({
    fields,
    initialValues,
    onExport,
    validationSchema,
    disabled,
}: TProps<FormData>) => {
    const formik = useFormik<FormData>({
        initialValues,
        validationSchema,
        onSubmit: (data) => {
            onExport(data);
        },
    });

    return (
        <Section>
            <form onSubmit={formik.handleSubmit} className={styles.form} noValidate>
                <div className={styles.filters}>
                    {fields.map((field) => (
                        <div className={styles.formGroup} key={field.name}>
                            {field.label && (
                                <label className={styles.formLabel}>{field.label}</label>
                            )}
                            <div className={styles.field}>
                                {field.type === 'text' ? (
                                    <Form.Control
                                        className={classNames(styles.input, {
                                            [styles.inputError]: formik.errors[field.name] && formik.touched[field.name],
                                        })}
                                        type="text"
                                        name={field.name}
                                        placeholder={field.placeholder}
                                        value={formik.values[field.name]}
                                        onChange={formik.handleChange}
                                        disabled={disabled}
                                    />
                                ) : null}
                                {field.type === 'date' ? (
                                    <Form.Control
                                        className={classNames(styles.input, {
                                            [styles.inputError]: formik.errors[field.name] && formik.touched[field.name],
                                        })}
                                        type="text"
                                        name={field.name}
                                        value={formik.values[field.name]}
                                        placeholder={field.placeholder}
                                        onChange={formik.handleChange}
                                        max={field.maxDate}
                                        min={field.minDate}
                                        onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                            event.target.type = 'date';
                                        }}
                                        onBlur={(event: FocusEvent<HTMLInputElement>) => {
                                            event.target.type = 'text';
                                        }}
                                        disabled={disabled}
                                    />
                                ) : null}
                                {field.type === 'select' ? (
                                    <Select
                                        className={classNames(styles.select, {
                                            [styles.selectError]: formik.errors[field.name] && formik.touched[field.name],
                                        })}
                                        options={field.options}
                                        isSearchable={field.isSearchable || false}
                                        maxMenuHeight={field.maxMenuHeight}
                                        onChange={(item) => {
                                            formik.setFieldValue(field.name, item?.value);
                                        }}
                                        value={field.options.find((option: TSelectFieldOption) => {
                                            return option.value === formik.values[field.name];
                                        })}
                                        disabled={disabled}
                                    />
                                ) : null}
                                {formik.errors[field.name] && formik.touched[field.name] && (
                                    <p className={styles.error}>{formik.errors[field.name]}</p>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <button
                    className={styles.exportButton}
                    type="submit"
                    disabled={disabled}
                >
                    Export
                </button>
            </form>
        </Section>
    );
};

export default ExportPanel;

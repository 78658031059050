import React, { useState, ReactNode } from 'react';
import styles from './styles.module.scss';
import { ReactComponent as ChevronDown } from 'assets/images/chevronDown.svg';

interface INewStoryAccordion {
    title: string;
    children: ReactNode;
}

type Height = number | 'auto' | `${number}%`;

const NewStoryAccordion: React.FC<INewStoryAccordion> = ({ title, children }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [height, setHeight] = useState<Height>(0);
    const handleClick = () => {
        setHeight(height === 0 ? 'auto' : 0);
        setOpen(prevState => !prevState);
    };
    return (
        <div>
            <div className={styles.formBlockClose} onClick={() => handleClick()}>
                {title}{' '}
                <ChevronDown className={open ? styles.chevronClose : styles.chevron} />
            </div>
            <div
                style={{
                    overflow: 'hidden',
                    maxHeight: height === 0 ? '0' : 5000,
                    transition: 'all 0.5s ease-in-out',
                }}>
                <div className={styles.formBlockOpen}>{children}</div>
            </div>
        </div>
    );
};

export default NewStoryAccordion;

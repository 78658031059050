import React, { FC, memo } from 'react';
import DarkFooter from './DarkFooter';
import LightFooter from 'portalComponents/Footer/LightFooter';

export type TFooterProps = {
    variant: 'dark' | 'light';
    showSubscribeButton?: boolean;
}

const Footer: FC<TFooterProps> = ({
    variant,
    showSubscribeButton = true,
}) => {
    switch (variant) {
        case 'dark': {
            return <DarkFooter showSubscribeButton={showSubscribeButton} />;
        }
        case 'light': {
            return <LightFooter />;
        }
        default: {
            return null;
        }
    }
};

export default memo(Footer);

import {
    AdminsListActionTypes,
    IInitialState,
    AdminListsTypes,
} from 'store/actions/adminList/adminList.types';

const InitialState: IInitialState = {
    adminsList: [],
    loading: false,
    error: '',
    page: 1,
    perPage: 10,
    totalNumber: 0,
    message: '',
};

export const AdminsListReducer = (
    state = InitialState,
    action: AdminsListActionTypes,
): IInitialState => {
    switch (action.type) {
        case AdminListsTypes.FETCH_ADMINS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminListsTypes.FETCH_ADMINS_SUCCESS:
            return {
                ...state,
                loading: false,
                adminsList: action.payload.items,
                perPage: action.payload.perPage,
                page: action.payload.page,
                totalNumber: action.payload.totalNumber,
            };
        case AdminListsTypes.FETCH_ADMINS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminListsTypes.DEACTIVATE_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminListsTypes.DEACTIVATE_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case AdminListsTypes.DEACTIVATE_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminListsTypes.ACTIVATE_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminListsTypes.ACTIVATE_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case AdminListsTypes.ACTIVATE_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminListsTypes.ADD_NEW_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminListsTypes.ADD_NEW_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                message: action.payload,
            };
        case AdminListsTypes.ADD_NEW_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminListsTypes.CLEAR_ADMIN_LIST_MESSAGE:
            return {
                ...state,
                loading: false,
                error: '',
                message: '',
            };
        default:
            return state;
    }
};

import { axiosInstance } from 'configs/axiosConfig';
import { TStatistics } from 'types/shared';
import { TApiResponse } from 'types/api';
import { TCategoryItem } from 'types/category';

const getStatistics = async (): Promise<TApiResponse<TStatistics>> => {
    return axiosInstance.get('/category-statistics/statistics', {
        params: { type: 'story' }
    });
};

const getStoryCategories = async (): Promise<TApiResponse<TCategoryItem[]>> => {
    return axiosInstance.get('/short-story-categories');
};

export const storyApi = {
    getStatistics,
    getStoryCategories,
};

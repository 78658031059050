import {
    AdminKiosksTypes,
    DeactivateAdminKiosksRequestActionType,
    FetchAdminKiosksRequestActionType,
    postNewKioskRequestActionType,
    DeleteAdminKiosksRequestActionType,
    activateAdminKiosksRequestActionType,
    getKiosksExportRequestActionType,
} from './../actions/adminKiosks/adminKiosks.types';
import * as adminKiosksEndPoints from '../../services/admin.services/adminKiosks/adminKiosks.endpoints';
import { IAdminKiosks } from './../../services/admin.services/adminKiosks/adminKiosks.types';
import * as AdminKiosksActions from '../actions/adminKiosks/adminKiosks.action';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IPaginationResponse } from 'services/types';
import { AxiosResponse } from 'axios';
import { IKioskCategories } from 'store/actions/adminKiosks/adminKiosks.types';
import { IFilterParams } from 'models/filters';
import { getAdminsKiosksCategoriesFailureAction } from '../actions/adminKiosks/adminKiosks.action';
import { activateAdminFailureAction } from 'store/actions/adminList/adminsList.action';

function* fetchAdminKiosksWorker(action: FetchAdminKiosksRequestActionType) {
    const values = action.payload;
    const filteredObj = Object.keys(values).reduce((acc, item) => {
        if (values[item as keyof IFilterParams] !== '') {
            return {
                ...acc,
                [item]: values[item as keyof IFilterParams],
            };
        } else return acc;
    }, {});

    const { page, searchWord, categoryId } = filteredObj as IFilterParams;

    try {
        const { data }: { data: IPaginationResponse<IAdminKiosks> } = yield call(
            adminKiosksEndPoints.getAdminKiosksEndpoint,
            page,
            searchWord,
            categoryId,
        );
        yield put(AdminKiosksActions.fetchAdminKiosksSuccesstAction(data));
    } catch (error) {}
}

function* deactivateAdminKiosksWorker(action: DeactivateAdminKiosksRequestActionType) {
    const id = action.payload.podcastId;
    const page = action.payload.page;

    try {
        yield call(adminKiosksEndPoints.deactivateAdminKiosksEndpoint, id, page);
        yield put(AdminKiosksActions.deactivateAdminKiosksSuccesstAction());
        yield put(AdminKiosksActions.fetchAdminKiosksRequestAction(page));
    } catch (error: any) {}
}

function* deleteAdminKiosksWorker(action: DeleteAdminKiosksRequestActionType) {
    const id = action.payload.kioskId;
    const { page, searchWord, categoryId } = action.payload.params;

    try {
        yield call(adminKiosksEndPoints.deleteAdminKiosksEndpoint, id);
        yield put(AdminKiosksActions.deleteAdminKiosksSuccesstAction());
        yield put(
            AdminKiosksActions.fetchAdminKiosksRequestAction(
                page,
                searchWord,
                categoryId,
            ),
        );
    } catch (error: any) {
        yield put(AdminKiosksActions.deleteAdminKiosksFailureAction(error.message));
    }
}

function* activateAdminKiosksWorker(action: activateAdminKiosksRequestActionType) {
    const id = action.payload.kioskId;
    const page = action.payload.page;
    const value = action.payload.value;

    try {
        yield call(adminKiosksEndPoints.activateAdminKiosksEndpoint, id, page, value);
        yield put(AdminKiosksActions.activateAdminKiosksSuccesstAction());
        yield put(AdminKiosksActions.fetchAdminKiosksRequestAction(page));
    } catch (error: any) {
        yield put(activateAdminFailureAction(error.message));
    }
}

export function* getAdminKiosksCategoriesWorker() {
    try {
        const categoriesResponse: AxiosResponse<IKioskCategories[]> = yield call(
            adminKiosksEndPoints.getAdminKiosksCategoriesEndpoin,
        );
        yield put(
            AdminKiosksActions.getAdminsKiosksCategoriesSuccessAction(
                categoriesResponse.data,
            ),
        );
    } catch (error: any) {
        yield put(getAdminsKiosksCategoriesFailureAction(error.message as string));
    }
}

export function* getAdminsKiosksExportToCsv(action: getKiosksExportRequestActionType) {
    try {
        const exportResponse: AxiosResponse = yield call(
            adminKiosksEndPoints.getAdminsKiosksExportToCsvEndpoint,
            action.payload,
        );
        yield put(
            AdminKiosksActions.getAdminsKiosksExportToCsvSuccessAction(
                exportResponse.data,
            ),
        );
    } catch (error: any) {
        yield put(getAdminsKiosksCategoriesFailureAction(error.message as string));
    }
}

export function* postNewKioskWorker(action: postNewKioskRequestActionType) {
    try {
        yield call(adminKiosksEndPoints.postNewKioskEndpoint, action.payload);
        yield put(AdminKiosksActions.postNewKioskSuccessAction('Created Successfully'));
    } catch (error: any) {
        yield put(AdminKiosksActions.postNewKioskFailureAction(error.message as string));
    }
}

export function* adminKiosksSaga() {
    yield all([
        takeLatest(AdminKiosksTypes.FETCH_ADMIN_KIOSKS_REQUEST, fetchAdminKiosksWorker),
        takeLatest(
            AdminKiosksTypes.DEACTIVATE_ADMIN_KIOSKS_REQUEST,
            deactivateAdminKiosksWorker,
        ),
        takeLatest(
            AdminKiosksTypes.ACTIVATE_ADMIN_KIOSKS_REQUEST,
            activateAdminKiosksWorker,
        ),
        takeLatest(
            AdminKiosksTypes.KIOSKS_CATEGORIES_REQUEST,
            getAdminKiosksCategoriesWorker,
        ),
        takeLatest(AdminKiosksTypes.POST_NEW_KIOSK_REQUEST, postNewKioskWorker),
        takeLatest(AdminKiosksTypes.DELETE_ADMIN_KIOSKS_REQUEST, deleteAdminKiosksWorker),
        takeLatest(AdminKiosksTypes.KIOSKS_EXPORT_REQUEST, getAdminsKiosksExportToCsv),
    ]);
}

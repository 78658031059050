import { IMapDataTypes } from '../adminMapData/adminMapData.types';

export enum mapDataDetailsTypes {
    FETCH_MAP_DATA_DETAIL_REQUEST = 'FETCH_MAP_DATA_DETAIL_REQUEST',
    FETCH_MAP_DATA_DETAIL_SUCCESS = 'FETCH_MAP_DATA_DETAIL_SUCCESS',
    FETCH_MAP_DATA_DETAIL_FAILURE = 'FETCH_MAP_DATA_DETAIL_FAILURE',

    EDIT_MAP_DATA_REQUEST = 'EDIT_MAP_DATA_REQUEST',
    EDIT_MAP_DATA_SUCCESS = 'EDIT_MAP_DATA_SUCCESS',
    EDIT_MAP_DATA_FAILURE = 'EDIT_MAP_DATA_FAILURE',
}

export interface IInitialMapDataState {
    mapDataDetails: IMapDataTypes | null;
    loading: boolean;
    error: string;
    message: string;
}

export interface fetchMapDataDetailsRequestActionType {
    type: mapDataDetailsTypes.FETCH_MAP_DATA_DETAIL_REQUEST;
    payload: string;
}

export interface fetchMapDataDetailsSuccessActionType {
    type: mapDataDetailsTypes.FETCH_MAP_DATA_DETAIL_SUCCESS;
    payload: IMapDataTypes;
}

export interface fetchMapDataDetailsFailureActionType {
    type: mapDataDetailsTypes.FETCH_MAP_DATA_DETAIL_FAILURE;
    payload: string;
}

export interface editMapDataRequestActionType {
    type: mapDataDetailsTypes.EDIT_MAP_DATA_REQUEST;
    payload: {
        newLocation: any;
        id: string;
    };
}

export interface editMapDataSuccessActionType {
    type: mapDataDetailsTypes.EDIT_MAP_DATA_SUCCESS;
    payload: string;
}

export interface editMapDataFailureActionType {
    type: mapDataDetailsTypes.EDIT_MAP_DATA_FAILURE;
    payload: string;
}
export type MapDataDetailsActionTypes =
    | fetchMapDataDetailsRequestActionType
    | fetchMapDataDetailsSuccessActionType
    | fetchMapDataDetailsFailureActionType
    | editMapDataRequestActionType
    | editMapDataSuccessActionType
    | editMapDataFailureActionType;

import {
    IPodcastDetailResponse,
    IShortStoryAndPodcastResponse,
    IStoryDetailsResponse,
} from 'services/portalServices/portal.types';
import {
    IgetAnodtherPodcastDetails,
    IgetAnotherStoryDetailFailure,
    IgetAnotherStoryDetailRequest,
    IgetAnotherStoryDetailSuccess,
    IgetPodcastDetailFailure,
    IgetPodcastDetailRequest,
    IgetPodcastDetailSuccess,
    IgetRandomPodcastFailure,
    IgetRandomPodcastRequest,
    IgetRandomPodcastSuccess,
    IgetRandomStoryFailure,
    IgetRandomStoryRequest,
    IgetRandomStorySuccess,
    IgetShortStoryCategoriesFailure,
    IgetShortStoryCategoriesRequest,
    IgetShortStoryCategoriesSuccess,
    IgetStoryDetailFailure,
    IgetStoryDetailRequest,
    IgetStoryDetailSuccess,
    shortStoryActionTypes,
} from './portal.types';

//// story /////
export const getShortStoryCategoryAction = {
    request: (isAudioPage: boolean): IgetShortStoryCategoriesRequest => ({
        type: shortStoryActionTypes.GET_SHORT_STORY_CATEGORIES_REQUEST,
        payload: isAudioPage,
    }),
    success: (
        payload: IShortStoryAndPodcastResponse[],
    ): IgetShortStoryCategoriesSuccess => ({
        type: shortStoryActionTypes.GET_SHORT_STORY_CATEGORIES_SUCCESS,
        payload,
    }),
    failure: (payload: string): IgetShortStoryCategoriesFailure => ({
        type: shortStoryActionTypes.GET_SHORT_STORY_CATEGORIES_FAILURE,
        payload,
    }),
};
export const getStoryDetailAction = {
    request: (id: string): IgetStoryDetailRequest => ({
        type: shortStoryActionTypes.GET_STORY_DETAIL_REQUEST,
        payload: id,
    }),
    success: (payload: IStoryDetailsResponse): IgetStoryDetailSuccess => ({
        type: shortStoryActionTypes.GET_STORY_DETAIL_SUCCESS,
        payload,
    }),
    failure: (payload: string): IgetStoryDetailFailure => ({
        type: shortStoryActionTypes.GET_STORY_DETAIL_FAILURE,
        payload,
    }),
};

export const getAnotherStoryAction = {
    request: (
        categodyId: string,
        ommitStoryId: string,
    ): IgetAnotherStoryDetailRequest => ({
        type: shortStoryActionTypes.GET_ANOTHER_STORY_DETAILS_REQUEST,
        payload: {
            ommitStoryId,
            categodyId,
        },
    }),
    success: (payload: IStoryDetailsResponse): IgetAnotherStoryDetailSuccess => ({
        type: shortStoryActionTypes.GET_ANOTHER_STORY_DETAILS_SUCCESS,
        payload,
    }),
    failure: (payload: string): IgetAnotherStoryDetailFailure => ({
        type: shortStoryActionTypes.GET_ANOTHER_STORY_DETAILS_FAILURE,
        payload,
    }),
};
export const getRandomStoryAction = {
    request: (payload: string): IgetRandomStoryRequest => ({
        type: shortStoryActionTypes.GET_RANDOM_STORY_REQUEST,
        payload,
    }),
    success: (): IgetRandomStorySuccess => ({
        type: shortStoryActionTypes.GET_RANDOM_STORY_SUCCESS,
    }),
    failure: (payload: string): IgetRandomStoryFailure => ({
        type: shortStoryActionTypes.GET_RANDOM_STORY_FAILURE,
        payload,
    }),
};

//// audio ///
export const getPodcastDetailAction = {
    request: (id: string): IgetPodcastDetailRequest => ({
        type: shortStoryActionTypes.GET_PODCAST_DETAIL_REQUEST,
        payload: id,
    }),
    success: (payload: IPodcastDetailResponse): IgetPodcastDetailSuccess => ({
        type: shortStoryActionTypes.GET_PODCAST_DETAIL_SUCCESS,
        payload,
    }),
    failure: (payload: string): IgetPodcastDetailFailure => ({
        type: shortStoryActionTypes.GET_PODCAST_DETAIL_FAILURE,
        payload,
    }),
};
export const getAnotherPodcastDetailAction = (
    id: string,
    audioToOmitId: string,
): IgetAnodtherPodcastDetails => ({
    type: shortStoryActionTypes.GET_ANOTHER_PODCAST_DETAILS,
    payload: { id, audioToOmitId },
});

export const getRandomPodcastAction = {
    request: (payload: string): IgetRandomPodcastRequest => ({
        type: shortStoryActionTypes.GET_RANDOM_PODCAST_REQUEST,
        payload,
    }),
    success: (): IgetRandomPodcastSuccess => ({
        type: shortStoryActionTypes.GET_RANDOM_PODCAST_SUCCESS,
    }),
    failure: (payload: string): IgetRandomPodcastFailure => ({
        type: shortStoryActionTypes.GET_RANDOM_PODCAST_FAILURE,
        payload,
    }),
};

import React, { FC } from 'react';
import classNames from 'classnames';
import storyBgCard1 from 'assets/images/storyCategoryCardBg1.svg';
import storyBgCard2 from 'assets/images/storyCategoryCardBg2.svg';
import storyBgCard3 from 'assets/images/storyCategoryCardBg3.svg';
import storyBgCard4 from 'assets/images/storyCategoryCardBg4.svg';
import podcastBgCard1 from 'assets/images/podcastCategoryCard1.svg';
import podcastBgCard2 from 'assets/images/podcastCategoryCard2.svg';
import podcastBgCard3 from 'assets/images/podcastCategoryCard3.svg';
import podcastBgCard4 from 'assets/images/podcastCategoryCard4.svg';
import { TCategoryItem } from 'types/category';
import styles from './CategoriesList.module.scss';

const bgList = {
    story: {
        1: storyBgCard1,
        2: storyBgCard2,
        3: storyBgCard3,
        4: storyBgCard3,
    },
    podcast: {
        1: podcastBgCard1,
        2: podcastBgCard2,
        3: podcastBgCard3,
        4: podcastBgCard4,
    },
};

export type TCategoriesListProps = {
    categories: TCategoryItem[];
    variant: 'story' | 'podcast';
    onCardClick: (id: string) => void;
};

const CategoriesList: FC<TCategoriesListProps> = ({
    categories,
    variant,
    onCardClick,
}) => (
    <div className={styles.wrapper}>
        {categories.map((item, index) => {
            const title = item.titleAr || item.titleEn || item.titleFr;
            const description =
                item.descriptionAr || item.descriptionEn || item.descriptionFr;
            const img = `${process.env.REACT_APP_URL}${item.image}`;
            const background = bgList[variant][((index % 4) + 1) as 1 | 2 | 3 | 4];

            return (
                <button
                    key={item.id}
                    className={styles.cardWrapper}
                    onClick={() => onCardClick(item.id)}>
                    <img
                        src={background}
                        alt="Card background"
                        className={styles.cardBackground}
                    />
                    <div className={styles.cardContent}>
                        <img
                            src={img}
                            alt="Card icon"
                            className={classNames(styles.cardImage, variant)}
                        />
                        <div className={styles.cardText}>
                            <div className={styles.cardTitle}>{title}</div>
                            <div className={classNames(styles.cardSubtitle, variant)}>
                                {description}
                            </div>
                        </div>
                    </div>
                </button>
            );
        })}
    </div>
);

export default CategoriesList;

import { INewPodcast } from 'adminComponents/NewPodcast/NewPodcastForm/constants';
import { IFilterParams } from 'models/filters';
import { IPaginationResponse } from 'services/types';
import { IStoryPodcastCategories } from '../adminStories/adminStories.types';
import { IAdminPodcasts } from './../../../services/admin.services/adminPodcasts/adminPodcasts.types';
import { AdminPodcastsTypes } from './adminPodcasts.types';

export const fetchAdminPodcastsRequestAction = (
    page: number = 0,
    searchWord?: string,
    categoryId?: string,
) => ({
    type: AdminPodcastsTypes.FETCH_ADMIN_PODCASTS_REQUEST,
    payload: { page, searchWord, categoryId },
});

export const fetchAdminPodcastsSuccesstAction = (
    podcastsResponse: IPaginationResponse<IAdminPodcasts>,
) => ({
    type: AdminPodcastsTypes.FETCH_ADMIN_PODCASTS_SUCCESS,
    payload: podcastsResponse,
});

export const fetchAdminPodcastsFailureAction = (error: string) => ({
    type: AdminPodcastsTypes.FETCH_ADMIN_PODCASTS_FAILURE,
    payload: error,
});

export const deactivateAdminPodcastsRequestAction = (
    podcastId: string,
    page: number,
) => ({
    type: AdminPodcastsTypes.DEACTIVATE_ADMIN_PODCASTS_REQUEST,
    payload: {
        podcastId,
        page,
    },
});

export const deleteAdminPodcastRequestAction = (podcastId: string, params: IFilterParams) => ({
    type: AdminPodcastsTypes.DELETE_ADMIN_PODCASTS_REQUEST,

    payload: {
        podcastId,
        params,
    },
});

export const deleteAdminPodcastsFailureAction = (error: string) => ({
    type: AdminPodcastsTypes.DELETE_ADMIN_PODCASTS_FAILURE,
    payload: error,
});

export const deleteAdminPodcastsSuccesstAction = () => {
    return {
        type: AdminPodcastsTypes.DELETE_ADMIN_PODCASTS_SUCCESS,
    };
};
export const deactivateAdminPodcastsSuccesstAction = () => ({
    type: AdminPodcastsTypes.DEACTIVATE_ADMIN_PODCASTS_SUCCESS,
});

export const deactivateAdminPodcastsFailureAction = (error: string) => ({
    type: AdminPodcastsTypes.DEACTIVATE_ADMIN_PODCASTS_FAILURE,
    payload: error,
});
export const activateAdminPodcastsRequestAction = (podcastId: string, page: number) => ({
    type: AdminPodcastsTypes.ACTIVATE_ADMIN_PODCASTS_REQUEST,
    payload: {
        podcastId,
        page,
    },
});

export const activateAdminPodcastsSuccesstAction = () => ({
    type: AdminPodcastsTypes.ACTIVATE_ADMIN_PODCASTS_SUCCESS,
});

export const activateAdminPodcastsFailureAction = (error: string) => ({
    type: AdminPodcastsTypes.ACTIVATE_ADMIN_PODCASTS_FAILURE,
    payload: error,
});

export const getAdminsPodcastsCategoriesRequestAction = () => ({
    type: AdminPodcastsTypes.PODCASTS_CATEGORIES_REQUEST,
});

export const getAdminsPodcastsExportToCsvRequestAction = () => ({
    type: AdminPodcastsTypes.PODCASTS_EXPORT_REQUEST,
});

export const getAdminsPodcastsExportToCsvFailureAction = () => ({
    type: AdminPodcastsTypes.PODCASTS_EXPORT_FAILURE,
});

export const getAdminsPodcastsExportToCsvSuccessAction = (csv:string) => ({
    type: AdminPodcastsTypes.PODCASTS_EXPORT_SUCCESS,
    payload: csv,
});

export const getAdminsPodcastsCategoriesSuccessAction = (
    categories: IStoryPodcastCategories[],
) => ({
    type: AdminPodcastsTypes.PODCASTS_CATEGORIES_SUCCESS,
    payload: categories,
});

export const getAdminsPodcastsCategoriesFailureAction = (message: string) => ({
    type: AdminPodcastsTypes.PODCASTS_CATEGORIES_FAILURE,
    payload: message,
});

export const postNewPodcastRequestAction = (newPodcast: INewPodcast | FormData) => ({
    type: AdminPodcastsTypes.POST_NEW_PODCAST_REQUEST,
    payload: newPodcast,
});

export const postNewPodcastSuccessAction = (message: string) => ({
    type: AdminPodcastsTypes.POST_NEW_PODCAST_SUCCESS,
    payload: message,
});

export const postNewPodcastFailureAction = (message: string) => ({
    type: AdminPodcastsTypes.POST_NEW_PODCAST_FAILURE,
    payload: message,
});
export const cleanNewPodcastMessageAction = () => ({
    type: AdminPodcastsTypes.CLEAN_PODCAST_MESSAGE,
});

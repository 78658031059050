import React from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import {
    IInitialStoriesValue,
    FieldsVariation,
    initialStoryPageFormsFields,
    IFieldStoryItem,
    IStoryOptions,
} from './constants';
import Switch from 'react-switch';
import styles from '../NewStory.module.scss';
import TextEditor from 'sharedComponents/TextEditor';
import { IStoryPodcastCategories } from 'store/actions/adminStories/adminStories.types';
import { newStoriesValidationSchema } from 'utils/validationSchemas';
import DragDropFile from 'sharedComponents/DropdownFile/DropDownFile';
import Select from 'react-select';
import { disableSubmit, websiteLinkGenerator } from '../../../utils/helpers';
import NewStoryAccordion from '../NewStoryAccordion/NewStoryAccordion';

interface INewStoryFrom {
    categories: IStoryPodcastCategories[];
    initialValues: IInitialStoriesValue;
    onSubmit: (value: IInitialStoriesValue) => void;
    buttonText: string;
}

const createInputField = <T extends IInitialStoriesValue>(
    formik: FormikProps<T>,
    story: IFieldStoryItem,
    options: IStoryPodcastCategories[] = [],
) => {
    const {
        touched: formikTouch,
        errors: formikError,
        setFieldTouched,
        setFieldValue,
        values,
    } = formik;
    const name = story.name;
    const label = story.label;
    const placeholder = label;
    const inputType = story.inputType;
    const optionsType: IStoryOptions[] = [
        { id: 1, titleEn: 'Adult' },
        { id: 2, titleEn: 'Children' },
    ];

    switch (story.field) {
        case FieldsVariation.IMAGE_INPUT:
            return (
                <div key={`${name}-${label}`} className="mb-1 position-relative pb-1">
                    <div>
                        {story.required && (
                            <span className="me-1 admin-required-field">*</span>
                        )}
                        <span>{label}</span>
                    </div>
                    <DragDropFile fieldName={name} />
                    {formikError[name] && formikTouch[name] ? (
                        <div className={styles.slateError}>{formikError[name]}</div>
                    ) : null}
                </div>
            );
        case FieldsVariation.INPUT:
            return (
                <div key={`${name}-${label}`} className="mb-1 position-relative pb-1">
                    <label className="form-label d-flex align-items-start flex-column">
                        <div>
                            {story.required && (
                                <span className="me-1 admin-required-field">*</span>
                            )}
                            <span>{label}</span>
                        </div>
                        <Field
                            onKeyDown={disableSubmit}
                            className="form-control mt-1"
                            type={inputType}
                            name={name}
                            placeholder={placeholder}
                        />
                    </label>
                    {formikError[name] && formikTouch[name] ? (
                        <div className={styles.error}>{formikError[name]}</div>
                    ) : null}
                </div>
            );
        case FieldsVariation.DROPDOWN_TYPE:
            const selectedOptions = optionsType.map(option => ({
                value: option.id,
                label: option.titleEn,
            }));

            return (
                <div key={label} className="mb-1 position-relative pb-1 h-auto">
                    <label className="form-label d-block align-items-start flex-column">
                        <div>
                            {story.required && (
                                <span className="me-1 admin-required-field">*</span>
                            )}
                            <span>{label}</span>
                        </div>
                        <Select
                            onKeyDown={disableSubmit}
                            id="categories"
                            options={selectedOptions}
                            defaultValue={{
                                value: 1,
                                label: 'Select type ...',
                            }}
                            isSearchable={false}
                            onBlur={() => {
                                setFieldTouched(name, true);
                            }}
                            maxMenuHeight={100}
                            onChange={value => {
                                setFieldValue(name, value?.value);
                            }}
                            value={selectedOptions.find(
                                option => option.value === values?.storyType,
                            )}
                        />
                    </label>
                    {formikError[name] && formikTouch[name] ? (
                        <div className={styles.error}>{formikError[name]}</div>
                    ) : null}
                </div>
            );
        case FieldsVariation.DROPDOWN:
            const selectOptions = options.map(option => ({
                value: option.id,
                label: option.titleEn,
            }));

            return (
                <div key={label} className="mb-1 position-relative pb-1 h-auto">
                    <label className="form-label d-block align-items-start flex-column">
                        <div>
                            {story.required && (
                                <span className="me-1 admin-required-field">*</span>
                            )}
                            <span>{label}</span>
                        </div>
                        <Select
                            onKeyDown={disableSubmit}
                            id="categories"
                            options={selectOptions}
                            defaultValue={{
                                value: '',
                                label: 'Select category ...',
                            }}
                            isSearchable={false}
                            onBlur={() => {
                                setFieldTouched(name, true);
                            }}
                            maxMenuHeight={100}
                            onChange={value => {
                                setFieldValue(name, value?.value);
                            }}
                            value={selectOptions.find(
                                option => option.value === values?.categoryId,
                            )}
                        />
                    </label>
                    {formikError[name] && formikTouch[name] ? (
                        <div className={styles.error}>{formikError[name]}</div>
                    ) : null}
                </div>
            );
        case FieldsVariation.TEXTAREA:
            return (
                <label key={label} className="form-label d-block pl-2">
                    <div>
                        {story.required && (
                            <span className="me-1 admin-required-field">*</span>
                        )}
                        <span>{label}</span>
                    </div>
                    <div className="mb-2 position-relative pb-1 mt-1 form-control">
                        <TextEditor
                            placeholder={placeholder}
                            fieldName={name as string}
                        />
                        {formikError[name] && formikTouch[name] ? (
                            <div className={styles.slateError}>{formikError[name]}</div>
                        ) : null}
                    </div>
                </label>
            );

        case FieldsVariation.SWITCH:
            return (
                <>
                    {name === 'darkLogo' && (
                        <label key={label} className="form-label d-block pl-2">
                            <div>
                                {story.required && (
                                    <span className="me-1 admin-required-field">*</span>
                                )}
                                <span>{label}</span>
                            </div>
                            <div className="mb-2 position-relative pb-1 mt-1 ">
                                <Switch
                                    onKeyDown={disableSubmit}
                                    checked={values ? values.darkLogo : false}
                                    onChange={switchValue => {
                                        setFieldValue(name, switchValue);
                                    }}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    width={37}
                                    height={17}
                                    activeBoxShadow=""
                                />
                                {formikError[name] && formikTouch[name] ? (
                                    <div className={styles.slateError}>
                                        {formikError[name]}
                                    </div>
                                ) : null}
                            </div>
                        </label>
                    )}
                    {name === 'translateEn' && (
                        <label key={label} className="form-label d-block pl-2">
                            <div>
                                {story.required && (
                                    <span className="me-1 admin-required-field">*</span>
                                )}
                                <span>{label}</span>
                            </div>
                            <div className="mb-2 position-relative pb-1 mt-1 ">
                                <Switch
                                    onKeyDown={disableSubmit}
                                    checked={values ? values.translateEn : false}
                                    onChange={switchValue => {
                                        setFieldValue(name, switchValue);
                                    }}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    width={37}
                                    height={17}
                                    activeBoxShadow=""
                                />
                                {formikError[name] && formikTouch[name] ? (
                                    <div className={styles.slateError}>
                                        {formikError[name]}
                                    </div>
                                ) : null}
                            </div>
                        </label>
                    )}
                    {name === 'translateFr' && (
                        <label key={label} className="form-label d-block pl-2">
                            <div>
                                {story.required && (
                                    <span className="me-1 admin-required-field">*</span>
                                )}
                                <span>{label}</span>
                            </div>
                            <div className="mb-2 position-relative pb-1 mt-1 ">
                                <Switch
                                    onKeyDown={disableSubmit}
                                    checked={values ? values.translateFr : false}
                                    onChange={switchValue => {
                                        setFieldValue(name, switchValue);
                                    }}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    width={37}
                                    height={17}
                                    activeBoxShadow=""
                                />
                                {formikError[name] && formikTouch[name] ? (
                                    <div className={styles.slateError}>
                                        {formikError[name]}
                                    </div>
                                ) : null}
                            </div>
                        </label>
                    )}
                </>
            );

        case FieldsVariation.LINK:
            const linkValue = websiteLinkGenerator(values.categoryId);
            return (
                <label key={label} className="form-label d-block">
                    <div>
                        {story.required && (
                            <span className="me-1 admin-required-field">*</span>
                        )}
                        <span>{label}</span>
                    </div>
                    <div
                        className={`mb-1 position-relative pb-1 mt-1 form-control ${styles.linkWrapper}`}>
                        <a href={linkValue} target="_blank" rel="noreferrer">
                            {linkValue}
                        </a>
                        {formikError[name] && formikTouch[name] ? (
                            <div className={styles.slateError}>{formikError[name]}</div>
                        ) : null}
                    </div>
                </label>
            );

        default:
            return <div>DEFAULT</div>;
    }
};

export const NewStoryForm: React.FC<INewStoryFrom> = ({
    categories,
    initialValues,
    onSubmit,
    buttonText,
}) => {
    return (
        <>
            <div className={styles.newAdminFormWrapper}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={newStoriesValidationSchema}
                    onSubmit={values => onSubmit(values)}>
                    {helpers => {
                        return (
                            <Form>
                                <div className={styles.formBlockWrapper}>
                                    <NewStoryAccordion title="English Content">
                                        {initialStoryPageFormsFields.en.map(story =>
                                            createInputField<IInitialStoriesValue>(
                                                helpers,
                                                story,
                                            ),
                                        )}
                                    </NewStoryAccordion>
                                </div>
                                <div className={styles.formBlockWrapper}>
                                    <NewStoryAccordion title="Arabic Content">
                                        {initialStoryPageFormsFields.ar.map(story =>
                                            createInputField<IInitialStoriesValue>(
                                                helpers,
                                                story,
                                            ),
                                        )}
                                    </NewStoryAccordion>
                                </div>
                                <div className={styles.formBlockWrapper}>
                                    <NewStoryAccordion title="French Content">
                                        {initialStoryPageFormsFields.fr.map(story =>
                                            createInputField<IInitialStoriesValue>(
                                                helpers,
                                                story,
                                            ),
                                        )}
                                    </NewStoryAccordion>
                                </div>
                                <div className={styles.formBlockWrapper}>
                                    {initialStoryPageFormsFields.general.map(story =>
                                        createInputField<IInitialStoriesValue>(
                                            helpers,
                                            story,
                                            categories,
                                        ),
                                    )}
                                </div>
                                <div className={styles.formBlockWrapper}>
                                    {initialStoryPageFormsFields.switches.map(story =>
                                        createInputField<IInitialStoriesValue>(
                                            helpers,
                                            story,
                                        ),
                                    )}
                                </div>
                                {buttonText === 'Edit' && (
                                    <div className={styles.formBlockWrapper}>
                                        {initialStoryPageFormsFields.links.map(story =>
                                            createInputField<IInitialStoriesValue>(
                                                helpers,
                                                story,
                                            ),
                                        )}
                                    </div>
                                )}

                                <button
                                    className="btn btn-secondary align-self-end mt-2"
                                    type="submit">
                                    {buttonText}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </>
    );
};

export default NewStoryForm;

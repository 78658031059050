import {
    AdminPodcastsActionTypes,
    IInitialState,
    AdminPodcastsTypes,
} from 'store/actions/adminPodcasts/adminPodcasts.types';

const InitialState: IInitialState = {
    csv: '',
    adminPodcasts: [],
    loading: false,
    error: '',
    page: 0,
    perPage: 10,
    totalNumber: 0,
    podcastsCategories: [],
    postNewPodcastError: '',
    message: '',
};

export const AdminPodcastsReducer = (
    state = InitialState,
    action: AdminPodcastsActionTypes,
): IInitialState => {
    switch (action.type) {
        case AdminPodcastsTypes.FETCH_ADMIN_PODCASTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminPodcastsTypes.FETCH_ADMIN_PODCASTS_SUCCESS:
            return {
                ...state,
                loading: false,
                adminPodcasts: action.payload.items,
                page: action.payload.page,
                perPage: action.payload.perPage,
                totalNumber: action.payload.totalNumber,
            };
        case AdminPodcastsTypes.FETCH_ADMIN_PODCASTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminPodcastsTypes.DEACTIVATE_ADMIN_PODCASTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminPodcastsTypes.DEACTIVATE_ADMIN_PODCASTS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case AdminPodcastsTypes.DEACTIVATE_ADMIN_PODCASTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminPodcastsTypes.PODCASTS_EXPORT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminPodcastsTypes.PODCASTS_EXPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                csv: action.payload
            };
        case AdminPodcastsTypes.PODCASTS_EXPORT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminPodcastsTypes.DELETE_ADMIN_PODCASTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminPodcastsTypes.DELETE_ADMIN_PODCASTS_SUCCESS:
            return {
                ...state,
                message: 'Deleted Successfully',
                loading: false,
            };
        case AdminPodcastsTypes.DELETE_ADMIN_PODCASTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminPodcastsTypes.ACTIVATE_ADMIN_PODCASTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminPodcastsTypes.ACTIVATE_ADMIN_PODCASTS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case AdminPodcastsTypes.ACTIVATE_ADMIN_PODCASTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminPodcastsTypes.PODCASTS_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminPodcastsTypes.PODCASTS_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                podcastsCategories: action.payload,
            };
        case AdminPodcastsTypes.PODCASTS_CATEGORIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminPodcastsTypes.POST_NEW_PODCAST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminPodcastsTypes.POST_NEW_PODCAST_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload,
            };
        case AdminPodcastsTypes.POST_NEW_PODCAST_FAILURE:
            return {
                ...state,
                loading: false,
                postNewPodcastError: action.payload,
            };
        case AdminPodcastsTypes.CLEAN_PODCAST_MESSAGE:
            return {
                ...state,
                loading: false,
                message: '',
                postNewPodcastError: '',
            };
        default:
            return state;
    }
};

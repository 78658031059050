import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import NewKioskDevice from 'adminComponents/NewKioskDevice/NewKioskDevice';

const NewKioskPage = () => {
    return (
        <AdminLayout title="New Kiosk Device">
            <NewKioskDevice />
        </AdminLayout>
    );
};

export default NewKioskPage;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Banner } from 'assets/images/homeBookmark.svg';
import PageLayout from 'layouts/PageLayout';
import EmailSubscriptionForm from './EmailSubscriptionForm';
import styles from './EmailSubscription.module.scss';
import { Link } from 'react-router-dom';
import Paths from 'constants/paths';
import Button from 'portalComponents/Button';

const EmailSubscription: FC = () => {
    const { t } = useTranslation();

    return (
        <PageLayout
            showHeader
            headerProps={{
                variant: 'normal',
                symbols: { position: 'left' },
                banner: <Banner />,
                content: {
                    leftRenderer: () => (
                        <Link to={Paths.HOME}>
                            <Button>{t('buttons.homePage')}</Button>
                        </Link>
                    ),
                },
                languageSelect: { variant: 'default' }
            }}
            showFooter
            footerProps={{ variant: 'dark', showSubscribeButton: false }}
            contentClassName={styles.content}
        >
            <h3 className={styles.title}>
                {t('emailSubscription.title')}
            </h3>
            <EmailSubscriptionForm />
        </PageLayout>
    );
};

export default EmailSubscription;

import Paths from 'constants/paths';
export interface ILink {
    name: string;
    icon: string;
    user_types?: string[];
    url: string;
    children?: Omit<ILink, 'children' | 'user_types'>;
}

export const Links: ILink[] = [
    {
        name: 'Admins List',
        icon: 'bi bi-person-check',
        url: Paths.ADMIN_LIST,
    },
    {
        name: 'Stories',
        icon: 'bi bi-file-text',
        url: Paths.ADMIN_STORIES,
    },
    {
        name: 'Podcasts',
        icon: 'bi bi-headphones',
        url: Paths.ADMIN_PODCASTS,
    },
    {
        name: 'Kiosks Devices',
        icon: 'bi bi-printer',
        url: Paths.ADMIN_KIOSKS,
    },
    {
        name: 'Map Data',
        icon: 'bi-geo-alt',
        url: Paths.ADMIN_MAP_DATA,
    },
    {
        name: 'Email Storage',
        icon: 'bi-envelope',
        url: Paths.ADMIN_EMAIL_STORAGE,
    },
];

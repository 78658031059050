import React, { FC } from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import styles from './styles.module.scss';
import {
    IInitialLocationValue,
    IFieldLocationItem,
    FieldsVariation,
    initialLocationPageFormsFields,
} from './constans';
import { IMapDataOptions } from 'store/actions/adminMapData/adminMapData.types';
import { disableSubmit } from 'utils/helpers';
import Select from 'react-select';
import { newLocationValidationSchema } from 'utils/validationSchemas';

interface INewLocationForm {
    initialValues: any;
    onSubmit: (value: IInitialLocationValue) => void;
    buttonText: string;
}

const createInputField = <T extends IInitialLocationValue>(
    formik: FormikProps<T>,
    location: IFieldLocationItem,
) => {
    const options: IMapDataOptions[] = [
        { id: 1, titleEn: 'Short Stories' },
        { id: 2, titleEn: 'Kiosk Device' },
        { id: 3, titleEn: 'Literature Cloud Device' },
    ];
    const {
        touched: formikTouch,
        errors: formikError,
        setFieldValue,
        setFieldTouched,
        values,
    } = formik;
    const name = location.name;
    const label = location.label;
    const inputType = location.inputType;
    const isDisaled = location.isDisabled;
    switch (location.field) {
        case FieldsVariation.INPUT:
            return (
                <div key={label} className="mb-1 position-relative pb-1">
                    <label className="form-label d-flex align-items-start flex-column">
                        {label}
                        <Field
                            onKeyDown={disableSubmit}
                            className="form-control mt-1"
                            type={inputType}
                            name={name}
                            disabled={isDisaled}
                            placeholder={label}
                        />
                    </label>
                    {formikError[name] && formikTouch[name] ? (
                        <div className={styles.error}>{formikError[name]}</div>
                    ) : null}
                </div>
            );
        case FieldsVariation.DROPDOWN:
            const selectOptions = options.map(option => ({
                value: option.id,
                label: option.titleEn,
            }));

            return (
                <div key={label} className="mb-1 position-relative pb-1 h-auto">
                    <label className="form-label d-block align-items-start flex-column">
                        {label}
                        <Select
                            onKeyDown={disableSubmit}
                            id="categories"
                            options={selectOptions}
                            defaultValue={{
                                value: '',
                                label: 'Select a Type',
                            }}
                            isSearchable={false}
                            onBlur={() => {
                                setFieldTouched(name, true);
                            }}
                            maxMenuHeight={100}
                            onChange={value => {
                                setFieldValue(name, value?.value);
                            }}
                            value={selectOptions.find(
                                option => option.value === values?.type,
                            )}
                        />
                    </label>
                    {formikError[name] && formikTouch[name] ? (
                        <div className={styles.error}>{formikError[name]}</div>
                    ) : null}
                </div>
            );
    }
};

const NewLocationForm: FC<INewLocationForm> = ({
    buttonText,
    initialValues,
    onSubmit,
}) => {
    return (
        <>
            <div className={styles.newLocationFormWrapper}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={newLocationValidationSchema}
                    onSubmit={onSubmit}>
                    {helpers => {
                        return (
                            <Form>
                                <div className={styles.formBlockWrapper}>
                                    {buttonText === 'Edit' && (
                                        <>
                                            {initialLocationPageFormsFields.info.map(
                                                location =>
                                                    createInputField<IInitialLocationValue>(
                                                        helpers,
                                                        location,
                                                    ),
                                            )}
                                        </>
                                    )}
                                    {initialLocationPageFormsFields.ar.map(location =>
                                        createInputField<IInitialLocationValue>(
                                            helpers,
                                            location,
                                        ),
                                    )}
                                    <div className={styles.formBlockWrapper}>
                                        {initialLocationPageFormsFields.en.map(location =>
                                            createInputField<IInitialLocationValue>(
                                                helpers,
                                                location,
                                            ),
                                        )}
                                    </div>
                                    <div className={styles.formBlockWrapper}>
                                        {initialLocationPageFormsFields.fr.map(location =>
                                            createInputField<IInitialLocationValue>(
                                                helpers,
                                                location,
                                            ),
                                        )}
                                    </div>
                                    <div className={styles.formBlockWrapper}>
                                        {initialLocationPageFormsFields.general.map(
                                            location =>
                                                createInputField<IInitialLocationValue>(
                                                    helpers,
                                                    location,
                                                ),
                                        )}
                                    </div>
                                    <div className={styles.formBlockWrapper}>
                                        {initialLocationPageFormsFields.mapLocation.map(
                                            location =>
                                                createInputField<IInitialLocationValue>(
                                                    helpers,
                                                    location,
                                                ),
                                        )}
                                    </div>
                                </div>
                                <button
                                    className="btn btn-secondary align-self-end mt-2"
                                    type="submit">
                                    {buttonText}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </>
    );
};

export default NewLocationForm;

import React from 'react';
import { NavLink } from 'react-router-dom';
import { Links } from './constants';

import styles from './AdminSidebar.module.scss';

interface IAdminSidebarProps {
    isCondensed: boolean;
}

const AdminSidebar: React.FC<IAdminSidebarProps> = ({ isCondensed }) => {
    return (
        <div
            className={`${styles.left_side_menu} ${
                !isCondensed ? styles.open : styles.close
            }`}>
            <div className={styles.side_menu_items}>
                {Links.map(link => {
                    return (
                        <p key={link.name}>
                            <NavLink
                                to={link.url}
                                className={styles.menu_item}
                                activeClassName={styles.activeLink}>
                                <i className={`${styles.icon} ${link.icon}`}></i>
                                <span>{link.name} </span>
                            </NavLink>
                        </p>
                    );
                })}
            </div>
        </div>
    );
};

export default AdminSidebar;

import {
    AdminsStoryActionTypes,
    AdminStoryTypes,
    IInitialState,
} from 'store/actions/adminStories/adminStories.types';

const initialState: IInitialState = {
    csv: '',
    stories: [],
    error: '',
    loading: false,
    page: 0,
    perPage: 10,
    totalNumber: 0,
    storyCategories: [],
    postNewStoryError: '',
    message: '',
};

export const AdminStoriesReducer = (
    state = initialState,
    action: AdminsStoryActionTypes,
): IInitialState => {
    switch (action.type) {
        case AdminStoryTypes.FETCH_STORIES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminStoryTypes.FETCH_STORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                stories: action.payload.items,
                page: action.payload.page,
                perPage: action.payload.perPage,
                totalNumber: action.payload.totalNumber,
            };
        case AdminStoryTypes.FETCH_STORIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminStoryTypes.DEACTIVATE_STORY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminStoryTypes.DEACTIVATE_STORY_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case AdminStoryTypes.DEACTIVATE_STORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminStoryTypes.DELETE_STORY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminStoryTypes.DELETE_STORY_SUCCESS:
            return {
                ...state,
                loading: false,
                message: 'Deleted Successfully',
            };
        case AdminStoryTypes.DELETE_STORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminStoryTypes.ACTIVATE_STORY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminStoryTypes.ACTIVATE_STORY_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case AdminStoryTypes.ACTIVATE_STORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminStoryTypes.SHORT_STORY_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminStoryTypes.SHORT_STORY_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                storyCategories: action.payload,
            };
        case AdminStoryTypes.SHORT_STORY_CATEGORIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminStoryTypes.POST_NEW_STORY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminStoryTypes.POST_NEW_STORY_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload,
            };
        case AdminStoryTypes.POST_NEW_STORY_FAILURE:
            return {
                ...state,
                loading: false,
                postNewStoryError: action.payload,
            };
        case AdminStoryTypes.CLEAN_STORY_MESSAGE:
            return {
                ...state,
                loading: false,
                message: '',
                postNewStoryError: '',
            };
        case AdminStoryTypes.STORY_EXPORT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminStoryTypes.STORY_EXPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                csv: action.payload
            };
        case AdminStoryTypes.STORY_EXPORT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

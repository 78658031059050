import { IPaginationResponse } from 'services/types';
import {
    AdminEmailsStorageTypes,
    IEmailStorageListItem,
} from './adminEmailsStorage.types';
import { IFilterParams } from 'models/filters';
import { INewNotification } from 'services/admin.services/adminEmailsStorage/adminEmailsStorage.types';

export const fetchEmailsStorageRequestAction = (
    page: number = 1,
    searchWord?: string,
    categoryId?: string
    ) => ({
    type: AdminEmailsStorageTypes.FETCH_EMAILS_STORAGE_DATA_REQUEST,
    payload: { page, searchWord, categoryId },
});

export const fetchEmailsStorageSuccessAction = (
    emailsDataResponse: IPaginationResponse<IEmailStorageListItem>,
) => ({
    type: AdminEmailsStorageTypes.FETCH_EMAILS_STORAGE_DATA_SUCCESS,
    payload: emailsDataResponse,
});

export const fetchEmailsStorageFailureAction = (message: string) => ({
    type: AdminEmailsStorageTypes.FETCH_EMAILS_STORAGE_DATA_FAILURE,
    payload: message,
});

export const getAdminEmailsStorageExportToCsvRequestAction = () => ({
    type: AdminEmailsStorageTypes.EMAILS_STORAGE_EXPORT_REQUEST,
});

export const getAdminEmailsStorageExportToCsvSuccessAction = (csv: string) => ({
    type: AdminEmailsStorageTypes.EMAILS_STORAGE_EXPORT_SUCCESS,
    payload: csv,
});
export const getAdminEmailsStorageExportToCsvFailureAction = () => ({
    type: AdminEmailsStorageTypes.EMAILS_STORAGE_EXPORT_FAILURE,
});

export const addNewNotificationRequestAction = (notification: INewNotification) => ({
    type: AdminEmailsStorageTypes.ADD_NEW_NOTIFICATION_REQUEST,
    payload: notification,
});

export const addNewNotificationSuccessAction = (message: string) => ({
    type: AdminEmailsStorageTypes.ADD_NEW_NOTIFICATION_SUCCESS,
    payload: message,
});

export const addNewNotificationFailureAction = (message: string) => ({
    type: AdminEmailsStorageTypes.ADD_NEW_NOTIFICATION_FAILURE,
    payload: message,
});

export const cleanNewNotificationMessageAction = () => ({
    type: AdminEmailsStorageTypes.CLEAN_NEW_NOTIFICATION_MESSAGE,
});

export const deleteAdminEmailRequestAction = (
    emailId: string,
    params: IFilterParams,
) => ({
    type: AdminEmailsStorageTypes.DELETE_ADMIN_EMAIL_REQUEST,
    payload: {
        emailId,
        params,
    },
});
export const deleteAdminEmailFailureAction = (error: string) => ({
    type: AdminEmailsStorageTypes.DELETE_ADMIN_EMAIL_FAILURE,
    payload: error,
});

export const deleteAdminEmailSuccessAction = () => {
    return {
        type: AdminEmailsStorageTypes.DELETE_ADMIN_EMAIL_SUCCESS,
    };
};

export const deactivateAdminEmailStorageRequestAction = (
    emailId: string,
    page: number,
) => ({
    type: AdminEmailsStorageTypes.DEACTIVATE_ADMIN_EMAIL_REQUEST,
    payload: {
        emailId,
        page,
    },
});

export const deactivateAdminEmailStorageSuccessAction = () => ({
    type: AdminEmailsStorageTypes.DEACTIVATE_ADMIN_EMAIL_SUCCESS,
});

export const deactivateAdminEmailStorageFailureAction = (error: string) => ({
    type: AdminEmailsStorageTypes.DEACTIVATE_ADMIN_EMAIL_FAILURE,
    payload: error,
});

export const activateAdminEmailStorageRequestAction = (emailId: string, page: number,  value: boolean,) => ({
    type: AdminEmailsStorageTypes.ACTIVATE_ADMIN_EMAIL_REQUEST,
    payload: {
        emailId,
        page,
        value,
    },
});

export const activateAdminEmailStorageSuccessAction = () => ({
    type: AdminEmailsStorageTypes.ACTIVATE_ADMIN_EMAIL_SUCCESS,
});

export const activateAdminEmailStorageFailureAction = (error: string) => ({
    type: AdminEmailsStorageTypes.ACTIVATE_ADMIN_EMAIL_FAILURE,
    payload: error,
});



import { FC } from 'react';
import { Field, Formik, Form } from 'formik';
import styles from './styles.module.scss';
import { IInitialNotificationValue } from './constants';
import { NewNotificationValidationSchema } from 'utils/validationSchemas';

interface INewLocationForm {
    initialValues: any;
    onSubmit: (value: IInitialNotificationValue) => void;
    buttonText: string;
}

interface IInitialValues {
    title: string;
    body: string;
}

const initialValues: IInitialValues = {
    title: '',
    body: '',
};

const AddNotificationForm: FC<INewLocationForm> = ({ buttonText, onSubmit }) => {
    return (
        <div className={styles.newNotificationFormWrapper}>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={NewNotificationValidationSchema}>
                {({ errors, touched }) => (
                    <Form>
                        <div className="mb-3 position-relative pb-1">
                            <label className="form-label d-flex align-items-start flex-column">
                                Email Title
                                <Field
                                    className="form-control mt-2"
                                    type="text"
                                    name="title"
                                    placeholder="Email Title"
                                />
                            </label>
                            {errors.title && touched.title ? (
                                <div className={styles.error}>{errors.title}</div>
                            ) : null}
                        </div>
                        <div className="mb-3 position-relative pb-1">
                            <label className="form-label d-flex align-items-start flex-column">
                                Email Body
                                <Field
                                    component="textarea"
                                    className="form-control mt-2"
                                    type="textarea"
                                    name="body"
                                    rows="10"
                                    placeholder="Email Body"
                                />
                            </label>
                            {errors.body && touched.body ? (
                                <div className={styles.error}>{errors.body}</div>
                            ) : null}
                        </div>
                        <button className={styles.submitBtn} type="submit">
                            {buttonText}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AddNotificationForm;

import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks';
import { Spinner } from 'react-bootstrap';
import Alert from 'sharedComponents/Alert';
import {
    fetchMapDataDetailsRequestAction,
    editMapDataRequestRequestAction,
} from 'store/actions/adminMapDataDetails/adminMapDataDetails.action';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import NewLocationForm from 'adminPages/NewMapDataPage/NewLocationForm/NewLocationForm';
import { getDifferentValues } from 'utils/helpers';
import { IInitialLocationValue } from 'adminPages/NewMapDataPage/NewLocationForm/constans';

const AdminMapDataDetails = () => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const mapDataDetails = useAppSelector(
        store => store.adminMapDataDetails.mapDataDetails,
    );
    const loading = useAppSelector(store => store.adminMapDataDetails.loading);
    const error = useAppSelector(store => store.adminMapDataDetails.error);
    const message = useAppSelector(store => store.adminMapDataDetails.message);

    useEffect(() => {
        dispatch(fetchMapDataDetailsRequestAction(id));
    }, [dispatch, id]);

    const onSubmit = (values: IInitialLocationValue) => {
        const formattedValues = getDifferentValues(mapDataDetails, values);
        dispatch(editMapDataRequestRequestAction(formattedValues, id));
    };
    return (
        <AdminLayout title="Map Data Details">
            {message && <Alert message={message} isError={false} />}
            {error && <Alert message={error} isError={true} />}
            {loading ? (
                <div className="container-fluid d-flex justify-content-center align-items-center h-100">
                    <Spinner animation="border" />
                </div>
            ) : (
                mapDataDetails && (
                    <NewLocationForm
                        initialValues={mapDataDetails}
                        onSubmit={onSubmit}
                        buttonText={'Edit'}
                    />
                )
            )}
        </AdminLayout>
    );
};

export default AdminMapDataDetails;

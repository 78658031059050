import { INewLocation } from 'adminPages/NewMapDataPage/NewLocationForm/constans';
import { IPaginationResponse } from 'services/types';
import { IFilterParams } from 'models/filters';

export interface IMapDataTypes {
    id: string;
    titleAr: string;
    titleEn: string;
    descriptionAr?: string;
    descriptionEn?: string;
    type: number | string;
}

export interface IMapDataOptions {
    id: string | number;
    titleEn: string;
}

export type IInitialState = IPaginationResponse<IMapDataListItem> & {
    loading: boolean;
    error: string;
    message?: string;
};

export interface IMapDataListItem {
    id: string;
    titleAr: string;
    titleEn: string;
    descriptionEn?: string;
    descriptionAr?: string;
    isDeleted: boolean;
    type: number | string;
}
export enum AdminMapDataTypes {
    FETCH_ADMIN_MAP_DATA_REQUEST = 'FETCH_ADMIN_MAP_DATA_REQUEST',
    FETCH_ADMIN_MAP_DATA_SUCCESS = 'FETCH_ADMIN_MAP_DATA_SUCCESS',
    FETCH_ADMIN_MAP_DATA_FAILURE = 'FETCH_ADMIN_MAP_DATA_FAILURE',

    DELETE_ADMIN_MAP_DATA_REQUEST = 'DELETE_ADMIN_MAP_DATA_REQUEST',
    DELETE_ADMIN_MAP_DATA_SUCCESS = ' DELETE_ADMIN_MAP_DATA_SUCCESS',
    DELETE_ADMIN_MAP_DATA_FAILURE = ' DELETE_ADMIN_MAP_DATA_FAILURE',

    POST_NEW_MAP_DATA_REQUEST = 'POST_NEW_MAP_DATA_REQUEST',
    POST_NEW_MAP_DATA_SUCCESS = 'POST_NEW_MAP_DATA_SUCCESS',
    POST_NEW_MAP_DATA_FAILURE = 'POST_NEW_MAP_DATA_FAILURE',

    CLEAN_NEW_MAP_MESSAGE = 'CLEAN_NEW_MAP_MESSAGE'
}

export interface FetchAdminMapDataRequestActionType {
    type: AdminMapDataTypes.FETCH_ADMIN_MAP_DATA_REQUEST;
    payload: { page: number; categoryId: string; searchWord: string; storyType:number;};
}

export interface FetchAdminMapDataRequestSuccessActionType {
    type: AdminMapDataTypes.FETCH_ADMIN_MAP_DATA_SUCCESS;
    payload: {
        curPage: number;
        data: IMapDataListItem[];
        totalDocs: number;
        totalPages: number;
    };
}

export interface FetchAdminMapDataRequestFailureActionType {
    type: AdminMapDataTypes.FETCH_ADMIN_MAP_DATA_FAILURE;
    payload: string;
}

export interface DeleteAdminMapDataRequestActionType {
    type: AdminMapDataTypes.DELETE_ADMIN_MAP_DATA_REQUEST;
    payload: {
        locationId: string;
        params: IFilterParams;
    };
}
export interface DeleteAdminMapDataSuccessActionType {
    type: AdminMapDataTypes.DELETE_ADMIN_MAP_DATA_SUCCESS;
}
export interface DeleteAdminMapDataFailureActionType {
    type: AdminMapDataTypes.DELETE_ADMIN_MAP_DATA_FAILURE;
    payload: string;
}

export interface postNewLocationRequestActionType {
    type: AdminMapDataTypes.POST_NEW_MAP_DATA_REQUEST;
    payload: INewLocation;
}
export interface postNewLocationSuccessActionType {
    type: AdminMapDataTypes.POST_NEW_MAP_DATA_SUCCESS;
    payload: string;
}
export interface postNewLocationFailureActionType {
    type: AdminMapDataTypes.POST_NEW_MAP_DATA_FAILURE;
    payload: string;
}

export interface cleanNewLocationMessageActionType {
    type: AdminMapDataTypes.CLEAN_NEW_MAP_MESSAGE;
}

export type AdminMapDataActionTypes =
    | FetchAdminMapDataRequestActionType
    | FetchAdminMapDataRequestSuccessActionType
    | FetchAdminMapDataRequestFailureActionType
    | postNewLocationRequestActionType
    | postNewLocationSuccessActionType
    | postNewLocationFailureActionType
    | cleanNewLocationMessageActionType
    | DeleteAdminMapDataRequestActionType
    | DeleteAdminMapDataSuccessActionType
    | DeleteAdminMapDataFailureActionType;

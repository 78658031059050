import Paths from 'constants/paths';
import { IFilterParams } from 'models/filters';
import { compile } from 'path-to-regexp';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Switch from 'react-switch';
import { IAdminKiosks } from 'services/admin.services/adminKiosks/adminKiosks.types';
import { DeleteModal } from 'sharedComponents/DeleteModal';
import { activateAdminKiosksRequestAction } from 'store/actions/adminKiosks/adminKiosks.action';
import { ITableHeadItem } from '../constants';
import { deleteAdminKioskRequestAction } from '../../../store/actions/adminKiosks/adminKiosks.action';
interface IKioskTableProps {
    tableHead: ITableHeadItem[];
    tableBody: IAdminKiosks[];
    params: IFilterParams;
    page: number;
}

const KiosksTable: React.FC<IKioskTableProps> = ({ tableHead, tableBody, page, params }) => {
    const [modalShow, changeModalShow] = useState(false);
    const [kioskData, setKioskData] = useState({ id: '', page: 0 });
    const dispatch = useDispatch();
    const history = useHistory();
    const renderTableIcon = (
        iconSrc: string,
        identificator: string,
        cb: (id: string, page?: number) => void,
        page?: number,
    ): React.ReactNode => (
        <i
            className={`App-table-icon ${iconSrc}`}
            onClick={() => cb(identificator, page)}
        />
    );

    const detailsRedicrect = (id: string) => {
        history.push(compile(Paths.ADMIN_KIOSK_DETAIL)({ id }));
    };
    const deleteKiosk = (id: string, page: number = 0) => {
        changeModalShow(true);
        setKioskData({ id, page });
    };

    if (!tableBody.length) return <div className="w-100 d-flex justify-content-center align-items-center">Not found</div>;

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        {tableHead.map(item => (
                            <th key={item.key}>{item.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableBody.map((podcast, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{podcast.description}</td>
                            <td>{podcast.city}</td>
                            <td>{podcast.kioskGroup}</td>
                            <td>
                                {renderTableIcon(
                                    'bi bi-eye',
                                    podcast.id,
                                    detailsRedicrect,
                                )}
                            </td>
                            <td>
                                <Switch
                                    checked={podcast.isDisabled}
                                    onChange={() => {
                                        dispatch(
                                            activateAdminKiosksRequestAction(
                                                podcast.id,
                                                page,
                                                podcast.isDisabled === false ? true : false
                                            ),
                                        )
                                    }}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    width={37}
                                    height={17}
                                    activeBoxShadow=""
                                />
                            </td>
                            <td>
                                {renderTableIcon(
                                    'bi bi-trash',
                                    podcast.id,
                                    deleteKiosk,
                                    page,
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {modalShow &&
                <DeleteModal
                    open={modalShow}
                    onClose={() => {
                        changeModalShow(false);
                    }}
                    onSuccess={() => {
                        dispatch(
                            deleteAdminKioskRequestAction(
                                kioskData.id,
                                params
                            ),
                        );

                    }}
                />
            }
        </>
    );
};

export default KiosksTable;

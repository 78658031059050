import {
    IInitialPodcastDetailsState,
    PodcastDetailsActionTypes,
    PodcastDetailsTypes,
} from 'store/actions/adminPodcastDetails/podcastDetails.types';

const InitialState: IInitialPodcastDetailsState = {
    podcastDetails: null,
    loading: false,
    error: '',
    message: '',
};

export const AdminPodcastDetailsReducer = (
    state = InitialState,
    action: PodcastDetailsActionTypes,
): IInitialPodcastDetailsState => {
    switch (action.type) {
        case PodcastDetailsTypes.FETCH_PODCAST_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PodcastDetailsTypes.FETCH_PODCAST_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                podcastDetails: action.payload,
            };
        case PodcastDetailsTypes.FETCH_PODCAST_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case PodcastDetailsTypes.EDIT_PODCAST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PodcastDetailsTypes.EDIT_PODCAST_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload,
            };
        case PodcastDetailsTypes.EDIT_PODCAST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case PodcastDetailsTypes.CLEAR_PODCAST_MESSAGE:
            return {
                ...state,
                message: '',
                error: '',
            };
        default:
            return state;
    }
};

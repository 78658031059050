import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as HomeIcon } from 'assets/images/home_icon.svg';
import { ReactComponent as AllIcon } from 'assets/images/all.svg';
import { ReactComponent as PodcastIcon } from 'assets/images/podcastIcon.svg';
import { ReactComponent as PodcastWhiteIcon } from 'assets/images/podcastLogo.svg';
import { ReactComponent as StoryIcon } from 'assets/images/storyIcon.svg';
import { ReactComponent as StoryYellowIcon } from 'assets/images/storyYellow.svg';
import { ReactComponent as KioskIcon } from 'assets/images/print.svg';
import { ReactComponent as KioskPurpleIcon } from 'assets/icons/print_purple.svg';
import { Link } from 'react-router-dom';
import Paths from 'constants/paths';
import styles from './styles.module.scss';
import { TMapDataFilters } from 'types/map';

type TMapMenuProps = {
    setItemsFilter: React.Dispatch<React.SetStateAction<TMapDataFilters | null>>;
}

const MapMenu: FC<TMapMenuProps> = ({ setItemsFilter }) => {
    const [isActive, setIsActive] = useState<String | null>(null);

    return (
        <div className={styles.dropdownMenu}>
            <div
                className={classNames(styles.firstCircle, {
                    [styles.isActiveCircle]: isActive === 'first-circle',
                })}
                onClick={() => {
                    setIsActive('first-circle');
                    setItemsFilter(null);
                }}
            >
                <AllIcon />
            </div>
            <div
                className={classNames(styles.secondCircle, {
                    [styles.isActiveCircle]: isActive === 'second-circle',
                })}
                onClick={() => {
                    setIsActive('second-circle');
                    setItemsFilter('cloud');
                }}
            >
                {isActive === 'second-circle' ? <PodcastWhiteIcon /> : <PodcastIcon />}
            </div>
            <div
                className={classNames(styles.thirdCircle, {
                    [styles.isActiveCircle]: isActive === 'third-circle',
                })}
                onClick={() => {
                    setIsActive('third-circle');
                    setItemsFilter('story');
                }}
            >
                {isActive === 'third-circle' ? <StoryYellowIcon /> : <StoryIcon />}
            </div>
            <div
                className={classNames(styles.fourthCircle, {
                    [styles.isActiveCircle]: isActive === 'fourth-circle',
                })}
                onClick={() => {
                    setIsActive('fourth-circle');
                    setItemsFilter('kiosk');
                }}
            >
                {isActive === 'fourth-circle' ? <KioskPurpleIcon /> : <KioskIcon />}
            </div>

            <Link to={Paths.HOME}>
                <div className={styles.activeCircle}>
                    <HomeIcon />
                </div>
            </Link>
        </div>
    );
};

export default MapMenu;

import React, { FC, ReactNode } from 'react';
import { ReactComponent as ButtonBg } from 'assets/images/buttonBg.svg';
import styles from './Button.module.scss';
import classNames from 'classnames';

type TProps = {
    children: ReactNode;
    onClick?: () => void;
    variant?: 'default' | 'podcast' | 'story';
    width?: 'fixedDefault' | 'fixedLarge' | 'default',
    fontSize?: 'default' | 'small',
    type?: 'button' | 'submit',
    startIcon?: ReactNode;
    disabled?: boolean;
}

const Button: FC<TProps> = ({
    children,
    onClick,
    variant = 'default',
    width = 'fixedDefault',
    fontSize = 'default',
    type = 'button',
    startIcon,
    disabled,
}) => (
    <button
        className={classNames(styles.buttonWrapper, width)}
        type={type}
        onClick={onClick}
        disabled={disabled}
    >
        <div className={classNames(styles.buttonBg, variant)}>
            <ButtonBg />
        </div>
        <div className={styles.buttonContent}>
            {startIcon && (
                <span className={styles.rightIcon}>
                    {startIcon}
                </span>
            )}
            <span className={classNames(styles.text, fontSize)}>
                {children}
            </span>
        </div>
    </button>
);

export default Button;

import {
    IInitialKioskDetailsState,
    KioskDetailsActionTypes,
    KioskDetailsTypes,
} from 'store/actions/adminKioskDetails/kioskDetails.types';

const InitialState: IInitialKioskDetailsState = {
    podcastDetails: null,
    loading: false,
    error: '',
    message: '',
};

export const AdminKioskDetailsReducer = (
    state = InitialState,
    action: KioskDetailsActionTypes,
): IInitialKioskDetailsState => {
    switch (action.type) {
        case KioskDetailsTypes.FETCH_KIOSK_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case KioskDetailsTypes.FETCH_KIOSK_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                podcastDetails: action.payload,
            };
        case KioskDetailsTypes.FETCH_KIOSK_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case KioskDetailsTypes.EDIT_KIOSK_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case KioskDetailsTypes.EDIT_KIOSK_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload,
            };
        case KioskDetailsTypes.EDIT_KIOSK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case KioskDetailsTypes.CLEAR_KIOSK_MESSAGE:
            return {
                ...state,
                message: '',
                error: '',
            };
        default:
            return state;
    }
};

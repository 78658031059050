import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PageLayout from 'layouts/PageLayout';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks';
import { getRandomPodcastAction, getShortStoryCategoryAction } from 'store/actions/portalActions/portal.actions';
import { useAnalytics } from 'utils/googleAnalytics';
import podcastLogo from 'assets/images/podcastLogo.svg';
import CategoriesContent from 'portalComponents/CategoriesContent';
import styles from './PodcastCategories.module.scss';
import Paths from 'constants/paths';
import IconButton from 'portalComponents/IconButton';
import { ReactComponent as MapIcon } from 'assets/icons/map.svg';

const PodcastCategories = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [setNamePage] = useAnalytics();
    const { shortStoryAndPodcastCategories, loaded } = useAppSelector(state => state.portal);

    useEffect(() => {
        dispatch(getShortStoryCategoryAction.request(true));
        setNamePage('Literature Cloud Categories');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCardClick = useCallback((id: string) => {
        dispatch(getRandomPodcastAction.request(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageLayout
            showHeader
            headerProps={{
                variant: 'normal',
                languageSelect: { variant: 'podcast' },
                logo: (<img
                    src={podcastLogo}
                    alt="Story logo"
                />),
                content: {
                    leftRenderer: () => (
                        <Link to={Paths.MAP}>
                            <IconButton height="small">
                                <MapIcon />
                            </IconButton>
                        </Link>
                    )
                }
            }}
            showFooter
            footerProps={{
                variant: 'light'
            }}
            contentClassName={styles.wrapper}
        >
            <CategoriesContent
                title={t('categories.title')}
                loading={!loaded}
                variant="podcast"
                categories={shortStoryAndPodcastCategories}
                onCardClick={handleCardClick}
                statisticsPath={Paths.PODCAST_STATISTICS}
            />
        </PageLayout>
    );
};

export default PodcastCategories;

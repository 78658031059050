import React from 'react';
import AddAdmin from 'adminComponents/AddAdmin/AddAdmin';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';

const AddAdminPage = () => {
    return (
        <AdminLayout title={'New Admin'}>
            <AddAdmin />
        </AdminLayout>
    );
};

export default AddAdminPage;

import Paths from 'constants/paths';
import { IFilterParams } from 'models/filters';
import { compile } from 'path-to-regexp';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Switch from 'react-switch';
import { IAdminPodcasts } from 'services/admin.services/adminPodcasts/adminPodcasts.types';
import { DeleteModal } from 'sharedComponents/DeleteModal';
import {
    activateAdminPodcastsRequestAction,
    deactivateAdminPodcastsRequestAction,
    deleteAdminPodcastRequestAction,
} from 'store/actions/adminPodcasts/adminPodcasts.action';
import { ITableHeadItem } from '../constants';
interface IPodcastsTableProps {
    tableHead: ITableHeadItem[];
    tableBody: IAdminPodcasts[];
    params: IFilterParams;
    page: number;
}

const PodcastsTable: React.FC<IPodcastsTableProps> = ({ tableHead, tableBody, page, params }) => {
    const [modalShow, changeModalShow] = useState(false);
    const [podcastData, setPodcastData] = useState({ id: '', page: 0 });
    const dispatch = useDispatch();
    const history = useHistory();

    const renderTableImage = (src: string, alt: string): React.ReactNode => (
        <img
            className="App-table-img"
            src={`${process.env.REACT_APP_URL}/${src}`}
            alt={alt}
        />
    );
    const renderTableIcon = (
        iconSrc: string,
        identificator: string,
        cb: (id: string, page?: number) => void,
        page?: number,
    ): React.ReactNode => (
        <i
            className={`App-table-icon ${iconSrc}`}
            onClick={() => cb(identificator, page)}
        />
    );

    const detailsRedicrect = (id: string) => {
        history.push(compile(Paths.ADMIN_PODCAST_DETAIL)({ id }));
    };

    const formatStringDate = (date: string): string => {
        return new Date(date).toLocaleDateString();
    };

    const deletePodcast = (id: string, page: number = 0) => {
        changeModalShow(true);
        setPodcastData({ id, page });
    };

    if(!tableBody.length) return <div className="w-100 d-flex justify-content-center align-items-center">Not found</div>;

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        {tableHead.map(item => (
                            <th key={item.key}>{item.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableBody.map((podcast, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{renderTableImage(podcast.imageAr, podcast.imageAr)}</td>
                            <td>{podcast.titleAr}</td>
                            <td>{podcast.publisherNameAr}</td> 
                            <td>{podcast.authorAr}</td>
                            <td>{podcast.category.titleEn}</td>
                            <td>{formatStringDate(podcast.createdAt)}</td>
                            <td>
                                {renderTableIcon(
                                    'bi bi-eye',
                                    podcast.id,
                                    detailsRedicrect,
                                )}
                            </td>
                            <td>
                                <Switch
                                    checked={podcast.isDeleted}
                                    onChange={() =>
                                        !podcast.isDeleted
                                            ? dispatch(
                                                  deactivateAdminPodcastsRequestAction(
                                                      podcast.id,
                                                      page,
                                                  ),
                                              )
                                            : dispatch(
                                                  activateAdminPodcastsRequestAction(
                                                      podcast.id,
                                                      page,
                                                  ),
                                              )
                                    }
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    width={37}
                                    height={17}
                                    activeBoxShadow=""
                                />
                            </td>
                            <td>
                                {renderTableIcon(
                                    'bi bi-trash',
                                    podcast.id,
                                    deletePodcast,
                                    page,
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {modalShow && (
                <DeleteModal
                    open={modalShow}
                    onClose={() => {
                        changeModalShow(false);
                    }}
                    onSuccess={() => {
                        dispatch(
                            deleteAdminPodcastRequestAction(
                                podcastData.id,
                                params
                            ),
                        );

                    }}
                />
            )}
        </>
    );
};

export default PodcastsTable;

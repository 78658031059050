import { createSlice } from '@reduxjs/toolkit';
import { TEmailStorageState } from 'store/portal/emailStorage/types';
import * as actions from 'store/portal/emailStorage/actions';

const initialState: TEmailStorageState = {
    subscribe: {
        loading: false,
        error: null,
        data: null,
    }
};

export const emailStorageSlice = createSlice({
    name: 'portalEmailStorage',
    initialState,
    reducers: {
        resetSubscribeState: (state) => {
            state.subscribe.loading = false;
            state.subscribe.data = null;
            state.subscribe.error = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(actions.emailStorageSubscribe.pending, (state) => {
            state.subscribe.loading = true;
        });
        builder.addCase(actions.emailStorageSubscribe.fulfilled, (state, action) => {
            state.subscribe.loading = false;
            state.subscribe.data = action.payload;
            state.subscribe.error = null;
        });
        builder.addCase(actions.emailStorageSubscribe.rejected, (state, action) => {
            state.subscribe.loading = false;
            state.subscribe.data = null;
            state.subscribe.error = action.payload as string;
        });
    }
});

export default emailStorageSlice.reducer;

import React, { useEffect } from 'react';
import cn from 'classnames';
import ItemDetailLayout from 'layouts/ItemDetailLayout';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import publisherTime from 'assets/images/publisherTime.svg';
import publisherEpisode from 'assets/images/publisherEpisode.svg';
import styles from './Publisher.module.scss';
import { useAppSelector } from 'hooks';
import { getEngOrArData, paragraphIsBlank } from 'utils/helpers';
import { deserialize } from 'utils/richTextToHTML';
import {
    getPodcastDetailAction,
    getStoryDetailAction,
} from 'store/actions/portalActions/portal.actions';
import { useDispatch } from 'react-redux';
import { useAnalytics } from 'utils/googleAnalytics';

const Publisher = () => {
    const { t } = useTranslation();
    const location = useLocation().pathname;
    const isPodcast = location.includes('LC');
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const [setNamePage, captureOutboundLink] = useAnalytics();
    
    const { storyDetail, podcastDetail } = useAppSelector(state => state.portal);
    const data = isPodcast ? podcastDetail : storyDetail;
    const image = `${process.env.REACT_APP_URL}/${getEngOrArData('publisherImage',data,)}`;
    const moreInfo = getEngOrArData('moreInfo', storyDetail) as string;
    const publisherDetails = getEngOrArData('publisherDetails', data) as string;
    const audioDetails = getEngOrArData('audioDetails', data) as string;
    const details = isPodcast ? audioDetails : publisherDetails;
    const handleClick = () => {
        const publisherLink = getEngOrArData('publisherLink', data) as string;
        captureOutboundLink(publisherLink)
        window.open(publisherLink);
    };
    const publisherName =  getEngOrArData('publisherName', data) as string;
    const publisherLink = getEngOrArData('publisherLink', data) as string;
    const title =  getEngOrArData('title', data) as string;
    const totalDuration = getEngOrArData('totalDuration',podcastDetail,) as number;
    const numberEpisodes = getEngOrArData('numberEpisodes', podcastDetail)
    
    useEffect(() => {
        isPodcast
            ? dispatch(getPodcastDetailAction.request(id))
            : dispatch(getStoryDetailAction.request(id));
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if(title && publisherDetails) setNamePage(`${title} - ${publisherName}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const PodcastDescription = () => {
        return (
            <div className={styles.podcastDescription}>
                {publisherDetails &&
                    JSON.parse(publisherDetails)?.map((el: any) => deserialize(el))}
            </div>
        )
    };
    const Storydescription = () => {
        return (
            <div className={styles.article}>
                <div className={styles.description}>
                    {moreInfo && JSON.parse(moreInfo)?.map((el: any) => deserialize(el))}
                </div>
            </div>
        )
    };
    return (
        <ItemDetailLayout>
            <div className={styles.header}>
                {getEngOrArData('publisherImage',data) && <div
                    className={cn(
                        styles.publisherPhoto,
                        data.darkLogo && styles.darkLogo,
                    )}>
                    <img src={image} alt="Logo" />
                </div>}
                <div className={styles.title}>
                    {getEngOrArData(isPodcast ? 'title' : 'publisherName', data)}
                </div>
                {isPodcast && (
                    <div className={styles.subtitle}>
                        {getEngOrArData('author', data)}
                    </div>
                )}
                {!paragraphIsBlank(details) && <div className={styles.publisherInfo}>
                    {JSON.parse(details).map((el: any) => deserialize(el))}
                </div>}
                {isPodcast && (totalDuration || numberEpisodes) &&  (
                    <div className={styles.podcastInfo}>
                        {totalDuration && <div className={styles.item}>
                            <img src={publisherTime} alt="" />
                            <div className={styles.itemSubtitle}>
                                {Math.floor(totalDuration)}
                            </div>
                        </div>}
                        {numberEpisodes && <div className={styles.item}>
                            <img src={publisherEpisode} alt="" />
                            <div className={styles.itemSubtitle}>
                                {numberEpisodes}
                            </div>
                        </div>}
                    </div>
                )}
                {publisherLink && <button
                    className={cn(styles.linkBtn, isPodcast && styles.podcastLinkBtn)}
                    onClick={handleClick}>
                    {t('publisher.programLink')}
                </button>}
            </div>
            {isPodcast 
                ? !paragraphIsBlank(publisherDetails) && 
                    <div className={styles.body}> 
                        <div className={styles.title}>{t('publisher.moreInformation')}</div>
                        <PodcastDescription/>
                    </div>
                : !paragraphIsBlank(moreInfo) &&
                    <div className={styles.body}> 
                        <div className={styles.title}>{t('publisher.moreInformation')}</div>
                        <Storydescription/>
                    </div>
            }
        </ItemDetailLayout>
    );
};

export default Publisher;

import React, { FC } from 'react';
import { Spinner } from 'react-bootstrap';
import styles from 'portalComponents/CategoriesContent/CaterogiesContent.module.scss';
import CategoriesNavigation, {
    TCategoriesNavigationProps,
} from 'portalComponents/CategoriesNavigation';
import CategoriesList, { TCategoriesListProps } from './CategoriesList';

type TProps = TCategoriesListProps &
    TCategoriesNavigationProps & {
        title: string;
        loading: boolean;
    };

const CategoriesContent: FC<TProps> = ({
    title,
    loading,
    variant,
    categories,
    onCardClick,
    statisticsPath,
}) => (
    <div className={styles.wrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.list}>
            {loading ? (
                <Spinner animation="border" />
            ) : (
                <CategoriesList
                    categories={categories}
                    variant={variant}
                    onCardClick={onCardClick}
                />
            )}
        </div>
        <CategoriesNavigation variant={variant} statisticsPath={statisticsPath} />
    </div>
);

export default CategoriesContent;

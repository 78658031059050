import { useEffect,useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { cleanNewLocationMessageAction, fetchMapDataRequestAction } from 'store/actions/adminMapData/adminMapData.action';
import AdminTopPanel from 'adminComponents/AdminTopPanel/AdminTopPanel';
import Pagination from 'adminComponents/Pagination/Pagination';
import Paths from 'constants/paths';
import MapDataTable from './MapDataTable/MapDataTable';
import { mapDataTableHead } from './constants';
import { useAppSelector } from 'hooks';
import { IFilterParams } from 'models/filters';
import { Spinner } from 'react-bootstrap';
import Alert from 'sharedComponents/Alert';


const MapData = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const loading = useAppSelector(state => state.adminMapData.loading);
    const page = useAppSelector(state => state.adminMapData.page);
    const lastPage = useAppSelector(state => state.adminMapData.totalNumber);
    const message = useAppSelector(state => state.adminMapData.message);
    const error = useAppSelector(state => state.adminMapData.error);
    const [filterParams] = useState<IFilterParams>({
        page: 1,
        searchWord: '',
        categoryId: '',
    });

    useEffect(() => {
        const { page, categoryId, searchWord } = filterParams as IFilterParams;
        dispatch(fetchMapDataRequestAction(page, searchWord, categoryId));
        return () => {
            dispatch(cleanNewLocationMessageAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterParams]);
    const locationList = useAppSelector(state => state.adminMapData.items);

    const changePageHandler = (page: number) => {
        dispatch(fetchMapDataRequestAction(page + 1));
    };
    return (
        <>
            {error && <Alert message={error} isError={true} />}
            {message && <Alert message={message} isError={false} />}
            <AdminTopPanel
                butonText={'New Location'}
                buttonOnClick={() => history.push(Paths.ADMIN_NEW_MAP_DATA)}
            />
            {loading ? (
                <div className="container-fluid d-flex justify-content-center align-items-center mt-5">
                    <Spinner animation="border" />
                </div>
            ) : (
            <div className="App-table-default-wrapper">
                <div className="table-responsive">
                    <MapDataTable tableHead={mapDataTableHead} tableBody={locationList} page={page} params={filterParams}/>
                    <Pagination
                        currentPage={page - 1}
                        changePageHandler={changePageHandler}
                        lastPage={lastPage}
                    />
                </div>
            </div>)}
        </>
    );
};

export default MapData;

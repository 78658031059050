import React, { useEffect } from 'react';
import { Field, Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import {
    addNewAdminRequestAction,
    clearAdminListMessageAction,
} from 'store/actions/adminList/adminsList.action';
import { NewAdminValidationSchema } from 'utils/validationSchemas';

import styles from '../AddAdmin.module.scss';

interface IInitialValues {
    name: string;
    email: string;
    password: string;
}

const initialValues: IInitialValues = {
    name: '',
    email: '',
    password: '',
};

const AddAdminForm = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearAdminListMessageAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.newAdminFormWrapper}>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { resetForm }) => {
                    dispatch(addNewAdminRequestAction(values));
                    resetForm();
                }}
                validationSchema={NewAdminValidationSchema}>
                {({ errors, touched }) => (
                    <Form>
                        <div className="mb-3 position-relative pb-1">
                            <label className="form-label d-flex align-items-start flex-column">
                                Name
                                <Field
                                    className="form-control mt-2"
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                />
                            </label>
                            {errors.name && touched.name ? (
                                <div className={styles.error}>{errors.name}</div>
                            ) : null}
                        </div>
                        <div className="mb-3 position-relative pb-1">
                            <label className="form-label d-flex align-items-start flex-column">
                                Email
                                <Field
                                    className="form-control mt-2"
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                />
                            </label>
                            {errors.email && touched.email ? (
                                <div className={styles.error}>{errors.email}</div>
                            ) : null}
                        </div>
                        <div className="mb-3 position-relative pb-1">
                            <label className="form-label d-flex align-items-start flex-column">
                                Password
                                <Field
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    className="form-control mt-2"
                                />
                            </label>
                            {errors.password && touched.password ? (
                                <div className={styles.error}>{errors.password}</div>
                            ) : null}
                        </div>
                        <hr />
                        <button className={styles.submitBtn} type="submit">
                            Save
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AddAdminForm;

/* eslint-disable no-self-compare */
import * as Yup from 'yup';
import { TFunction } from 'react-i18next';
import {
    PASSWORD_REGEX,
    LINK_REGEX,
    PHONE_NUMBER_REGEX,
    FULL_NAME_REGEX,
} from './regExs';
import { REQUIRED_ERROR } from 'constants/errors';

export const passwrodErrorMessage =
    'Password should consist of 8 characters with at least 1 number , 1 special character, 1 upper case letter, and 1 lower case letter';
let cachedPassword: string;

export const LoginValidationSchema = Yup.object().shape({
    email: Yup.string().email('Email format is incorrect').required('Email is required!'),
    password: Yup.string()
        .max(20, 'Password must be at most 20 characters')
        .min(5, 'Password must be at least 5 characters')
        .required('Password is required!'),
});

export const NewAdminValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Required!')
        .min(3, 'Name should be more than 3')
        .max(20, 'Name should be less than 20'),
    email: Yup.string().email('Email format is incorrect').required('Required!'),
    password: Yup.string()
        .required('Password is required!')
        .matches(
            PASSWORD_REGEX,
            'Password should consist of 8 characters with at least 1 number , 1 special character, 1 upper case letter, and 1 lower case letter',
        ),
});

export const newStoriesValidationSchema = Yup.object().shape({
    titleEn: Yup.string().nullable(),
    titleAr: Yup.string().required('Required!'),
    titleFr: Yup.string().nullable(),
    storyDurationEn: Yup.number()
        .nullable(true)
        .transform((_, val) => (val === val ? val : null)),
    storyDurationAr: Yup.number()
        .nullable(true)
        .transform((_, val) => (val === val ? val : null)),
    storyDurationFr: Yup.number()
        .nullable(true)
        .transform((_, val) => (val === val ? val : null)),
    imageEn: Yup.string().nullable(),
    imageAr: Yup.string().nullable(),
    imageFr: Yup.string().nullable(),
    categoryId: Yup.string().required('Required!'),
    storyTextEn: Yup.array().of(Yup.object()).nullable(),
    storyTextAr: Yup.array()
        .of(Yup.object())
        .test('length', 'Required!', (value: any) => {
            const rawResult: boolean =
                value?.[0]?.['children']?.[0]?.['text']?.length >= 1;
            const listResult: boolean =
                value?.[0]?.type.includes('list') &&
                value?.[0]?.['children']?.length >= 1;
            return listResult || rawResult;
        })
        .nullable(),
    storyTextFr: Yup.array().of(Yup.object()).nullable(),
    bookNameEn: Yup.string().nullable(),
    bookNameAr: Yup.string().nullable(),
    bookNameFr: Yup.string().nullable(),
    authorEn: Yup.string().nullable(),
    authorAr: Yup.string().required('Required!'),
    authorFr: Yup.string().nullable(),
    publisherNameEn: Yup.string().nullable(),
    publisherNameAr: Yup.string().required('Required!'),
    publisherNameFr: Yup.string().nullable(),
    publisherImageEn: Yup.string().nullable(),
    publisherImageAr: Yup.string().nullable(),
    publisherImageFr: Yup.string().nullable(),
    publisherLinkEn: Yup.string().matches(LINK_REGEX, 'Enter correct url!').nullable(),
    publisherLinkAr: Yup.string().matches(LINK_REGEX, 'Enter correct url!').nullable(),
    publisherLinkFr: Yup.string().matches(LINK_REGEX, 'Enter correct url!').nullable(),
    publisherDetailsEn: Yup.array().of(Yup.object()).nullable(),
    publisherDetailsAr: Yup.array().of(Yup.object()).nullable(),
    publisherDetailsFr: Yup.array().of(Yup.object()).nullable(),
    moreInfoEn: Yup.array().of(Yup.object()).nullable(),
    moreInfoAr: Yup.array().of(Yup.object()).nullable(),
    moreInfoFr: Yup.array().of(Yup.object()).nullable(),
});

export const newPodcastValidationSchema = Yup.object().shape({
    titleEn: Yup.string().nullable(),
    titleAr: Yup.string().required('Required!'),
    totalDurationEn: Yup.number()
        .nullable(true)
        .transform((_, val) => (val === val ? val : null)),
    totalDurationAr: Yup.number()
        .nullable(true)
        .transform((_, val) => (val === val ? val : null)),
    imageEn: Yup.string().nullable(),
    imageAr: Yup.string().nullable(),
    categoryId: Yup.string().required('Required!'),
    audioEn: Yup.string().nullable(),
    audioAr: Yup.string().required('Required!'),
    audioDetailsEn: Yup.array().of(Yup.object()).nullable(),
    audioDetailsAr: Yup.array().of(Yup.object()).nullable(),
    numberEpisodesEn: Yup.number()
        .nullable(true)
        .transform((_, val) => (val === val ? val : null)),
    numberEpisodesAr: Yup.number()
        .nullable(true)
        .transform((_, val) => (val === val ? val : null)),
    authorEn: Yup.string().nullable(),
    authorAr: Yup.string().required('Required!'),
    publisherNameEn: Yup.string().nullable(),
    publisherNameAr: Yup.string().required('Required!'),
    publisherImageEn: Yup.string().nullable(),
    publisherImageAr: Yup.string().nullable(),
    publisherLinkEn: Yup.string().matches(LINK_REGEX, 'Enter correct url!').nullable(),
    publisherLinkAr: Yup.string().matches(LINK_REGEX, 'Enter correct url!').nullable(),
    publisherDetailsEn: Yup.array().of(Yup.object()).nullable(),
    publisherDetailsAr: Yup.array().of(Yup.object()).nullable(),
});

export const newKioskValidationSchema = Yup.object().shape({
    id: Yup.string(),
    description: Yup.string(),
    kioskGroup: Yup.string(),
});

export const changePasswordValidationSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Required!'),
    newPassword: Yup.string()
        .matches(PASSWORD_REGEX, passwrodErrorMessage)
        .test('', val => {
            cachedPassword = val as string;
            return true;
        })
        .required('Required!'),
    confirmPassword: Yup.string()
        .test('', 'Passwords must match', val => {
            return cachedPassword === val;
        })
        .required('Required!'),
});

export const emailSubscriptionValidationSchema = (t: TFunction) =>
    Yup.object().shape({
        name: Yup.string()
            .matches(FULL_NAME_REGEX, t('errors.text'))
            .required(REQUIRED_ERROR),
        email: Yup.string().email(t('errors.email')).required(REQUIRED_ERROR),
        phone: Yup.string()
            .matches(PHONE_NUMBER_REGEX, t('errors.phone'))
            .required(REQUIRED_ERROR),
    });

export const newLocationValidationSchema = Yup.object().shape({
    description: Yup.string(),
    titleEn: Yup.string().required('Required!'),
    titleAr: Yup.string().required('Required!'),
    type: Yup.string().required('Required!'),
    latitude: Yup.number()
        .required('Required!Should be at least -90 and not more than 90')
        .min(-90)
        .max(90),
    longitude: Yup.number()
        .required('Required! Should be at least -180 and not more than 180')
        .min(-180)
        .max(180),
});

export const NewNotificationValidationSchema = Yup.object().shape({
    title: Yup.string().required('Required!').min(3, 'Title should be more than 3'),
    body: Yup.string().required('Required!').min(3, 'Body should be more than 3'),
});

export const exportKioskValidationSchema = Yup.object().shape({
    isDisabled: Yup.boolean().required('Required'),
    deviceId: Yup.string(),
    storyCategory: Yup.string(),
    dateFrom: Yup.date()
        .max(Yup.ref('dateTo'), 'Date from should be lower than date to')
        .required('Required'),
    dateTo: Yup.date()
        .min(Yup.ref('dateFrom'), 'Date to should be greater than date from')
        .required('Required'),
});

import React from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import {
    IInitialKiosksValue,
    FieldsVariation,
    IFieldKioskItem,
    initialKioskDevicePageFormsFields,
} from './constants';
import styles from '../NewKioskDevice.module.scss';
import { newKioskValidationSchema } from 'utils/validationSchemas';
import { IStoryPodcastCategories } from 'store/actions/adminStories/adminStories.types';
import { disableSubmit } from '../../../utils/helpers';

interface INewKioskDeviceForm {
    categories: IStoryPodcastCategories[];
    initialValues: any;
    onSubmit: (value: IInitialKiosksValue) => void;
    buttonText: string;
}

const createInputField = <T extends IInitialKiosksValue>(
    formik: FormikProps<T>,
    kiosk: IFieldKioskItem,
) => {
    const {
        touched: formikTouch,
        errors: formikError,
        setFieldValue,
        values
    } = formik;
    const name = kiosk.name;
    const label = kiosk.label;
    const inputType = kiosk.inputType;
    const isDisaled = kiosk.isDisabled;
    switch (kiosk.field) {

        case FieldsVariation.INPUT:
            return (
                <div key={label} className="mb-1 position-relative pb-1">
                    <label className="form-label d-flex align-items-start flex-column">
                        {label}
                        <Field
                            onKeyDown={disableSubmit}
                            className="form-control mt-1"
                            type={inputType}
                            name={name}
                            disabled={isDisaled}
                            placeholder={label}
                        />
                    </label>
                    {formikError[name] && formikTouch[name] ? (
                        <div className={styles.error}>{formikError[name]}</div>
                    ) : null}
                </div>
            );


        case FieldsVariation.TEXTAREA:
            return (
                <div key={label} className="mb-1 position-relative pb-1">
                    <label className="form-label d-flex align-items-start flex-column">
                        {label}
                        <Field name={name}>
                            {() => {
                                return (
                                    <textarea
                                        onKeyDown={disableSubmit}
                                        className="form-control mt-1"
                                        placeholder={label}
                                        value={values[name]}
                                        onChange={val => {
                                            setFieldValue(name, val);
                                        }}
                                    />
                                );
                            }}
                        </Field>

                    </label>
                    {formikError[name] && formikTouch[name] ? (
                        <div className={styles.error}>{formikError[name]}</div>
                    ) : null}
                </div>
            );

        default:
            return <div>DEFAULT</div>;
    }
};

const NewKioskDeviceForm: React.FC<INewKioskDeviceForm> = ({
    initialValues,
    onSubmit,
    buttonText,
}) => {
    return (
        <>
            <div className={styles.newAdminFormWrapper}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={newKioskValidationSchema}
                    onSubmit={onSubmit}>
                    {helpers => {
                        return (
                            <Form>
                                <div className={styles.formBlockWrapper}>
                                    {buttonText === 'Edit' && <>
                                        {initialKioskDevicePageFormsFields.info.map(story =>
                                            createInputField<IInitialKiosksValue>(
                                                helpers,
                                                story,
                                            ),
                                        )} </>}
                                    {initialKioskDevicePageFormsFields.main.map(kiosk =>
                                        createInputField<IInitialKiosksValue>(
                                            helpers,
                                            kiosk,
                                        ),
                                    )}

                                </div>
                                <button
                                    className="btn btn-secondary align-self-end mt-2"
                                    type="submit">
                                    {buttonText}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </>
    );
};

export default NewKioskDeviceForm;

import React, { FC, memo, ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import headerBg from 'assets/images/headerBg.svg';
import headerSmallBg from 'assets/images/headerSmallBg.svg';
import headerSymbols from 'assets/images/headerSymbols.svg';
import LanguageSelect from './LanguageSelect';
import styles from './Header.module.scss';

const headerBgVariants = {
    normal: headerBg,
    small: headerSmallBg,
    without: '',
};

export type THeaderProps = {
    variant: 'normal' | 'small' | 'without';
    symbols?: {
        position: 'left' | 'right';
    };
    languageSelect?: {
        variant: 'default' | 'podcast' | 'story' | 'map';
    };
    banner?: ReactElement;
    logo?: ReactElement;
    content?: {
        leftRenderer?: () => ReactNode;
        rightRenderer?: () => ReactNode;
    };
};

const Header: FC<THeaderProps> = ({
    variant,
    symbols,
    languageSelect,
    banner,
    logo,
    content,
}) => {
    const headerBackground = headerBgVariants[variant];
    return (
        <div className={classNames(styles.header, variant)}>
            {headerBackground ? (
                <img
                    src={headerBackground}
                    alt="Header background"
                    className={styles.headerBg}
                />
            ) : null}
            {symbols && (
                <div className={styles.headerSymbolsWrapper}>
                    <div
                        className={classNames(
                            styles.headerSymbols,
                            symbols.position,
                            variant,
                        )}>
                        <img src={headerSymbols} alt="Header symbols" />
                    </div>
                </div>
            )}
            {languageSelect && (
                <div className={styles.languageSelect}>
                    <LanguageSelect
                        variant={languageSelect.variant}
                        showLang={['ar', 'en', 'fr']}
                    />
                </div>
            )}
            {banner && <div className={styles.bannerWrapper}>{banner}</div>}
            {logo && <div className={styles.logoWrapper}>{logo}</div>}
            {content && (
                <div className={styles.contentWrapper}>
                    <div className={styles.contentLeft}>
                        {content.leftRenderer ? content.leftRenderer() : null}
                    </div>
                    <div className={styles.contentRight}>
                        {content.rightRenderer ? content.rightRenderer() : null}
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(Header);

import React from 'react';
import Podcasts from '../../adminComponents/Podcasts/Podcasts';
import AdminLayout from '../../layouts/AdminLayout/AdminLayout';

const PodcastsPage = () => {
    return (
        <AdminLayout title='Podcasts List'>
            <Podcasts />
        </AdminLayout>
    );
};

export default PodcastsPage;

import { Descendant } from 'slate/dist/interfaces/node';
import { IStoryPodcastCategories } from 'store/actions/adminStories/adminStories.types';

export enum FieldsVariation {
    LINK = 'LINK',
    SWITCH = 'SWITCH',
    TEXTAREA = 'TEXTAREA',
    IMAGE_INPUT = 'IMAGE_INPUT',
    INPUT = 'INPUT',
    DROPDOWN = 'DROPDOWN',
    DROPDOWN_TYPE = 'DROPDOWN_TYPE',
}

export enum InputTypes {
    TEXT = 'text',
    NUMBER = 'number',
}

export interface IStoryOptions {
    id: number;
    titleEn: string;
}

export const slateInitialValue = JSON.stringify([
    {
        type: 'paragraph',
        children: [{ text: '' }],
    },
]);

export interface IInitialStoriesValue {
    titleEn: string;
    titleAr: string;
    titleFr: string;
    storyDurationEn: number;
    storyDurationAr: number;
    storyDurationFr: number;
    imageEn: undefined | File;
    imageAr: undefined | File;
    imageFr: undefined | File;
    categoryId: string;
    storyType: number;
    storyTextEn: Descendant[] | string;
    storyTextAr: Descendant[] | string;
    storyTextFr: Descendant[] | string;
    bookNameEn: string;
    bookNameAr: string;
    bookNameFr: string;
    authorEn: string;
    authorAr: string;
    authorFr: string;
    publisherNameEn: string;
    publisherNameAr: string;
    publisherNameFr: string;
    publisherImageEn: undefined | File;
    publisherImageAr: undefined | File;
    publisherImageFr: undefined | File;
    publisherLinkEn: string;
    publisherLinkAr: string;
    publisherLinkFr: string;
    publisherDetailsEn: Descendant[] | string;
    publisherDetailsAr: Descendant[] | string;
    publisherDetailsFr: Descendant[] | string;
    moreInfoEn: Descendant[] | string;
    moreInfoAr: Descendant[] | string;
    moreInfoFr: Descendant[] | string;
    id?: string;
    category?: IStoryPodcastCategories;
    darkLogo: boolean;
    websiteLink: string;
    translateEn: boolean;
    translateFr: boolean;
}

export interface INewStory extends IInitialStoriesValue {
}

export const initialStoriesValues = {
    titleEn: '',
    titleAr: '',
    titleFr: '',
    storyDurationEn: 0,
    storyDurationAr: 0,
    storyDurationFr: 0,
    imageEn: undefined,
    imageAr: undefined,
    imageFr: undefined,
    categoryId: '',
    storyType: 0,
    storyTextEn: slateInitialValue,
    storyTextAr: slateInitialValue,
    storyTextFr: slateInitialValue,
    bookNameEn: '',
    bookNameAr: '',
    bookNameFr: '',
    authorEn: '',
    authorAr: '',
    authorFr: '',
    publisherNameEn: '',
    publisherNameAr: '',
    publisherNameFr: '',
    publisherImageEn: undefined,
    publisherImageAr: undefined,
    publisherImageFr: undefined,
    publisherLinkEn: '',
    publisherLinkAr: '',
    publisherLinkFr: '',
    publisherDetailsEn: slateInitialValue,
    publisherDetailsAr: slateInitialValue,
    publisherDetailsFr: slateInitialValue,
    moreInfoEn: slateInitialValue,
    moreInfoAr: slateInitialValue,
    moreInfoFr: slateInitialValue,
    darkLogo: false,
    websiteLink: '',
    translateEn: false,
    translateFr: false
};

export interface IFieldStoryItem {
    name: keyof IInitialStoriesValue;
    field: FieldsVariation;
    label: string;
    inputType?: InputTypes;
    required?: boolean;
}

interface IInitialStoryPageFormsFields {
    ar: IFieldStoryItem[];
    en: IFieldStoryItem[];
    fr: IFieldStoryItem[];
    general: IFieldStoryItem[];
    switches: IFieldStoryItem[];
    links: IFieldStoryItem[];
}

export const initialStoryPageFormsFields: IInitialStoryPageFormsFields = {
    en: [
        {
            name: 'titleEn',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Title | En',
        },
        {
            name: 'storyDurationEn',
            inputType: InputTypes.NUMBER,
            field: FieldsVariation.INPUT,
            label: 'Story Duration | En',
            required: true,
        },
        {
            name: 'imageEn',
            field: FieldsVariation.IMAGE_INPUT,
            label: 'Story Image | En',
        },
        {
            name: 'storyTextEn',
            field: FieldsVariation.TEXTAREA,
            label: 'Story Text | En',
        },
        {
            name: 'bookNameEn',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Book Name | En',
        },
        {
            name: 'authorEn',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Author Name | En',
        },
        {
            name: 'publisherNameEn',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Publisher Name | En',
        },
        {
            name: 'publisherImageEn',
            field: FieldsVariation.IMAGE_INPUT,
            label: 'Publisher Image | En',
        },
        {
            name: 'publisherLinkEn',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Publisher Website Link | En',
            required: true,
        },
        {
            name: 'publisherDetailsEn',
            field: FieldsVariation.TEXTAREA,
            label: 'Publisher Details | En',
        },
        {
            name: 'moreInfoEn',
            field: FieldsVariation.TEXTAREA,
            label: 'Publisher More Info | En',
        },
    ],
    ar: [
        {
            name: 'titleAr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Title | Ar',
            required: true,
        },
        {
            name: 'storyDurationAr',
            inputType: InputTypes.NUMBER,
            field: FieldsVariation.INPUT,
            label: 'Story Duration | Ar',
            required: true,
        },
        {
            name: 'imageAr',
            field: FieldsVariation.IMAGE_INPUT,
            label: 'Story Image | Ar',
            required: true,
        },
        {
            name: 'storyTextAr',
            field: FieldsVariation.TEXTAREA,
            label: 'Story Text | Ar',
            required: true,
        },
        {
            name: 'bookNameAr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Book Name | Ar',
        },
        {
            name: 'authorAr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Author Name | Ar',
            required: true,
        },
        {
            name: 'publisherNameAr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Publisher Name | Ar',
            required: true,
        },
        {
            name: 'publisherImageAr',
            field: FieldsVariation.IMAGE_INPUT,
            label: 'Publisher Image | Ar',
            required: true,
        },
        {
            name: 'publisherLinkAr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Publisher Website Link | Ar',
            required: true,
        },
        {
            name: 'publisherDetailsAr',
            field: FieldsVariation.TEXTAREA,
            label: 'Publisher Details | Ar',
        },
        {
            name: 'moreInfoAr',
            field: FieldsVariation.TEXTAREA,
            label: 'Publisher More Info | Ar',
        },
    ],
    fr: [
        {
            name: 'titleFr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Title | Fr',
        },
        {
            name: 'storyDurationFr',
            inputType: InputTypes.NUMBER,
            field: FieldsVariation.INPUT,
            label: 'Story Duration | Fr',
            required: true,
        },
        {
            name: 'imageFr',
            field: FieldsVariation.IMAGE_INPUT,
            label: 'Story Image | Fr',
        },
        {
            name: 'storyTextFr',
            field: FieldsVariation.TEXTAREA,
            label: 'Story Text | Fr',
        },
        {
            name: 'bookNameFr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Book Name | Fr',
        },
        {
            name: 'authorFr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Author Name | Fr',
        },
        {
            name: 'publisherNameFr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Publisher Name | Fr',
        },
        {
            name: 'publisherImageFr',
            field: FieldsVariation.IMAGE_INPUT,
            label: 'Publisher Image | Fr',
        },
        {
            name: 'publisherLinkFr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Publisher Website Link | Fr',
            required: true,
        },
        {
            name: 'publisherDetailsFr',
            field: FieldsVariation.TEXTAREA,
            label: 'Publisher Details | Fr',
        },
        {
            name: 'moreInfoFr',
            field: FieldsVariation.TEXTAREA,
            label: 'Publisher More Info | Fr',
        },
    ],
    general: [
        {
            name: 'storyType',
            field: FieldsVariation.DROPDOWN_TYPE,
            label: 'Story Type',
            required: true,
        },
        {
            name: 'categoryId',
            field: FieldsVariation.DROPDOWN,
            label: 'Story Category',
            required: true,
        },
    ],
    switches: [
        {
            name: 'darkLogo',
            field: FieldsVariation.SWITCH,
            label: 'Logo - Dark Background',
        },
        {
            name: 'translateEn',
            field: FieldsVariation.SWITCH,
            label: 'English Content',
        },
        {
            name: 'translateFr',
            field: FieldsVariation.SWITCH,
            label: 'French Content',
        },
    ],
    links: [
        {
            name: 'websiteLink',
            field: FieldsVariation.LINK,
            label: 'Website Link',
        },
    ],
};

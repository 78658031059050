// import facebook from 'assets/images/facebook.svg';
// import linkedin from 'assets/images/linkedin.svg';
import youtube from 'assets/images/youtube.svg';
import instagram from 'assets/images/instagram.svg';
import twitter from 'assets/images/twitter.svg';
import whatsapp from 'assets/images/whatsapp.svg';

const socialMedia = [
    // {
    //     mediaIcon: facebook,
    //     mediaLink: ""
    // },
    // {
    //     mediaIcon: linkedin,
    //     mediaLink: ""
    // },
    {
        mediaIcon: youtube,
        mediaLink: 'https://www.youtube.com/c/MOCSaudi',
    },
    {
        mediaIcon: instagram,
        mediaLink: 'https://www.instagram.com/mocsaudi/',
    },
    {
        mediaIcon: twitter,
        mediaLink: 'https://twitter.com/mocsaudi',
    },
    {
        mediaIcon: whatsapp,
        mediaLink: 'https://wa.me/+966115207053',
    },
];

export default socialMedia;

import { INewKiosk } from 'adminComponents/NewKioskDevice/NewKioskDeviceForm/constants';
import { IFilterParams } from 'models/filters';
import { IPaginationResponse } from 'services/types';
import { IKioskCategories } from '../adminKiosks/adminKiosks.types';
import { IAdminKiosks } from '../../../services/admin.services/adminKiosks/adminKiosks.types';
import { AdminKiosksTypes } from './adminKiosks.types';

export const fetchAdminKiosksRequestAction = (
    page: number = 0,
    searchWord?: string,
    categoryId?: string,
) => ({
    type: AdminKiosksTypes.FETCH_ADMIN_KIOSKS_REQUEST,
    payload: { page, searchWord, categoryId },
});

export const fetchAdminKiosksSuccesstAction = (
    podcastsResponse: IPaginationResponse<IAdminKiosks>,
) => ({
    type: AdminKiosksTypes.FETCH_ADMIN_KIOSKS_SUCCESS,
    payload: podcastsResponse,
});

export const fetchAdminKiosksFailureAction = (error: string) => ({
    type: AdminKiosksTypes.FETCH_ADMIN_KIOSKS_FAILURE,
    payload: error,
});

export const deactivateAdminKiosksRequestAction = (podcastId: string, page: number) => ({
    type: AdminKiosksTypes.DEACTIVATE_ADMIN_KIOSKS_REQUEST,
    payload: {
        podcastId,
        page,
    },
});

export const deleteAdminKioskRequestAction = (
    kioskId: string,
    params: IFilterParams,
) => ({
    type: AdminKiosksTypes.DELETE_ADMIN_KIOSKS_REQUEST,

    payload: {
        kioskId,
        params,
    },
});

export const deleteAdminKiosksFailureAction = (error: string) => ({
    type: AdminKiosksTypes.DELETE_ADMIN_KIOSKS_FAILURE,
    payload: error,
});

export const deleteAdminKiosksSuccesstAction = () => {
    return {
        type: AdminKiosksTypes.DELETE_ADMIN_KIOSKS_SUCCESS,
    };
};
export const deactivateAdminKiosksSuccesstAction = () => ({
    type: AdminKiosksTypes.DEACTIVATE_ADMIN_KIOSKS_SUCCESS,
});

export const deactivateAdminKiosksFailureAction = (error: string) => ({
    type: AdminKiosksTypes.DEACTIVATE_ADMIN_KIOSKS_FAILURE,
    payload: error,
});
export const activateAdminKiosksRequestAction = (
    kioskId: string,
    page: number,
    value: boolean,
) => ({
    type: AdminKiosksTypes.ACTIVATE_ADMIN_KIOSKS_REQUEST,
    payload: {
        kioskId,
        page,
        value,
    },
});

export const activateAdminKiosksSuccesstAction = () => ({
    type: AdminKiosksTypes.ACTIVATE_ADMIN_KIOSKS_SUCCESS,
});

export const activateAdminKiosksFailureAction = (error: string) => ({
    type: AdminKiosksTypes.ACTIVATE_ADMIN_KIOSKS_FAILURE,
    payload: error,
});

export const getAdminsKiosksCategoriesRequestAction = () => ({
    type: AdminKiosksTypes.KIOSKS_CATEGORIES_REQUEST,
});

export const getAdminsKiosksExportToCsvRequestAction = (val: boolean) => ({
    type: AdminKiosksTypes.KIOSKS_EXPORT_REQUEST,
    payload: val,
});

export const clearAdminKioskExportRequestAction = () => ({
    type: AdminKiosksTypes.CLEAR_EXPORT_REQUST,
});

export const getAdminsKiosksExportToCsvFailureAction = () => ({
    type: AdminKiosksTypes.KIOSKS_EXPORT_FAILURE,
});

export const getAdminsKiosksExportToCsvSuccessAction = (csv: string) => ({
    type: AdminKiosksTypes.KIOSKS_EXPORT_SUCCESS,
    payload: csv,
});

export const getAdminsKiosksCategoriesSuccessAction = (
    categories: IKioskCategories[],
) => ({
    type: AdminKiosksTypes.KIOSKS_CATEGORIES_SUCCESS,
    payload: categories,
});

export const getAdminsKiosksCategoriesFailureAction = (message: string) => ({
    type: AdminKiosksTypes.KIOSKS_CATEGORIES_FAILURE,
    payload: message,
});

export const postNewKioskRequestAction = (newKiosk: INewKiosk | FormData) => ({
    type: AdminKiosksTypes.POST_NEW_KIOSK_REQUEST,
    payload: newKiosk,
});

export const postNewKioskSuccessAction = (message: string) => ({
    type: AdminKiosksTypes.POST_NEW_KIOSK_SUCCESS,
    payload: message,
});

export const postNewKioskFailureAction = (message: string) => ({
    type: AdminKiosksTypes.POST_NEW_KIOSK_FAILURE,
    payload: message,
});
export const cleanNewKioskMessageAction = () => ({
    type: AdminKiosksTypes.CLEAN_KIOSK_MESSAGE,
});

import { StoryDetailsTypes } from './storyDetails.types';
import {
    IInitialStoriesValue,
    INewStory,
} from 'adminComponents/NewStory/NewStoryFrom/constants';

export const fetchStoryDetailsRequestAction = (id: string) => ({
    type: StoryDetailsTypes.FETCH_STORY_DETAIL_REQUEST,
    payload: id,
});

export const fetchStoryDetailsFailureAction = (message: string) => ({
    type: StoryDetailsTypes.FETCH_STORY_DETAIL_FAILURE,
    payload: message,
});

export const fetchStoryDetailsSuccessAction = (storyDetail: IInitialStoriesValue) => ({
    type: StoryDetailsTypes.FETCH_STORY_DETAIL_SUCCESS,
    payload: storyDetail,
});
export const editStoryRequestAction = (newStory: INewStory | FormData, id: string) => ({
    type: StoryDetailsTypes.EDIT_STORY_REQUEST,
    payload: {
        newStory,
        id,
    },
});

export const editStorySuccessAction = (message: string) => ({
    type: StoryDetailsTypes.EDIT_STORY_SUCCESS,
    payload: message,
});

export const editStoryFailureAction = (message: string) => ({
    type: StoryDetailsTypes.EDIT_STORY_FAILURE,
    payload: message,
});

export const cleanStoryMessageAction = () => ({
    type: StoryDetailsTypes.CLEAR_STORY_MESSAGE,
});

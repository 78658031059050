import NewPodcastForm from 'adminComponents/NewPodcast/NewPodcastForm';
import { IInitialPodcastsValue } from 'adminComponents/NewPodcast/NewPodcastForm/constants';
import { useAppSelector } from 'hooks';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import { serialize } from 'object-to-formdata';
import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import Alert from 'sharedComponents/Alert';
import {
    cleanPodcastMessageAction,
    editPodcastRequestAction,
    fetchPodcastDetailsRequestAction,
} from 'store/actions/adminPodcastDetails/podcastDetails.action';
import { getAdminsPodcastsCategoriesRequestAction } from 'store/actions/adminPodcasts/adminPodcasts.action';
import { difference } from 'utils/helpers';

const AdminPodcastDetails = () => {
    const { id } = useParams<{ id: string }>();
    const podcastDetails = useAppSelector(
        store => store.adminPodcastDetails.podcastDetails,
    );
    const loading = useAppSelector(store => store.adminPodcastDetails.loading);
    const categories = useAppSelector(state => state.adminPodcasts.podcastsCategories);
    const message = useAppSelector(state => state.adminPodcastDetails.message);
    const error = useAppSelector(state => state.adminPodcastDetails.error);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAdminsPodcastsCategoriesRequestAction());
        return () => {
            dispatch(cleanPodcastMessageAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(fetchPodcastDetailsRequestAction(id));
    }, [dispatch, id]);

    const onSubmit = (values: IInitialPodcastsValue) => {
        const formData = serialize(difference(podcastDetails!, values));
        dispatch(editPodcastRequestAction(formData, id));
    };

    return (
        <AdminLayout title={'Podcast Details'}>
            {message && <Alert message={message} isError={false} />}
            {error && <Alert message={error} isError={true} />}
            {loading ? (
                <div className="container-fluid d-flex justify-content-center align-items-center h-100">
                    <Spinner animation="border" />
                </div>
            ) : (
                podcastDetails && <NewPodcastForm
                    categories={categories}
                    initialValues={podcastDetails!}
                    onSubmit={onSubmit}
                    buttonText={'Edit'}
                />
            )}
        </AdminLayout>
    );
};

export default AdminPodcastDetails;

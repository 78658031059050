import { INewStory } from 'adminComponents/NewStory/NewStoryFrom/constants';
import { IFilterParams } from 'models/filters';
import { IPaginationResponse } from 'services/types';
import {
    AdminStoryTypes,
    IAdminStoryItem,
    IStoryPodcastCategories,
} from './adminStories.types';

export const fetchAdminsStoriesRequestAction = (
    page: number = 0,
    searchWord?: string,
    categoryId?: string,
    storyType?:number | null
) => ({
    type: AdminStoryTypes.FETCH_STORIES_REQUEST,
    payload: { page, searchWord, categoryId, storyType },
});

export const fetchAdminsStoriesSuccessAction = (
    storiesResponse: IPaginationResponse<IAdminStoryItem>,
) => ({
    type: AdminStoryTypes.FETCH_STORIES_SUCCESS,
    payload: storiesResponse,
});

export const fetchAdminsStoriesFailureAction = (message: string) => ({
    type: AdminStoryTypes.FETCH_STORIES_FAILURE,
    payload: message,
});

export const deactivateAdminStoryRequestAction = (storyId: string, page: number) => ({
    type: AdminStoryTypes.DEACTIVATE_STORY_REQUEST,
    payload: {
        storyId,
        page,
    },
});

export const deactivateAdminStorySuccessAction = () => ({
    type: AdminStoryTypes.DEACTIVATE_STORY_SUCCESS,
});

export const deleteAdminStoryFailureAction = (message: string) => ({
    type: AdminStoryTypes.DELETE_STORY_FAILURE,
    payload: message,
});

export const deleteAdminStoryRequestAction = (storyId: string, params: IFilterParams) => ({
    type: AdminStoryTypes.DELETE_STORY_REQUEST,
    payload: {
        storyId,
        params,
    },
});

export const deleteAdminStorySuccessAction = () => ({
    type: AdminStoryTypes.DELETE_STORY_SUCCESS,
});

export const deactivateAdminStoryFailureAction = (message: string) => ({
    type: AdminStoryTypes.DEACTIVATE_STORY_FAILURE,
    payload: message,
});

export const activateAdminStoryRequestAction = (storyId: string, page: number) => ({
    type: AdminStoryTypes.ACTIVATE_STORY_REQUEST,
    payload: {
        storyId,
        page,
    },
});

export const activateAdminStorySuccessAction = () => ({
    type: AdminStoryTypes.ACTIVATE_STORY_SUCCESS,
});

export const activateAdminStoryFailureAction = (message: string) => ({
    type: AdminStoryTypes.ACTIVATE_STORY_FAILURE,
    payload: message,
});

export const getAdminsStoriesCategoriesRequestAction = () => ({
    type: AdminStoryTypes.SHORT_STORY_CATEGORIES_REQUEST,
});

export const getAdminsStoriesCategoriesSuccessAction = (
    categories: IStoryPodcastCategories[],
) => ({
    type: AdminStoryTypes.SHORT_STORY_CATEGORIES_SUCCESS,
    payload: categories,
});

export const getAdminsStoriesCategoriesFailureAction = (message: string) => ({
    type: AdminStoryTypes.SHORT_STORY_CATEGORIES_FAILURE,
    payload: message,
});

export const postNewStoriesRequestAction = (newStory: INewStory | FormData) => ({
    type: AdminStoryTypes.POST_NEW_STORY_REQUEST,
    payload: newStory,
});

export const postNewStoriesSuccessAction = (message: string) => ({
    type: AdminStoryTypes.POST_NEW_STORY_SUCCESS,
    payload: message,
});

export const postNewStoriesFailureAction = (message: string) => ({
    type: AdminStoryTypes.POST_NEW_STORY_FAILURE,
    payload: message,
});

export const cleanNewStoryMessageAction = () => ({
    type: AdminStoryTypes.CLEAN_STORY_MESSAGE,
});

export const getAdminsStoryExportToCsvRequestAction = () => ({
    type: AdminStoryTypes.STORY_EXPORT_REQUEST,
});

export const getAdminsStoryExportToCsvFailureAction = () => ({
    type: AdminStoryTypes.STORY_EXPORT_FAILURE,
});

export const getAdminsStoryExportToCsvSuccessAction = (csv:string) => ({
    type: AdminStoryTypes.STORY_EXPORT_SUCCESS,
    payload: csv,
});

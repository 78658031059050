import { axiosInstance } from '../../interceptor';
import { AxiosResponse } from 'axios';
import { IEmailStorageListItem, INewNotification } from './adminEmailsStorage.types';

import { IPaginationResponse } from 'services/types';

export const getEmailStorageListEndpoint = (
    page = 0,
    searchWord: string,
    categoryId: string,
    perPage = 10,
): Promise<AxiosResponse<IPaginationResponse<IEmailStorageListItem>>> => {
    return axiosInstance.get('admin/email-storage', {
        params: { page, perPage, categoryId, searchWord },
    });
};

export const getAdminsEmailStorageExportToCsvEndpoint = (): Promise<
    AxiosResponse<string>
> => {
    return axiosInstance.get('admin/email-storage/csv');
};

export const createNewNotificationEndpoint = (
    notification: INewNotification,
): Promise<AxiosResponse> => {
    return axiosInstance.post(`admin/email-storage/notify`, notification);
};


export const deleteEmailStorageEndpoint = (
    emailId: string,
): Promise<AxiosResponse> => {
    return axiosInstance.delete(`admin/email-storage/${emailId}`);
};

export const deactivateAdminEmailStorageEndpoint = (
    emailId: string,
    page: number,
): Promise<AxiosResponse> => {
    return axiosInstance.put(`admin/email-storage/${emailId}`,{ params: { page } });
};

export const activateAdminEmailStorageEndpoint = (
    emailId: string,
    page: number,
    value: boolean,
): Promise<AxiosResponse> => {
    return axiosInstance.put(`admin/email-storage/${emailId}`, {
        isDisabled: value,
    });
};
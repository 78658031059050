import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IPaginationResponse } from 'services/types';
import * as AdminEmailsStorageEndPoints from 'services/admin.services/adminEmailsStorage/adminEmailsStorage.endpoints';
import * as AdminEmailsStorageActions from '../actions/adminEmailsStorage/adminEmailsStorage.action';
import { AxiosResponse } from 'axios';
import {
    FetchAdminEmailsStorageRequestActionType,
    IEmailStorageListItem,
    AdminEmailsStorageTypes,
    AddNewNotificationRequestActionType,
    DeleteAdminEmailRequestActionType,
    DeactivateAdminEmailStorageRequestActionType,
    ActivateAdminEmailStorageRequestActionType,
} from 'store/actions/adminEmailsStorage/adminEmailsStorage.types';
import { IFilterParams } from 'models/filters';

function* fetchAdminEmailStorageDataWorker(
    action: FetchAdminEmailsStorageRequestActionType,
) {
    const values = action.payload;
    const filteredObj = Object.keys(values).reduce((acc, item) => {
        if (values[item as keyof IFilterParams] !== '') {
            return {
                ...acc,
                [item]: values[item as keyof IFilterParams],
            };
        } else return acc;
    }, {});
    const { page, searchWord, categoryId } = filteredObj as IFilterParams;

    try {
        const { data }: { data: IPaginationResponse<IEmailStorageListItem> } = yield call(
            AdminEmailsStorageEndPoints.getEmailStorageListEndpoint,
            page,
            searchWord,
            categoryId,
        );
        yield put(AdminEmailsStorageActions.fetchEmailsStorageSuccessAction(data));
    } catch (error) {}
}

export function* getAdminEmailStorageExportToCsv() {
    try {
        const exportResponse: AxiosResponse = yield call(
            AdminEmailsStorageEndPoints.getAdminsEmailStorageExportToCsvEndpoint,
        );
        yield put(
            AdminEmailsStorageActions.getAdminEmailsStorageExportToCsvSuccessAction(
                exportResponse.data,
            ),
        );
    } catch (error) {
        //;
    }
}
function* addNewNotificationWorker(action: AddNewNotificationRequestActionType) {
    const notification = action.payload;

    try {
        yield call(
            AdminEmailsStorageEndPoints.createNewNotificationEndpoint,
            notification,
        );
        yield put(
            AdminEmailsStorageActions.addNewNotificationSuccessAction(
                'Created Successfully',
            ),
        );
    } catch (error: any) {
        yield put(
            AdminEmailsStorageActions.addNewNotificationFailureAction(error.message),
        );
    }
}
function* deleteAdminEmailsStorageWorker(action: DeleteAdminEmailRequestActionType) {
    const id = action.payload.emailId;
    const { page, searchWord, categoryId } = action.payload.params;

    try {
        yield call(AdminEmailsStorageEndPoints.deleteEmailStorageEndpoint, id);
        yield put(AdminEmailsStorageActions.deleteAdminEmailSuccessAction());
        yield put(
            AdminEmailsStorageActions.fetchEmailsStorageRequestAction(
                page,
                searchWord,
                categoryId,
            ),
        );
    } catch (error: any) {
        yield put(AdminEmailsStorageActions.deleteAdminEmailFailureAction(error.message));
    }
}

function* deactivateAdminEmailStorageWorker(
    action: DeactivateAdminEmailStorageRequestActionType
) {
    const id = action.payload.emailId;
    const page = action.payload.page;
    

    try {
        yield call(AdminEmailsStorageEndPoints.deactivateAdminEmailStorageEndpoint, id, page);
        yield put(AdminEmailsStorageActions.deactivateAdminEmailStorageSuccessAction());
        yield put(AdminEmailsStorageActions.fetchEmailsStorageRequestAction(page));
    } catch (error: any) {
        // yield put(deactivateAdminFailureAction(error.message));
    }
}

function* activateAdminEmailStorageWorker(action: ActivateAdminEmailStorageRequestActionType) {
    const id = action.payload.emailId;
    const page = action.payload.page;
    const value = action.payload.value;

    try {
        yield call(AdminEmailsStorageEndPoints.activateAdminEmailStorageEndpoint, id, page,value);
        yield put(AdminEmailsStorageActions.activateAdminEmailStorageSuccessAction());
        yield put(AdminEmailsStorageActions.fetchEmailsStorageRequestAction(page));
    } catch (error: any) {
        // yield put(activateAdminFailureAction(error.message));
    }
}

export function* adminEmailsStorageSaga() {
    yield all([
        takeLatest(
            AdminEmailsStorageTypes.FETCH_EMAILS_STORAGE_DATA_REQUEST,
            fetchAdminEmailStorageDataWorker,
        ),
        takeLatest(
            AdminEmailsStorageTypes.EMAILS_STORAGE_EXPORT_REQUEST,
            getAdminEmailStorageExportToCsv,
        ),
        takeLatest(
            AdminEmailsStorageTypes.ADD_NEW_NOTIFICATION_REQUEST,
            addNewNotificationWorker,
        ),
        takeLatest(
            AdminEmailsStorageTypes.DELETE_ADMIN_EMAIL_REQUEST,
            deleteAdminEmailsStorageWorker,
        ),
        takeLatest(
            AdminEmailsStorageTypes.DEACTIVATE_ADMIN_EMAIL_REQUEST,
            deactivateAdminEmailStorageWorker,
        ),
        takeLatest(
            AdminEmailsStorageTypes.ACTIVATE_ADMIN_EMAIL_REQUEST,
            activateAdminEmailStorageWorker,
        ),
    ]);
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { TExportKioskDeviceData } from 'types/kiosk';
import { adminKioskApi } from 'store/admin/kiosk/api';

export const exportKiosksList = createAsyncThunk<string,
    { data: TExportKioskDeviceData, onSuccess: (data: string) => void },
    { rejectValue: string }>(
    'adminKiosk/exportToCSV',
    async ({data, onSuccess}, { rejectWithValue }) => {
        const response = await adminKioskApi.exportToSCV(data);

        if (response.success) {
            onSuccess(response.data)
            return response.data;
        }

        return rejectWithValue(response.error);
    }
);

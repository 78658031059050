import { AxiosResponse } from 'axios';
import { axiosInstance } from 'services/interceptor';
import { ChangePasswordBody, LoginResponse, LoginUserBody } from './auth.types';

export const loginUserEndpoint = (
    body: LoginUserBody,
): Promise<AxiosResponse<LoginResponse>> => {
    return axiosInstance.post('/auth/login', body);
};

export const logoutUserEndpoint = (): Promise<AxiosResponse> => {
    return axiosInstance.post('/auth/logout');
};
export const changePasswordEndpoint = (
    body: ChangePasswordBody,
): Promise<AxiosResponse> => {
    return axiosInstance.put('auth/password', body);
};

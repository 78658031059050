import {
    ChangePasswordBody,
    LoginUserBody,
} from 'services/admin.services/auth/auth.types';

export enum AuthTypes {
    LOGIN_REQUEST = 'LOGIN_REQUEST',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILURE = 'LOGIN_FAILURE',

    LOGOUT_REQUEST = 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE = 'LOGOUT_FAILURE',

    CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE',
}

export interface LoginUserRequestAction {
    type: AuthTypes.LOGIN_REQUEST;
    payload: LoginUserBody;
}

export interface LoginUserSuccessAction {
    type: AuthTypes.LOGIN_SUCCESS;
    payload: string;
}

export interface LoginUserFailureAction {
    type: AuthTypes.LOGIN_FAILURE;
    payload: string;
}

export interface LogoutUserRequestAction {
    type: AuthTypes.LOGOUT_REQUEST;
}
export interface LogoutUserSuccessAction {
    type: AuthTypes.LOGOUT_SUCCESS;
}
export interface LogoutUserFailureAction {
    type: AuthTypes.LOGOUT_FAILURE;
    payload: string;
}

export interface ChangePasswordRequestAction {
    type: AuthTypes.CHANGE_PASSWORD_REQUEST;
    payload: ChangePasswordBody;
}

export interface ChangePasswordSuccessAction {
    type: AuthTypes.CHANGE_PASSWORD_SUCCESS;
    payload: string;
}

export interface ChangePasswordFailureAction {
    type: AuthTypes.CHANGE_PASSWORD_FAILURE;
    payload: string;
}
export interface IInitialState {
    adminToken: string;
    authenticated: boolean;
    loginError: string;
    loading: boolean;
    message: string;
}

export type AuthActionTypes =
    | LoginUserRequestAction
    | LoginUserSuccessAction
    | LoginUserFailureAction
    | LogoutUserRequestAction
    | LogoutUserSuccessAction
    | LogoutUserFailureAction
    | ChangePasswordRequestAction
    | ChangePasswordSuccessAction
    | ChangePasswordFailureAction;

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IInitialPodcastsValue } from 'adminComponents/NewPodcast/NewPodcastForm/constants';
import {
    editPodcastEndpoin,
    getPodcastDetailsEndpoint,
} from 'services/admin.services/adminPodcasts/podcastDetails.endpoint';
import {
    editPodcastFailureAction,
    editPodcastSuccessAction,
    fetchPodcastDetailsFailureAction,
    fetchPodcastDetailsRequestAction,
    fetchPodcastDetailsSuccessAction,
} from 'store/actions/adminPodcastDetails/podcastDetails.action';
import {
    editPodcastRequestActionType,
    fetchPodcastDetailsRequestActionType,
    PodcastDetailsTypes,
} from 'store/actions/adminPodcastDetails/podcastDetails.types';

function* fetchPodcastDetailsWorker(action: fetchPodcastDetailsRequestActionType) {
    const podcastId = action.payload;
    try {
        const { data }: { data: IInitialPodcastsValue } = yield call(
            getPodcastDetailsEndpoint,
            podcastId,
        );

        const initialValues = {
            titleEn: data.titleEn,
            titleAr: data.titleAr,
            totalDurationEn: data.totalDurationEn,
            totalDurationAr: data.totalDurationAr,
            imageEn: data.imageEn,
            imageAr: data.imageAr,
            categoryId: data.category?.id,
            audioEn: data.audioLinkEn,
            audioAr: data.audioLinkAr,
            audioDetailsEn: data.audioDetailsEn,
            audioDetailsAr: data.audioDetailsAr,
            numberEpisodesEn: data.numberEpisodesEn,
            numberEpisodesAr: data.numberEpisodesAr,
            authorEn: data.authorEn,
            authorAr: data.authorAr,
            publisherNameEn: data.publisherNameEn,
            publisherNameAr: data.publisherNameAr,
            publisherImageEn: data.publisherImageEn,
            publisherImageAr: data.publisherImageAr,
            publisherLinkEn: data.publisherLinkEn,
            publisherLinkAr: data.publisherLinkAr,
            publisherDetailsEn: data.publisherDetailsEn,
            publisherDetailsAr: data.publisherDetailsAr,
            darkLogo: data.darkLogo
        } as IInitialPodcastsValue;
        yield put(fetchPodcastDetailsSuccessAction(initialValues));
    } catch (error: any) {
        yield put(fetchPodcastDetailsFailureAction(error.message));
    }
}

function* editPodcastWorker(action: editPodcastRequestActionType) {
    const podcastId = action.payload.id;
    const newPodcast = action.payload.newPodcast;

    try {
        yield call(editPodcastEndpoin, newPodcast, podcastId);
        yield put(editPodcastSuccessAction('Updated Successfully'));
        yield put(fetchPodcastDetailsRequestAction(podcastId));
    } catch (error: any) {
        yield put(editPodcastFailureAction(error.message));
    }
}

export function* adminPodcastDetailsSaga() {
    yield all([
        takeLatest(
            PodcastDetailsTypes.FETCH_PODCAST_DETAIL_REQUEST,
            fetchPodcastDetailsWorker,
        ),
        takeLatest(PodcastDetailsTypes.EDIT_PODCAST_REQUEST, editPodcastWorker),
    ]);
}

import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useClickOutside } from 'hooks/useClickOutside';
import styles from './DeleteModal.module.scss';

interface IDeleteModal {
    onClose: (event: any) => void;
    open: boolean;
    onSuccess: () => void;
}

export const DeleteModal = ({ onSuccess, onClose, open }: IDeleteModal) => {
    const modalRoot = document.getElementById('modal-root')!;
    const modalRef = useRef(null);

    useClickOutside(modalRef, onClose, open);

    return ReactDOM.createPortal(
        <div className={styles.mainContainer}>
            <div className={styles.modalContainer} ref={modalRef}>
                <div className={styles.header}>
                    <div className={styles.title}>Are you sure you want to delete?</div>
                    <i className={'bi bi-x-lg'} onClick={onClose}></i>
                </div>
                <div className={styles.line}></div>
                <div className={styles.buttons}>
                    <button onClick={onSuccess}>Yes</button>
                    <button onClick={onClose}>No</button>
                </div>
            </div>
        </div>,
        modalRoot,
    );
};

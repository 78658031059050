import DropDown from 'adminComponents/DropDown/DropDown';
import React from 'react';

import styles from './AdminTopbar.module.scss';

interface IAdminTopbarProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdminTopbar: React.FC<IAdminTopbarProps> = ({ setOpen }) => {
    const toggleMenu = () => setOpen(prev => !prev);

    return (
        <div className={styles.topbar}>
            <div className={styles.sideBarBtnWrapper}>
                <button
                    type="button"
                    onMouseDown={toggleMenu}
                    className={styles.sideBarBtn}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        fill="currentColor"
                        className="bi bi-list"
                        viewBox="0 0 16 16">
                        <path
                            fillRule="evenodd"
                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                        />
                    </svg>
                </button>
            </div>
            <div className={styles.dropdownWrapper}>
                <DropDown userName={'Admin'} />
            </div>
        </div>
    );
};

export default AdminTopbar;

import { useEffect, useState } from 'react';
import ReactGA, { ga } from 'react-ga';

export const makePageView = (pageName:string) => 
    ReactGA.pageview(window.location.pathname, [],pageName);

export const useAnalytics = () =>{
    const [pageName, setPageName] = useState("")
    const captureOutboundLink = (url:string) => {
        ga('send', 'event', 'outbound', 'click', url, {
          'transport': 'beacon',
          'hitCallback': () => document.location = url,
        });
    }
    useEffect(() => {
        pageName && makePageView(pageName);
    }, [pageName]);

    return [setPageName, captureOutboundLink]
}

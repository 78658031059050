import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import footerLightBg from 'assets/images/footerLightBg.svg';
import Paths from 'constants/paths';
import footerLogo from 'assets/images/footerLogo.svg';
import styles from './LightFooter.module.scss';
import { Link } from 'react-router-dom';
import Button from 'portalComponents/Button';

const LightFooter: FC = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.footer}>
            <div className={styles.footerBgWrapper}>
                <img
                    src={footerLightBg}
                    alt="Footer background"
                    className={styles.footerBg}
                />
            </div>
            <div className={styles.footerContent}>
                <Link to={Paths.EMAIL_SUBSCRIPTION}>
                    <Button width="fixedLarge" fontSize="small">
                        {t('footer.registerButton')}
                    </Button>
                </Link>
                <Link to={Paths.HOME}>
                    <img
                        src={footerLogo}
                        alt="Footer logo"
                        className={styles.footerLogo}
                    />
                </Link>
            </div>
        </div>
    );
};

export default LightFooter;

import React, { useEffect } from 'react';
import './App.scss';
import moment from 'moment';
import 'moment/locale/ar-sa';
import 'moment/locale/en-gb';
import ProtectedRouter from 'routes';
import { useLocation } from 'react-router-dom';
import { ADMIN } from 'routes/config';
import { v4 as uuidv4 } from 'uuid';

const langMap = new Map([
    ['ar', 'ar'],
    ['fr', 'fr'],
    ['en', 'en'],
]);

function App() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const langParam = langMap.get(searchParams.get('lang') as unknown as string);
    const lang = localStorage.getItem('i18nextLng') || 'ar';
    localStorage.setItem('i18nextLng', lang);
    if (!localStorage.getItem('sessionId')) localStorage.setItem('sessionId', uuidv4());
    document.documentElement.lang = lang;

    useEffect(() => {
        if (langParam) {
            localStorage.setItem('i18nextLng', langParam);
            document.documentElement.lang = langParam;
            window.location.replace(location.pathname);
        }
    }, [location.pathname, langParam]);

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [location.pathname]);

    useEffect(() => {
        moment.locale('en-gb');
        if (lang === 'ar' && !location.pathname.includes(ADMIN)) {
            document.body.setAttribute('dir', 'rtl');
            moment.locale('ar-sa');
        }
        if (lang === 'fr' && !location.pathname.includes(ADMIN)) {
            moment.locale('fr');
        }
        // if (
        //     localStorage.getItem('i18nextLng') === 'fr' &&
        //     !location.pathname.includes('SHS/')
        // ) {
        //     localStorage.setItem('i18nextLng', 'ar');
        // }
    }, [lang, location.pathname]);

    return <ProtectedRouter />;
}

export default App;

import {
    IInitialLocationValue,
    INewLocation,
} from 'adminPages/NewMapDataPage/NewLocationForm/constans';
import { mapDataDetailsTypes } from './adminMapDataDetails.types';

export const fetchMapDataDetailsRequestAction = (id: string) => ({
    type: mapDataDetailsTypes.FETCH_MAP_DATA_DETAIL_REQUEST,
    payload: id,
});

export const fetchMapDataSuccessAction = (mapDataDetails: IInitialLocationValue) => ({
    type: mapDataDetailsTypes.FETCH_MAP_DATA_DETAIL_SUCCESS,
    payload: mapDataDetails,
});
export const fetchMapDataDetailsFailureAction = (message: string) => ({
    type: mapDataDetailsTypes.FETCH_MAP_DATA_DETAIL_FAILURE,
    payload: message,
});

export const editMapDataRequestRequestAction = (
    newLocation: INewLocation,
    id: string,
) => ({
    type: mapDataDetailsTypes.EDIT_MAP_DATA_REQUEST,
    payload: {
        newLocation,
        id,
    },
});

export const editMapDataSuccessAction = (message: string) => ({
    type: mapDataDetailsTypes.EDIT_MAP_DATA_SUCCESS,
    payload: message,
});

export const editMapDataFailureAction = (message: string) => ({
    type: mapDataDetailsTypes.EDIT_MAP_DATA_FAILURE,
    payload: message,
});

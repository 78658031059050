import { IRandomStoryResponse } from './../../services/portalServices/portal.types';
import {
    getAnotherPodcastDetail,
    getPodcastDetail,
    getAnotherStory,
    getShortPodcastCategories,
    getStoryDetail,
    getRandomStory,
    getRandomPodcast,
} from './../../services/portalServices/portal.endpoints';
import {
    getPodcastDetailAction,
    getAnotherStoryAction,
    getShortStoryCategoryAction,
    getStoryDetailAction,
    getRandomStoryAction,
    getRandomPodcastAction,
} from '../actions/portalActions/portal.actions';
import { getShortStoryCategories } from 'services/portalServices/portal.endpoints';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    IgetAnodtherPodcastDetails,
    IgetAnotherStoryDetailRequest,
    IgetRandomPodcastRequest,
    IgetRandomStoryRequest,
    IgetShortStoryCategoriesRequest,
    IgetStoryDetailRequest,
    shortStoryActionTypes,
} from 'store/actions/portalActions/portal.types';
import {
    IPodcastDetailResponse,
    IShortStoryAndPodcastResponse,
    IStoryDetailsResponse,
} from 'services/portalServices/portal.types';
import history from 'routes/history';
import { compile } from 'path-to-regexp';
import Paths from 'constants/paths';

///// story //////

export function* getshortStoryCategoriesWorker({
    payload,
}: IgetShortStoryCategoriesRequest) {
    try {
        const getCategory = payload ? getShortPodcastCategories : getShortStoryCategories;
        let data: IShortStoryAndPodcastResponse[] = yield call(getCategory);
        yield put(getShortStoryCategoryAction.success(data));
    } catch (e) {
        yield put(getShortStoryCategoryAction.failure(e as string));
    }
}
export function* getStoryDetailWorker({ payload }: IgetStoryDetailRequest) {
    try {
        const data: IStoryDetailsResponse = yield call(getStoryDetail, payload);
        yield put(getStoryDetailAction.success(data));
    } catch (e) {
        yield put(getStoryDetailAction.failure(e as string));
    }
}
export function* getAnotherStoryDetailWorker(action: IgetAnotherStoryDetailRequest) {
    const { ommitStoryId, categodyId } = action.payload;
    try {
        const data: IStoryDetailsResponse = yield call(
            getAnotherStory,
            categodyId,
            ommitStoryId,
        );
        yield put(getAnotherStoryAction.success(data));
        yield history.push(
            compile(Paths.STORY)({
                categoryId: categodyId,
                storyId: data.id,
            }),
        );
    } catch (e) {
        yield put(getStoryDetailAction.failure(e as string));
    }
}
export function* getRandomStoryWorker({ payload }: IgetRandomStoryRequest) {
    try {
        const data: IRandomStoryResponse = yield call(getRandomStory, payload);
        yield put(getRandomStoryAction.success());
        yield history.push(
            compile(Paths.STORY)({ categoryId: payload, storyId: data.id }),
        );
    } catch (e) {
        yield put(getRandomStoryAction.failure(e as string));
    }
}
//// audio //////
export function* getPodcastDetailworker({ payload }: IgetStoryDetailRequest) {
    try {
        const data: IPodcastDetailResponse = yield call(getPodcastDetail, payload);
        yield put(getPodcastDetailAction.success(data));
    } catch (e) {
        yield put(getStoryDetailAction.failure(e as string));
    }
}
export function* getAnotherPodcastDetailworker({ payload }: IgetAnodtherPodcastDetails) {
    try {
        const data: IPodcastDetailResponse = yield call(
            getAnotherPodcastDetail,
            payload.id,
            payload.audioToOmitId,
        );
        yield put(getPodcastDetailAction.success(data));
        yield history.push(
            compile(Paths.PODCAST)({
                categoryId: payload.id,
                podcastId: data.id,
            }),
        );
    } catch (e) {
        yield put(getPodcastDetailAction.failure(e as string));
    }
}
export function* getRandomPodcastWorker({ payload }: IgetRandomPodcastRequest) {
    try {
        const data: IRandomStoryResponse = yield call(getRandomPodcast, payload);
        yield put(getRandomPodcastAction.success());
        yield (window.location.pathname = compile(Paths.PODCAST)({
            categoryId: payload,
            podcastId: data.id,
        }));
    } catch (e) {
        yield put(getRandomPodcastAction.failure(e as string));
    }
}
export function* portalSaga() {
    yield all([
        takeLatest(
            shortStoryActionTypes.GET_SHORT_STORY_CATEGORIES_REQUEST,
            getshortStoryCategoriesWorker,
        ),
        takeLatest(shortStoryActionTypes.GET_STORY_DETAIL_REQUEST, getStoryDetailWorker),
        takeLatest(
            shortStoryActionTypes.GET_ANOTHER_STORY_DETAILS_REQUEST,
            getAnotherStoryDetailWorker,
        ),
        takeLatest(
            shortStoryActionTypes.GET_PODCAST_DETAIL_REQUEST,
            getPodcastDetailworker,
        ),
        takeLatest(
            shortStoryActionTypes.GET_ANOTHER_PODCAST_DETAILS,
            getAnotherPodcastDetailworker,
        ),
        takeLatest(shortStoryActionTypes.GET_RANDOM_STORY_REQUEST, getRandomStoryWorker),
        takeLatest(
            shortStoryActionTypes.GET_RANDOM_PODCAST_REQUEST,
            getRandomPodcastWorker,
        ),
    ]);
}


import {
    IInitialPodcastsValue,
    INewPodcast,
} from 'adminComponents/NewPodcast/NewPodcastForm/constants';
import { PodcastDetailsTypes } from './podcastDetails.types';

export const fetchPodcastDetailsRequestAction = (id: string) => ({
    type: PodcastDetailsTypes.FETCH_PODCAST_DETAIL_REQUEST,
    payload: id,
});

export const fetchPodcastDetailsFailureAction = (message: string) => ({
    type: PodcastDetailsTypes.FETCH_PODCAST_DETAIL_FAILURE,
    payload: message,
});

export const fetchPodcastDetailsSuccessAction = (
    podcastDetail: IInitialPodcastsValue,
) => ({
    type: PodcastDetailsTypes.FETCH_PODCAST_DETAIL_SUCCESS,
    payload: podcastDetail,
});

export const editPodcastRequestAction = (
    newPodcast: INewPodcast | FormData,
    id: string,
) => ({
    type: PodcastDetailsTypes.EDIT_PODCAST_REQUEST,
    payload: {
        newPodcast,
        id,
    },
});

export const editPodcastSuccessAction = (message: string) => ({
    type: PodcastDetailsTypes.EDIT_PODCAST_SUCCESS,
    payload: message,
});

export const editPodcastFailureAction = (message: string) => ({
    type: PodcastDetailsTypes.EDIT_PODCAST_FAILURE,
    payload: message,
});

export const cleanPodcastMessageAction = () => ({
    type: PodcastDetailsTypes.CLEAR_PODCAST_MESSAGE,
});

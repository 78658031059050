import {
    IInitialLocationValue,
    INewLocation,
} from 'adminPages/NewMapDataPage/NewLocationForm/constans';
import { AxiosResponse } from 'axios';
import { axiosInstance } from 'services/interceptor';

export const getMapDataDetailsEndpoint = (
    id: string,
): Promise<AxiosResponse<IInitialLocationValue>> => {
    return axiosInstance.get(`admin/map-data/fetch/${id}`);
};

export const editMapDataEndpoint = (
    id: string,
    newLocation: INewLocation | string,
): Promise<AxiosResponse> => {
    return axiosInstance.put(`admin/map-data/update/${id}`, newLocation, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

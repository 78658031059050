import React from 'react';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import NewStory from 'adminComponents/NewStory/NewStory';

const NewStoryPage = () => {
    return (
        <AdminLayout title="New Story">
            <NewStory />
        </AdminLayout>
    );
};

export default NewStoryPage;

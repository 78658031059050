import React, { FC, ReactNode } from 'react';
import styles from './ButtonComponent.module.scss';
import cn from 'classnames';

interface IButtonComponentProps {
    children: ReactNode;
    onClick?: () => void;
    variant?: ButtonVariants;
    wide?: boolean;
    type?: 'button' | 'submit';
}

export enum ButtonVariants {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    THIRD = 'third',
}

const ButtonComponent: FC<IButtonComponentProps> = ({
    children,
    onClick,
    variant = ButtonVariants.PRIMARY,
    wide,
    type = 'button',
}) => {
    return (
        <button
            className={cn(styles.btn, variant, {
                [styles.wide]: wide,
            })}
            onClick={onClick}
            type={type}
        >
            {children}
        </button>
    );
};

export default ButtonComponent;

import { axiosInstance } from 'configs/axiosConfig';
import { TApiResponse } from 'types/api';
import { TGetMapDataResponse } from './types';


const getMapData = async (): Promise<TApiResponse<TGetMapDataResponse>> => {
    return axiosInstance.get('/map-data/fetch');
};

export const portalMapApi = {
    getMapData,
};

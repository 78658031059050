import { useAppSelector } from 'hooks';
import React from 'react';
import Alert from 'sharedComponents/Alert';
import AddAdminForm from './AddAdminForm';

const AddAdmin = () => {
    const error = useAppSelector(state => state.admins.error);
    const message = useAppSelector(state => state.admins.message);
    return (
        <>
            {error && <Alert message={error} isError={true} />}
            {message && <Alert message={message} isError={false} />}
            <AddAdminForm />
        </>
    );
};

export default AddAdmin;

import React from 'react';
import { ITableHeadItem } from '../constants';
import Switch from 'react-switch';
import { IAdminListItem } from 'store/actions/adminList/adminList.types';
import { useDispatch } from 'react-redux';
import {
    activateAdminRequestAction,
    deactivateAdminRequestAction,
} from 'store/actions/adminList/adminsList.action';
interface IAdminListTable {
    tableHead: ITableHeadItem[];
    tableBody: IAdminListItem[];
    page: number;
}

const AdminListTable: React.FC<IAdminListTable> = ({ tableHead, tableBody, page }) => {
    const dispatch = useDispatch();
    return (
        <table className="table">
            <thead>
                <tr>
                    {tableHead.map(item => (
                        <th key={item.key}>{item.title}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {tableBody.map((admin, i) => (
                    <tr key={admin.id}>
                        <td>{i + 1}</td>
                        <td>{admin.name}</td>
                        <td>{admin.email}</td>
                        <td>
                            <Switch
                                checked={admin.isDeleted}
                                onChange={() =>
                                    !admin.isDeleted
                                        ? dispatch(
                                              deactivateAdminRequestAction(
                                                  admin.id,
                                                  page,
                                              ),
                                          )
                                        : dispatch(
                                              activateAdminRequestAction(admin.id, page),
                                          )
                                }
                                uncheckedIcon={false}
                                checkedIcon={false}
                                width={37}
                                height={17}
                                activeBoxShadow=""
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default AdminListTable;

import { createSelector } from '@reduxjs/toolkit';
import { TAppState } from 'store/store';

export const selectStory = (state: TAppState) => state.portalStory;

export const selectStoryStatistics = createSelector(
    [selectStory],
    (story) => story.statistics
);

export const selectStoryCategories = createSelector(
    [selectStory],
    (story) => story.categories
);

import { INewKiosk } from 'adminComponents/NewKioskDevice/NewKioskDeviceForm/constants';
import { IFilterParams } from 'models/filters';
import { IPaginationResponse } from 'services/types';
import { IAdminKiosks } from '../../../services/admin.services/adminKiosks/adminKiosks.types';

export enum AdminKiosksTypes {
    FETCH_ADMIN_KIOSKS_REQUEST = 'FETCH_ADMIN_KIOSKS_REQUEST',
    FETCH_ADMIN_KIOSKS_SUCCESS = 'FETCH_ADMIN_KIOSKS_SUCCESS',
    FETCH_ADMIN_KIOSKS_FAILURE = 'FETCH_ADMIN_KIOSKS_FAILURE',

    DEACTIVATE_ADMIN_KIOSKS_REQUEST = 'DEACTIVATE_ADMIN_KIOSKS_REQUEST',
    DEACTIVATE_ADMIN_KIOSKS_SUCCESS = 'DEACTIVATE_ADMIN_KIOSKS_SUCCESS',
    DEACTIVATE_ADMIN_KIOSKS_FAILURE = 'DEACTIVATE_ADMIN_KIOSKS_FAILURE',

    DELETE_ADMIN_KIOSKS_REQUEST = 'DELETE_ADMIN_KIOSKS_REQUEST',
    DELETE_ADMIN_KIOSKS_SUCCESS = 'DELETE_ADMIN_KIOSKS_SUCCESS',
    DELETE_ADMIN_KIOSKS_FAILURE = 'DELETE_ADMIN_KIOSKS_FAILURE',

    KIOSKS_CATEGORIES_REQUEST = 'KIOSKS_CATEGORIES_REQUEST',
    KIOSKS_CATEGORIES_SUCCESS = 'KIOSKS_CATEGORIES_SUCCESS',
    KIOSKS_CATEGORIES_FAILURE = 'KIOSKS_CATEGORIES_FAILURE',

    POST_NEW_KIOSK_REQUEST = 'POST_NEW_KIOSK_REQUEST',
    POST_NEW_KIOSK_SUCCESS = 'POST_NEW_KIOSK_SUCCESS',
    POST_NEW_KIOSK_FAILURE = 'POST_NEW_KIOSK_FAILURE',

    ACTIVATE_ADMIN_KIOSKS_REQUEST = 'ACTIVATE_ADMIN_KIOSKS_REQUEST',
    ACTIVATE_ADMIN_KIOSKS_SUCCESS = 'ACTIVATE_ADMIN_KIOSKS_SUCCESS',
    ACTIVATE_ADMIN_KIOSKS_FAILURE = 'ACTIVATE_ADMIN_KIOSKS_FAILURE',

    CLEAN_KIOSK_MESSAGE = 'CLEAN_KIOSK_MESSAGE',

    KIOSKS_EXPORT_REQUEST = 'KIOSKS_EXPORT_REQUEST',
    KIOSKS_EXPORT_SUCCESS = 'KIOSKS_EXPORT_SUCCESS',
    KIOSKS_EXPORT_FAILURE = 'KIOSKS_EXPORT_FAILURE',

    CLEAR_EXPORT_REQUST = 'CLEAR_EXPORT_REQUST',
}

export interface IKioskCategories {
    id: string;
    image: string;
    titleEn: string;
    descriptionEn: string;
    titleAr?: string;
    descriptionAr?: string;
    sort?: number;
}

export interface IInitialState {
    csv: string;
    adminKiosks: IAdminKiosks[];
    loading: boolean;
    error: string;
    page: number;
    perPage: number;
    totalNumber: number;
    podcastsCategories: IKioskCategories[];
    postNewKioskError: string;
    message: string;
}

export interface FetchAdminKiosksRequestActionType {
    type: AdminKiosksTypes.FETCH_ADMIN_KIOSKS_REQUEST;
    payload: { page: number; categoryId: string; searchWord: string; storyType:number;};
}

export interface FetchAdminKiosksSuccessActionType {
    type: AdminKiosksTypes.FETCH_ADMIN_KIOSKS_SUCCESS;
    payload: IPaginationResponse<IAdminKiosks>;
}

export interface FetchAdminKiosksFailureActionType {
    type: AdminKiosksTypes.FETCH_ADMIN_KIOSKS_FAILURE;
    payload: string;
}

export interface DeactivateAdminKiosksRequestActionType {
    type: AdminKiosksTypes.DEACTIVATE_ADMIN_KIOSKS_REQUEST;
    payload: {
        podcastId: string;
        page: number;
    };
}
export interface DeactivateAdminKiosksSuccessActionType {
    type: AdminKiosksTypes.DEACTIVATE_ADMIN_KIOSKS_SUCCESS;
}

export interface DeactivateAdminKiosksFailureActionType {
    type: AdminKiosksTypes.DEACTIVATE_ADMIN_KIOSKS_FAILURE;
    payload: string;
}
export interface activateAdminKiosksRequestActionType {
    type: AdminKiosksTypes.ACTIVATE_ADMIN_KIOSKS_REQUEST;
    payload: {
        kioskId: string;
        page: number;
        value: boolean;
    };
}

export interface activateAdminKiosksSuccessActionType {
    type: AdminKiosksTypes.ACTIVATE_ADMIN_KIOSKS_SUCCESS;
}

export interface activateAdminKiosksFailureActionType {
    type: AdminKiosksTypes.ACTIVATE_ADMIN_KIOSKS_FAILURE;
    payload: string;
}

export interface DeleteAdminKiosksRequestActionType {
    type: AdminKiosksTypes.DELETE_ADMIN_KIOSKS_REQUEST;
    payload: {
        kioskId: string;
        params: IFilterParams;
    };
}

export interface DeleteAdminKiosksSuccessActionType {
    type: AdminKiosksTypes.DELETE_ADMIN_KIOSKS_SUCCESS;
}

export interface DeleteAdminKiosksFailureActionType {
    type: AdminKiosksTypes.DELETE_ADMIN_KIOSKS_FAILURE;
    payload: string;
}
export interface getKiosksCategoriesRequestActionType {
    type: AdminKiosksTypes.KIOSKS_CATEGORIES_REQUEST;
}

export interface getKiosksCategoriesSuccessActionType {
    type: AdminKiosksTypes.KIOSKS_CATEGORIES_SUCCESS;
    payload: IKioskCategories[];
}

export interface getKiosksCategoriesFailureActionType {
    type: AdminKiosksTypes.KIOSKS_CATEGORIES_FAILURE;
    payload: string;
}

export interface postNewKioskRequestActionType {
    type: AdminKiosksTypes.POST_NEW_KIOSK_REQUEST;
    payload: INewKiosk;
}

export interface postNewKioskSuccessActionType {
    type: AdminKiosksTypes.POST_NEW_KIOSK_SUCCESS;
    payload: string;
}

export interface postNewKioskFailureActionType {
    type: AdminKiosksTypes.POST_NEW_KIOSK_FAILURE;
    payload: string;
}
export interface cleanNewKioskMessageActionType {
    type: AdminKiosksTypes.CLEAN_KIOSK_MESSAGE;
}

export interface getKiosksExportFailureActionType {
    type: AdminKiosksTypes.KIOSKS_EXPORT_FAILURE;
    payload: string;
}

export interface getKiosksExportRequestActionType {
    type: AdminKiosksTypes.KIOSKS_EXPORT_REQUEST;
    payload: boolean;
}

export interface getKiosksExportSuccessActionType {
    type: AdminKiosksTypes.KIOSKS_EXPORT_SUCCESS;
    payload: string;
}
export interface clearAdminKioskExportRequestActionType {
    type: AdminKiosksTypes.CLEAR_EXPORT_REQUST;
}

export type AdminKiosksActionTypes =
    | FetchAdminKiosksRequestActionType
    | FetchAdminKiosksSuccessActionType
    | FetchAdminKiosksFailureActionType
    | DeactivateAdminKiosksRequestActionType
    | DeactivateAdminKiosksSuccessActionType
    | DeactivateAdminKiosksFailureActionType
    | activateAdminKiosksRequestActionType
    | activateAdminKiosksSuccessActionType
    | activateAdminKiosksFailureActionType
    | getKiosksCategoriesRequestActionType
    | getKiosksCategoriesSuccessActionType
    | getKiosksCategoriesFailureActionType
    | postNewKioskRequestActionType
    | postNewKioskSuccessActionType
    | postNewKioskFailureActionType
    | cleanNewKioskMessageActionType
    | DeleteAdminKiosksRequestActionType
    | DeleteAdminKiosksSuccessActionType
    | DeleteAdminKiosksFailureActionType
    | getKiosksExportSuccessActionType
    | getKiosksExportRequestActionType
    | getKiosksExportFailureActionType
    | clearAdminKioskExportRequestActionType;

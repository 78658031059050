import React from 'react';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import EmailStorage from 'adminComponents/EmailStorage/EmailStorage';

const AdminEmailStoragePage = () => {
    return (
        <AdminLayout title={'Email Storage'}>
            <EmailStorage />
        </AdminLayout>
    );
};

export default AdminEmailStoragePage;

export interface IChangePasswordFields {
    name: string;
    label: string;
}
export const fields: IChangePasswordFields[] = [
    {
        name: 'oldPassword',
        label: 'Old Password',
    },
    {
        name: 'newPassword',
        label: 'New Password',
    },
    {
        name: 'confirmPassword',
        label: 'Confirm Password',
    },
];

import React,{ FC,useState } from 'react';
import Switch from 'react-switch';
import { useDispatch } from 'react-redux';
import { DeleteModal } from 'sharedComponents/DeleteModal';
import { ITableHeadItem } from '../constants';
import { IEmailStorageListItem } from 'store/actions/adminEmailsStorage/adminEmailsStorage.types';
import { deleteAdminEmailRequestAction ,activateAdminEmailStorageRequestAction} from 'store/actions/adminEmailsStorage/adminEmailsStorage.action';
import { IFilterParams } from 'models/filters';

interface IEmailStorageTableProps {
    tableHead: ITableHeadItem[];
    tableBody: IEmailStorageListItem[];
    params: IFilterParams;
    page: number;
}

const EmailStorageDataTable: FC<IEmailStorageTableProps> = ({ tableHead, tableBody, page, params }) => {
    const dispatch = useDispatch();
    const [modalShow, changeModalShow] = useState(false);
    const [emailData, setEmailData] = useState({ id: '', page: 0 });

    const renderTableIcon = (
        iconSrc: string,
        identificator: string,
        cb: (id: string, page?: number) => void,
        page?: number,
    ): React.ReactNode => (
        <i
            className={`App-table-icon ${iconSrc}`}
            onClick={() => cb(identificator, page)}
        />
    );
    const deleteEmail = (id: string, page: number = 0 ) => {
        changeModalShow(true);
        setEmailData({ id, page });
    };
    return(
        <>
        <table className="table">
            <thead>
            <tr>
                {tableHead.map(item => (
                    <th key={item.key}>{item.title}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {tableBody.map((emails, i) => (
                <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{emails.name}</td>
                    <td>{emails.email}</td>
                    <td>{emails.phone}</td>
                    <td>
                        <Switch
                            checked={emails.isDisabled}
                            onChange={() => {
                                dispatch(
                                    activateAdminEmailStorageRequestAction(
                                        emails.id,
                                        page,
                                        emails.isDisabled === false ? true : false
                                    ),
                                )
                            }}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            width={37}
                            height={17}
                            activeBoxShadow=""
                        />
                    </td>
                    <td>
                        {renderTableIcon(
                            'bi bi-trash',
                            emails.id,
                            deleteEmail,
                            page,
                        )}
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
        {modalShow &&
            <DeleteModal
                open={modalShow}
                onClose={() => {
                    changeModalShow(false);
                }}
                onSuccess={() => {
                    dispatch(
                        deleteAdminEmailRequestAction(
                            emailData.id,
                            params
                        ),
                    );
                }}
            />
        }
        </>
    );
}

export default EmailStorageDataTable;

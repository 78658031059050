import { createAsyncThunk } from '@reduxjs/toolkit';
import { portalMapApi } from './api';
import { TGetMapDataResponse } from './types';

export const getMapData = createAsyncThunk<TGetMapDataResponse, void, { rejectValue: string }>(
    'portalMap/getMapData',
    async (_, { rejectWithValue }) => {
        const response = await portalMapApi.getMapData();

        if (response.success) {
            return response.data;
        }

        return rejectWithValue(response.error);
    }
);

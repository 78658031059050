import {useEffect} from 'react'
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import Alert from 'sharedComponents/Alert';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import AddNotificationForm from './AddNotificationForm/AddNotificationForm';
import { initialNotificationValues } from './AddNotificationForm/constants';
import { useAppSelector } from 'hooks';
import { addNewNotificationRequestAction,cleanNewNotificationMessageAction } from 'store/actions/adminEmailsStorage/adminEmailsStorage.action';
import { IInitialNotificationValue } from './AddNotificationForm/constants';

const NewNotificationPage = () => {
    const dispatch = useDispatch();
    const onSubmit = (values: IInitialNotificationValue) => {
        dispatch(addNewNotificationRequestAction(values));
    };

    useEffect(() => {
        return () => {
            dispatch(cleanNewNotificationMessageAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loading = useAppSelector(state => state.adminEmailsStorage.loading);
    const error = useAppSelector(state => state.adminEmailsStorage.error);
    const message = useAppSelector(state => state.adminEmailsStorage.message);
    return (
        <>
            <AdminLayout title="New Notification">
                {loading ? (
                    <div className="container-fluid d-flex justify-content-center align-items-center h-100">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <>
                        {error && <Alert message={error} isError={true} />}
                        {message && <Alert message={message} isError={false} />}
                        <AddNotificationForm
                            onSubmit={onSubmit}
                            buttonText={'Submit'}
                            initialValues={initialNotificationValues}
                        />
                    </>
                )}
            </AdminLayout>
        </>
    );
};

export default NewNotificationPage;

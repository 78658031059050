import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Input from 'portalComponents/Input';
import { REQUIRED_ERROR } from 'constants/errors';
import Button from 'portalComponents/Button';
import { emailSubscriptionValidationSchema } from 'utils/validationSchemas';
import styles from './EmailSubscriptionForm.module.scss';
import { useDispatch } from 'react-redux';
import { emailStorageSubscribe, resetEmailStorageSubscribe } from 'store/portal/emailStorage/actions';
import { useAppSelector } from 'hooks';
import { selectEmailStorageSubscribe } from 'store/portal/emailStorage/selectors';
import classNames from 'classnames';

type TEmailSubscriptionForm = {
    name: string;
    email: string;
    phone: string;
}

const EmailSubscriptionForm: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loading, data, error } = useAppSelector(selectEmailStorageSubscribe);

    const formik = useFormik<TEmailSubscriptionForm>({
        initialValues: {
            name: '',
            email: '',
            phone: '',
        },
        validationSchema: emailSubscriptionValidationSchema(t),
        onSubmit: (data) => {
            dispatch(emailStorageSubscribe({
                data: {
                    name: data.name,
                    email: data.email,
                    phone: `+966${data.phone}`,
                },
                onSuccess: () => {
                    formik.resetForm();
                }
            }));
        },
    });

    useEffect(() => {
        return () => {
            dispatch(resetEmailStorageSubscribe());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.wrapper}>
            {error && (
                <p className={classNames(styles.message, styles.error)}>
                    {t('emailSubscription.subscribeError')}
                </p>
            )}
            {data && (
                <p className={classNames(styles.message, styles.success)}>
                    {t('emailSubscription.subscribeSuccess')}
                </p>
            )}
            <form onSubmit={formik.handleSubmit} className={styles.form} noValidate>
                <Input
                    label={t('emailSubscription.form.name')}
                    name="name"
                    value={formik.values.name}
                    touched={formik.touched.name}
                    hasError={!!formik.errors.name}
                    error={formik.errors.name === REQUIRED_ERROR ? '' : formik.errors.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <Input
                    type="email"
                    label={t('emailSubscription.form.email')}
                    name="email"
                    value={formik.values.email}
                    touched={formik.touched.email}
                    hasError={!!formik.errors.email}
                    error={formik.errors.email === REQUIRED_ERROR ? '' : formik.errors.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderVariant="secondary"
                />
                <Input
                    type="tel"
                    label={t('emailSubscription.form.phone')}
                    name="phone"
                    value={formik.values.phone}
                    touched={formik.touched.phone}
                    hasError={!!formik.errors.phone}
                    error={formik.errors.phone === REQUIRED_ERROR ? '' : formik.errors.phone}
                    onChange={(event) => {
                        if (event.target.value.length <= 9) {
                            formik.handleChange(event);
                        }
                    }}
                    onBlur={formik.handleBlur}
                    startContent="+966"
                />
                <Button width="fixedLarge" type="submit" disabled={loading}>
                    {t('emailSubscription.form.submitButton')}
                </Button>
            </form>
        </div>
    );
};

export default EmailSubscriptionForm;

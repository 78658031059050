export interface ITableHeadItem {
    title: string;
    key: string;
}
export interface ITableHeadItem {
    title: string;
    key: string;
}

export const storiesTableHead: ITableHeadItem[] = [
    {
        title: '#',
        key: '#',
    },
    {
        title: 'Story Image | Ar',
        key: 'image_ar',
    },
    {
        title: 'Story Title | Ar',
        key: 'title_ar',
    },
    {
        title: 'Publisher Name | Ar',
        key: 'publisherNameAr',
    },
    {
        title: 'Author Name | Ar',
        key: 'authorAr',
    },

    {
        title: 'Category',
        key: 'category',
    },
    {
        title: 'Created Date',
        key: 'created_date',
    },
    {
        title: 'Details',
        key: 'details',
    },
    {
        title: 'Inactive',
        key: 'inactive',
    },
    {
        title: 'Delete',
        key: 'delete_story',
    },
];

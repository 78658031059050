import {
    IPodcastDetailResponse,
    IShortStoryAndPodcastResponse,
    IStoryDetailsResponse,
} from 'services/portalServices/portal.types';

export enum shortStoryActionTypes {
    ////// story /////
    GET_SHORT_STORY_CATEGORIES_REQUEST = 'GET_SHORT_STORY_CATEGORIES_REQUEST',
    GET_SHORT_STORY_CATEGORIES_SUCCESS = 'GET_SHORT_STORY_CATEGORIES_REQUEST_SUCCESS',
    GET_SHORT_STORY_CATEGORIES_FAILURE = 'GET_SHORT_STORY_CATEGORIES_REQUEST_FAILURE',

    GET_STORY_DETAIL_REQUEST = 'GET_STORY_DETAIL_REQUEST',
    GET_STORY_DETAIL_SUCCESS = 'GET_STORY_DETAIL_SUCCESS',
    GET_STORY_DETAIL_FAILURE = 'GET_STORY_DETAIL_FAILURE',

    GET_ANOTHER_STORY_DETAILS_REQUEST = 'GET_ANOTHER_STORY_DETAILS_REQUEST',
    GET_ANOTHER_STORY_DETAILS_SUCCESS = 'GET_ANOTHER_STORY_DETAILS_SUCCESS',
    GET_ANOTHER_STORY_DETAILS_FAILURE = 'GET_ANOTHER_STORY_DETAILS_FAILURE',

    GET_RANDOM_STORY_REQUEST = 'GET_RANDOM_STORY_REQUEST',
    GET_RANDOM_STORY_SUCCESS = 'GET_RANDOM_STORY_SUCCESS',
    GET_RANDOM_STORY_FAILURE = 'GET_RANDOM_STORY_FAILURE',

    ///// audio //////

    GET_ANOTHER_PODCAST_DETAILS = 'GET_ANOTHER_PODCAST_DETAILS',

    GET_PODCAST_DETAIL_REQUEST = 'GET_PODCAST_DETAIL_REQUEST',
    GET_PODCAST_DETAIL_SUCCESS = 'GET_PODCAST_DETAIL_SUCCESS',
    GET_PODCAST_DETAIL_FAILURE = 'GET_PODCAST_DETAIL_FAILURE',

    GET_RANDOM_PODCAST_REQUEST = 'GET_RANDOM_PODCAST_REQUEST',
    GET_RANDOM_PODCAST_SUCCESS = 'GET_RANDOM_PODCAST_SUCCESS',
    GET_RANDOM_PODCAST_FAILURE = 'GET_RANDOM_PODCAST_FAILURE',
}

export type IshortStoryInitialState = {
    error: string;
    loaded: boolean;
    shortStoryAndPodcastCategories: IShortStoryAndPodcastResponse[];
    storyDetail: IStoryDetailsResponse;
    podcastDetail: IPodcastDetailResponse;
    currentCategory: string;
};

//// story /////
export interface IgetShortStoryCategoriesRequest {
    type: shortStoryActionTypes.GET_SHORT_STORY_CATEGORIES_REQUEST;
    payload: boolean;
}

export interface IgetShortStoryCategoriesSuccess {
    type: shortStoryActionTypes.GET_SHORT_STORY_CATEGORIES_SUCCESS;
    payload: IShortStoryAndPodcastResponse[];
}
export interface IgetShortStoryCategoriesFailure {
    type: shortStoryActionTypes.GET_SHORT_STORY_CATEGORIES_FAILURE;
    payload: string;
}

export interface IgetStoryDetailRequest {
    type: shortStoryActionTypes.GET_STORY_DETAIL_REQUEST;
    payload: string;
}

export interface IgetStoryDetailSuccess {
    type: shortStoryActionTypes.GET_STORY_DETAIL_SUCCESS;
    payload: IStoryDetailsResponse;
}
export interface IgetStoryDetailFailure {
    type: shortStoryActionTypes.GET_STORY_DETAIL_FAILURE;
    payload: string;
}

export interface IgetAnotherStoryDetailRequest {
    type: shortStoryActionTypes.GET_ANOTHER_STORY_DETAILS_REQUEST;
    payload: { ommitStoryId: string; categodyId: string };
}
export interface IgetAnotherStoryDetailSuccess {
    type: shortStoryActionTypes.GET_ANOTHER_STORY_DETAILS_SUCCESS;
    payload: IStoryDetailsResponse;
}
export interface IgetAnotherStoryDetailFailure {
    type: shortStoryActionTypes.GET_ANOTHER_STORY_DETAILS_FAILURE;
    payload: string;
}

export interface IgetRandomStoryRequest {
    type: shortStoryActionTypes.GET_RANDOM_STORY_REQUEST;
    payload: string;
}

export interface IgetRandomStorySuccess {
    type: shortStoryActionTypes.GET_RANDOM_STORY_SUCCESS;
}

export interface IgetRandomStoryFailure {
    type: shortStoryActionTypes.GET_RANDOM_STORY_FAILURE;
    payload: string;
}

//// audio ////

export interface IgetPodcastDetailRequest {
    type: shortStoryActionTypes.GET_PODCAST_DETAIL_REQUEST;
    payload: string;
}
export interface IgetPodcastDetailSuccess {
    type: shortStoryActionTypes.GET_PODCAST_DETAIL_SUCCESS;
    payload: IPodcastDetailResponse;
}
export interface IgetPodcastDetailFailure {
    type: shortStoryActionTypes.GET_PODCAST_DETAIL_FAILURE;
    payload: string;
}

export interface IgetPodcastDetailRequest {
    type: shortStoryActionTypes.GET_PODCAST_DETAIL_REQUEST;
    payload: string;
}
export interface IgetPodcastDetailSuccess {
    type: shortStoryActionTypes.GET_PODCAST_DETAIL_SUCCESS;
    payload: IPodcastDetailResponse;
}
export interface IgetPodcastDetailFailure {
    type: shortStoryActionTypes.GET_PODCAST_DETAIL_FAILURE;
    payload: string;
}

export interface IgetAnodtherPodcastDetails {
    type: shortStoryActionTypes.GET_ANOTHER_PODCAST_DETAILS;
    payload: Record<'id' | 'audioToOmitId', string>;
}

export interface IgetRandomPodcastRequest {
    type: shortStoryActionTypes.GET_RANDOM_PODCAST_REQUEST;
    payload: string;
}

export interface IgetRandomPodcastSuccess {
    type: shortStoryActionTypes.GET_RANDOM_PODCAST_SUCCESS;
}

export interface IgetRandomPodcastFailure {
    type: shortStoryActionTypes.GET_RANDOM_PODCAST_FAILURE;
    payload: string;
}

export type shortStoryTypes =
    | IgetShortStoryCategoriesRequest
    | IgetShortStoryCategoriesSuccess
    | IgetShortStoryCategoriesFailure
    | IgetStoryDetailRequest
    | IgetStoryDetailSuccess
    | IgetStoryDetailFailure
    | IgetPodcastDetailRequest
    | IgetPodcastDetailSuccess
    | IgetPodcastDetailFailure
    | IgetAnodtherPodcastDetails
    | IgetAnotherStoryDetailRequest
    | IgetAnotherStoryDetailSuccess
    | IgetAnotherStoryDetailFailure
    | IgetRandomStoryRequest
    | IgetRandomStorySuccess
    | IgetRandomStoryFailure
    | IgetRandomPodcastRequest
    | IgetRandomPodcastSuccess
    | IgetRandomPodcastFailure;

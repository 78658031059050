import {
    ChangePasswordBody,
    LoginUserBody,
} from 'services/admin.services/auth/auth.types';
import {
    AuthTypes,
    ChangePasswordFailureAction,
    ChangePasswordRequestAction,
    ChangePasswordSuccessAction,
} from './auth.types';

export const loginUserRequest = (user: LoginUserBody) => ({
    type: AuthTypes.LOGIN_REQUEST,
    payload: user,
});
export const loginUserSuccess = (token: string) => ({
    type: AuthTypes.LOGIN_SUCCESS,
    payload: token,
});
export const loginUserFailure = (error: string) => ({
    type: AuthTypes.LOGIN_FAILURE,
    payload: error,
});

export const logoutUserRequest = () => ({
    type: AuthTypes.LOGOUT_REQUEST,
});

export const logoutUserSuccess = () => ({
    type: AuthTypes.LOGOUT_SUCCESS,
});

export const logoutUserFailure = (error: string) => ({
    type: AuthTypes.LOGOUT_FAILURE,
    payload: error,
});

export const ChangePasswordRequest = (
    payload: ChangePasswordBody,
): ChangePasswordRequestAction => ({
    type: AuthTypes.CHANGE_PASSWORD_REQUEST,
    payload,
});

export const ChangePasswordSuccess = (payload: string): ChangePasswordSuccessAction => ({
    type: AuthTypes.CHANGE_PASSWORD_SUCCESS,
    payload,
});

export const ChangePasswordFailure = (payload: string): ChangePasswordFailureAction => ({
    type: AuthTypes.CHANGE_PASSWORD_FAILURE,
    payload,
});

import React from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import {
    IInitialPodcastsValue,
    FieldsVariation,
    IFieldPodcastsItem,
    initialPodcastPageFormsFields,
} from './constants';
import TextEditor from 'sharedComponents/TextEditor';
import Switch from 'react-switch';
import styles from '../NewPodcast.module.scss';
import { newPodcastValidationSchema } from 'utils/validationSchemas';
import { IStoryPodcastCategories } from 'store/actions/adminStories/adminStories.types';
import DragDropFile from 'sharedComponents/DropdownFile/DropDownFile';
import Select from 'react-select';
import AudioInput from 'adminComponents/AudioInput/AudioInput';
import { disableSubmit, websiteLinkGenerator } from '../../../utils/helpers';

interface INewPodcastForm {
    categories: IStoryPodcastCategories[];
    initialValues: IInitialPodcastsValue;
    onSubmit: (value: IInitialPodcastsValue) => void;
    buttonText: string;
}

const createInputField = <T extends IInitialPodcastsValue>(
    formik: FormikProps<T>,
    podcast: IFieldPodcastsItem,
    options: IStoryPodcastCategories[] = [],
) => {
    const {
        touched: formikTouch,
        setFieldValue,
        values,
        errors: formikError,
        setFieldTouched,
    } = formik;
    const name = podcast.name;
    const label = podcast.label;
    const placeholder = label;
    const inputType = podcast.inputType;
    switch (podcast.field) {
        case FieldsVariation.IMAGE_INPUT:
            return (
                <div key={`${name}-${label}`} className="mb-1 position-relative pb-1">
                    <div>
                        {podcast.required && (
                            <span className="me-1 admin-required-field">*</span>
                        )}
                        <span>{label}</span>
                    </div>
                    <DragDropFile fieldName={name} />
                    {formikError[name] && formikTouch[name] ? (
                        <div className={styles.slateError}>{formikError[name]}</div>
                    ) : null}
                </div>
            );
        case FieldsVariation.AUDIO_INPUT:
            return (
                <div key={`${name}-${label}`} className="mb-1 position-relative pb-1">
                    <div>
                        {podcast.required && (
                            <span className="me-1 admin-required-field">*</span>
                        )}
                        <span>{label}</span>
                    </div>
                    <AudioInput fieldName={name} />
                    {formikError[name] && formikTouch[name] ? (
                        <div className={styles.slateError}>{formikError[name]}</div>
                    ) : null}
                </div>
            );
        case FieldsVariation.INPUT:
            return (
                <div key={label} className="mb-1 position-relative pb-1">
                    <label className="form-label d-flex align-items-start flex-column">
                        <div>
                            {podcast.required && (
                                <span className="me-1 admin-required-field">*</span>
                            )}
                            <span>{label}</span>
                        </div>
                        <Field
                            onKeyDown={disableSubmit}
                            className="form-control mt-1"
                            type={inputType}
                            name={name}
                            placeholder={label}
                        />
                    </label>
                    {formikError[name] && formikTouch[name] ? (
                        <div className={styles.error}>{formikError[name]}</div>
                    ) : null}
                </div>
            );
        case FieldsVariation.SWITCH:
            return (
                <label key={label} className="form-label d-block pl-2">
                    <div>
                        {podcast.required && (
                            <span className="me-1 admin-required-field">*</span>
                        )}
                        <span>{label}</span>
                    </div>
                    <div className="mb-2 position-relative pb-1 mt-1 ">
                        <Switch
                            onKeyDown={disableSubmit}
                            checked={values && values.darkLogo}
                            onChange={switchValue => {
                                setFieldValue(name, switchValue);
                            }}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            width={37}
                            height={17}
                            activeBoxShadow=""
                        />
                        {formikError[name] && formikTouch[name] ? (
                            <div className={styles.slateError}>{formikError[name]}</div>
                        ) : null}
                    </div>
                </label>
            );
        case FieldsVariation.DROPDOWN:
            const selectOptions = options.map(option => ({
                value: option.id,
                label: option.titleEn,
            }));

            return (
                <div key={label} className="mb-1 position-relative pb-1 h-auto">
                    <label className="form-label d-block align-items-start flex-column">
                        <div>
                            {podcast.required && (
                                <span className="me-1 admin-required-field">*</span>
                            )}
                            <span>{label}</span>
                        </div>
                        <Select
                            onKeyDown={disableSubmit}
                            id="categories"
                            options={selectOptions}
                            defaultValue={{
                                value: '',
                                label: 'Select category ...',
                            }}
                            isSearchable={false}
                            onBlur={() => {
                                setFieldTouched(name, true);
                            }}
                            maxMenuHeight={100}
                            onChange={value => {
                                setFieldValue(name, value?.value);
                            }}
                            value={selectOptions.find(
                                option => option.value === values?.categoryId,
                            )}
                        />
                    </label>
                    {formikError[name] && formikTouch[name] ? (
                        <div className={styles.error}>{formikError[name]}</div>
                    ) : null}
                </div>
            );
        case FieldsVariation.TEXTAREA:
            return (
                <label key={label} className="form-label d-block">
                    <div>
                        {podcast.required && (
                            <span className="me-1 admin-required-field">*</span>
                        )}
                        <span>{label}</span>
                    </div>
                    <div className="mb-1 position-relative pb-1 mt-1 form-control">
                        <TextEditor placeholder={placeholder} fieldName={name} />
                        {formikError[name] && formikTouch[name] ? (
                            <div className={styles.slateError}>{formikError[name]}</div>
                        ) : null}
                    </div>
                </label>
            );

        case FieldsVariation.LINK:
            const linkValue = websiteLinkGenerator(values.categoryId);
            return (
                <label key={label} className="form-label d-block">
                    <div>
                        {podcast.required && (
                            <span className="me-1 admin-required-field">*</span>
                        )}
                        <span>{label}</span>
                    </div>
                    <div className={`mb-1 position-relative pb-1 mt-1 form-control ${styles.linkWrapper}`}>
                        <a href={linkValue} target="_blank" rel="noreferrer">{linkValue}</a>
                        {formikError[name] && formikTouch[name] ? (
                            <div className={styles.slateError}>{formikError[name]}</div>
                        ) : null}
                    </div>
                </label>
            );

        default:
            return <div>DEFAULT</div>;
    }
};

const NewPodcastForm: React.FC<INewPodcastForm> = ({
    categories,
    initialValues,
    onSubmit,
    buttonText,
}) => {
    return (
        <>
            <div className={styles.newAdminFormWrapper}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={newPodcastValidationSchema}
                    onSubmit={onSubmit}
                >
                    {helpers => {
                        return (
                            <Form>
                                <div className={styles.formBlockWrapper}>
                                    {initialPodcastPageFormsFields.en.map(podcast =>
                                        createInputField<IInitialPodcastsValue>(
                                            helpers,
                                            podcast,
                                        ),
                                    )}
                                </div>
                                <div className={styles.formBlockWrapper}>
                                    {initialPodcastPageFormsFields.ar.map(podcast =>
                                        createInputField<IInitialPodcastsValue>(
                                            helpers,
                                            podcast,
                                        ),
                                    )}
                                </div>
                                <div className={styles.formBlockWrapper}>
                                    {initialPodcastPageFormsFields.general.map(podcast =>
                                        createInputField<IInitialPodcastsValue>(
                                            helpers,
                                            podcast,
                                            categories,
                                        ),
                                    )}
                                </div>
                                <div className={styles.formBlockWrapper}>
                                    {initialPodcastPageFormsFields.switches.map(story =>
                                        createInputField<IInitialPodcastsValue>(
                                            helpers,
                                            story,
                                        ),
                                    )}
                                </div>
                                {buttonText === 'Edit' && <div className={styles.formBlockWrapper}>
                                    {initialPodcastPageFormsFields.links.map(story =>
                                        createInputField<IInitialPodcastsValue>(
                                            helpers,
                                            story,
                                        ),
                                    )}
                                </div>}
                                <button
                                    className="btn btn-secondary align-self-end mt-2"
                                    type="submit"
                                >
                                    {buttonText}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </>
    );
};

export default NewPodcastForm;

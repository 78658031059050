import Paths from 'constants/paths';
import { IFilterParams } from 'models/filters';
import { compile } from 'path-to-regexp';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Switch from 'react-switch';
import { DeleteModal } from 'sharedComponents/DeleteModal';
import {
    activateAdminStoryRequestAction,
    deactivateAdminStoryRequestAction,
    deleteAdminStoryRequestAction,
} from 'store/actions/adminStories/adminStories.action';
import { IAdminStoryItem } from 'store/actions/adminStories/adminStories.types';
import { ITableHeadItem } from '../constants';

interface IStoriesTableProps {
    tableHead: ITableHeadItem[];
    tableBody: IAdminStoryItem[];
    params: IFilterParams;
    page: number;
}


const StoriesTable: React.FC<IStoriesTableProps> = ({ params, tableHead, tableBody, page }) => {
    const [modalShow, changeModalShow] = useState(false);
    const [storyData, setStoryData] = useState({ id: '', page: 0 });
    const dispatch = useDispatch();
    const history = useHistory();

    const renderTableIcon = (
        iconSrc: string,
        identificator: string,
        cb: (id: string, page?: number) => void,
        page?: number,
    ): React.ReactNode => (
        <i
            className={`App-table-icon ${iconSrc}`}
            onClick={() => cb(identificator, page)}
        />
    );

    const renderTableImage = (src: string, alt: string): React.ReactNode => (
        <img
            className="App-table-img"
            src={`${process.env.REACT_APP_URL}/${src}`}
            alt={alt}
        />
    );

    const detailsRedicrect = (id: string) => {
        history.push(compile(Paths.ADMIN_STORY_DETAIL)({ id }));
    };


    if(!tableBody.length) return <div className="w-100 d-flex justify-content-center align-items-center">Not found</div>;
    
    const deleteStory = (id: string, page: number = 0) => {
        changeModalShow(true);
        setStoryData({ id, page });
    };

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        {tableHead.map(item => (
                            <th key={item.key}>{item.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableBody.map((story, i) => (
                        <tr key={story.id}>
                            <td>{i + 1}</td>
                            <td>{renderTableImage(story.imageAr, story.imageAr)}</td>
                            <td>{story.titleAr}</td>
                            <td>{story.publisherNameAr}</td>
                            <td>{story.authorAr}</td>
                            <td>{story.category.titleEn}</td>
                            <td>{new Date(story.createdAt).toLocaleDateString()}</td>
                            <td>
                                {renderTableIcon('bi bi-eye', story.id, detailsRedicrect)}
                            </td>
                            <td>
                                <Switch
                                    checked={story.isDeleted}
                                    onChange={() =>
                                        !story.isDeleted
                                            ? dispatch(
                                                  deactivateAdminStoryRequestAction(
                                                      story.id,
                                                      page,
                                                  ),
                                              )
                                            : dispatch(
                                                  activateAdminStoryRequestAction(
                                                      story.id,
                                                      page,
                                                  ),
                                              )
                                    }
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    width={37}
                                    height={17}
                                    activeBoxShadow=""
                                />
                            </td>
                            <td>
                                {renderTableIcon(
                                    'bi bi-trash',
                                    story.id,
                                    deleteStory,
                                    page,
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {modalShow && (
                <DeleteModal
                    open={modalShow}
                    onClose={() => {
                        changeModalShow(false);
                    }}
                    onSuccess={() => {
                        dispatch(                            
                            deleteAdminStoryRequestAction(
                                storyData.id,
                                params
                            ),
                        );
                    }}
                />
            )}
        </>
    );
};

export default StoriesTable;

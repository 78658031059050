import { INewAdmin } from 'services/admin.services/admins/admins.types';
import { IPaginationResponse } from 'services/types';
import { AdminListsTypes, IAdminListItem } from './adminList.types';

export const fetchAdminsRequestAction = (page: number = 0) => ({
    type: AdminListsTypes.FETCH_ADMINS_REQUEST,
    payload: page,
});

export const fetchAdminsSuccessAction = (
    adminsResponse: IPaginationResponse<IAdminListItem>,
) => ({
    type: AdminListsTypes.FETCH_ADMINS_SUCCESS,
    payload: adminsResponse,
});

export const fetchAdminsFailureAction = (message: string) => ({
    type: AdminListsTypes.FETCH_ADMINS_FAILURE,
    payload: message,
});

export const deactivateAdminRequestAction = (adminId: string, page: number) => ({
    type: AdminListsTypes.DEACTIVATE_ADMIN_REQUEST,
    payload: {
        adminId,
        page,
    },
});

export const deactivateAdminSuccessAction = () => ({
    type: AdminListsTypes.DEACTIVATE_ADMIN_SUCCESS,
});

export const deactivateAdminFailureAction = (message: string) => ({
    type: AdminListsTypes.DEACTIVATE_ADMIN_FAILURE,
    payload: message,
});

export const activateAdminRequestAction = (adminId: string, page: number) => ({
    type: AdminListsTypes.ACTIVATE_ADMIN_REQUEST,
    payload: {
        adminId,
        page,
    },
});

export const activateAdminSuccessAction = () => ({
    type: AdminListsTypes.ACTIVATE_ADMIN_SUCCESS,
});

export const activateAdminFailureAction = (message: string) => ({
    type: AdminListsTypes.ACTIVATE_ADMIN_FAILURE,
    payload: message,
});

export const addNewAdminRequestAction = (admin: INewAdmin) => ({
    type: AdminListsTypes.ADD_NEW_ADMIN_REQUEST,
    payload: admin,
});

export const addNewAdminSuccessAction = (message: string) => ({
    type: AdminListsTypes.ADD_NEW_ADMIN_SUCCESS,
    payload: message,
});

export const addNewAdminFailureAction = (message: string) => ({
    type: AdminListsTypes.ADD_NEW_ADMIN_FAILURE,
    payload: message,
});

export const clearAdminListMessageAction = () => ({
    type: AdminListsTypes.CLEAR_ADMIN_LIST_MESSAGE,
});

import Paths from 'constants/paths';
import React from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { logoutUserRequest } from 'store/actions/adminAuth/auth.actions';

import styles from './DropDown.module.scss';
interface IDropDown {
    userName: string;
}

const DropDown: React.FC<IDropDown> = ({ userName }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <div className={styles.dropdownMenuWrapper}>
            <DropdownButton as={ButtonGroup} variant={'secondary'} title={userName}>
                <div className={styles.dropdownItems}>
                    <Dropdown.Item disabled>Wellcome</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                        onClick={() => {
                            history.push(Paths.CHANGE_PASSWORD);
                        }}
                        eventKey="0">
                        Change Password
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => {
                            dispatch(logoutUserRequest());
                        }}
                        eventKey="1">
                        Log Out
                    </Dropdown.Item>
                </div>
            </DropdownButton>
        </div>
    );
};

export default DropDown;

import React, { FC, memo, useCallback, useMemo, useRef } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Swiper as TSwiper } from 'swiper/types';
import podcastMarker from 'assets/icons/markerPodcast.svg';
import podcastBigMarker from 'assets/icons/markerPodcastBig.svg';
import storyMarker from 'assets/icons/markerStory.svg';
import storyBigMarker from 'assets/icons/markerStoryBig.svg';
import kioskMarker from 'assets/icons/purplePin.svg';
import kioskBigMarker from 'assets/icons/LargePurplePin.svg';


import { TMapItem } from 'types/map';
import styles from './MapView.module.scss';

const icons = {
    1: storyMarker,
    2:  kioskMarker,
    3: podcastMarker
};

const selectedIcon = {
    1: storyBigMarker,
    2: kioskBigMarker,
    3: podcastBigMarker
};

type TLatLng = google.maps.LatLngLiteral;

type TProps = {
    activeItem: TMapItem;
    items: TMapItem[];
    onMarkerClick: (item: TMapItem) => void;
    swiperRef: TSwiper | null;
}

const MapView: FC<TProps> = ({
    activeItem,
    items,
    onMarkerClick,
    swiperRef
}) => {
    const mapRef = useRef<GoogleMap | null>(null);

    const onLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const onUnmount = useCallback(() => {
        mapRef.current = null;
    }, []);

    const handleMarkerClick = useCallback((mapItem: TMapItem) => {
        onMarkerClick(mapItem);
        const index = items.findIndex((item) => item.id === mapItem.id);
        swiperRef?.slideTo(index + items.length);
    }, [items, onMarkerClick, swiperRef]);

    const center = useMemo((): TLatLng => ({
        lat: activeItem.latitude,
        lng: activeItem.longitude
    }), [activeItem]);

    return (
        <div className={styles.mapWrapper}>
            <GoogleMap
                onLoad={onLoad}
                onUnmount={onUnmount}
                zoom={10}
                center={center}
                mapContainerClassName={styles.mapContainer}
                options={{
                    disableDefaultUI: true,
                    keyboardShortcuts: false,
                }}
            >
                {items.filter((item) => item.id !== activeItem.id).map((item) => (
                    <Marker
                        key={item.id}
                        position={{ lat: item.latitude, lng: item.longitude }}
                        onClick={() => handleMarkerClick(item)}
                        icon={icons[item.type as keyof typeof icons]}
                    />
                ))}
                <Marker
                    position={center}
                    icon={selectedIcon[activeItem.type as keyof typeof selectedIcon]}
                />
            </GoogleMap>
        </div>
    );
};

export default memo(MapView);

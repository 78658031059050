import React from 'react';
import { ADMIN } from './config';
import AdminRouter from './adminRouter/AdminRouter';
import PortalRouter from './portalRouter/PortalRouter';
import { useLocation } from 'react-router-dom';

const ProtectedRouter = () => {
    const location = useLocation();
    const isAdmin = location?.pathname.includes(ADMIN);

    return isAdmin ? <AdminRouter /> : <PortalRouter />;
};
export default ProtectedRouter;

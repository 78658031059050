import AdminTopPanel from 'adminComponents/AdminTopPanel/AdminTopPanel';
import React, { useEffect } from 'react';
import AdminListTable from './AdminListTable';
import { tableHead } from './constants';
import Pagination from 'adminComponents/Pagination/Pagination';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks';
import { fetchAdminsRequestAction } from 'store/actions/adminList/adminsList.action';
import { Spinner } from 'react-bootstrap';
import Paths from 'constants/paths';
import { useHistory } from 'react-router';

const AdminList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const adminsList = useAppSelector(state => state.admins.adminsList);
    const loading = useAppSelector(state => state.admins.loading);
    const page = useAppSelector(state => state.admins.page);
    const totalElements = useAppSelector(state => state.admins.totalNumber);
    const perPage = useAppSelector(state => state.admins.perPage);

    const lastPage: number = Math.ceil(totalElements / perPage);

    useEffect(() => {
        dispatch(fetchAdminsRequestAction());
    }, [dispatch]);

    const changePageHandler = (newPage: number) => {
        dispatch(fetchAdminsRequestAction(newPage));
    };

    return (
        <>
            {loading ? (
                <div className="container-fluid d-flex justify-content-center align-items-center h-100">
                    <Spinner animation="border" />
                </div>
            ) : (
                <>
                    <AdminTopPanel
                        butonText={'New Admin'}
                        buttonOnClick={() => history.push(Paths.ADMIN_NEW_ADMIN)}
                    />
                    <div className="App-table-default-wrapper">
                        <div className="table-responsive">
                            <AdminListTable
                                tableHead={tableHead}
                                tableBody={adminsList}
                                page={page}
                            />
                            <Pagination
                                currentPage={page}
                                changePageHandler={changePageHandler}
                                lastPage={lastPage}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default AdminList;

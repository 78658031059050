import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './DropDownFile.module.scss';

interface IDropDownFileProps {
    fieldName: string;
}

const DragDropFile: React.FC<IDropDownFileProps> = ({ fieldName }) => {
    const [, meta, helpers] = useField(fieldName);

    const { value, initialValue } = meta;
    const { setValue } = helpers;

    const [noClickable, setNoClickable] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState<string>('');

    useEffect(() => {
        if (!value) {
            setValue(undefined);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!value) {
            setBackgroundImage('');
        } else if (typeof value === 'string') {
            setBackgroundImage(`${process.env.REACT_APP_URL}/${initialValue}`);
        } else {
            setBackgroundImage(URL.createObjectURL(value));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const onDrop = (acceptedFile: File[]) => {
        setBackgroundImage(URL.createObjectURL(acceptedFile[0]));
        setValue(acceptedFile?.[0]);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: ['image/jpeg', 'image/png'],
        onDrop,
        noClick: noClickable,
    });

    const handleRemoveBtn = () => {
        setNoClickable(false);
        setBackgroundImage('');
        setValue('null');
    };

    return (
        <div
            role="button"
            className={`form-control ${!backgroundImage && styles.dragAndDrop}`}>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {!backgroundImage ? (
                    <div className="text-center">
                        <i style={{ fontSize: 50 }} className="bi bi-cloud-arrow-up"></i>
                        <p className="text-center">
                            Drag and drop some files here, or click to upload
                        </p>
                    </div>
                ) : (
                    <div>
                        <div>
                            <img
                                src={backgroundImage}
                                className="rounded w-25 mx-auto d-block"
                                alt=""
                            />
                        </div>
                    </div>
                )}
                {backgroundImage && (
                    <div className="d-flex justify-content-end">
                        <button
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleRemoveBtn();
                            }}
                            className="btn btn-secondary">
                            Remove
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DragDropFile;

import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import ExportPanel from 'adminComponents/ExportPanel';
import { getStoryCategories } from 'store/portal/story/actions';
import { Spinner } from 'react-bootstrap';
import { useAppSelector } from 'hooks';
import { selectStoryCategories } from 'store/portal/story/selectors';
import Section from 'adminPages/Section';
import { TExportPanelFields } from 'adminComponents/ExportPanel/ExportPanel';
import { exportKioskValidationSchema } from 'utils/validationSchemas';
import { selectKioskExportCSV } from 'store/admin/kiosk/selectors';
import { exportKiosksList } from 'store/admin/kiosk/actions';
import { saveAs } from 'file-saver';

type TExportForm = {
    isDisabled: boolean | undefined;
    deviceId: string | undefined;
    storyCategory: string | undefined;
    dateFrom: string;
    dateTo: string;
}

const KiosksExportPanel: FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getStoryCategories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { loading: isStoryCategoriesLoading, data: storyCategoriesData } = useAppSelector(selectStoryCategories);
    const { loading: isExportLoading } = useAppSelector(selectKioskExportCSV);

    const storyCategories = useMemo(() => {
        if (!storyCategoriesData) {
            return null;
        }
        return storyCategoriesData.map((item) => ({
            label: item.titleEn || '',
            value: item.id,
        })) || [];
    }, [storyCategoriesData]);

    const fields = useMemo((): TExportPanelFields<TExportForm> => [
        {
            type: 'select',
            name: 'isDisabled',
            label: 'isDisabled:',
            options: [
                { value: true, label: 'True' },
                { value: false, label: 'False' },
            ],
        },
        {
            type: 'text',
            name: 'deviceId',
            label: 'Device Id:',
            placeholder: 'Device Id',
        },
        {
            type: 'select',
            name: 'storyCategory',
            label: 'Story Category:',
            options: storyCategories || [],
        },
        {
            type: 'date',
            name: 'dateFrom',
            placeholder: 'Date - From',
            minDate: '2021-01-01',
            maxDate: new Date().toISOString().slice(0, 10),
        },
        {
            type: 'date',
            name: 'dateTo',
            placeholder: 'Date - After',
            minDate: '2021-01-01',
            maxDate: new Date().toISOString().slice(0, 10),
        }
    ], [storyCategories]);

    const initialValues: TExportForm = {
        isDisabled: undefined,
        deviceId: undefined,
        storyCategory: undefined,
        dateFrom: '',
        dateTo: '',
    };

    const handleExport = (data: TExportForm) => {
        dispatch(exportKiosksList({
            data: {
                isDisabled: data.isDisabled ?? false,
                deviceId: data.deviceId,
                storyCategory: data.storyCategory,
                dateRange: `${data.dateFrom}/${data.dateTo}`,
            },
            onSuccess: (data) => {
                const fileData = new Blob([data], { type: 'text/csv' });
                saveAs(fileData, 'kiosks');
            }
        }));
    };

    return (
        <>
            {isStoryCategoriesLoading ? (
                <Section className="d-flex justify-content-center">
                    <Spinner animation="border" />
                </Section>
            ) : null}
            {!isStoryCategoriesLoading && storyCategories ? (
                <ExportPanel
                    fields={fields}
                    initialValues={initialValues}
                    onExport={handleExport}
                    validationSchema={exportKioskValidationSchema}
                    disabled={isExportLoading}
                />
            ) : null}
        </>
    );
};

export default KiosksExportPanel;

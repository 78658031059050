import {
    IInitialStoryDetailsState,
    StoryDetailsActionTypes,
    StoryDetailsTypes,
} from '../actions/adminStoryDetails/storyDetails.types';

const InitialState: IInitialStoryDetailsState = {
    storyDetails: null,
    loading: false,
    error: '',
    message: '',
};

export const AdminStoryDetailsReducer = (
    state = InitialState,
    action: StoryDetailsActionTypes,
): IInitialStoryDetailsState => {
    switch (action.type) {
        case StoryDetailsTypes.FETCH_STORY_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case StoryDetailsTypes.FETCH_STORY_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                storyDetails: action.payload,
            };
        case StoryDetailsTypes.FETCH_STORY_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case StoryDetailsTypes.EDIT_STORY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case StoryDetailsTypes.EDIT_STORY_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload,
            };
        case StoryDetailsTypes.EDIT_STORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case StoryDetailsTypes.CLEAR_STORY_MESSAGE:
            return {
                ...state,
                message: '',
                error: '',
            };
        default:
            return state;
    }
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { TStatistics } from 'types/shared';
import { podcastApi } from './api';

export const getPodcastStatistics = createAsyncThunk<TStatistics, void, { rejectValue: string }>(
    'portalPodcast/getStatistics',
    async (_, { rejectWithValue }) => {
        const response = await podcastApi.getStatistics();

        if (response.success) {
            return response.data;
        }

        return rejectWithValue(response.error);
    }
);

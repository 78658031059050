import React from 'react';
import Select from 'react-select';
import styles from './AdminFilterPanel.module.scss';
import { IStoryPodcastCategories,IStoryPodcastTypes } from 'store/actions/adminStories/adminStories.types';
import { useFormik } from 'formik';
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router-dom';
import { IFilterParams } from 'models/filters';

interface IAdminFilterPanelProps {
    options: IStoryPodcastCategories[];
    setFilterParams: React.Dispatch<React.SetStateAction<IFilterParams>>;
    params: IFilterParams;
    totalNumber: number;
    typeFilter?:boolean
    optionsType?:IStoryPodcastTypes[];
}

const AdminFilterPanel: React.FC<IAdminFilterPanelProps> = ({
    options,
    params,
    setFilterParams,
    totalNumber,
    optionsType,
    typeFilter

}) => {
    const defaultOption = {
        value: '',
        label: 'Select a category',
    };
    const defaultOptionType = {
        value: 0,
        label: 'Select a type',
    };
    const location = useLocation().pathname;
    const isPodcast = location.includes('LC');
    const countLabel = isPodcast ? "Podcasts Count" : "Stories Count";
    const selectOptions = options.map(option => ({
        value: option.id,
        label: option.titleEn,
    }));

    selectOptions.push(defaultOption);

    const selectOptionsType = optionsType?.map(option => ({
        value: option.id,
        label: option.titleEn,
    }));

    selectOptionsType?.push(defaultOptionType);


    const formik = useFormik({
        initialValues: {
            categoryId: params.categoryId,
            searchWord: params.searchWord,
            storyType: params.storyType,
        },
        onSubmit: values => {
            setFilterParams({
                ...values,
                page: 0,
            });
        },
    });
    return (
        <div className={styles.panelWrapper}>
            <div className={styles.panelCategory}>
                <div>Category:</div>

                <div className={styles.panelSelect + ' position-relative panelSelect '}>
                    <label className="d-block align-items-start flex-column">
                        <Select
                            id="categories"
                            options={selectOptions.slice(0, -1)}
                            isSearchable={false}
                            maxMenuHeight={100}
                            onChange={value => {
                                formik.setFieldValue('categoryId', value?.value);
                            }}
                            value={selectOptions.find(
                                option => option.value === formik?.values?.['categoryId'],
                            )}
                        />
                    </label>
                </div>
                {typeFilter && 
                <>
                    <div>Type:</div>
                        <div className={styles.panelSelect + ' position-relative panelSelect '}>
                            <label className="d-block align-items-start flex-column">
                                <Select
                                    id="categories"
                                    options={selectOptionsType?.slice(0, -1)}
                                    isSearchable={false}
                                    maxMenuHeight={100}
                                    onChange={value => {
                                        formik.setFieldValue('storyType', value?.value);
                                    }}
                                    value={selectOptionsType?.find(
                                        option => option.value === formik?.values?.['storyType'],
                                    )}
                                />
                            </label>
                        </div>
                </>}
                <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={e => {
                        formik.setFieldValue('searchWord', e.target.value);
                    }}
                    value={formik.values['searchWord']}
                    className={styles.panelSearch}
                />
                <Form.Group className={styles.totalCountContainer}>
                    <Form.Label>{countLabel}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={countLabel}
                        disabled={true}
                        value={totalNumber}
                        className={styles.totalCount}
                    />
                </Form.Group>
            </div>
            <div className={styles.panelButtonGroup}>
                <button
                    type="submit"
                    onClick={() => formik.handleSubmit()}
                    className={styles.panelButton}>
                    Get Data
                </button>
                <button
                    onClick={() => {
                        formik.setValues({
                            categoryId: '',
                            searchWord: '',
                            storyType: null
                        });
                        formik.handleSubmit();
                    }}
                    className={styles.panelButton}>
                    Clear
                </button>
            </div>
        </div>
    );
};

export default AdminFilterPanel;

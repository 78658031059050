import React, { ReactNode } from 'react';
import Logo from 'assets/images/footerLogo.svg';
import styles from './ItemDetail.module.scss';
import { useHistory, useLocation } from 'react-router';
import Paths from 'constants/paths';
import { useTranslation } from 'react-i18next';
import ButtonComponent, { ButtonVariants } from 'sharedComponents/Button';

interface IItemDetailLayout {
    withoutBtn?: boolean;
    children: ReactNode;
}
const ItemDetailLayout: React.FC<IItemDetailLayout> = ({ children, withoutBtn }) => {
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    return (
        <div className={styles.pageWrapper}>
            {children}
            <div className={styles.footer}>
                <div className={styles.buttonSectionMain}>
                    {!withoutBtn && (
                        <div className={styles.buttonTopSectionMain}>
                            <ButtonComponent
                                onClick={() =>
                                    history.push(
                                        location.pathname.includes('SHS')
                                            ? Paths.STORY_STATISTICS
                                            : Paths.PODCAST_STATISTICS,
                                    )
                                }
                                variant={
                                    location.pathname.includes('SHS')
                                        ? ButtonVariants.SECONDARY
                                        : ButtonVariants.THIRD
                                }>
                                {t('footer.statisticButton')}
                            </ButtonComponent>
                            <ButtonComponent onClick={() => history.push(Paths.HOME)}>
                                {t('footer.homeButton')}
                            </ButtonComponent>
                        </div>
                    )}
                    {!withoutBtn && (
                        <div className={styles.buttonTopSectionSecond}>
                            <ButtonComponent wide>
                                {t('footer.registerButton')}
                            </ButtonComponent>
                        </div>
                    )}
                </div>
                <img src={Logo} alt="" onClick={() => history.push(Paths.HOME)} />
            </div>
        </div>
    );
};
export default ItemDetailLayout;


import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import NewPodcast from 'adminComponents/NewPodcast/NewPodcast';

const NewPodcastPage = () => {
    return (
        <AdminLayout title="New Podcast">
            <NewPodcast />
        </AdminLayout>
    );
};

export default NewPodcastPage;

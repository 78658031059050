export interface ITableHeadItem {
    title: string;
    key: string;
}

export const podcastsTableHead: ITableHeadItem[] = [
    {
        title: '#',
        key: '#',
    },
    {
        title: 'Podcast Image | Ar',
        key: 'podcast_image_ar',
    },
    {
        title: 'Podcast Title | Ar',
        key: 'podcast_title_ar',
    },
    {
        title: 'Publisher Name | Ar',
        key: 'publisherNameAr',
    },
    {
        title: 'Author Name | Ar',
        key: 'authorAr',
    },
    {
        title: 'Category',
        key: 'category',
    },
    {
        title: 'Created Date',
        key: 'created_date',
    },
    {
        title: 'Details',
        key: 'details',
    },
    {
        title: 'Inactive',
        key: 'delete',
    },
    {
        title: 'Delete',
        key: 'delete_podcast',
    },
];

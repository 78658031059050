import React, { FC, memo, ReactNode } from 'react';
import Header, { THeaderProps } from 'portalComponents/Header';
import Footer, { TFooterProps } from 'portalComponents/Footer';
import styles from './PageLayout.module.scss';
import classNames from 'classnames';

type TCommonProps = {
    children: ReactNode;
    contentClassName?: string;
    wrapperClassName?: string;
}

type THeaderConditionalProps =
    | { showHeader?: false; headerProps?: never }
    | { showHeader: true; headerProps: THeaderProps }

type TFooterConditionalProps =
    | { showFooter?: false; footerProps?: never }
    | { showFooter: true; footerProps: TFooterProps }

type TProps = TCommonProps & THeaderConditionalProps & TFooterConditionalProps;

const PageLayout: FC<TProps> = ({
    children,
    showHeader,
    headerProps,
    showFooter,
    footerProps,
    contentClassName,
    wrapperClassName
}) => (
    <div className={classNames(styles.wrapper, wrapperClassName)}>
        {(showHeader && headerProps) ? (
            <Header {...headerProps} />
        ) : null}
        <div className={classNames(styles.content, contentClassName)}>
            {children}
        </div>
        {(showFooter && footerProps) ? (
            <Footer {...footerProps} />
        ) : null}
    </div>
);

export default memo(PageLayout);
import { useEffect, useState } from 'react';
import AdminTopPanel from 'adminComponents/AdminTopPanel/AdminTopPanel';
import KiosksTable from './KiosksTable/KiosksTable';
import { kioskTableHead } from './constants';
import Pagination from 'adminComponents/Pagination/Pagination';
import { useHistory } from 'react-router';
import Paths from 'constants/paths';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks';
import { Spinner } from 'react-bootstrap';
import Alert from 'sharedComponents/Alert';
import { IFilterParams } from 'models/filters';
import {
    fetchAdminKiosksRequestAction,
    cleanNewKioskMessageAction,
} from 'store/actions/adminKiosks/adminKiosks.action';
import KiosksExportPanel from './KiosksExportPanel';

const KiosksDevices = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const loading = useAppSelector(state => state.adminKiosks.loading);
    const kioskList = useAppSelector(state => state.adminKiosks.adminKiosks);
    const page = useAppSelector(state => state.adminKiosks.page);
    const perPage = useAppSelector(state => state.adminKiosks.perPage);
    const totalElements = useAppSelector(state => state.adminKiosks.totalNumber);
    const error = useAppSelector(state => state.adminKiosks.postNewKioskError);
    const message = useAppSelector(state => state.adminKiosks.message);
    const lastPage: number = Math.ceil(totalElements / perPage);
    const [filterParams] = useState<IFilterParams>({
        page: 0,
        searchWord: '',
        categoryId: '',
    });

    useEffect(() => {
        const { page, categoryId, searchWord } = filterParams as IFilterParams;
        dispatch(fetchAdminKiosksRequestAction(page, searchWord, categoryId));
        return () => {
            dispatch(cleanNewKioskMessageAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterParams]);

    const changePageHandler = (newPage: number): void => {
        const { categoryId, searchWord } = filterParams as IFilterParams;
        dispatch(fetchAdminKiosksRequestAction(newPage, searchWord, categoryId));
    };

    return (
        <>
            {error && <Alert message={error} isError={true} />}
            {message && <Alert message={message} isError={false} />}
            <KiosksExportPanel />
            <AdminTopPanel
                butonText={'New Kiosk Device'}
                buttonOnClick={() => history.push(Paths.ADMIN_NEW_KIOSK)}
            />
            {loading ? (
                <div className="container-fluid d-flex justify-content-center align-items-center mt-5">
                    <Spinner animation="border" />
                </div>
            ) : (
                <div className="App-table-default-wrapper">
                    <div className="table-responsive">
                        <KiosksTable
                            params={filterParams}
                            tableHead={kioskTableHead}
                            tableBody={kioskList}
                            page={page}
                        />
                        <Pagination
                            currentPage={page}
                            changePageHandler={changePageHandler}
                            lastPage={lastPage}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default KiosksDevices;

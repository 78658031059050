import React from 'react';
import ChangePasswordForm from 'adminComponents/ChangePasswordForm';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import { fields } from './constants';

const ChangePassword = () => {
    return (
        <AdminLayout title="Change password">
            <ChangePasswordForm fields={fields} />
        </AdminLayout>
    );
};
export default ChangePassword;

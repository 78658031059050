export enum FieldsVariation {
    TEXTAREA = 'TEXTAREA',
    INPUT = 'INPUT',
}
export enum InputTypes {
    TEXT = 'text',
    NUMBER = 'number',
}

export const slateInitialValue = JSON.stringify([
    {
        type: 'paragraph',
        children: [{ text: '' }],
    },
]);
export interface IInitialKiosksValue {
    description: string;
    city: string;
    kioskGroup: string;
    id?: string;
    readingCount: string;
}

export interface INewKiosk extends IInitialKiosksValue {}

export const initialKiosksValues = {
    description: '',
    city: '',
    kioskGroup: '',
    isDisabled: false,
};

export interface IFieldKioskItem {
    name: keyof IInitialKiosksValue;
    field: FieldsVariation;
    label: string;
    inputType?: InputTypes;
    isDisabled?: boolean;
}

export const kioskPageFormsFields: IFieldKioskItem[] = [];

interface IInitialKioskPageFormsFields {
    info: IFieldKioskItem[];
    main: IFieldKioskItem[];
}

export const initialKioskDevicePageFormsFields: IInitialKioskPageFormsFields = {
    info: [
        {
            name: 'id',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            isDisabled: true,
            label: 'ID',
        },
        {
            name: 'readingCount',
            inputType: InputTypes.TEXT,
            isDisabled: true,
            field: FieldsVariation.INPUT,
            label: 'Reading Count',
        },
    ],
    main: [
        {
            name: 'city',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'City',
        },
        {
            name: 'description',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Description',
        },
        {
            name: 'kioskGroup',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Kiosk Group',
        },
    ],
};

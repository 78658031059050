import React, { useEffect, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Podcast from 'assets/images/literatureCloud.svg';
import StoryHeader from 'assets/images/literatureStory.svg';
import Partner from 'assets/images/literaturePartner.svg';
import Paths from 'constants/paths';
import PageLayout from 'layouts/PageLayout';
import { useAnalytics } from 'utils/googleAnalytics';
import Banner from 'assets/images/homeBookmark.svg';
import styles from './Home.module.scss';

const conditionLink = (
    el: {
        route: Paths;
        src: string;
    },
    i: number,
) => {
    const image = <img className={styles.link} src={el.src} alt="" />;
    if (el.route.startsWith('/')) {
        return (
            <Link to={el.route} key={i}>
                {image}
            </Link>
        );
    } else {
        return (
            <a href={el.route} rel="noreferrer" target="_blank" key={i}>
                {image}
            </a>
        );
    }
};

const Home = () => {
    window.location.replace('https://engage.moc.gov.sa/literature_everywhere');

    return null;

    const { t } = useTranslation();
    const links = [
        { src: Podcast, route: Paths.PODCAST_CATEGORIES },
        { src: Partner, route: Paths.PARTNER },
        { src: StoryHeader, route: Paths.STORY_CATEGORIES },
    ];
    const [setNewPage] = useAnalytics();
    useEffect(() => setNewPage('Home Page'));

    return (
        <PageLayout
            showHeader
            headerProps={{
                variant: 'normal',
                symbols: { position: 'left' },
                languageSelect: { variant: 'default' },
                banner: <img src={Banner} alt="Home banner" />,
            }}
            contentClassName={styles.body}
            showFooter
            footerProps={{ variant: 'dark' }}>
            <div className={styles.title}>{t('home.title')}</div>
            <div className={styles.description}>{t('home.description')}</div>
            <div className={styles.pageNavigation}>
                <div className={styles.title}>{t('home.title_2')}</div>
                <div className={styles.description}>{t('home.description_2')}</div>

                <div className={styles.links}>
                    {links.map((el, i) => conditionLink(el, i))}
                </div>
                <div className={styles.linksBgTest} />
            </div>

            <div className={styles.programsSection}>
                <span className={styles.title}>{t('home.programsSection.title')}</span>
                <span className={styles.desc}>{t('home.programsSection.desc')}</span>
                <a
                    href="https://engage.moc.gov.sa/literature_everywhere/tracks/655/new"
                    rel="noreferrer"
                    target="_blank"
                    className={styles.btn}>
                    {t('home.programsSection.btn')}
                </a>
            </div>
        </PageLayout>
    );
};
export default Home;

import React from 'react';
import styles from './AdminTopPanel.module.scss';

interface IAdminTopPanelProps {
    butonText: string;
    buttonOnClick: () => void;
    exportButtonOnClick?: any;
    withDouble?: boolean;
}

const AdminTopPanel: React.FC<IAdminTopPanelProps> = ({
    butonText,
    buttonOnClick,
    exportButtonOnClick,
    withDouble,
}) => {
    return (
        <div className={styles.panelWrapper}>
            <div></div>
            <div>
                <button
                    onClick={() => buttonOnClick()}
                    className={`${styles.panelButton} ${styles.panelButtonMain}`}>
                    {butonText}
                </button>
                {exportButtonOnClick && withDouble ? (
                    <>
                        <button
                            className={`${styles.panelButton} ${styles.panelButtonExportActive}`}
                            onClick={() => exportButtonOnClick(false)}>
                            Export Acvite Kiosks
                        </button>
                        <button
                            className={`${styles.panelButton} ${styles.panelButtonExportDisactive}`}
                            onClick={() => exportButtonOnClick(true)}>
                            Export Inactive Kiosks
                        </button>
                    </>
                ) : exportButtonOnClick ? (
                    <button
                        className={`${styles.panelButton} ${styles.panelButtonExport}`}
                        onClick={() => exportButtonOnClick()}>
                        Export
                    </button>
                ) : null}
            </div>
        </div>
    );
};

export default AdminTopPanel;

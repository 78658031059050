import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    fetchMapDataDetailsRequestActionType,
    editMapDataRequestActionType,
} from 'store/actions/adminMapDataDetails/adminMapDataDetails.types';
import {
    getMapDataDetailsEndpoint,
    editMapDataEndpoint,
} from 'services/admin.services/adminDataMaps/adminDataMapsDetails.endpoint';
import {
    fetchMapDataDetailsRequestAction,
    fetchMapDataSuccessAction,
    fetchMapDataDetailsFailureAction,
    editMapDataSuccessAction,
    editMapDataFailureAction,
} from 'store/actions/adminMapDataDetails/adminMapDataDetails.action';
import { mapDataDetailsTypes } from 'store/actions/adminMapDataDetails/adminMapDataDetails.types';
import { IInitialLocationValue } from 'adminPages/NewMapDataPage/NewLocationForm/constans';

function* fetchMapDataDetailsWorker(action: fetchMapDataDetailsRequestActionType) {
    const mapDataId = action.payload;
    try {
        const { data }: { data: IInitialLocationValue } = yield call(
            getMapDataDetailsEndpoint,
            mapDataId,
        );
        yield put(fetchMapDataSuccessAction(data));
    } catch (error: any) {
        yield put(fetchMapDataDetailsFailureAction(error.message));
    }
}

function* editMapDataWorker(action: editMapDataRequestActionType) {
    const mapDataId = action.payload.id;
    const { id, readingCount, ...newLocation } = action.payload.newLocation;
    const json = JSON.stringify(newLocation);

    try {
        yield call(editMapDataEndpoint, mapDataId, json);
        yield put(editMapDataSuccessAction('Updated Successfully'));
        yield put(fetchMapDataDetailsRequestAction(mapDataId));
    } catch (error: any) {
        yield put(editMapDataFailureAction(error.message));
    }
}

export function* adminMapDataDetailsSaga() {
    yield all([
        takeLatest(
            mapDataDetailsTypes.FETCH_MAP_DATA_DETAIL_REQUEST,
            fetchMapDataDetailsWorker,
        ),
        takeLatest(mapDataDetailsTypes.EDIT_MAP_DATA_REQUEST, editMapDataWorker),
    ]);
}

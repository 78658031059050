import { IPodcastDetailResponse } from './../../services/portalServices/portal.types';
import {
    IShortStoryAndPodcastResponse,
    IStoryDetailsResponse,
} from 'services/portalServices/portal.types';
import {
    IshortStoryInitialState,
    shortStoryActionTypes,
    shortStoryTypes,
} from 'store/actions/portalActions/portal.types';

const initialState: IshortStoryInitialState = {
    loaded: true,
    error: '',
    shortStoryAndPodcastCategories: [] as IShortStoryAndPodcastResponse[],
    storyDetail: {} as IStoryDetailsResponse,
    podcastDetail: {} as IPodcastDetailResponse,
    currentCategory: '',
};

const PortalReducer = (
    state = initialState,
    action: shortStoryTypes,
): IshortStoryInitialState => {
    switch (action.type) {
        case shortStoryActionTypes.GET_SHORT_STORY_CATEGORIES_REQUEST:
        case shortStoryActionTypes.GET_STORY_DETAIL_REQUEST:
        case shortStoryActionTypes.GET_PODCAST_DETAIL_REQUEST:
        case shortStoryActionTypes.GET_ANOTHER_PODCAST_DETAILS:
        case shortStoryActionTypes.GET_RANDOM_STORY_REQUEST:
        case shortStoryActionTypes.GET_ANOTHER_STORY_DETAILS_REQUEST:
        case shortStoryActionTypes.GET_RANDOM_PODCAST_REQUEST:
            return {
                ...state,
                loaded: false,
            };
        case shortStoryActionTypes.GET_SHORT_STORY_CATEGORIES_SUCCESS:
            return {
                ...state,
                loaded: true,
                shortStoryAndPodcastCategories: action.payload,
                error: '',
            };
        case shortStoryActionTypes.GET_STORY_DETAIL_SUCCESS:
            return {
                ...state,
                loaded: true,
                storyDetail: action.payload,
            };
        case shortStoryActionTypes.GET_RANDOM_STORY_SUCCESS:
        case shortStoryActionTypes.GET_RANDOM_PODCAST_SUCCESS:
            return {
                ...state,
                loaded: true,
            };
        case shortStoryActionTypes.GET_ANOTHER_STORY_DETAILS_SUCCESS:
            return {
                ...state,
                loaded: true,
                storyDetail: action.payload,
            };
        case shortStoryActionTypes.GET_PODCAST_DETAIL_SUCCESS:
            return {
                ...state,
                loaded: true,
                podcastDetail: action.payload,
            };
        case shortStoryActionTypes.GET_SHORT_STORY_CATEGORIES_FAILURE:
        case shortStoryActionTypes.GET_STORY_DETAIL_FAILURE:
        case shortStoryActionTypes.GET_RANDOM_STORY_FAILURE:
        case shortStoryActionTypes.GET_RANDOM_PODCAST_FAILURE:
        case shortStoryActionTypes.GET_ANOTHER_STORY_DETAILS_FAILURE:
        case shortStoryActionTypes.GET_PODCAST_DETAIL_FAILURE:
            return {
                ...state,
                loaded: true,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default PortalReducer;

import { IStoryPodcastCategories } from './../../../store/actions/adminStories/adminStories.types';
import { Descendant } from 'slate/dist/interfaces/node';

export enum FieldsVariation {
    LINK = 'LINK',
    SWITCH = 'SWITCH',
    TEXTAREA = 'TEXTAREA',
    IMAGE_INPUT = 'IMAGE_INPUT',
    AUDIO_INPUT = 'AUDIO_INPUT',
    INPUT = 'INPUT',
    DROPDOWN = 'DROPDOWN',
}

export enum InputTypes {
    TEXT = 'text',
    NUMBER = 'number',
}

export const slateInitialValue = JSON.stringify([
    {
        type: 'paragraph',
        children: [{ text: '' }],
    },
]);

export interface IInitialPodcastsValue {
    titleEn: string;
    titleAr: string;
    totalDurationEn: number;
    totalDurationAr: number;
    imageEn: undefined | File;
    imageAr: undefined | File;
    categoryId: string;
    audioEn: undefined | File;
    audioAr: undefined | File;
    audioDetailsEn: Descendant[] | string;
    audioDetailsAr: Descendant[] | string;
    numberEpisodesEn: number;
    numberEpisodesAr: number;
    authorEn: string;
    authorAr: string;
    publisherNameEn: string;
    publisherNameAr: string;
    publisherImageEn: undefined | File;
    publisherImageAr: undefined | File;
    publisherLinkEn: string;
    publisherLinkAr: string;
    publisherDetailsEn: Descendant[] | string;
    publisherDetailsAr: Descendant[] | string;
    id?: string;
    category?: IStoryPodcastCategories;
    audioLinkEn?: string;
    audioLinkAr?: string;
    darkLogo: boolean;
    websiteLink: string;
}

export interface INewPodcast extends IInitialPodcastsValue {
}

export const initialPodcastsValues = {
    titleEn: '',
    titleAr: '',
    totalDurationEn: 0,
    totalDurationAr: 0,
    imageEn: undefined,
    imageAr: undefined,
    categoryId: '',
    audioEn: undefined,
    audioAr: undefined,
    audioDetailsEn: slateInitialValue,
    audioDetailsAr: slateInitialValue,
    numberEpisodesEn: 0,
    numberEpisodesAr: 0,
    authorEn: '',
    authorAr: '',
    publisherNameEn: '',
    publisherNameAr: '',
    publisherImageEn: undefined,
    publisherImageAr: undefined,
    publisherLinkEn: '',
    publisherLinkAr: '',
    publisherDetailsEn: slateInitialValue,
    publisherDetailsAr: slateInitialValue,
    darkLogo: false,
    websiteLink: ''
};

export interface IFieldPodcastsItem {
    name: keyof IInitialPodcastsValue;
    field: FieldsVariation;
    label: string;
    inputType?: InputTypes;
    required?: boolean;
}

export const podcastPageFormsFields: IFieldPodcastsItem[] = [];

interface IInitialPodcastPageFormsFields {
    ar: IFieldPodcastsItem[];
    en: IFieldPodcastsItem[];
    general: IFieldPodcastsItem[];
    switches: IFieldPodcastsItem[];
    links: IFieldPodcastsItem[];
}

export const initialPodcastPageFormsFields: IInitialPodcastPageFormsFields = {
    en: [
        {
            name: 'titleEn',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Title | En',
        },
        {
            name: 'imageEn',
            field: FieldsVariation.IMAGE_INPUT,
            label: 'Podcast Image | En',
        },
        {
            name: 'audioEn',
            field: FieldsVariation.AUDIO_INPUT,
            label: 'Podcast File | En',
        },
        {
            name: 'audioDetailsEn',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.TEXTAREA,
            label: 'Podcast More Details | En',
        },
        {
            name: 'numberEpisodesEn',
            inputType: InputTypes.NUMBER,
            field: FieldsVariation.INPUT,
            label: 'Number of episodes | En',
            required: true,
        },
        {
            name: 'totalDurationEn',
            inputType: InputTypes.NUMBER,
            field: FieldsVariation.INPUT,
            label: 'Podcast Total Duration | En',
            required: true,
        },
        {
            name: 'authorEn',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Author Name | En',
        },
        {
            name: 'publisherNameEn',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Publisher Name | En',
        },
        {
            name: 'publisherImageEn',
            field: FieldsVariation.IMAGE_INPUT,
            label: 'Publisher Image | En',
        },
        {
            name: 'publisherLinkEn',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Publisher Website Link | En',
            required: true,
        },
        {
            name: 'publisherDetailsEn',
            field: FieldsVariation.TEXTAREA,
            label: 'Publisher More Info | En',
        },
    ],
    ar: [
        {
            name: 'titleAr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Title | Ar',
            required: true,
        },
        {
            name: 'imageAr',
            field: FieldsVariation.IMAGE_INPUT,
            label: 'Podcast Image | Ar',
            required: true,
        },
        {
            name: 'audioAr',
            field: FieldsVariation.AUDIO_INPUT,
            label: 'Podcast File | Ar',
            required: true,
        },
        {
            name: 'audioDetailsAr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.TEXTAREA,
            label: 'Podcast More Details | Ar',
        },
        {
            name: 'numberEpisodesAr',
            inputType: InputTypes.NUMBER,
            field: FieldsVariation.INPUT,
            label: 'Number of episodes | Ar',
            required: true,
        },
        {
            name: 'totalDurationAr',
            inputType: InputTypes.NUMBER,
            field: FieldsVariation.INPUT,
            label: 'Podcast Total Duration | Ar',
            required: true,
        },
        {
            name: 'authorAr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Author Name  | Ar',
            required: true,
        },
        {
            name: 'publisherNameAr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Publisher Name | Ar',
            required: true,
        },
        {
            name: 'publisherImageAr',
            field: FieldsVariation.IMAGE_INPUT,
            label: 'Publisher Image | Ar',
            required: true,
        },
        {
            name: 'publisherLinkAr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Publisher Website Link | Ar',
            required: true,
        },
        {
            name: 'publisherDetailsAr',
            field: FieldsVariation.TEXTAREA,
            label: 'Publisher More Info | Ar',
        },
    ],
    general: [
        {
            name: 'categoryId',
            field: FieldsVariation.DROPDOWN,
            label: 'Podcast Category',
            required: true,
        },
    ],
    switches: [
        {
            name: 'darkLogo',
            field: FieldsVariation.SWITCH,
            label: 'Logo - Dark Background'
        },
    ],
    links: [
        {
            name: 'websiteLink',
            field: FieldsVariation.LINK,
            label: 'Website Link'
        },
    ]
};

import { INewAdmin } from 'services/admin.services/admins/admins.types';
import { IPaginationResponse } from 'services/types';

export enum AdminListsTypes {
    FETCH_ADMINS_REQUEST = 'FETCH_ADMINS_REQUEST',
    FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS',
    FETCH_ADMINS_FAILURE = 'FETCH_ADMINS_FAILURE',

    DEACTIVATE_ADMIN_REQUEST = 'DEACTIVATE_ADMIN_REQUEST',
    DEACTIVATE_ADMIN_SUCCESS = 'DEACTIVATE_ADMIN_SUCCESS',
    DEACTIVATE_ADMIN_FAILURE = 'DEACTIVATE_ADMIN_FAILURE',

    ADD_NEW_ADMIN_REQUEST = 'ADD_NEW_ADMIN_REQUEST',
    ADD_NEW_ADMIN_SUCCESS = 'ADD_NEW_ADMIN_SUCCESS',
    ADD_NEW_ADMIN_FAILURE = 'ADD_NEW_ADMIN_FAILURE',

    ACTIVATE_ADMIN_REQUEST = 'ACTIVATE_ADMIN_REQUEST',
    ACTIVATE_ADMIN_SUCCESS = 'ACTIVATE_ADMIN_SUCCESS',
    ACTIVATE_ADMIN_FAILURE = 'ACTIVATE_ADMIN_FAILURE',

    CLEAR_ADMIN_LIST_MESSAGE = 'CLEAR_ADMIN_LIST_MESSAGE',
}

export interface IInitialState {
    adminsList: IAdminListItem[];
    loading: boolean;
    error: string;
    page: number;
    perPage: number;
    totalNumber: number;
    message: string;
}

export interface IAdminListItem {
    id: string;
    name: string;
    email: string;
    isDeleted: boolean;
}

export interface FetchAdminsRequestActionType {
    type: AdminListsTypes.FETCH_ADMINS_REQUEST;
    payload: number;
}

export interface FetchAdminsSuccessActionType {
    type: AdminListsTypes.FETCH_ADMINS_SUCCESS;
    payload: IPaginationResponse<IAdminListItem>;
}

export interface FetchAdminsFailureActionType {
    type: AdminListsTypes.FETCH_ADMINS_FAILURE;
    payload: string;
}
export interface DeactivateAdminRequestActionType {
    type: AdminListsTypes.DEACTIVATE_ADMIN_REQUEST;
    payload: {
        adminId: string;
        page: number;
    };
}

export interface DeactivateAdminSuccessActionType {
    type: AdminListsTypes.DEACTIVATE_ADMIN_SUCCESS;
}

export interface DeactivateAdminFailureActionType {
    type: AdminListsTypes.DEACTIVATE_ADMIN_FAILURE;
    payload: string;
}
export interface activateAdminRequestActionType {
    type: AdminListsTypes.ACTIVATE_ADMIN_REQUEST;
    payload: {
        adminId: string;
        page: number;
    };
}

export interface activateAdminSuccessActionType {
    type: AdminListsTypes.ACTIVATE_ADMIN_SUCCESS;
}

export interface activateAdminFailureActionType {
    type: AdminListsTypes.ACTIVATE_ADMIN_FAILURE;
    payload: string;
}
export interface AddNewAdminRequestActionType {
    type: AdminListsTypes.ADD_NEW_ADMIN_REQUEST;
    payload: INewAdmin;
}

export interface AddNewAdminSuccessActionType {
    type: AdminListsTypes.ADD_NEW_ADMIN_SUCCESS;
    payload: string;
}

export interface AddNewAdminFailureActionType {
    type: AdminListsTypes.ADD_NEW_ADMIN_FAILURE;
    payload: string;
}
export interface clearAdminListMessageActionType {
    type: AdminListsTypes.CLEAR_ADMIN_LIST_MESSAGE;
}

export type AdminsListActionTypes =
    | FetchAdminsRequestActionType
    | FetchAdminsSuccessActionType
    | FetchAdminsFailureActionType
    | DeactivateAdminRequestActionType
    | DeactivateAdminSuccessActionType
    | DeactivateAdminFailureActionType
    | activateAdminRequestActionType
    | activateAdminSuccessActionType
    | activateAdminFailureActionType
    | AddNewAdminRequestActionType
    | AddNewAdminSuccessActionType
    | AddNewAdminFailureActionType
    | clearAdminListMessageActionType;

import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import bannerEn from 'assets/images/storyStatisticsBannerEn.png';
import bannerAr from 'assets/images/storyStatisticsBannerAr.svg';
import Paths from 'constants/paths';
import PageLayout from 'layouts/PageLayout';
import StatisticsContent from 'portalComponents/StatisticsContent';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks';
import { getStoryStatistics } from 'store/portal/story/actions';
import { selectStoryStatistics } from 'store/portal/story/selectors';
import styles from './StoryStatistics.module.scss';
import { TLanguages } from 'types/shared';
import { Link } from 'react-router-dom';
import Button from 'portalComponents/Button';

const storyStatisticsBanners = {
    en: bannerEn,
    ar: bannerAr,
};

const StoryStatistics: FC = () => {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { data, loading } = useAppSelector(selectStoryStatistics);

    const banner = storyStatisticsBanners[language as TLanguages];

    useEffect(() => {
        dispatch(getStoryStatistics());
    }, [dispatch]);

    console.log(data, loading);

    return (
        <PageLayout
            showHeader
            headerProps={{
                variant: 'small',
                symbols: { position: 'left' },
                banner: (
                    <img
                        src={banner}
                        alt="Story statistics banner"
                        className={styles.headerBanner}
                    />
                ),
                content: {
                    leftRenderer: () => (
                        <Link to={Paths.HOME}>
                            <Button>{t('buttons.homePage')}</Button>
                        </Link>
                    ),
                },
                languageSelect: { variant: 'story' },
            }}
            showFooter
            footerProps={{ variant: 'dark' }}
            contentClassName={styles.wrapper}>
            {loading && <Spinner animation="border" />}
            {!loading && data && (
                <StatisticsContent
                    type="story"
                    total={{
                        onClick: () => history.push(Paths.STORY_CATEGORIES),
                        title: t('story.statistics.total'),
                        count: data.totalNumber,
                    }}
                    items={data.items}
                />
            )}
        </PageLayout>
    );
};

export default StoryStatistics;


import { useEffect } from 'react';
import NewKioskDeviceForm from 'adminComponents/NewKioskDevice/NewKioskDeviceForm';
import { IInitialKiosksValue } from 'adminComponents/NewKioskDevice/NewKioskDeviceForm/constants';
import { useAppSelector } from 'hooks';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import Alert from 'sharedComponents/Alert';
import { editKioskRequestAction, fetchKioskDetailsRequestAction } from 'store/actions/adminKioskDetails/kioskDetails.action';
import { cleanPodcastMessageAction } from 'store/actions/adminPodcastDetails/podcastDetails.action';
import { getDifferentValues } from 'utils/helpers';


const AdminKioskDeatils = () => {
    const { id } = useParams<{ id: string }>();
    const kioskDetails = useAppSelector(
        store => store.adminKioskDetails.podcastDetails,
    );
    const loading = useAppSelector(store => store.adminKioskDetails.loading);
    const categories = useAppSelector(state => state.adminPodcasts.podcastsCategories);
    const message = useAppSelector(state => state.adminKioskDetails.message);
    const error = useAppSelector(state => state.adminKioskDetails.error);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(cleanPodcastMessageAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(fetchKioskDetailsRequestAction(id));
    }, [dispatch, id]);

    const onSubmit = (values: IInitialKiosksValue) => {
        const formattedValues = getDifferentValues(kioskDetails, values)
        dispatch(editKioskRequestAction(formattedValues, id));
    };

    return (
        <AdminLayout title='Kiosk Device Details'>
            {message && <Alert message={message} isError={false} />}
            {error && <Alert message={error} isError={true} />}
            {loading ? (
                <div className="container-fluid d-flex justify-content-center align-items-center h-100">
                    <Spinner animation="border" />
                </div>
            ) : (
                kioskDetails && <NewKioskDeviceForm
                    categories={categories}
                    initialValues={kioskDetails}
                    onSubmit={onSubmit}
                    buttonText={'Edit'}
                />
            )}
        </AdminLayout>
    );
};

export default AdminKioskDeatils;

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { Swiper as TSwiper } from 'swiper/types';
import { TMapDataFilters, TMapItem } from 'types/map';
import MapView from './MapView';
import MapMenu from './MapMenu';
import CardList from './CardList';
import styles from './Map.module.scss';
import PageLayout from 'layouts/PageLayout';
import { useDispatch } from 'react-redux';
import { getMapData } from 'store/portal/map/actions';
import { useAppSelector } from 'hooks';
import { selectMapItems } from 'store/portal/map/selectors';
import { selectMapItemsByFilter } from 'portalPages/Map/helpers';

const Map: FC = () => {
    const dispatch = useDispatch();
    const { isLoaded: isMapLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    });

    const [itemsFilter, setItemsFilter] = useState<TMapDataFilters | null>(null);
    const [swiperRef, setSwiperRef] = useState<TSwiper | null>(null);
    const [selectedItem, setSelectedItem] = useState<TMapItem | null>(null);
    const { data, loading } = useAppSelector(selectMapItems);

    const mapItems = useMemo(() => {
        if (!data) {
            return [];
        }
        const preFilter = data.data.filter((item) => {
            return item.type === 1 || item.type === 2 || item.type === 3;
        });

        return selectMapItemsByFilter(preFilter, itemsFilter);
    }, [data, itemsFilter]);

    useEffect(() => {
        dispatch(getMapData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (mapItems?.length) {
            setSelectedItem(mapItems[0]);
            swiperRef?.slideTo(mapItems.length);
        }
    }, [mapItems, swiperRef]);

    return (
        <PageLayout
            showHeader
            headerProps={{
                variant: "without",
                symbols: { position: 'right' },
                languageSelect: { variant: 'map' },
                content: {
                    leftRenderer: () => (
                        <MapMenu setItemsFilter={setItemsFilter} />
                    ),
                }
            }}
            wrapperClassName={styles.pageWrapper}
        >
            {isMapLoaded && !loading && mapItems && selectedItem ? (
                <>
                    <MapView
                        items={mapItems}
                        activeItem={selectedItem}
                        onMarkerClick={setSelectedItem}
                        swiperRef={swiperRef}
                    />
                    <CardList
                        items={mapItems}
                        onSelectedChange={setSelectedItem}
                        swiperRef={swiperRef}
                        setSwiperRef={setSwiperRef}
                    />
                </>
            ) : null}
        </PageLayout>
    );
};

export default Map;

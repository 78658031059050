import {
    // INewKiosk,
    IInitialKiosksValue,
} from 'adminComponents/NewKioskDevice/NewKioskDeviceForm/constants';

export enum KioskDetailsTypes {
    FETCH_KIOSK_DETAIL_REQUEST = 'FETCH_KIOSK_DETAIL_REQUEST',
    FETCH_KIOSK_DETAIL_SUCCESS = 'FETCH_KIOSK_DETAIL_SUCCESS',
    FETCH_KIOSK_DETAIL_FAILURE = 'FETCH_KIOSK_DETAIL_FAILURE',

    EDIT_KIOSK_REQUEST = 'EDIT_KIOSK_REQUEST',
    EDIT_KIOSK_SUCCESS = 'EDIT_KIOSK_SUCCESS',
    EDIT_KIOSK_FAILURE = 'EDIT_KIOSK_FAILURE',

    CLEAR_KIOSK_MESSAGE = 'CLEAR_KIOSK_MESSAGE',
}

export interface IInitialKioskDetailsState {
    podcastDetails: IInitialKiosksValue | null;
    loading: boolean;
    error: string;
    message: string;
}

export interface fetchKioskDetailsRequestActionType {
    type: KioskDetailsTypes.FETCH_KIOSK_DETAIL_REQUEST;
    payload: string;
}

export interface fetchKioskDetailsSuccessActionType {
    type: KioskDetailsTypes.FETCH_KIOSK_DETAIL_SUCCESS;
    payload: IInitialKiosksValue;
}

export interface fetchKioskDetailsFailureActionType {
    type: KioskDetailsTypes.FETCH_KIOSK_DETAIL_FAILURE;
    payload: string;
}

export interface editKioskRequestActionType {
    type: KioskDetailsTypes.EDIT_KIOSK_REQUEST;
    payload: {
        newKiosk:any;
        id: string;
    };
}

export interface editKioskSuccessActionType {
    type: KioskDetailsTypes.EDIT_KIOSK_SUCCESS;
    payload: string;
}

export interface editKioskFailureActionType {
    type: KioskDetailsTypes.EDIT_KIOSK_FAILURE;
    payload: string;
}
export interface cleanKioskMessageActionType {
    type: KioskDetailsTypes.CLEAR_KIOSK_MESSAGE;
}

export type KioskDetailsActionTypes =
    | fetchKioskDetailsRequestActionType
    | fetchKioskDetailsSuccessActionType
    | fetchKioskDetailsFailureActionType
    | editKioskRequestActionType
    | editKioskSuccessActionType
    | editKioskFailureActionType
    | cleanKioskMessageActionType;

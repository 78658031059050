export enum FieldsVariation {
    TEXTAREA = 'TEXTAREA',
    INPUT = 'INPUT',
}
export enum InputTypes {
    TEXT = 'text',
}

export interface IInitialNotificationValue {
    id?: string;
    title: string;
    body: string;
}
export interface IFieldNotificationItem {
    name: string;
    field: FieldsVariation;
    label: string;
    inputType?: InputTypes;
    isDisabled?: boolean;
}

export interface INewNotification extends IInitialNotificationValue {}

export const initialNotificationValues = {
    id: '',
    title: '',
    body: '',
};
interface IInitialNotificationPageFormsFields {
    main: IFieldNotificationItem[];
}

export const initialNotificationPageFormsFields: IInitialNotificationPageFormsFields = {
    main: [
        {
            name: 'Email Title',
            field: FieldsVariation.INPUT,
            inputType: InputTypes.TEXT,
            label: 'Email Title',
        },
        {
            name: 'Email Body',
            field: FieldsVariation.TEXTAREA,
            inputType: InputTypes.TEXT,
            label: 'Email Body',
        },
    ],
};

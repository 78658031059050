import { useAppSelector } from 'hooks';
import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import Alert from 'sharedComponents/Alert';
import {
    cleanNewPodcastMessageAction,
    getAdminsPodcastsCategoriesRequestAction,
} from 'store/actions/adminPodcasts/adminPodcasts.action';
import { serialize } from 'object-to-formdata';
import { useDispatch } from 'react-redux';
import { postNewPodcastRequestAction } from 'store/actions/adminPodcasts/adminPodcasts.action';
import NewPodcastForm from './NewPodcastForm';
import { IInitialPodcastsValue, initialPodcastsValues } from './NewPodcastForm/constants';
import { existingValues } from 'utils/helpers';

const NewPodcast = () => {
    const error = useAppSelector(state => state.adminPodcasts.postNewPodcastError);
    const message = useAppSelector(state => state.adminPodcasts.message);
    const loading = useAppSelector(state => state.adminPodcasts.loading);
    const categories = useAppSelector(state => state.adminPodcasts.podcastsCategories);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAdminsPodcastsCategoriesRequestAction());
        return () => {
            dispatch(cleanNewPodcastMessageAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (values: IInitialPodcastsValue) => {
        const formData = serialize(existingValues(values));
        dispatch(postNewPodcastRequestAction(formData));
    };
    return (
        <>
            {loading ? (
                <div className="container-fluid d-flex justify-content-center align-items-center h-100">
                    <Spinner animation="border" />
                </div>
            ) : (
                <>
                    {error && <Alert message={error} isError={true} />}
                    {message && <Alert message={message} isError={false} />}
                    <NewPodcastForm
                        initialValues={initialPodcastsValues}
                        categories={categories}
                        onSubmit={onSubmit}
                        buttonText={'Submit'}
                    />
                </>
            )}
        </>
    );
};

export default NewPodcast;

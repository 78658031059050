import {
    AuthActionTypes,
    AuthTypes,
    IInitialState,
} from 'store/actions/adminAuth/auth.types';

const initialState: IInitialState = {
    adminToken: '',
    authenticated: false,
    loginError: '',
    loading: false,
    message: '',
};

export const AuthReducer = (state = initialState, action: AuthActionTypes) => {
    switch (action.type) {
        case AuthTypes.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AuthTypes.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                adminToken: action.payload,
                authenticated: true,
                loginError: '',
            };
        case AuthTypes.LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                loginError: action.payload,
                authenticated: false,
            };
        case AuthTypes.LOGOUT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AuthTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                adminToken: '',
                authenticated: false,
            };
        case AuthTypes.LOGOUT_FAILURE:
            return {
                ...state,
                loading: false,
                loginError: action.payload,
            };
        case AuthTypes.CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AuthTypes.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                loginError: '',
                message: action.payload,
            };
        case AuthTypes.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                loginError: action.payload,
            };
        default:
            return state;
    }
};

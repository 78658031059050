import { AxiosResponse } from 'axios';
import { axiosInstance } from 'services/interceptor';
import {
    IPodcastDetailResponse,
    IRandomPodcastResponse,
    IRandomStoryResponse,
    IShortStoryAndPodcastResponse,
    IStoryDetailsResponse,
} from './portal.types';

///// story endpoints /////

export const getShortStoryCategories = (): Promise<AxiosResponse<IShortStoryAndPodcastResponse>> =>
    axiosInstance.get('/short-story-categories').then(res => res.data);

export const getRandomStory = (
    categoryId: string,
): Promise<AxiosResponse<IRandomStoryResponse>> =>
    axiosInstance
        .get('/stories/random', {
            params: {
                categoryId,
            },
        })
        .then(res => res.data);

export const getStoryDetail = (
    id: string,
): Promise<AxiosResponse<IStoryDetailsResponse>> =>
    axiosInstance.get(`/stories/${id}`).then(res => res.data);

export const getAnotherStory = (
    categoryId: string,
    storyId: string,
): Promise<AxiosResponse<IStoryDetailsResponse>> =>
    axiosInstance
        .get('/stories/random', {
            params: {
                categoryId,
                // storyToOmitId: storyId,
            },
        })
        .then(res => res.data);

////// audio endpoints //////

export const getPodcastDetail = (
    id: string,
): Promise<AxiosResponse<IPodcastDetailResponse>> =>
    axiosInstance.get(`/audio/${id}`).then(res => res.data);

export const getAnotherPodcastDetail = (
    categoryId: string,
    audioToOmitId: string,
): Promise<AxiosResponse<IPodcastDetailResponse>> =>
    axiosInstance
        .get('/audio/random', {
            params: { categoryId },
        })
        .then(res => res.data);

export const getShortPodcastCategories = (): Promise<AxiosResponse<IShortStoryAndPodcastResponse>> =>
    axiosInstance.get('/audio-categories').then(res => res.data);

export const getRandomPodcast = (
    categoryId: string,
): Promise<AxiosResponse<IRandomPodcastResponse>> =>
    axiosInstance
        .get('/audio/random', {
            params: {
                categoryId,
            },
        })
        .then(res => res.data);

import { all } from 'redux-saga/effects';
import { authorizationSaga } from './adminAuth.saga';
import { adminsListSaga } from './adminList.saga';
import { adminsStoriesSaga } from './adminStories.saga';
import { portalSaga } from './portal.saga';
import { adminPodcastsSaga } from './adminPodcasts.saga';
import { adminStoryDetailsSaga } from './adminStoryDetails.saga';
import { adminPodcastDetailsSaga } from './adminPodcastDetails.saga';
import { adminKiosksSaga } from './adminKiosks.saga';
import { adminKioskDetailsSaga } from './adminKioskDetails.saga';
import { adminMapDataSaga } from './adminMapData.saga';
import { adminMapDataDetailsSaga } from './adminMapDataDetails.saga';
import { adminEmailsStorageSaga } from './adminEmailsStorage.saga';

export default function* rootSaga() {
    yield all([
        authorizationSaga(),
        portalSaga(),
        adminsListSaga(),
        adminPodcastsSaga(),
        adminsStoriesSaga(),
        adminStoryDetailsSaga(),
        adminPodcastDetailsSaga(),
        adminKiosksSaga(),
        adminKioskDetailsSaga(),
        adminMapDataSaga(),
        adminMapDataDetailsSaga(),
        adminEmailsStorageSaga(),
    ]);
}

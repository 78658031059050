import { call, all, takeLatest, put } from 'redux-saga/effects';
import * as AdminAuthEndpoints from 'services/admin.services/auth/auth.endpoints';
import { changePasswordEndpoint } from 'services/admin.services/auth/auth.endpoints';
import { LoginResponse } from 'services/admin.services/auth/auth.types';
import * as AdminAuthActions from 'store/actions/adminAuth/auth.actions';
import {
    loginUserFailure,
    logoutUserFailure,
} from 'store/actions/adminAuth/auth.actions';
import {
    AuthTypes,
    ChangePasswordRequestAction,
    LoginUserRequestAction,
} from 'store/actions/adminAuth/auth.types';

function* loginUserWorker(action: LoginUserRequestAction) {
    const body = action.payload;
    try {
        const { data }: { data: LoginResponse } = yield call(
            AdminAuthEndpoints.loginUserEndpoint,
            body,
        );
        yield localStorage.setItem('token', data.access_token);
        yield put(AdminAuthActions.loginUserSuccess(data.access_token));
    } catch (error: any) {
        yield put(loginUserFailure(error.message));
    }
}
function* logoutUserWorker() {
    try {
        yield call(AdminAuthEndpoints.logoutUserEndpoint);
        yield localStorage.removeItem('token');
        yield put(AdminAuthActions.logoutUserSuccess());
    } catch (error: any) {
        yield put(logoutUserFailure(error.message));
    }
}

function* changePasswordWorker({ payload }: ChangePasswordRequestAction) {
    try {
        yield call(changePasswordEndpoint, payload);
        yield put(AdminAuthActions.ChangePasswordSuccess('Updated successfully'));
    } catch (error: any) {
        yield put(AdminAuthActions.ChangePasswordFailure(error.message));
    }
}
export function* authorizationSaga() {
    yield all([
        takeLatest(AuthTypes.LOGIN_REQUEST, loginUserWorker),
        takeLatest(AuthTypes.LOGOUT_REQUEST, logoutUserWorker),
        takeLatest(AuthTypes.CHANGE_PASSWORD_REQUEST, changePasswordWorker),
    ]);
}

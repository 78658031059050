import { INewPodcast } from './../../../adminComponents/NewPodcast/NewPodcastForm/constants';
import { axiosInstance } from './../../interceptor';
import { IAdminPodcasts } from './adminPodcasts.types';
import { AxiosResponse } from 'axios';
import { IPaginationResponse } from 'services/types';
import { IStoryPodcastCategories } from 'store/actions/adminStories/adminStories.types';

export const getAdminPodcastsEndpoint = (
    page: number = 0,
    searchWord: string,
    categoryId: string,
    perPage: number = 30,
): Promise<AxiosResponse<IPaginationResponse<IAdminPodcasts>>> => {
    return axiosInstance.get('admin/audio', {
        params: { page, perPage, categoryId, searchWord },
    });
};

export const deactivateAdminPodcastsEndpoint = (
    adminPodcastId: string,
    page: number,
): Promise<AxiosResponse> => {
    return axiosInstance.delete(`/admin/audio/${adminPodcastId}`, { params: { page } });
};

export const deleteAdminPodcastsEndpoint = (
    adminPodcastId: string,
): Promise<AxiosResponse> => {
    return axiosInstance.delete(`/admin/audio/irreversible/${adminPodcastId}`);
};

export const activateAdminPodcastsEndpoint = (
    adminPodcastId: string,
    page: number,
): Promise<AxiosResponse> => {
    return axiosInstance.put(`/admin/audio/restore/${adminPodcastId}`, {
        params: { page },
    });
};

export const getAdminsPodcastsExportToCsvEndpoint = (): Promise<AxiosResponse<string>> => {
    return axiosInstance.get('/admin/audio/export-to-csv');
};

export const getAdminPodcastsCategoriesEndpoin = (): Promise<
    AxiosResponse<IStoryPodcastCategories[]>
> => {
    return axiosInstance.get('/audio-categories');
};

export const postNewPodcastEndpoin = (
    newPodcast: INewPodcast | FormData,
): Promise<AxiosResponse> => {
    return axiosInstance.post('/admin/audio', newPodcast, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export enum FieldsVariation {
    INPUT = 'INPUT',
    DROPDOWN = 'DROPDOWN',
}
export enum InputTypes {
    TEXT = 'text',
    NUMBER = 'number',
}

export interface IInitialLocationValue {
    id?: string;
    titleAr: string;
    titleEn: string;
    titleFr: string;
    descriptionAr: string;
    descriptionEn: string;
    descriptionFr: string;
    type: string | number;
    latitude: number;
    longitude: number;
}

export interface INewLocation extends IInitialLocationValue {}

export const initialLocationValues = {
    titleAr: '',
    titleEn: '',
    titleFr: '',
    descriptionAr: '',
    descriptionEn: '',
    descriptionFr: '',
    type: '',
    latitude: '',
    longitude: '',
};

export interface IFieldLocationItem {
    name: keyof IInitialLocationValue;
    field: FieldsVariation;
    label: string;
    inputType?: InputTypes;
    isDisabled?: boolean;
}

export const newLocationPageFormsFields: IFieldLocationItem[] = [];

interface IInitialLocationPageFormsFields {
    info: IFieldLocationItem[];
    ar: IFieldLocationItem[];
    en: IFieldLocationItem[];
    fr: IFieldLocationItem[];
    general: IFieldLocationItem[];
    mapLocation: IFieldLocationItem[];
}

export const initialLocationPageFormsFields: IInitialLocationPageFormsFields = {
    info: [
        {
            name: 'id',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            isDisabled: true,
            label: 'ID',
        },
    ],
    ar: [
        {
            name: 'titleAr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Title | Ar',
        },
        {
            name: 'descriptionAr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Description | Ar',
        },
    ],

    en: [
        {
            name: 'titleEn',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Title | En',
        },

        {
            name: 'descriptionEn',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Description | En',
        },
    ],

    fr: [
        {
            name: 'titleFr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Title | Fr',
        },
        {
            name: 'descriptionFr',
            inputType: InputTypes.TEXT,
            field: FieldsVariation.INPUT,
            label: 'Description | Fr',
        },
    ],
    general: [
        {
            name: 'type',
            field: FieldsVariation.DROPDOWN,
            label: 'Type',
        },
    ],
    mapLocation: [
        {
            name: 'latitude',
            field: FieldsVariation.INPUT,
            label: 'Latitude',
        },
        {
            name: 'longitude',
            field: FieldsVariation.INPUT,
            label: 'Longitude',
        },
    ],
};

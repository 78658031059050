import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import KiosksDevices from 'adminComponents/KiosksDevices/KiosksDevices';

const KiosksPage = () => {
    return (
        <AdminLayout title="Kiosks Devices List">
            <KiosksDevices />
        </AdminLayout>
    );
};

export default KiosksPage;

import { FC,useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';


import { compile } from 'path-to-regexp';
import { DeleteModal } from 'sharedComponents/DeleteModal';
import Paths from 'constants/paths';
import { ITableHeadItem } from '../constants';
import { IMapDataListItem } from '../../../store/actions/adminMapData/adminMapData.types';
import { deleteAdminEmailRequestAction } from 'store/actions/adminMapData/adminMapData.action';
import { IFilterParams } from 'models/filters';


interface IMapDataTableProps {
    tableHead: ITableHeadItem[];
    tableBody: IMapDataListItem[];
    params: IFilterParams;
    page?: number;
}
const MapDataTable: FC<IMapDataTableProps> = ({ tableHead, tableBody,page,params }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [modalShow, changeModalShow] = useState(false);
    const [locationData, setLocationData] = useState({ id: '', page: 0 });

    const typeChecker = (type: string | number) => {
        if (type === 1) {
            return <td>Short Stories</td>;
        }
        if (type === 2) {
            return <td>Kiosk Device</td>;
        }
        if (type === 3) {
            return <td>Literature Cloud Device</td>;
        }
    };

    const renderTableIcon = (
        iconSrc: string,
        identificator: string,
        cb: (id: string, page?: number) => void,
        page?: number,
    ): React.ReactNode => (
        <i
            className={`App-table-icon ${iconSrc}`}
            onClick={() => cb(identificator, page)}
        />
    );
    const detailsRedirect = (id: string) => {
        history.push(compile(Paths.ADMIN_MAP_DATA_DETAIL)({ id }));
    };
    const deleteLocation = (id: string, page: number = 0) => {
        changeModalShow(true);
        setLocationData({ id, page });
    };
    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        {tableHead.map(item => (
                            <th key={item.key}>{item.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableBody.map((location, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{location.titleAr}</td>
                            <td>{location.titleEn}</td>
                            {typeChecker(location.type)}
                            <td>
                                {renderTableIcon('bi bi-eye', location.id, detailsRedirect)}
                            </td>
                            <td>
                            {renderTableIcon(
                                'bi bi-trash',
                                location.id,
                                deleteLocation,
                            )}
                        </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {modalShow &&
                <DeleteModal
                    open={modalShow}
                    onClose={() => {
                        changeModalShow(false);
                    }}
                    onSuccess={() => {
                        dispatch(
                            deleteAdminEmailRequestAction(
                                locationData.id,
                                params
                            ),
                        );
                    }}
                />
            }
        </>
    );
};

export default MapDataTable;

import {
    AdminMapDataActionTypes,
    AdminMapDataTypes,
    IInitialState,
} from 'store/actions/adminMapData/adminMapData.types';

const InitialState: IInitialState = {
    items: [],
    loading: false,
    error: '',
    message: '',
    page: 1,
    perPage: 10,
    totalNumber: 0,
};

export const AdminMapDataReducer = (
    state = InitialState,
    action: AdminMapDataActionTypes,
): IInitialState => {
    switch (action.type) {
        case AdminMapDataTypes.FETCH_ADMIN_MAP_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminMapDataTypes.FETCH_ADMIN_MAP_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.data,
                perPage: 10,
                page: action.payload.curPage,
                totalNumber: action.payload.totalPages,
            };
        case AdminMapDataTypes.FETCH_ADMIN_MAP_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminMapDataTypes.POST_NEW_MAP_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminMapDataTypes.POST_NEW_MAP_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload,
            };
        case AdminMapDataTypes.POST_NEW_MAP_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case AdminMapDataTypes.CLEAN_NEW_MAP_MESSAGE:
            return {
                ...state,
                message: '',
                error: '',
            };
        case AdminMapDataTypes.DELETE_ADMIN_MAP_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AdminMapDataTypes.DELETE_ADMIN_MAP_DATA_SUCCESS:
            return {
                ...state,
                message: 'Deleted Successfully',
                loading: false,
            };
        case AdminMapDataTypes.DELETE_ADMIN_MAP_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

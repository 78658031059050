import { useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import Alert from 'sharedComponents/Alert';
import {
    cleanNewPodcastMessageAction,
} from 'store/actions/adminPodcasts/adminPodcasts.action';
import { useDispatch } from 'react-redux';
import { IInitialKiosksValue, initialKiosksValues } from './NewKioskDeviceForm/constants';
import NewKioskDeviceForm from './NewKioskDeviceForm';
import { postNewKioskRequestAction } from '../../store/actions/adminKiosks/adminKiosks.action';

const NewKioskDevice = () => {
    const error = useAppSelector(state => state.adminKiosks.postNewKioskError);
    const message = useAppSelector(state => state.adminKiosks.message);
    const loading = useAppSelector(state => state.adminKiosks.loading);
    const categories = useAppSelector(state => state.adminKiosks.podcastsCategories);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(cleanNewPodcastMessageAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (values: IInitialKiosksValue) => {
        dispatch(postNewKioskRequestAction(values));
    };
    return (
        <>
            {loading ? (
                <div className="container-fluid d-flex justify-content-center align-items-center h-100">
                    <Spinner animation="border" />
                </div>
            ) : (
                <>
                    {error && <Alert message={error} isError={true} />}
                    {message && <Alert message={message} isError={false} />}
                    <NewKioskDeviceForm
                        initialValues={initialKiosksValues}
                        categories={categories}
                        onSubmit={onSubmit}
                        buttonText={'Submit'}
                    />
                </>
            )}
        </>
    );
};

export default NewKioskDevice;

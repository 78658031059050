import { useAppSelector } from 'hooks';
import { serialize } from 'object-to-formdata';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Alert from 'sharedComponents/Alert';
import {
    cleanNewStoryMessageAction,
    getAdminsStoriesCategoriesRequestAction,
    postNewStoriesRequestAction,
} from 'store/actions/adminStories/adminStories.action';
import { existingValues } from 'utils/helpers';
import NewStoryForm from './NewStoryFrom';
import { IInitialStoriesValue, initialStoriesValues } from './NewStoryFrom/constants';

const NewStory = () => {
    const error = useAppSelector(state => state.adminStories.postNewStoryError);
    const message = useAppSelector(state => state.adminStories.message);
    const loading = useAppSelector(state => state.adminStories.loading);
    const categories = useAppSelector(state => state.adminStories.storyCategories);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAdminsStoriesCategoriesRequestAction());
        return () => {
            dispatch(cleanNewStoryMessageAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (values: IInitialStoriesValue) => {
        const formData = serialize(existingValues(values));
        dispatch(postNewStoriesRequestAction(formData));
    };

    return (
        <>
            {loading ? (
                <div className="container-fluid d-flex justify-content-center align-items-center h-100">
                    <Spinner animation="border" />
                </div>
            ) : (
                <>
                    {error && <Alert message={error} isError={true} />}
                    {message && <Alert message={message} isError={false} />}
                    <NewStoryForm
                        initialValues={initialStoriesValues}
                        categories={categories}
                        onSubmit={onSubmit}
                        buttonText={'Submit'}
                    />
                </>
            )}
        </>
    );
};

export default NewStory;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { TStatistics } from 'types/shared';
import { TCategoryItem } from 'types/category';
import { storyApi } from './api';

export const getStoryStatistics = createAsyncThunk<
    TStatistics,
    void,
    { rejectValue: string }
>('portalStory/getStatistics', async (_, { rejectWithValue }) => {
    const response = await storyApi.getStatistics();
    if (response.success) return response.data;
    return rejectWithValue(response.error);
});

export const getStoryCategories = createAsyncThunk<
    TCategoryItem[],
    void,
    { rejectValue: string }
>('portalStory/getCategories', async (_, { rejectWithValue }) => {
    const response = await storyApi.getStoryCategories();
    if (response.success) return response.data;
    return rejectWithValue(response.error);
});

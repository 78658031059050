import { useField } from 'formik';
import React, { useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';

import styles from './AudioInput.module.scss';

interface IAudioProps {
    fieldName: string;
}

const AudioInput: React.FC<IAudioProps> = ({ fieldName }) => {
    const [audioFile, setAudioFile] = useState<File | undefined>(undefined);
    const [, meta, helpers] = useField(fieldName);

    const { initialValue } = meta;
    const { setValue } = helpers;

    const initialFile = audioFile
        ? audioFile.name
        : initialValue && `${process.env.REACT_APP_URL}/${initialValue}`;

    return (
        <div className="form-control d-flex justify-content-center w-100">
            {!audioFile ? (
                <>
                    {initialFile ? (
                        <>
                            <ReactAudioPlayer
                                className={styles.player}
                                src={initialFile}
                                controls
                            />
                        </>
                    ) : (
                        <div
                            className={
                                'w-100 d-flex justify-content-center align-items-center'
                            }>
                            Upload Audio File
                        </div>
                    )}
                    <label className={`${styles.label} btn btn-secondary`}>
                        Upload
                        <input
                            className={styles.input}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setValue(e?.target?.files?.[0]);
                                setAudioFile(e?.target?.files?.[0]);
                            }}
                            type="file"
                            accept=".mp3"
                        />
                    </label>
                </>
            ) : (
                <>
                    <p className={styles.file_name}>{audioFile.name}</p>
                    <button
                        onClick={() => {
                            setValue(initialValue);
                            setAudioFile(undefined);
                        }}
                        className="btn btn-secondary">
                        Cancel
                    </button>
                </>
            )}
        </div>
    );
};

export default AudioInput;

import { TApiResponse } from 'types/api';
import { TSubscribeData, TSubscribeResponse } from 'store/portal/emailStorage/types';
import { axiosInstance } from 'configs/axiosConfig';

const subscribe = async (data: TSubscribeData): Promise<TApiResponse<TSubscribeResponse>> => {
    return axiosInstance.post('/email-storage/save', data);
};

export const emailStorageApi = {
    subscribe,
};

import { useEffect, useState } from 'react';
import AdminTopPanel from 'adminComponents/AdminTopPanel/AdminTopPanel';
import AdminFilterPanel from 'adminComponents/AdminFilterPanel/AdminFilterPanel';
import PodcastsTable from './PodcastsTable/PodcastsTable';
import { podcastsTableHead } from './constants';
import Pagination from 'adminComponents/Pagination/Pagination';
import { useHistory } from 'react-router';
import Paths from 'constants/paths';
import { useDispatch } from 'react-redux';
import {
    fetchAdminPodcastsRequestAction,
    getAdminsPodcastsCategoriesRequestAction,
    cleanNewPodcastMessageAction,
    getAdminsPodcastsExportToCsvRequestAction,
} from 'store/actions/adminPodcasts/adminPodcasts.action';
import { useAppSelector } from 'hooks';
import { Spinner } from 'react-bootstrap';
import Alert from 'sharedComponents/Alert';
import { IFilterParams } from 'models/filters';
import { saveAs } from 'file-saver';

const Podcasts = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        loading,
        adminPodcasts,
        podcastsCategories,
        page,
        perPage,
        postNewPodcastError: error,
        message,
        totalNumber,
        csv,
    } = useAppSelector(state => state.adminPodcasts);
    const lastPage = Math.ceil(totalNumber / perPage);
    const [filterParams, setFilterParams] = useState<IFilterParams>({
        page: 0,
        searchWord: '',
        categoryId: '',
    });

    useEffect(() => {
        const { page, categoryId, searchWord } = filterParams as IFilterParams;
        dispatch(getAdminsPodcastsCategoriesRequestAction());
        dispatch(fetchAdminPodcastsRequestAction(page, searchWord, categoryId));
        return () => {
            dispatch(cleanNewPodcastMessageAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterParams]);

    useEffect(() => {
        dispatch(getAdminsPodcastsExportToCsvRequestAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adminPodcasts]);

    const changePageHandler = (newPage: number): void => {
        const { categoryId, searchWord } = filterParams as IFilterParams;
        dispatch(fetchAdminPodcastsRequestAction(newPage, searchWord, categoryId));
    };

    const exportCSV = () => {
        if (!csv) return;
        const fileData = new Blob([csv], { type: 'text/csv' });
        saveAs(fileData, 'podcasts');
    };

    return (
        <>
            {error && <Alert message={error} isError={true} />}
            {message && <Alert message={message} isError={false} />}
            <AdminTopPanel
                butonText={'New Podcast'}
                buttonOnClick={() => history.push(Paths.ADMIN_NEW_PODCAST)}
                exportButtonOnClick={exportCSV}
            />
            <AdminFilterPanel
                options={podcastsCategories}
                params={filterParams}
                setFilterParams={setFilterParams}
                totalNumber={totalNumber}
            />
            {loading ? (
                <div className="container-fluid d-flex justify-content-center align-items-center mt-5">
                    <Spinner animation="border" />
                </div>
            ) : (
                <div className="App-table-default-wrapper">
                    <div className="table-responsive">
                        <PodcastsTable
                            params={filterParams}
                            tableHead={podcastsTableHead}
                            tableBody={adminPodcasts}
                            page={page}
                        />
                        <Pagination
                            currentPage={page}
                            changePageHandler={changePageHandler}
                            lastPage={lastPage}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default Podcasts;

export interface ITableHeadItem {
    title: string;
    key: string;
}

export const kioskTableHead: ITableHeadItem[] = [
    {
        title: '#',
        key: '#',
    },
    {
        title: 'Description',
        key: 'podcast_descrioption',
    },
    {
        title: 'City',
        key: 'podcast_title_ar',
    },
    {
        title: 'Kiosk Group',
        key: 'publisherNameAr',
    },
    {
        title: 'Details',
        key: 'details',
    },
    {
        title: 'Inactive',
        key: 'delete',
    },
    {
        title: 'Delete',
        key: 'delete_podcast',
    },
];

import React, { FC, memo, useState } from 'react';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import buttonBg from 'assets/images/socialMediaShareBg 1.svg';
import shareIcon from 'assets/icons/share.svg';
import twitterIcon from 'assets/icons/twitter.svg';
import facebookIcon from 'assets/icons/facebook.svg';
import whatsappIcon from 'assets/icons/whatsapp.svg';
import twitterIconLight from 'assets/images/twitter.svg';
import facebookIconLight from 'assets/images/facebook.svg';
import whatsappIconLight from 'assets/images/whatsapp.svg';
import styles from './SocialMediaShare.module.scss';

type TProps = {
    url: string;
    text: string;
    darkMode?: boolean;
};

const SocialMediaShare: FC<TProps> = ({ 
    url,
    text,
    darkMode = false 
}) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    
    const toggleButton = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <div className={styles.wrapper}>
            <button className={styles.button} onClick={toggleButton}>
                <img src={buttonBg} alt="" className={styles.buttonBg} />
                <div className={styles.buttonContent}>
                    <span className={styles.buttonText}>{t('share.button')}</span>
                    <img src={shareIcon} alt="" />
                </div>
            </button>
            <div className={classNames(styles.shareButtonsList, {
                    [styles.open]: isOpen,
                })}>
                <TwitterShareButton 
                    url={url} 
                    title={text}
                >
                    <div className={styles.shareButton}>
                        { !darkMode ? 
                            <img src={twitterIcon} alt="" /> : 
                            <img src={twitterIconLight} alt="" />}
                    </div>
                </TwitterShareButton>
                <FacebookShareButton url={url} title={text}>
                    <div className={styles.shareButton}>
                        { !darkMode ? 
                            <img src={facebookIcon} alt="" /> :
                            <img src={facebookIconLight} alt="" />
                        }
                    </div>
                </FacebookShareButton>
                <WhatsappShareButton url={url} title={text}>
                    <div className={styles.shareButton} >
                        { !darkMode ? 
                            <img src={whatsappIcon} alt="" /> :
                            <img src={whatsappIconLight} alt="" />
                        }
                    </div>
                </WhatsappShareButton>
            </div>
        </div>
    );
};

export default memo(SocialMediaShare);

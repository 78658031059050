import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    postNewLocationRequestActionType,
    FetchAdminMapDataRequestActionType,
    IMapDataListItem,
    DeleteAdminMapDataRequestActionType
} from 'store/actions/adminMapData/adminMapData.types';
import * as adminMapDataEndPoints from '../../services/admin.services/adminDataMaps/adminDataMaps.endpoints';
import * as AdminMapDataActions from '../actions/adminMapData/adminMapData.action';
import { AdminMapDataTypes } from 'store/actions/adminMapData/adminMapData.types';
import { IPaginationResponse } from 'services/types';
import { IFilterParams } from 'models/filters';

function* fetchAdminMapDataWorker(
    action: FetchAdminMapDataRequestActionType,
) {
    const values = action.payload;
    const filteredObj = Object.keys(values).reduce((acc, item) => {
        if (values[item as keyof IFilterParams] !== '') {
            return {
                ...acc,
                [item]: values[item as keyof IFilterParams],
            };
        } else return acc;
    }, {});
    const { page, searchWord, categoryId } = filteredObj as IFilterParams;

    try {
        const { data }: { data: IPaginationResponse<IMapDataListItem> } = yield call(
            adminMapDataEndPoints.getAdminMapDataListEndpoint,
            page,
            searchWord,
            categoryId,
        );
        yield put(AdminMapDataActions.fetchMapDataSuccessAction(data));
    } catch (error) {}
}

export function* postNewLocationWorker(action: postNewLocationRequestActionType) {
    try {
        yield call(adminMapDataEndPoints.postNewLocationEndpoint, action.payload);
        yield put(
            AdminMapDataActions.postNewLocationSuccessAction('Created Successfully'),
        );
    } catch (error: any) {
        yield put(
            AdminMapDataActions.postNewLocationFailureAction(error.message as string),
        );
    }
}
function* deleteAdminEmailsStorageWorker(action: DeleteAdminMapDataRequestActionType) {
    const id = action.payload.locationId;
    const { page, searchWord, categoryId } = action.payload.params;

    try {
        yield call(adminMapDataEndPoints.deleteMapDataEndpoint, id);
        yield put(AdminMapDataActions.deleteAdminEmailSuccessAction());
        yield put(
            AdminMapDataActions.fetchMapDataRequestAction(
                page,
                searchWord,
                categoryId,
            ),
        );
    } catch (error: any) {
        yield put(AdminMapDataActions.deleteAdminEmailFailureAction(error.message));
    }
}

export function* adminMapDataSaga() {
    yield all([
        takeLatest(
            AdminMapDataTypes.FETCH_ADMIN_MAP_DATA_REQUEST,
            fetchAdminMapDataWorker,
        ),
        takeLatest(AdminMapDataTypes.POST_NEW_MAP_DATA_REQUEST, postNewLocationWorker),
        takeLatest(AdminMapDataTypes.DELETE_ADMIN_MAP_DATA_REQUEST, deleteAdminEmailsStorageWorker),
    ]);
}

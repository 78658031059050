import { axiosInstance } from '../../interceptor';
import { IAdminKiosks } from './adminKiosks.types';
import { AxiosResponse } from 'axios';
import { IPaginationResponse } from 'services/types';
import { IKioskCategories } from 'store/actions/adminKiosks/adminKiosks.types';
import { INewKiosk } from 'adminComponents/NewKioskDevice/NewKioskDeviceForm/constants';

export const getAdminKiosksEndpoint = (
    page = 0,
    searchWord: string,
    categoryId: string,
    perPage = 30,
): Promise<AxiosResponse<IPaginationResponse<IAdminKiosks>>> => {
    return axiosInstance.get('/device', {
        params: { page, perPage, categoryId, searchWord },
    });
};

export const deactivateAdminKiosksEndpoint = (
    adminKioskId: string,
    page: number,
): Promise<AxiosResponse> => {
    return axiosInstance.delete(`device/${adminKioskId}`, { params: { page } });
};

export const deleteAdminKiosksEndpoint = (
    adminKioskId: string,
): Promise<AxiosResponse> => {
    return axiosInstance.delete(`device/${adminKioskId}`);
};

export const activateAdminKiosksEndpoint = (
    adminKioskId: string,
    page: number,
    value: boolean,
): Promise<AxiosResponse> => {
    return axiosInstance.put(`device/${adminKioskId}`, {
        isDisabled: value,
    });
};

export const getAdminsKiosksExportToCsvEndpoint = (
    val: boolean,
): Promise<AxiosResponse<string>> => {
    return axiosInstance.get(`statistic-device/admin/export-to-csv/${val}`);
};

export const getAdminKiosksCategoriesEndpoin = (): Promise<
    AxiosResponse<IKioskCategories[]>
> => {
    return axiosInstance.get('/audio-categories');
};

export const postNewKioskEndpoint = (
    newKiosk: INewKiosk,
): Promise<AxiosResponse<string>> => {
    const json = JSON.stringify(newKiosk);
    return axiosInstance.post('device', json, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

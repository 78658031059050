import React from 'react';
import { ReactComponent as SettingIcon } from 'assets/icons/settings.svg';
import styles from './SettingBar.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface ISettingBar {
    handleClick(): void;
    decreaseFonSize(): void;
    increaseFonSize(): void;
    setDarkTheme: React.Dispatch<React.SetStateAction<boolean>>;
    contextMenuOpen: boolean;
    darkMode?:boolean;
}
const SettingBar = ({
    handleClick,
    decreaseFonSize,
    increaseFonSize,
    setDarkTheme,
    contextMenuOpen,
    darkMode
}: ISettingBar) => {
    const { t } = useTranslation();
    return (
        <>
            <div className={styles.btnWrapper} onClick={handleClick}>
                <SettingIcon className={styles.settingIcon} />
                <button className={styles.button}>{t('story-detail-page.link')}</button>
            </div>
            {contextMenuOpen && (
                <div className={styles.settingWrapper}>
                    <div className={styles.fontSizeBtn}>
                        <div className={styles.optionName}>
                            {t('story-detail-page.fontSizeOption')}
                        </div>
                        <button className={styles.optionButton} onClick={decreaseFonSize}>
                            {t('story-detail-page.contextSettingTheme')}-
                        </button>
                        <button className={styles.optionButton} onClick={increaseFonSize}>
                            {t('story-detail-page.contextSettingTheme')}+
                        </button>
                    </div>

                    <div className={styles.themeBtn}>
                        <div className={styles.optionName}>
                            {t('story-detail-page.themeOption')}
                        </div>
                        <button
                        className={classNames(styles.optionButtonThemeLight, {
                            [styles.optionButtonThemeLightActive]: !darkMode,
                        })}
                            onClick={() => setDarkTheme(false)}>
                            {t('story-detail-page.contextSettingTheme')}
                        </button>
                        <button
                        className={classNames(styles.optionButtonThemeDark, {
                            [styles.optionButtonThemeDarkActive]: darkMode,
                        })}
                            onClick={() => setDarkTheme(true)}>
                            {t('story-detail-page.contextSettingTheme')}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default SettingBar;

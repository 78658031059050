import React, { FC, useMemo } from 'react';
import CountUp from 'react-countup';
import classNames from 'classnames';
import { TStatisticsItem } from 'types/shared';
import styles from './StatiscticsContent.module.scss';

type TProps = {
    type: 'podcast' | 'story';
    total: {
        onClick: () => void;
        title: string;
        count: number;
    };
    items: TStatisticsItem[];
};

const StatisticsContent: FC<TProps> = ({ type, total, items }) => {
    const sortedItems = useMemo(
        () => [...items].sort((a, b) => a.sort - b.sort),
        [items],
    );

    return (
        <div className={styles.wrapper}>
            <button className={styles.totalButton} onClick={total.onClick}>
                <div className={styles.totalContent}>
                    <span className={classNames(styles.totalCount, type)}>
                        {total.count.toLocaleString('en-US')}
                    </span>
                    <span className={styles.totalTitle}>{total.title}</span>
                </div>
            </button>
            <div className={styles.itemsWrapper}>
                {sortedItems.map((item, index) => (
                    <div className={styles.item} key={index}>
                        <p className={classNames(styles.itemCounter, type)}>
                            <CountUp end={item.total} duration={1} />
                        </p>
                        <p className={styles.itemTitle}>{item.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StatisticsContent;

export interface ITableHeadItem {
    title: string;
    key: string;
}

export const mapDataTableHead: ITableHeadItem[] = [
    {
        title: '#',
        key: '#',
    },
    {
        title: 'Title | Ar',
        key: 'map_title_ar',
    },
    {
        title: 'Title | En',
        key: 'map_title_en',
    },
    {
        title: 'Type',
        key: 'map_type_en',
    },
    {
        title: 'Details',
        key: 'details',
    },
    {
        title: 'Delete',
        key: 'delete_location',
    },
];

import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import storyLogo from 'assets/images/storyLogo.svg';
// import childrenStoryIcon from 'assets/images/childrenStoryIcon.png';
import PageLayout from 'layouts/PageLayout';
import {
    getRandomStoryAction,
    getShortStoryCategoryAction,
} from 'store/actions/portalActions/portal.actions';
import { useAppSelector } from 'hooks';
import { useAnalytics } from 'utils/googleAnalytics';
import styles from './StoryCategories.module.scss';
import CategoriesContent from 'portalComponents/CategoriesContent';
import Paths from 'constants/paths';
// import Button from 'portalComponents/Button';
import { Link } from 'react-router-dom';
import IconButton from 'portalComponents/IconButton';
import { ReactComponent as MapIcon } from 'assets/icons/map.svg';

const StoryCategories = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [setPageName] = useAnalytics();
    const { shortStoryAndPodcastCategories, loaded } = useAppSelector(
        state => state.portal,
    );

    useEffect(() => {
        dispatch(getShortStoryCategoryAction.request(false));
        setPageName('Stories Categories');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCardClick = useCallback((id: string) => {
        dispatch(getRandomStoryAction.request(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageLayout
            showHeader
            headerProps={{
                variant: 'normal',
                languageSelect: { variant: 'story' },
                logo: <img src={storyLogo} alt="Story logo" />,
                content: {
                    leftRenderer: () => (
                        <div className={styles.headerButtons}>
                            <Link to={Paths.MAP}>
                                <IconButton height="small">
                                    <MapIcon />
                                </IconButton>
                            </Link>
                            {/*<Link to={Paths.CHILDREN_STORY_CATEGORIES}>*/}
                            {/*    <Button*/}
                            {/*        startIcon={<img src={childrenStoryIcon} alt="Children Story logo" />}*/}
                            {/*    >*/}
                            {/*        {t('story.categories.childrenContent')}*/}
                            {/*    </Button>*/}
                            {/*</Link>*/}
                        </div>
                    ),
                },
            }}
            showFooter
            footerProps={{
                variant: 'light',
            }}
            contentClassName={styles.wrapper}>
            <CategoriesContent
                title={t('categories.title')}
                loading={!loaded}
                variant="story"
                categories={shortStoryAndPodcastCategories}
                onCardClick={handleCardClick}
                statisticsPath={Paths.STORY_STATISTICS}
            />
        </PageLayout>
    );
};

export default StoryCategories;

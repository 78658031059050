import { createAsyncThunk } from '@reduxjs/toolkit';
import { TSubscribeData, TSubscribeResponse } from 'store/portal/emailStorage/types';
import { emailStorageApi } from 'store/portal/emailStorage/api';
import { emailStorageSlice } from 'store/portal/emailStorage/slice';

export const emailStorageSubscribe = createAsyncThunk<TSubscribeResponse,
    { data: TSubscribeData, onSuccess: () => void },
    { rejectValue: string }>(
    'portalEmailStorage/subscribe',
    async ({ data, onSuccess }, { rejectWithValue }) => {
        const response = await emailStorageApi.subscribe(data);

        if (response.success) {
            onSuccess();
            return response.data;
        }

        return rejectWithValue(response.error);
    }
);

export const resetEmailStorageSubscribe = emailStorageSlice.actions.resetSubscribeState;

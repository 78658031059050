import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Paths from 'constants/paths';
import Button from 'portalComponents/Button';
import styles from './CategoriesNavigation.module.scss';

export type TCategoriesNavigationProps = {
    variant: 'story' | 'podcast';
    statisticsPath: Paths;
};

const CategoriesNavigation: FC<TCategoriesNavigationProps> = ({
    variant,
    statisticsPath,
}) => {
    const { t } = useTranslation();

    return (
        <div className={styles.wrapper}>
            <Link to={statisticsPath}>
                <Button variant={'podcast'}>{t('footer.statisticButton')}</Button>
            </Link>
            <Link to={Paths.HOME}>
                <Button>{t('footer.homeButton')}</Button>
            </Link>
            <a
                href="https://engage.moc.gov.sa/content_creators"
                target="_blank"
                rel="noopener noreferrer">
                <Button variant={'story'}>{t('footer.creatorsButton')}</Button>
            </a>
        </div>
    );
};

export default CategoriesNavigation;

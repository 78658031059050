import React, { ChangeEventHandler, FC, memo, FocusEvent, ReactNode } from 'react';
import styles from './Input.module.scss';
import { ReactComponent as InputBorder } from 'assets/images/inputBorder.svg';
import classNames from 'classnames';

type TInputProps = {
    value: string;
    label: string;
    name: string;
    touched: boolean | undefined;
    hasError: boolean | undefined;
    error: string | undefined;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onBlur: (event: FocusEvent<HTMLInputElement>) => void;
    type?: 'text' | 'email' | 'tel';
    startContent?: ReactNode;
    borderVariant?: 'primary' | 'secondary';
}

const Input: FC<TInputProps> = ({
    value,
    label,
    name,
    touched,
    hasError,
    error,
    onChange,
    onBlur,
    type = 'text',
    startContent,
    borderVariant = 'primary',
}) => (
    <div className={styles.inputControl}>
        <div
            className={classNames(styles.inputWrapper, {
                [styles.error]: touched && hasError,
            })}
        >
            <div className={classNames(styles.inputBorder, borderVariant)}>
                <InputBorder />
            </div>
            <span className={styles.inputLabel}>{label}</span>
            <div
                className={styles.inputContent}
                dir={type === 'tel' ? 'ltr' : undefined}
            >
                {startContent && (
                    <span className={styles.startContent}>{startContent}</span>
                )}
                <input
                    className={styles.input}
                    type={type}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            </div>
        </div>
        {touched && error && <span className={styles.errorText}>{error}</span>}
    </div>
);

export default memo(Input);
